.s-onboarding-register-setup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 337px;
  animation: email-sent-entry 400ms ease-in-out;

  h1 {
    font-size: 24px;
    font-family: 'Montserrat', serif;
    font-weight: 500;
    text-align: center;
    margin: 42px 0 75px 0;
  }

  .btn-sign-up {
    margin-top: 75px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  form {
    width: 100%;

    .email-label,
    .password-label {
      margin: 8px 0;
    }
    .password-label {
      margin-top: 24px;
    }

    .input-group input {
      border-radius: 12px;
      padding: 17px 20px;
      font-size: 16px;
      &:focus {
        box-shadow: inset 0 0 0 2px #e9effd;
        border-color: #88abff;
      }
    }
    .password-requirements {
      display: flex;
      margin-top: 8px;

      .requirement-item {
        margin-right: 8px;
        display: flex;
        align-items: center;
        font-size: 12px;
        &.is-ok {
          i {
            color: #29a835;
            border-color: #29a835;
          }
        }

        i {
          padding: 3px;
          border-radius: 50%;
          border: 1px solid rgba(0, 0, 0, 0.15);
          color: transparent;
          font-size: 8px;
          margin-right: 4px;
          transition: all 200ms ease-in-out;
        }
      }
    }
  }

  .tos {
    position: fixed;
    bottom: 36px;
    max-width: 324px;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;

    a {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 500;
      &:hover {
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
  }
}

.s-onboarding-register-setup.is-exiting {
  animation: onboarding-setup-password-leave 500ms ease-in-out forwards;
}

@-webkit-keyframes email-sent-entry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes email-sent-entry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes onboarding-setup-password-leave {
  0% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  100% {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 50, 0);
  }
}
@keyframes onboarding-setup-password-leave {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
