.trigger-manager-wrapper {
  display: flex;
  flex-direction: column;
  z-index: 2;
  min-height: 0;
  overflow: hidden;
  background: #fff;
  flex-grow: 1;

  .trigger-manager {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 100%;
    min-height: 0;
    padding: 16px 0;

    .trigger-manager-header {
      display: flex;
      padding: 0 16px;

      .trigger-manager-title {
        margin: auto 0;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
      }

      i {
        cursor: pointer;
        margin-left: auto;
        color: #9598a9;
        padding: 4px;
      }
    }

    .triggers-content {
      padding: 0 16px;
      min-height: 0;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .default-wrapper {
        padding: 16px;
        border-radius: 12px;
        border: 1px solid #d9d9d9;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          gap: 8px;

          i {
            font-size: 12px;
          }
          .jimo-select {
            flex: 1 1 auto;
            width: 220px;
          }

          .jimo-select__menu {
            width: inherit;
          }

          .jimo-select {
            .jimo-select__value-container {
              padding: 8px;
              font-size: 12px;
            }

            .jimo-select__placeholder {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
