@import '../../../../Colors';

.s-settings-billing {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .title-3 {
    margin-bottom: 8px;
  }

  .card-current-plan {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .billing-plan-details {
    border-radius: 16px;
    border: 1px solid var(--n-300);
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &.plan-startup {
      .plan-title {
        color: var(--g-500);
      }
    }
    &.plan-growth {
      .plan-title {
        color: var(--o-500);
      }
    }
    &.plan-scale {
      .plan-title {
        color: var(--b-500);
      }
    }

    .main-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .addon-seats-wrapper {
      border-top: 1px solid var(--n-300);
      padding-top: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .addon-environments-wrapper {
      border-top: 1px solid var(--n-300);
      padding-top: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .addon-trackers-wrapper {
      border-top: 1px solid var(--n-300);
      padding-top: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .addon-mau-wrapper {
      border-top: 1px solid var(--n-300);
      padding-top: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .addon-white-label-wrapper {
      border-top: 1px solid var(--n-300);
      padding-top: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .left-side {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .plan-logo-title-wrapper {
        display: flex;
        gap: 12px;
      }
      .plan-logo {
        width: 28px;
        height: 28px;
        background-size: cover;
        background-position: center;
      }
      .plan-title {
        text-transform: capitalize;
      }
    }
    .right-side {
      display: flex;
      gap: 16px;
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .plan-details {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;

      .item-detail {
        border-radius: 12px;
        padding: 12px 16px;
        border: 1px solid var(--n-300);
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  .card-mau-usage {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &.exceeded {
      .card-header {
        .card-title {
          color: var(--r-500);
        }
      }
      .line-progress {
        .tracking {
          background-color: var(--r-300) !important;
        }
      }
    }
    &.percent90 {
      .card-header {
        .card-title {
          color: var(--o-500);
        }
      }
      .line-progress {
        .tracking {
          background-color: var(--o-400) !important;
        }
      }
    }

    .card-header {
      display: flex;
      justify-content: space-between;

      .card-title {
        display: flex;
        align-items: center;
        gap: 8px;

        i {
          font-size: 18px;
        }
      }
      .left-side {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .right-side {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        gap: 8px;
      }
    }
    .line-progress {
      .base {
        border-radius: 21px;
        background-color: var(--n-100) !important;
      }
      .tracking {
        border-radius: 21px;
        background-color: var(--b-200) !important;
      }
    }
    .card-body {
      gap: 8px;

      .additional-wrapper {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .card-saved-payment-method {
    width: 100%;
    box-sizing: border-box;

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .body-3 {
        display: flex;
        align-items: center;
        gap: 4px;
      }
      i {
        font-size: 18px;
      }
      .left-side {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .payment-method-brand {
        text-transform: capitalize;
      }
    }
  }

  .card-invoices {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .invoices-empty {
      border-radius: 16px;
      background-color: var(--n-50);
      padding: 16px 20px;
      text-align: center;
    }
    .invoice-list {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .list-header,
      .item-invoice {
        display: grid;
        grid-template-columns: 1fr 0.6fr 1fr 1fr;
      }
    }
  }
}
