.steps-list-adoption {
  display: flex;
  background: rgba(206, 206, 206, 0.2);
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  padding: 4px;
  align-items: center;
  gap: 6px;

  .group {
    display: flex;
    height: 42px;
    box-sizing: border-box;
    align-items: center;

    &.is-group {
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.05);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
      border-radius: 12px;
      padding: 0 3px;

      .tour-step {
        height: 34px;
        width: 34px;
      }
    }

    &.navigation {
      .tour-step {
        background: transparent;
        border-color: transparent;
      }
    }

    .tour-step {
      background: #fff;
      position: relative;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border: 2px solid rgba(0, 0, 0, 0.07);
      border-radius: 12px;
      display: flex;

      &.selected {
        background: rgba(18, 96, 235, 0.1);
        border: 2px solid #1260eb;
      }

      &.has-prev-step {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: 1px;
      }

      &.has-next-step {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: 1px;
      }

      i {
        margin: auto;
        font-size: 16px;
      }

      .step-preview-wrapper {
        display: none;
      }
    }
  }

  .group-separator {
    color: rgba(0, 0, 0, 0.2);
    font-size: 14px;
    margin: auto 10px;
  }
}

.steps-list-discovery {
  display: flex;
  border-radius: 16px;
  background: rgba(206, 206, 206, 0.2);
  padding: 4px;
  align-items: center;
  gap: 6px;

  .block-wrapper {
    display: flex;
    gap: 8px;

    &.is-step-concept {
      grid-gap: 0;
      gap: 0;
      &.is-sub-concept-step-selected {
        .block-selector {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border: 2px solid rgba(18, 96, 235, 0.2) !important;
        }
        .sub-blocks-wrapper {
          border: 2px solid rgb(18, 98, 235, 0.2);
          border-left: 0px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      &.is-parent-concept-step-selected {
        .block-selector {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        .sub-blocks-wrapper {
          border: 2px solid rgba(18, 98, 235, 0.2);
          border-left: 0px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    .block-selector {
      position: relative;
      cursor: pointer;
      display: flex;
      padding: 8px;
      border-radius: 12px;
      background: #ffffff;
      border: 2px solid rgba(0, 0, 0, 0.1);
      height: 44px;
      width: 44px;
      box-sizing: border-box;

      &.selected {
        background: #e6f5fd;
        border: 2px solid rgba(18, 96, 235, 1) !important;
      }
      &.invalid {
        border-style: dashed !important;
        border-color: #ffa5a5 !important;
      }
      .block-type-icon {
        font-size: 24px;
        display: flex;

        i {
          margin: auto;
        }
      }
    }

    .sub-blocks-wrapper {
      display: flex;
      border-radius: 16px;
      padding-right: 6px;
      align-items: center;

      &.is-sub-concept-step-selected {
        border: 2px solid rgba(18, 96, 235, 1);
        background-color: blue;
      }
      &.is-parent-concept-step-selected {
        border: 2px solid rgba(18, 96, 235, 1);
        background-color: red;
      }

      .sub-block-selector-wrapper {
        margin-left: 8px;
        height: 32px;
        width: 32px;

        .sub-block-selector {
          position: relative;
          cursor: pointer;
          display: flex;
          border-radius: 12px;
          background: #ffffff;
          border: 2px solid rgba(0, 0, 0, 0.1);
          height: 32px;
          width: 32px;
          box-sizing: border-box;

          &.selected {
            background: #e6f5fd;
            border: 2px solid rgba(18, 96, 235, 1) !important;
          }
          .block-type-icon {
            font-size: 18px;
            display: flex;
            margin: auto;

            i {
              margin: auto;
            }
          }
        }
      }

      .sub-list-dropdown-trigger {
        margin: auto;
        cursor: pointer;

        .btn-add-sub-block {
          margin-left: 8px;
          cursor: pointer;
          background: rgba(18, 96, 235, 0.1);
          border-radius: 12px;
          display: flex;
          height: 32px;
          width: 32px;
          box-sizing: border-box;

          i {
            margin: auto;
            font-size: 13px;
            color: #1260eb;
          }
        }
      }
    }
  }
}
