.attribute-value {
  display: flex;
  height: 50px;
  gap: 8px;

  .input-group {
    display: flex;

    .input {
      min-width: 200px;
    }

    &.labeled-right {
      .input {
        min-width: 92px;
      }
    }
  }

  .react-datepicker-wrapper {
    display: flex;

    .react-datepicker__input-container {
      display: flex;

      .reset-value-btn {
        cursor: pointer;

        i {
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
  }
}

.input-values-str {
  .loader {
    opacity: 1;
    transition: all 100ms ease-in-out;
    &.hidden {
      opacity: 0;
    }
  }
}

.input-group {
  .autocomplete-results {
    border-radius: 16px;
    font-size: 16px;

    .menu-item {
      border-radius: 12px !important;
    }
  }
}
