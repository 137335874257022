@import '../../../../Colors';

.s-settings-profile {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .title-3 {
    margin-bottom: 8px;
  }

  .card-infos {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .profile-user-avatar {
      display: flex;

      .left-wrapper,
      .right-wrapper {
        display: flex;
      }

      .right-wrapper {
        flex: 1;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
      }

      .user-avatar {
        width: 52px;
        height: 52px;
        border-radius: 12px;
        font-size: 30px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        margin-right: 16px;
        background-size: cover;
        background-color: transparent;
      }

      .label-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
  .card-email-password {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .profile-email,
    .profile-password {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
  .card-delete {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}

.modal-delete-account {
  p {
    display: block;
    padding: 24px 24px 0 24px;
    margin: 0;
  }
}
