.template-iframe-preview {
  height: 90%;
  width: 90%;
  overflow: hidden;

  iframe {
    height: 100%;
    width: 100%;
    border: 0;
  }
}
