@import './../../Colors';

$jimoNavbarWidth: 262px;

.jimo-navbar {
  width: $jimoNavbarWidth;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex: 0 0 $jimoNavbarWidth;
  flex-direction: column;
  box-sizing: border-box;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  .navbar-beta-label {
    background-color: #1260eb;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 8px 8px;
    text-align: center;
    color: #fff;
    font-size: 12px;
  }
  .header {
    margin: 16px 0;
    height: 58px;

    .nav-project-logo {
      width: 36px;
      height: 36px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border: 1px solid var(--n-300);
      border-radius: 12px;
      justify-content: center;
      align-items: center;
      display: flex;
      font-size: 18px;
      color: #f0f2f4;
      text-transform: uppercase;
      font-weight: 500;
    }

    .navbar-dropdown-trigger {
      .content-wrapper {
        display: flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex: 1 0 0;
        cursor: pointer;

        .content {
          display: flex;
          align-items: center;
          gap: 8px;
          flex: 1 0 0;

          i {
            margin-left: auto;
            font-size: 14px;
          }

          .names {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            flex: 1 0 0;
          }
        }
      }
    }
    .jimo-icon-wrapper {
      cursor: pointer;
    }
  }

  .menu-wrapper {
    font-weight: 500;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
    overflow-y: auto;

    .main-menu {
      padding: 0 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      i {
        font-size: 24px !important;
      }
      .section {
        display: flex;
        padding: 4px;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        align-self: stretch;

        & > * {
          align-self: stretch;
        }
      }

      .divider {
        width: calc(100% - 20px);
      }

      i {
        font-size: 18px;
      }
      i.icon-portal {
        font-size: 24px;
      }

      i.icon-chevron-right {
        margin-left: auto;
        font-size: 12px;
        transition: transform 200ms ease-in-out;

        &.open {
          transform: rotate(90deg);
        }
      }
    }

    .bottom-menu {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-self: stretch;
      padding: 8px;

      .support-menu-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;

        & > * {
          flex: 1 1 auto;
        }

        .circular-wrapper {
          display: flex;
          width: 40px;
          min-width: 40px;
          margin-right: 14px;

          .circular-progress {
            margin: auto;
            width: 24px;
            height: 24px;
          }
        }
      }

      i {
        display: block;
        font-size: 24px;
        margin-right: 0;
        color: #4d637b;
      }
      .emoji {
        margin-right: 10px;
      }
      .menu-item {
        display: flex;
        padding: 12px 8px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        border-radius: 16px;
        background: var(--n-50);
        color: var(--n-700);
        transition: 200ms all ease-in-out;

        &:hover {
          background: var(--n-100);

          i {
            color: var(--n-800);
          }
        }

        .icon-wrapper {
          width: unset;
          margin-right: 0;
        }
        .whats-new-badge {
          position: absolute;
          left: 37px;
          top: 26px;
          width: 10px;
          height: 10px;
          background-color: #ff4545;
          border-radius: 50%;
        }
      }
      .dropdown-trigger {
        display: block !important;
      }
    }

    .menu-item {
      position: relative;
      background-color: none;
      transition: 100ms all ease-in-out;
      color: #111c39;
      border-radius: 8px;
      font-weight: 400;
      font-size: 16px;
      padding: 12px;
      border-radius: 12px;
      height: 48px;
      box-sizing: border-box;

      &:hover {
        color: #1260eb;
        background: rgba(18, 96, 235, 0.05);
      }
      &.coming-soon {
        pointer-events: none;
        position: relative;
        color: rgba(13, 13, 13, 0.3);
        cursor: default;
        &:hover {
          background-color: transparent;
        }

        .label {
          margin-left: 8px;
        }
      }
      &.is-active {
        font-weight: 600;
        color: #1260eb;
        background: rgba(18, 96, 235, 0.05);
      }
      &.navbar-get-started-link {
        background-color: transparentize($color: $colorPrimary, $amount: 0.5);
        color: #fff;
        &:hover {
          background-color: $colorPrimary;
        }
        &.is-active {
          background-color: $colorPrimary;
        }

        i {
          margin-bottom: 8px;
        }
      }
      &.navbar-get-started {
        padding: 12px;
        justify-content: center;

        .text-icon-wrapper {
          display: flex;
          justify-content: space-between;
          flex: 1;
          align-items: center;

          i {
            padding: 8px;
            font-size: 12px;
            transition: all 100ms ease-in-out;
            &.rotated {
              transform: rotate(180deg);
            }
          }
        }

        .progress-label {
          display: flex;
          padding: 4px 8px;
          align-items: center;
          gap: 8px;
          border-radius: 16px;
          background: var(--blue-400-main, #1260eb);
          color: var(--neutral-white, var(--neutral-neutral-0, #fff));
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 18px */
        }
      }

      .icon-wrapper {
        display: flex;
        width: 40px;
        margin-right: 14px;

        i {
          margin: auto;
        }

        .circular-progress {
          position: relative;
          margin: auto;
        }

        .circular-progress-wrapper {
          margin: auto;
          height: 24px;
          width: 24px;
        }
      }
    }
  }
  .navbar-get-started-sub-menu {
    .menu-item {
      padding-left: 76px;
      padding-top: 10px;
      padding-bottom: 10px;
      color: rgba(0, 0, 0, 0.5);
      &:hover {
        color: rgba(0, 0, 0, 0.8);

        .circular-progress {
          opacity: 0.5;
        }
      }
      &.is-active {
        color: rgba(0, 0, 0, 1);

        .circular-progress {
          opacity: 1;
        }
      }
      &.is-disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
    .navbar-numbered-circular-progress {
      position: absolute;
      top: 4px;
      left: 8px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      &.is-one {
        span {
          left: 27px;
          top: 7px;
        }
      }

      .circular-progress {
        margin-right: 0 !important;
        top: 0;
        opacity: 0.1;
      }
      span {
        position: absolute;
        left: 25px;
        top: 7px;
      }
    }
  }
  .icon-exclamation {
    padding: 0 6px;
  }
  .icon-goto {
    font-size: 8px;
    padding: 0 4px;
  }
  .app-version {
    color: rgba(0, 0, 0, 0.25);
    font-weight: 500;
    font-size: 8px;
    text-align: center;
  }
}

.jimo-navbar.compact {
  width: 70px;
  flex: 0 0 70px;

  .header {
    display: flex;

    .navbar-dropdown-trigger {
      margin: auto;

      .content-wrapper {
        display: flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex: 1 0 0;
        cursor: pointer;

        .content {
          display: flex;
          align-items: center;
          gap: 8px;
          flex: 1 0 0;
        }
      }
    }
  }
  .nav-project-logo {
    width: 36px;
    height: 36px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid var(--n-300);
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 18px;
    color: #f0f2f4;
    text-transform: uppercase;
    font-weight: 500;
  }
  .menu-wrapper {
    .divider {
      width: 100%;
    }

    .main-menu {
      padding: 0 4px;

      .navbar-get-started {
        padding: 12px 0;
      }
      .icon-wrapper {
        margin-right: 0;
      }
      .menu-item {
        &.coming-soon {
          &::before {
            display: none;
          }
        }
      }
    }
    .divider {
      margin: 0;
      width: calc(100% - 24px);
    }
  }
  .bottom-menu {
    display: none;
  }
}

.menu-dropdown-content {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.navbar-dropdown-content {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.04);
  border-radius: 12px !important;
  animation: fade-in 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.support-menu-tooltip-content {
  border-radius: 16px !important;
  max-width: 400px;

  .tooltip-children {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.menu-item.badge-new-content {
  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--r-400);
    top: 10px;
    right: 14px;
    animation: whats-new-animation 1.2s cubic-bezier(0.215, 0.61, 0.355, 1)
      infinite;
  }
}

@keyframes whats-new-animation {
  0% {
    box-shadow: 0 0 0 0px var(--r-400);
  }
  100% {
    box-shadow: 0 0 0 6px transparent;
  }
}
