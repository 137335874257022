.nps-container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    height: 30px;
    width: 30px;
    line-height: 1;

    background-color: #f3f3f3;
    border: none;
    border-radius: 8px;
    color: #333;
    font-size: 12px;
    transition: all ease 0.1s;

    @media only screen and (max-width: 400px) {
      width: 26px;
      height: 36px;
    }

    &:hover {
      background-color: #0077ff !important;
      color: white !important;
      transform: scale(1.25);
    }

    &.selected {
      border: 2px solid black;
      transform: scale(1.15);
    }

    &.detractor {
      background-color: #fff3f5;

      &:nth-child(1) {
        background-color: #ff9eae;
      }
      &:nth-child(2) {
        background-color: #ffafbc;
      }
      &:nth-child(3) {
        background-color: #ffafbc;
      }
      &:nth-child(4) {
        background-color: #ffc0cb;
      }
      &:nth-child(5) {
        background-color: #ffd1d9;
      }
      &:nth-child(6) {
        background-color: #ffe2e7;
      }
    }

    &.promoter {
      background-color: #e5ffe5;

      &:nth-child(1) {
        background-color: #adecba;
      }
      &:nth-child(2) {
        background-color: #5ad974;
      }
    }
  }
}
