.pixel-picker {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  .pixel-picker-input {
    flex: 1 1 0px;
    min-width: 0;

    .input-group {
      height: 40px;

      .input-main-wrapper {
        height: 100%;
      }

      .input {
        height: 100%;
        width: 64px;
        border-right: 0;
        border-radius: 12px 0 0 12px;
        padding: 8px;
      }
      .label-right {
        border-radius: 0 12px 12px 0;
        padding: 10px 4px;
        color: rgba(0, 0, 0, 0.2);
        font-size: 14px;
      }
      .label-left {
        border-radius: 12px 0 0 12px;
        display: flex;
        padding: 0;

        .icon-wrapper {
          margin: auto 4px;
          height: 32px;
          width: 32px;
          display: flex;
          border-radius: 12px;
          background: rgba(18, 96, 235, 0.1);

          i {
            margin: auto;
            font-size: 10px;
            color: #1260eb;
          }
        }
      }

      &.labeled-left {
        .input {
          width: 34px;
          border-radius: 0;
          border-left: 0;
          padding-left: 0;
        }
      }
    }
  }

  .pixel-picker-slider {
    flex: 1 1 0px;
    min-width: 0;
    display: flex;

    .rc-slider {
      margin: auto;

      .rc-slider-rail {
        height: 2px;
        background: rgba(0, 0, 0, 0.1);
      }

      .rc-slider-track {
        height: 2px;
        background: #1260eb;
      }

      .rc-slider-handle {
        width: 16px;
        height: 16px;
        margin-top: -8px;
        border-radius: 50%;
        border: 0;
        opacity: 1;
        background: #ffffff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
      }
    }
  }
}
