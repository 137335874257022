.z-index-picker {
  width: 100%;

  .input-group {
    height: 40px;
    width: 100%;

    .input {
      width: 100%;
      border-radius: 0 12px 12px 0;
    }
    .label-left {
      border-top-left-radius: 12px !important;
      border-bottom-left-radius: 12px !important;
      padding: 4px;
      display: flex;

      .icon-wrapper {
        margin: auto;
        height: 32px;
        width: 32px;
        display: flex;
        border-radius: 12px;
        background: rgba(18, 96, 235, 0.1);

        i {
          margin: auto;
          font-size: 10px;
          color: #1260eb;
        }
      }
    }

    &.labeled-left {
      .input {
        border-left: 0;
        padding-left: 0;
      }
    }
  }
}
