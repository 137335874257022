.animation-check {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #0f1ffd;
  fill: none;
  animation: jimo-animation-check-stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1)
      both,
    jimo-animation-check-out 0.3s cubic-bezier(0.65, 0, 0.45, 1) 2s both;
}
.checkmark {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #0f1ffd;
  animation: jimo-animation-check-fill 0.4s ease-in-out 0.4s both,
    jimo-animation-check-scale 0.3s ease-in-out 0.9s both,
    jimo-animation-check-out 0.3s cubic-bezier(0.65, 0, 0.45, 1) 2s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: jimo-animation-check-stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1)
      0.8s both,
    jimo-animation-check-out 0.3s cubic-bezier(0.65, 0, 0.45, 1) 2s both;
}

@keyframes jimo-animation-check-stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes jimo-animation-check-scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.3, 1.3, 1);
  }
}
@keyframes jimo-animation-check-fill {
  100% {
    box-shadow: inset 0px 0px 0px 80px #0f1ffd;
  }
}
@keyframes jimo-animation-check-out {
  100% {
    opacity: 0;
  }
}
