@import '../../shared/front/scss/Responsive';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(17, 17, 17, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
}
.modal {
  width: 100%;
  outline: none;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  &.is-light {
    background-color: #ffffff;
    color: #000000;
    border: none;
  }
  &.modal-confirm {
    font-weight: 200;
    padding: 0;

    .modal-title {
      padding: 24px;
      border-bottom: 1px solid var(--n-100);
      margin-bottom: 0;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 24px;
    }

    .content {
      padding: 24px;
      font-weight: 400;
      font-size: 18px;
    }

    .actions {
      padding: 0;
      margin-top: 0;
      gap: 8px;
      padding-top: 24px;
      justify-content: flex-end;
    }
  }

  .actions {
    display: flex;
    margin: 0 24px 24px 24px;
    justify-content: flex-end;
    gap: 8px;
  }

  .modal-title {
    display: flex;
    padding: 24px;
    border-bottom: 1px solid var(--n-100);
    margin-bottom: 0;

    .icon-close {
      margin: auto;
      margin-left: auto;
      margin-right: 0;
      font-size: 16px;
      padding: 4px;
      cursor: pointer;
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    padding: 24px;
  }

  .modal-footer {
    display: flex;
    padding: 12px 16px;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    border-top: 1px solid var(--n-100);
  }

  @media (min-width: 0) and (max-width: $screen-sm) {
    & {
      width: 60%;
      left: 20%;
      &.is-large {
        width: 80%;
        left: 10%;
      }
      &.is-small {
        width: 50%;
        left: 25%;
      }
    }
  }
  @media (min-width: $screen-sm) {
    & {
      width: 70%;
      left: 15%;
      &.is-large {
        width: 80%;
        left: 10%;
      }
      &.is-small {
        width: 50%;
        left: 25%;
      }
    }
  }
  @media (min-width: $screen-md) {
    & {
      width: 40%;
      left: 30%;
      &.is-large {
        width: 70%;
        left: 15%;
      }
      &.is-small {
        width: 30%;
        left: 35%;
      }
    }
  }
  @media (min-width: $screen-lg) {
    & {
      width: 30%;
      left: 35%;
      &.is-large {
        width: 50%;
        left: 25%;
      }
      &.is-small {
        width: 20%;
        left: 40%;
      }
    }
  }
  @media (min-width: $screen-xl) {
    & {
      width: 30%;
      left: 35%;
      &.is-large {
        width: 50%;
        left: 25%;
      }
      &.is-small {
        width: 20%;
        left: 40%;
      }
    }
  }
}

.modal.modal-confirm-v2 {
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 440px;
  box-sizing: border-box;
  gap: 24px;

  .modal-header {
    text-align: center;
  }
  .modal-body {
    text-align: center;
  }
  .modal-actions {
    display: flex;
    gap: 12px;
    align-self: stretch;

    .btn {
      flex: 1;
      justify-content: center;
    }
  }
}
