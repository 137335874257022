.not-optimal-browser-modal,
.install-extension-modal,
.improve-building-modal {
  width: 480px;

  .modal-title {
    justify-content: center;
    padding: 16px;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    gap: 16px;

    .list-elem {
      display: flex;
      gap: 12px;

      .list-elem-icon {
        display: flex;
        align-items: center;
        font-size: 20px;
      }

      .list-elem-content {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }
  }

  .modal-footer {
    border-top: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;

    a {
      width: 100%;

      .btn {
        width: 100%;
        justify-content: center;
      }
    }

    .install-modal-footer-text {
      display: flex;
      gap: 6px;
      text-align: center;

      img {
        height: 18px;
      }
    }
  }
}
