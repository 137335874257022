.s-settings-plan .item-plan {
  position: relative;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  & > div:not(:first-child) {
    position: relative;
    z-index: 2;
  }
  &.plan-startup {
    iframe {
      width: 140%;
      height: 530px;
      position: absolute;
      top: -210px;
      left: 80px;
      transform: rotate(-10deg);
    }

    .plan-logo {
      background-image: url(../../../../../../assets/imgs/logo-startup.svg);
    }

    .section-features {
      .item-feature {
        i {
          color: var(--g-400);
        }
      }
    }
  }
  &.plan-growth {
    border: 4px solid var(--o-500);
    &::before {
      background-color: transparent;
      border-left: 8px solid var(--o-500);
      border-right: 8px solid var(--o-500);
      content: '';
      position: absolute;
      height: 10px;
      width: calc(100% - 8px);
      top: 0px;
      left: -4px;
      z-index: 1;
    }

    iframe {
      width: 110%;
      height: 500px;
      position: absolute;
      top: -215px;
      left: 110px;
      transform: rotate(-200deg);
      background-color: #fff;
    }

    .section-features {
      .item-feature {
        i {
          color: var(--o-500);
        }
      }
    }
  }
  &.plan-scale {
    iframe {
      width: 100%;
      height: 440px;
      position: absolute;
      top: -310px;
      left: 130px;
      transform: rotate(-180deg);
      background-color: #fff;
    }

    .section-features {
      .item-feature {
        i {
          color: var(--b-400);
        }
      }
    }
  }

  .plan-section {
    display: flex;
    flex-direction: column;
    &.section-header {
      gap: 20px;
    }
    &.section-price {
      margin: 24px 0;
      gap: 8px;
    }
  }

  .plan-spline-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 12px;
    background-color: #fff;
    z-index: 2;

    iframe {
      pointer-events: none;
    }
  }

  .plan-most-popular {
    align-self: flex-start;
    position: absolute !important;
    left: -4px;
    top: -40px;
    width: calc(100% + 8px);
    height: 40px;
    box-sizing: border-box;
    z-index: 2;
    background-color: var(--o-500);
    color: #fff;
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;

    i {
      font-size: 20px;
    }
  }

  .plan-current-trial {
    padding: 4px 12px;
    color: var(--g-400);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    i {
      font-size: 18px;
    }
  }

  .plan-logo {
    width: 46px;
    height: 46px;
    background-size: cover;
    background-position: center;
  }

  .plan-title {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 18px;
    & > div:first-child {
      text-transform: capitalize;
    }
  }

  .plan-price {
    display: flex;
    align-items: center;
    gap: 8px;
    & > div {
      text-decoration: line-through;
    }
  }

  .btn-upgrade {
    justify-content: center;
    z-index: 4;
    position: relative;
  }

  .section-mau {
    align-items: flex-start;

    .dropdown-trigger {
      display: flex;
    }
    .icon-chevron-bottom {
      font-size: 10px;
    }
  }

  .section-features {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-right: auto;

    .item-feature {
      display: flex;
      gap: 14px;

      i {
        position: relative;
        top: 4px;
        font-size: 16px;
      }
    }
  }
}
