.animation-smiley {
  background: linear-gradient(
    135deg,
    rgb(255, 233, 25) 0%,
    rgb(251, 192, 0) 100%
  );
  border-radius: 100%;
  padding: 25px;
  width: 60px;
  height: 60px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(calc(-50%));
  animation: jimo-animation-smiley-in 300ms cubic-bezier(0.645, 0.045, 0.355, 1)
      forwards,
    jimo-animation-smiley-out 300ms cubic-bezier(0.645, 0.045, 0.355, 1) 2.8s
      forwards;
}

.mouth {
  width: 60%;
  height: 30%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  box-sizing: border-box;
  position: absolute;
  bottom: 18%;
  left: 50%;
  margin-left: -30%;
  background: #b57700;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.eyes {
  width: 100%;
  margin-top: 15%;
  box-sizing: border-box;
  padding: 0 5px;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.eyes .eye {
  width: 12px;
  height: 12px;
  background: #b57700;
  float: left;
  border-radius: 100%;
  position: relative;
}

.eyes .eye:nth-of-type(2) {
  float: right;
}

.eyes .eye::after {
  content: '';
  display: block;
  position: absolute;
  width: 0%;
  height: 0%;
  background: #fed800;
  transform: rotate(0deg);
  top: -15px;
  left: 5px;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.eyes .eye:first-of-type::after {
  transform: rotate(0deg);
  left: auto;
  right: 5px;
}

/* Normal animation */
.animation-smiley.normal .mouth,
#normal[type='radio']:checked ~ .animation-smiley .mouth {
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  height: 10%;
  width: 40%;
  bottom: 25%;
  margin-left: -20%;
}

.animation-smiley.normal .eyes,
#normal[type='radio']:checked ~ .animation-smiley .eyes {
  margin-top: 30%;
}

/* Happy animation */

.animation-smiley.happy .mouth,
#happy[type='radio']:checked ~ .animation-smiley .mouth {
  animation: jimo-animation-smiley-move-mouth-down 6s;
  animation-delay: 1s;
  animation-iteration-count: 1;
}

@keyframes jimo-animation-smiley-move-mouth-down {
  0% {
    bottom: 18%;
  }

  4.55% {
    bottom: 16%;
  }

  8.45% {
    bottom: 16%;
  }

  13% {
    bottom: 18%;
  }
  100% {
    bottom: 18%;
  }
}

.animation-smiley.happy .eyes,
#happy[type='radio']:checked ~ .animation-smiley .eyes {
  animation: jimo-animation-smiley-move-eyes-down 6s;
  animation-delay: 1.1s;
  animation-iteration-count: 1;
}

@keyframes jimo-animation-smiley-move-eyes-down {
  0% {
    margin-top: 15%;
  }

  4.55% {
    margin-top: 19%;
  }

  8.45% {
    margin-top: 19%;
  }

  13% {
    margin-top: 15%;
  }

  100% {
    margin-top: 15%;
  }
}

.animation-smiley.happy .eye:nth-of-type(2),
#happy[type='radio']:checked ~ .animation-smiley .eye:nth-of-type(2) {
  height: 12px;
  margin-top: 0;
  animation: jimo-animation-smiley-wink 6s;
  animation-delay: 1s;
  animation-iteration-count: 1;
}

@keyframes jimo-animation-smiley-wink {
  0% {
    height: 20px;
    margin-top: 0;
  }

  3.9% {
    height: 3px;
    margin-top: 8px;
  }

  9.1% {
    height: 3px;
    margin-top: 8px;
  }

  13% {
    height: 12px;
    margin-top: 0;
  }

  100% {
    height: 12px;
    margin-top: 0;
  }
}

.animation-smiley.normal .eye,
#normal[type='radio']:checked ~ .animation-smiley .eye {
  height: 12px;
  margin-top: 0;
}

@keyframes jimo-animation-smiley-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes jimo-animation-smiley-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
