.changelog-builder-sidebar-wrapper {
  height: 100%;
  display: flex;
  z-index: 2;
  min-height: 0;

  &.is-selecting-preset {
    opacity: 0.3;
    pointer-events: none;
  }

  .poke-builder-sidebar {
    flex-shrink: 0;
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 56px;
    border-right: 1px solid #dad9db;
    gap: 8px;
    padding-top: 16px;

    .sidebar-item {
      cursor: pointer;
      margin: 0 auto;
      height: 40px;
      width: 40px;
      display: flex;
      border-radius: 8px;
      transition: all 150ms ease-in-out;
      border: 2px solid transparent;

      &:hover {
        background: rgba(18, 96, 235, 0.1);
        border: 2px solid #1260eb;
        color: #1260eb;
      }
      &.selected {
        background-color: #1260eb;
        color: #fff;
      }

      i {
        margin: auto;
        font-size: 24px;
      }
    }
  }

  .navigator-wrapper,
  .block-manager-wrapper,
  .theme-manager-wrapper,
  .translation-manager-wrapper {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 10px 4px 10px 0px rgba(0, 0, 0, 0.06);
  }
}
