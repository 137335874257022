@import '../../Colors';

.user-avatar {
  position: relative;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: $colorPrimary;
  text-align: center;
  line-height: 54px;
  font-size: 30px;
  font-weight: 200;
  text-transform: uppercase;
  &.has-avatar {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .badge-team {
    position: absolute;
    bottom: -2px;
    right: -10px;
    border-radius: 50%;
    background-color: $colorPrimary;
    color: var(--white);
    display: flex;
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    border: 3px solid var(--white);
    i {
      font-size: 10px;
    }
  }
}
