.block-visual-cue {
  display: flex;
  pointer-events: none;
}

@keyframes scaleInOut {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

@keyframes moveArrow {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(10px, -14px);
  }
}

@keyframes tap {
  0% {
    transform: translateY(0) scale(1);
  }
  20% {
    transform: translateY(4px) scale(0.95);
  }
  40% {
    transform: translateY(0) scale(1);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

@keyframes move {
  0%,
  100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(10px, -10px);
  }
  50% {
    transform: translate(-10px, 10px);
  }
  75% {
    transform: translate(10px, 10px);
  }
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes scrollRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes scrollHorizontal {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes scrollVertical {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes scrollUp {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes scrollDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes swipeLeft {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(-30px, -10px) rotate(-15deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes swipeRight {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(30px, -10px) rotate(15deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
