.btn.btn-google-login {
  display: flex;
  justify-content: space-between;
  padding: 16px 32px;
  width: 100%;

  .icon-google {
    font-size: 14px !important;
  }
  .icon-chevron-right {
    font-size: 12px !important;
    margin-left: 0 !important;
  }
}
