.builder-channel-interactive-option {
  position: relative;
  border: 1px solid #e5e5e5;
  transition: all 100ms ease-in-out;
  font-size: 16px;
  border-radius: 8px;
  &:not(.is-disabled):hover {
    background-color: #e7effd;
    border-color: #e7effd;
    box-shadow: 0px 0px 0px 2px #8fc6ff;
    color: #1260eb;

    .icon-wrapper {
      i {
        color: var(--b-400) !important;
      }
    }
  }
  &.is-disabled {
    color: #cecece !important;

    .option-main-wrapper {
      cursor: not-allowed;

      small {
        color: #cecece !important;
      }
    }

    i {
      color: #cecece !important;
    }
  }
  &.is-active {
    background-color: #e7effd;
    border-color: #4782e9;
    box-shadow: 0px 0px 0px 2px #8fc6ff;
    color: #1260eb;

    &:not(.is-disabled) {
      i {
        color: #1260eb !important;
      }
    }
  }
  &.has-children {
    .option-main-wrapper {
      padding: 16px 16px 0 16px;
    }
  }

  .option-main-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 16px;

    small {
      color: #5e6165 !important;
      margin-top: 4px;
    }
    .title-description-wrapper {
      flex: 1 1 auto;
    }
    .icon {
      font-size: 30px;
      margin-right: 16px;
    }
    .preview-icon {
      margin-right: 8px;
    }
    svg {
      margin-right: 8px;

      &.active {
        path {
          opacity: 1;
        }
      }
    }
    small {
      display: block;
    }
    .icon-radiobox,
    .icon-radiobox-o,
    .icon-checkbox,
    .icon-checkbox-o {
      font-size: 24px;
    }
  }
  .option-children-wrapper {
    padding: 16px;
  }
}
