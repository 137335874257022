.rules-modal {
  .rules-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .rules-title {
      display: none;
    }

    .path-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .global-operator-select {
      .jimo-select__control {
        height: 40px;
        border-radius: 12px;

        .jimo-select__value-container {
          padding: 6px;
        }
      }
    }
    .btn-add-path {
      padding: 8px 12px;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 16px;
      width: fit-content;

      i {
        font-size: 24px !important;
      }
    }

    .builder-audience-boosted-path {
      height: 40px;

      .jimo-select.operator-select {
        height: 100%;
        min-width: unset;

        .jimo-select__control {
          height: 100%;
          border-radius: 12px;
          border-right-color: rgba(0, 0, 0, 0.1);

          .jimo-select__value-container {
            padding: 4px;
            height: 100%;

            .jimo-select__single-value {
              white-space: pre-wrap;
            }
          }
        }
      }

      .path-input-wrapper {
        .input-group {
          &:hover {
            .label-right {
              i {
                opacity: 1;
              }
            }
          }
          .label-left {
            margin-left: -12px;
            border-left: 0;
            font-size: 14px;
            padding: 10px 0;
            padding-left: 18px;
          }

          .label-right {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            padding: 10px;

            i {
              opacity: 0;
              transition: all 0.2s ease-in-out;
            }
          }

          input {
            font-size: 14px;
            border-right: 0;
          }
        }
      }
    }
  }
}
