.checklist-completed-conditions {
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: center;
  background: var(--surface-primary, #fff);
  overflow: hidden;

  .trigger-conditions {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-self: stretch;
    padding-top: 0;

    .custom-condition-empty-state {
      display: flex;
      align-items: center;
      gap: 8px;

      .add-condition-btn {
        background: var(--b-100);
        padding: 8px;
      }
    }

    .event-conditions-wrapper {
      margin-bottom: 0;
      padding: 0;
      width: 100%;

      .logic-trait-wrapper {
        width: 45px;
      }
    }
  }

  .trigger-actions-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    background: var(--b-100);
    padding: 16px;
    gap: 12px;

    &.is-expanded {
      padding: 16px;

      &.is-empty {
        padding: 16px;
      }

      &:not(.is-empty) {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }

    .trigger-actions-content {
      display: flex;
      gap: 12px;

      .trigger-icon-wrapper {
        width: 45px;
        display: flex;
        justify-content: center;

        i {
          font-size: 24px;
        }
      }
    }
  }
}
