.success-tracker-header {
  display: flex;
  flex: 0 1 auto;
  box-sizing: border-box;
  padding: 32px 26px;
  padding-bottom: 0;
  margin: 0;
  flex: 0 0 50px;
  align-items: center;

  .actions-wrapper {
    margin: auto 0;
    margin-left: auto;
    display: flex;
    gap: 12px;

    .changelog-activation {
      display: flex;
      align-items: center;
      gap: 12px;

      &.disabled {
        color: var(--n-500);
      }
    }

    .divider {
      height: 42px;
      margin: auto;
    }

    .btns-wrapper {
      display: flex;
      gap: 12px;

      .btn {
        height: max-content;
      }

      .recent-comments-trigger {
        position: relative;

        .unread-comments-indicator {
          width: 12px;
          height: 12px;
          position: absolute;
          right: -2px;
          top: -2px;
          background-color: var(--r-400);
          border-radius: 50%;
        }
      }
    }
  }
}
