.feature-usage-stats {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  .stat-block {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 8px 60px 0px rgba(0, 0, 0, 0.05);

    .icon-wrapper {
      width: 32px;
      height: 32px;
      display: flex;

      i {
        margin: auto;
        font-size: 22px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;

      .title {
        color: var(--neutral-700, var(--neutral-neutral-700, #4d637b));
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
      }

      .value-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;

        .value {
          color: var(--neutral-800-black, var(--neutral-neutral-800, #071331));
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 125%; /* 25px */
        }

        .additional {
          color: var(--green-400-main, #159201);
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 125%; /* 20px */
        }
      }
    }
  }
}
