.poke-builder-header {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
  background: #fff;
  z-index: 3;
  height: 60px;
  flex-shrink: 0;
  overflow: hidden;
  gap: 8px;

  .left-wrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: flex-start;

    .btn-exit-extension {
      margin-left: 12px;

      i {
        margin-right: 0 !important;
      }
    }

    .logo-wrapper {
      padding: 12px 12px;

      img {
        height: 40px;
        width: 40px;
      }

      &.with-border {
        // border-right: 1px solid #e5e5e5;
      }
    }

    .btn-back {
      margin-left: 8px;

      i {
        color: var(--n-800);
        font-size: 16px;
      }
    }

    .name-wrapper {
      display: flex;
      align-items: center;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.3);
      margin-left: 6px;
      flex: 1 auto;
      width: 400px;

      .input-name {
        flex: 1 auto;

        input {
          font-size: 18px;
          font-weight: 500;
          padding: 8px;
          border: 0;
        }
      }
    }
  }

  .center-wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    min-width: 0;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .right-wrapper {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    position: relative;

    .safe-to-edit-wrapper {
      display: flex;
      gap: 4px;
      cursor: pointer;

      span {
        font-size: 12px;
        text-decoration: underline;
        text-wrap: nowrap;
      }
    }

    .btn {
      height: 34px;
      text-wrap: nowrap;
    }

    .btn-play,
    .btn-navigate {
      i {
        margin-right: 0 !important;
      }
    }

    .btn-target {
      margin-right: 8px;
      font-weight: 600;
      padding: 8px 12px;
      align-items: center;

      &.is-disabled {
        opacity: 0.3;
      }
      &.is-invalid {
        background: #fff !important;
        color: rgba(0, 0, 0, 0.4) !important;

        &:hover {
          color: rgba(0, 0, 0, 0.3) !important;
        }
      }

      .icon-wrapper {
        margin-left: 8px !important;
        height: 24px;
        width: 24px;
        border-radius: 8px;
        background: #ffe7e2;
        display: flex;

        i {
          margin: auto;
          color: #000;
        }
      }
    }

    .last-auto-save {
      font-size: 12px;
      font-weight: 500;
      color: var(--g-400);
      justify-content: center;
      opacity: 1;
      background-color: var(--g-100);
      padding: 4px 8px;
      border-radius: 12px;
      animation: last-auto-save-out 200ms ease-in-out 2000ms forwards;
      &:hover {
        animation-play-state: paused;
      }

      i {
        margin-left: 2px;
      }
    }
    .is-auto-saving {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      color: var(--o-400);
      justify-content: center;
      opacity: 1;
      background-color: var(--o-100);
      padding: 4px 8px;
      border-radius: 12px;

      .loader {
        opacity: 0.6;
        margin-left: 4px;
        border-color: var(--o-300);
        border-top-color: var(--o-400);
      }
    }
  }
}

.exit-modal {
  padding: 0;
  width: unset !important;

  .modal-title {
    padding: 24px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 0;
  }

  .content {
    padding: 24px;
    font-weight: 400;
    font-size: 18px;
  }

  .actions {
    margin-top: 0;
    gap: 8px;
    justify-content: flex-end;
  }
}

@-webkit-keyframes last-auto-save-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes last-auto-save-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
