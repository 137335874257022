.modal-create-segment {
  max-height: 700px;
  overflow: hidden;
  width: unset;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;

  .modal-title {
    display: flex;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 24px;
    font-family: 'Montserrat';
    margin-bottom: 0;
  }

  .inputs-wrapper {
    display: flex;
    flex-direction: column;
    padding: 24px;

    .section-title {
      margin-bottom: 24px;
      font-size: 18px;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 8px;
    }

    .input-group {
      width: 480px;
      margin-bottom: 12px;

      .input {
        border-radius: 12px;
        height: 56px;
        font-size: 16px;
      }
    }
  }

  .actions {
    padding: 24px;
    padding-top: 0;
    margin: 0;
    justify-content: flex-end;

    .btn {
      margin-right: 0;
      margin-left: 8px;
    }
  }
}
