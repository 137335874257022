.pushes-list-view {
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .tabs {
    .tab-list {
      margin-bottom: 0;
      border: none;
      margin-top: 0;

      .tab-list-item {
        position: relative;
        align-items: center;
        padding: 0;
        margin-right: 12px;
        transition: all 300ms ease;
        &:hover {
          .label-wrapper {
            background-color: rgba(18, 98, 235, 0.08);
            color: #1260eb;
          }
          .pokes-count {
            .count {
              display: none;
            }
            .evolution-view-menu {
              display: flex;
            }
          }
        }
        &.tab-all {
          margin-right: 24px;
        }
        &.tab-list-active:not(.tab-all) {
          margin-right: 46px;
        }
        &.tab-list-active {
          font-weight: 400;

          .label-wrapper {
            background-color: rgba(18, 98, 235, 0.08);
          }
          .pokes-count {
            color: #000000;
          }
        }

        .pokes-count {
          padding: 8px;
          width: 25px;
          height: 25px;
          right: -40px;
          top: -3px;
          position: absolute;

          .count,
          .evolution-view-menu {
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            font-size: 12px;
            background-color: rgba(0, 0, 0, 0.06);
            height: 100%;
          }

          .count {
            display: flex;
          }
          .evolution-view-menu {
            display: none;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
        .label-wrapper {
          padding: 8px 8px;
          margin-bottom: 4px;
          border-radius: 6px;
        }
      }
    }
  }

  .btn-list-view-add {
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(18, 98, 235, 0.08);
    border: none;
    color: #1260eb;
    &:hover {
      border: none !important;
      background-color: rgba(18, 98, 235, 0.2);
    }
  }
}
