.trigger-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1 auto;
  min-width: 0;
  justify-content: center;

  .empty-state-reduced-actions {
    padding: 4px 8px;
  }
}

.add-action-condition-content {
  min-width: 158px;
  background: #ffffff;
  box-shadow: 0px 14px 19px rgb(0 0 0 / 3%);
  border-radius: 8px;
  margin: 4px auto;
  z-index: 1002 !important;
  animation: openAnimation 0.225s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

  .actions-list {
    display: flex;
    flex-direction: column;
    padding: 4px;
    overflow-y: auto;

    .divider {
      margin: 4px;
      width: calc(100% - 8px);
    }

    .dropdown-menu-item-wrapper {
      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
        .dropdown-menu-item {
          pointer-events: none;
        }
      }

      .dropdown-menu-item {
        display: flex;
        gap: 12px;
        align-items: center;
        padding: 12px;
        border-radius: 8px;
        font-family: 'Montserrat';
        color: #000;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;

        cursor: pointer;
        &:hover {
          background-color: #1260eb0f;
        }

        .icon-wrapper {
          display: flex;
          height: 32px;
          width: 32px;
          border-radius: 8px;

          &.go-to-step {
            background-color: var(--g-200);
          }

          &.dismiss {
            background-color: var(--r-200);
          }

          &.launch-experience {
            background-color: var(--b-150);
          }

          &.navigate-to {
            background-color: var(--n-200);
          }

          &.run-js-code {
            background-color: var(--y-300);
          }

          &.open-post {
            background-color: var(--b-150);
          }

          &.book-interview {
            background-color: var(--o-200);
          }
          &.snooze {
            background-color: var(--p-200);
          }

          &.skip-task {
            background-color: var(--y-200);

            i {
              transform: rotate(180deg);
            }
          }

          i {
            margin: auto;
            font-size: 16px;

            &.isax {
              font-size: 20px;
            }
          }
        }

        .subtitle {
          display: flex;
          gap: 4px;
          align-items: center;
        }
      }
    }
  }
}

.action-icon {
  display: flex;
  height: 32px;
  width: 32px;
  border-radius: 8px;

  &.go-to-step {
    background-color: var(--g-200);
  }

  &.dismiss {
    background-color: var(--r-200);
  }

  &.launch-experience {
    background-color: var(--b-150);
  }

  &.navigate-to {
    background-color: var(--n-200);
  }

  &.run-js-code {
    background-color: var(--y-300);
  }

  &.open-post {
    background-color: var(--b-150);
  }

  &.book-interview {
    background-color: var(--o-200);
  }

  i {
    margin: auto;
    font-size: 16px;

    &.isax {
      font-size: 20px;
    }
  }
}
