.settings-survey-response {
  .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 8px;

    i {
      font-size: 12px;
    }
    .jimo-select {
      flex: 1 1 auto;
      width: 220px;

      &.condition-values-select {
        .jimo-select__value-container {
          height: unset;
          min-height: 40px;
        }
      }

      .jimo-select__value-container {
        height: 40px;
        padding: 2px 8px;
      }
    }

    .jimo-select__menu {
      width: inherit;
    }

    .input-group {
      .input-main-wrapper {
        min-height: 40px;
      }
    }
  }

  .default-wrapper {
    padding: 16px;
    border-radius: 12px;
    border: 1px solid #d9d9d9;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .condition-values-select {
    min-width: 0;

    .jimo-select__control {
      border-style: dashed;
    }
  }

  .value-input {
    height: 36px;
    width: 220px;

    .input {
      padding: 8px;
      font-size: 14px;
    }
  }

  .pixel-picker {
    width: 220px;

    .input-group {
      height: 36px;

      .input {
        padding: 8px;
        font-size: 14px;
      }
    }

    .pixel-picker-slider {
      flex-grow: 1;
    }
  }

  .opinion-scale-dropdown-btn {
    border-radius: 12px;
    font-weight: 500;

    i {
      margin-right: 4px;
    }
  }

  .opinion-scale-pick {
    display: flex;
    width: 100%;
    justify-content: space-evenly;

    .opinion-item {
      margin: auto;
      cursor: pointer;

      &:not(.is-active) {
        filter: grayscale(1);
      }
    }

    .emoji-animated-wrapper {
      cursor: pointer;
    }
  }
}

.settings-survey-response-readable {
  display: flex;
  gap: 4px;
  align-items: center;

  .settings-survey-response-readable-values {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    gap: 4px;

    .qcm-option {
      display: flex;
      padding: 4px 6px;
      align-items: center;
      border: 1px solid var(--n-300);
      border-radius: 6px;
    }
  }
}
