.poke-block-dismiss-cross {
  cursor: pointer;

  &:not(.is-in-banner) {
    position: absolute !important;
    top: 12px;
    right: 12px;
    z-index: 3;
  }
  &.is-in-banner {
    position: relative;
  }
  &.is-in-hint {
    top: 10px;
    right: 8px;

    i {
      padding: 8px;
    }
  }

  &.is-in-cursor {
    position: relative !important;
    top: unset;
    right: unset;

    i {
      padding: 8px;
    }
  }

  i {
    cursor: pointer;
    transition: all 100ms ease-in-out;
    display: inline-block;
    padding: 12px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}
