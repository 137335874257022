.poke-block-step {
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  //
  overflow: visible;
}
