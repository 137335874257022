.modal-image-fullscreen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  background: none;
  padding: 0;

  @media screen and (max-width: 600px) {
    width: 95%;
  }
  @media screen and (min-width: 601px) and (max-width: 1200px) {
    width: 80%;
  }

  img {
    flex: 1 0 auto;
    max-width: 100%;
    max-height: 80%;
  }
}
