.empty-state-block {
  display: flex;
  width: 320px;
  max-width: 320px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin: 24px auto;

  .empty-state-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    .title {
      align-self: stretch;
      color: var(--content-primary, #071331);
      text-align: center;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 125%; /* 25px */
    }

    .description {
      align-self: stretch;
      color: var(--content-secondary, #4d637b);
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
    }
  }
}
