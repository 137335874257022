.checklist-float-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  cursor: pointer;

  .count-progress {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background: #ffffff33;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
  }

  .label {
    font-size: 18px;
    font-weight: 500;
  }
}
