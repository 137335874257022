.block-settings.checking-list {
  .task-list-wrapper {
    width: 100%;
    padding: 8px 8px 12px 8px;
    border-radius: 16px;
    background: var(--n-50);
    display: flex;
    flex-direction: column;

    .item-wrapper {
      display: flex !important;
      margin-bottom: 8px;
      height: 37px;

      &.is-last {
        margin-bottom: 0;
      }

      &.is-editing {
        .item-content {
          background: var(--n-100);

          .actions {
            display: none;
          }
        }
      }

      .dragger {
        align-self: center;
        margin-right: 12px;
      }

      .dragger > i {
        color: gray;
        margin: 1px;
        font-size: 14px;
      }

      .item-content {
        position: relative;
        padding: 8px 12px 8px 12px;
        gap: 8px;
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: white;
        flex-grow: 1;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;

        &:hover {
          .actions {
            opacity: 1;
          }
        }

        .task-title-input {
          .input-main-wrapper {
            border: 0;

            input {
              padding: 0;
              font-size: 14px;
            }
          }
        }

        .actions {
          position: absolute;
          right: 12px;
          top: 8px;
          opacity: 0;
          transition: all 0.3s ease;
          display: flex;
          gap: 8px;

          .action-icon-wrapper {
            cursor: pointer;
            i {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .add-task-btn {
    width: 100%;
    justify-content: center;
  }
}
