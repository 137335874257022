.block-settings.pinned-element {
  .alignment-figure-wrapper {
    display: flex;

    .alignment-figure {
      width: 100%;
      height: 92px;
      margin: 40px 30px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 14px;
      position: relative;
      display: flex;

      .alignment-label {
        width: 100%;
        margin: auto 20px;
        text-align: center;
        font-weight: 500;
      }

      .position-dot {
        height: 10px;
        width: 10px;
        background: #d9d9d9;
        border-radius: 10px;
        border: 2px solid #fff;
        position: absolute;
        cursor: pointer;

        &.selected {
          background: #ffffff;
          border: 2px solid #1260eb;
        }

        &.top {
          top: -20px;
          left: 50%;
          transform: translateX(-50%);
        }

        &.top-right {
          top: -20px;
          right: 20px;
        }

        &.right-top {
          top: 20px;
          right: -20px;
        }

        &.right {
          top: 50%;
          transform: translateY(-50%);
          right: -20px;
        }

        &.right-bottom {
          bottom: 20px;
          right: -20px;
        }

        &.bottom-right {
          bottom: -20px;
          right: 20px;
        }

        &.bottom {
          bottom: -20px;
          left: 50%;
          transform: translateX(-50%);
        }

        &.bottom-left {
          bottom: -20px;
          left: 20px;
        }

        &.left-bottom {
          bottom: 20px;
          left: -20px;
        }

        &.left {
          top: 50%;
          transform: translateY(-50%);
          left: -20px;
        }

        &.left-top {
          top: 20px;
          left: -20px;
        }

        &.top-left {
          top: -20px;
          left: 20px;
        }
      }
    }
  }

  .section-item {
    .section-item-content {
      width: 176px !important;
    }
  }

  .section-item.z-index {
    .input-group {
      height: 40px;

      .input {
        border-radius: 12px 0 0 12px;
      }
      .label-left {
        border-radius: 12px 0 0 12px;
        padding: 4px;
        display: flex;
        margin-left: 4px;

        .icon-wrapper {
          margin: auto;
          height: 32px;
          width: 32px;
          display: flex;
          border-radius: 12px;
          background: rgba(18, 96, 235, 0.1);

          i {
            margin: auto;
            font-size: 10px;
            color: #1260eb;
          }
        }
      }
    }
  }

  .section-item.target-picker {
    .input-group {
      height: 40px;

      .input {
        border-radius: 12px 0 0 12px;
      }
      .label-left {
        border-radius: 12px 0 0 12px;
        display: flex;

        .position-icon-wrapper {
          display: flex;
          margin: auto 4px;
          width: 32px;
          height: 32px;
          box-sizing: border-box;
          border: 1px solid rgba(0, 0, 0, 0.1);
          box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
          border-radius: 12px;

          i {
            margin: auto 4px;
            font-size: 14px;
            width: 32px;
            box-sizing: border-box;
            border-radius: 12px;
            text-align: center;
          }
        }
      }
    }
  }
}

.dropdown-target-input-options-content {
  z-index: 99999 !important;
  border-radius: 12px;
  width: 150px;
  max-height: 300px;
  display: flex;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);

  .dropdown-content {
    background: #fff !important;
    flex: 1 auto;
    overflow-y: auto;
    padding: 4px;
    display: flex;
    flex-direction: column;

    .option {
      cursor: pointer;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.7);
      padding: 8px;
      border-radius: 8px;

      &:hover {
        color: #1260eb;
        background: rgba(18, 96, 235, 0.1);
      }
    }
  }
}
