.builder-audience-path-selector-old {
  .rules-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 16px;

    .rules-title {
      margin-bottom: 8px;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 16px;
    }

    .global-operator-select {
      margin-bottom: 8px;
    }
    .btn-add-path {
      padding: 8px 12px;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 16px;

      i {
        font-size: 24px !important;
      }
    }

    &.small {
      .jimo-select.operator-select {
        width: 100px;
        min-width: unset;

        .jimo-select__value-container {
          padding: 4px;
          height: 100%;

          .jimo-select__single-value {
            white-space: pre-wrap;
          }
        }
      }

      .path-input-wrapper {
        .label-left {
          font-size: 14px;
          padding: 17px 0;
          padding-left: 6px;
        }
        .input-group {
          input {
            font-size: 14px;
          }
        }
      }
    }

    .input-domain-filter {
      width: 100%;

      .icon-trash {
        &:hover {
          color: #000;
          cursor: pointer;
        }
      }
    }
  }

  .domains-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 16px;

    .domains-title {
      margin-bottom: 8px;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 16px;
    }

    .btn-add-domain {
      padding: 8px 12px;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 16px;

      i {
        font-size: 24px !important;
      }
    }

    .input-domain-filter {
      width: 100%;

      .icon-trash {
        &:hover {
          color: #000;
          cursor: pointer;
        }
      }
    }

    .filter-domain-info-wrapper {
      background-color: rgba(18, 98, 235, 0.05);
      padding: 8px;
      border-radius: 8px;
      margin: 12px 0;

      p {
        margin: 0;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
        &:first-child {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          color: rgb(18, 98, 235);
          i {
            margin-right: 4px;
          }
        }
      }
    }
    .filter-domain-info {
      align-items: center;

      i {
        position: relative;
        top: 2px;
        margin-right: 8px;
      }
      pre {
        display: inline-block;
        margin: 0 4px;
        color: rgb(18, 96, 235);
        background-color: rgba(18, 98, 235, 0.1);
        padding: 2px 4px;
        border-radius: 4px;
      }
    }
  }

  .url-tester-wrapper {
    position: relative;
    background: #f4f4f4;
    border-radius: 24px;
    padding: 16px;

    &.invalid-url {
      background: #fde6e6;

      .url-tester-text {
        color: #c9242d;
      }
    }
    &.url-no-match {
      background: #fff8e6;
      .url-tester-text {
        color: orange;
      }
    }
    &.url-match {
      background: #e6fde8;

      .url-tester-text {
        color: #13805c;
      }
    }

    .url-tester-title {
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 8px;
    }

    .url-tester {
      display: flex;

      .icon-wrapper {
        display: flex;
        transform: rotate(180deg);
        margin-right: 8px;

        i {
          margin: auto;
        }
      }

      .url-tester-input {
        flex: 1 1 auto;

        .input {
          font-size: 14px;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 12px;
        }
      }
    }

    .url-tester-text {
      margin-top: 8px;
    }
  }
}
.builder-audience-boosted-path-old {
  display: flex;
  margin-bottom: 8px;

  .jimo-select {
    min-width: 180px;
    .jimo-select__control {
      height: 52px;
      border-radius: 8px 0 0 8px;
      border-right-color: transparent;
    }
  }
  .path-input-wrapper {
    flex: 1;
    display: flex;

    .label-left {
      border-radius: 0 !important;
      padding-right: 0;
      color: #c4c4c469;
    }

    .input-group {
      flex-grow: 1;

      input {
        padding-left: 0;
        border-left: 0;
        font-size: 16px;
      }
    }
    .icon-trash {
      &:hover {
        color: #000;
        cursor: pointer;
      }
    }
  }
}
