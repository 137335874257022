.s-settings-environments {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .card-environments {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      .left-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .right-wrapper {
        .btn {
          text-wrap: nowrap;
        }
      }
    }

    .card-content {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 16px;

      @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      .environment-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 12px 16px;
        border-radius: 12px;
        cursor: pointer;
        flex: 1 1 0px;
        min-width: 0;
        transition: background-color 0.2s;

        &.green {
          background-color: var(--g-100);

          &:hover {
            background-color: var(--g-200);
          }

          .environment-icon {
            background-color: var(--g-200);
          }
        }

        &.blue {
          background-color: var(--b-100);

          &:hover {
            background-color: var(--b-150);
          }

          .environment-icon {
            background-color: var(--b-150);
          }
        }

        &.orange {
          background-color: var(--o-100);

          &:hover {
            background-color: var(--o-200);
          }

          .environment-icon {
            background-color: var(--o-200);
          }
        }

        &.red {
          background-color: var(--r-100);

          &:hover {
            background-color: var(--r-200);
          }

          .environment-icon {
            background-color: var(--r-200);
          }
        }

        &.yellow {
          background-color: var(--y-100);

          &:hover {
            background-color: var(--y-200);
          }

          .environment-icon {
            background-color: var(--y-200);
          }
        }

        &.purple {
          background-color: var(--p-100);

          &:hover {
            background-color: var(--p-200);
          }

          .environment-icon {
            background-color: var(--p-200);
          }
        }

        &.gray {
          background-color: var(--n-100);

          &:hover {
            background-color: var(--n-200);
          }

          .environment-icon {
            background-color: var(--n-200);
          }
        }

        .environment-icon {
          display: flex;
          height: 40px;
          width: 40px;
          justify-content: center;
          align-items: center;
          border-radius: 12px;
          font-size: 20px;
          flex-shrink: 0;
        }

        .environment-info {
          display: flex;
          flex-direction: column;
          gap: 4px;
          min-width: 0;

          .environment-name,
          .environment-domains {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
