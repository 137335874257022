.s-settings-installation {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .settings-header {
    display: flex;
    justify-content: space-between;

    .header-actions {
      display: flex;
      gap: 16px;

      .btn.is-tertiary {
        color: var(--g-300) !important;
        font-weight: 500;
      }
    }
  }

  .card-snippet {
    display: flex;
    flex-direction: column;

    .card-header {
      display: flex;

      .left-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .custom-tabs-wrapper {
        background-color: var(--n-100);
        padding: 4px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        gap: 4px;

        .custom-tab-item {
          padding: 8px 16px;
          border-radius: 8px;
          transition: all 100ms ease;
          color: var(--n-700);
          cursor: pointer;
          &.is-active {
            color: var(--n-800);
            background-color: #fff;
            box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
          }
          &:hover {
            color: var(--n-800);
          }
        }
        .custom-tab-divider {
          height: 12px;
          width: 1px;
          background-color: var(--n-300);
        }
      }
    }
    .snippet-wrapper {
      position: relative;

      .btn-copy-snippet {
        position: absolute;
        right: 24px;
        top: 38px;
      }
      pre {
        background-color: var(--n-800) !important;
        border-radius: 12px;
        padding: 24px !important;
        max-height: 320px;
        overflow-y: auto;
      }
      code {
        background-color: unset;
        color: unset;
      }
    }
  }
  .card-gtm {
    display: flex;
    flex-direction: column;

    .card-header {
      display: flex;
      gap: 16px;
      align-items: center;

      img {
        width: 36px;
        height: 36px;
      }
      .left-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 1;

        .custom-tag {
          padding: 2px 8px;
          background-color: var(--n-200);
          border-radius: 16px;
          display: inline-block;
          margin-left: 8px;
          font-weight: 500;
        }
      }
    }
    .card-body {
      padding-top: 24px;
    }
    .gtm-setup-wrapper {
      display: flex;
      gap: 24px;

      .select-group-wrapper {
        flex: 1;
      }
    }
    .gtm-actions {
      display: flex;
      gap: 16px;
      justify-content: flex-end;
      padding-top: 24px;
    }
  }
}

.modal-send-instructions {
  width: 528px !important;

  .modal-title {
    padding: 16px;
    font-size: 20px;
    font-weight: 600;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
  }
  .title {
    text-align: center;
  }
  .actions {
    margin: 0;
    justify-content: flex-end;
    margin-top: 14px;
  }
}
