.step-placeholder {
  width: 266px;
  height: 272px;
  background: #ffffff66;
  border: 1px dashed var(--b-400);
  display: flex;
  border-radius: 8px;

  .icon-wrapper {
    margin: auto;
    height: 20px;
    width: 20px;
    display: flex;
    border-radius: 8px;
    background: var(--b-400);

    i {
      margin: auto;
      color: white;
      font-size: 10px;
    }
  }
}

.react-flow__node-stepPlaceholder {
  visibility: visible !important;
}
