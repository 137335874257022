.setting-sub-nav {
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 24px;

  .tab-list {
    padding-left: 32px;
    margin-bottom: 0;
    border: none;
    margin-top: 0;

    .tab-list-item {
      position: relative;
      align-items: center;
      padding: 0;
      margin-right: 12px;
      transition: all 300ms ease;
      &:hover {
        .label-wrapper {
          background-color: rgba(18, 98, 235, 0.08);
          color: #1260eb;
        }
        .pokes-count {
          .count {
            display: none;
          }
          .evolution-view-menu {
            display: flex;
          }
        }
      }
      &.has-count {
        margin-right: 24px;
      }
      &.tab-list-active {
        font-weight: 400;

        .label-wrapper {
          background-color: rgba(18, 98, 235, 0.08);
        }
        .pokes-count {
          color: #000000;
        }
      }

      .item-count {
        padding: 8px;
        height: 25px;
        right: -26px;
        top: -3px;
        position: absolute;

        .count {
          padding: 0 4px;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          font-size: 12px;
          background-color: rgba(0, 0, 0, 0.06);
          height: 100%;
        }

        .count {
          display: flex;
        }
      }
      .label-wrapper {
        padding: 8px 8px;
        margin-bottom: 4px;
        border-radius: 6px;
      }
    }
  }
}
