.audience-section.goal {
  .audience-section-content {
    position: relative;
    display: flex;

    .goal-editor-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;

      span {
        color: #000;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
      }

      .builder-channel-interactive-option {
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        box-sizing: border-box;
        display: flex;

        .option-main-wrapper {
          flex: 1 auto;

          .icon {
            width: 20px;
            font-size: 20px;
            color: rgba(0, 0, 0);

            &.active-hovered-icon {
              display: none;
            }
          }

          .title-description-wrapper {
            display: flex;
            flex-direction: column;
          }

          .icon-checkbox {
            margin-left: auto;
          }
        }

        &.is-active {
          background: rgba(18, 96, 235, 0.08);

          &:hover {
            .icon {
              &.default-icon {
                display: none;
              }
              &.active-hovered-icon {
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}
