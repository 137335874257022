@import '../../Variables';

.poke-block-open-question {
  flex: 1;
  display: flex;
  animation: block-open-question-in $animationBlockInDuration ease-in-out
    $animationBlockInDelay both;
  &.is-animating-out {
    animation: block-open-question-out $animationBlockOutDuration ease-in-out
      both;
  }
  textarea {
    font-family: inherit;
    resize: none;
    border-style: solid;
    border-width: 1px;
    padding: 12px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    outline: none;
    transition: all 200ms ease-in-out;
  }
}

@-webkit-keyframes block-open-question-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes block-open-question-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes block-open-question-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes block-open-question-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
