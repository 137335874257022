.link-floating-toolbar-input-wrapper,
.link-floating-toolbar-edit-wrapper {
  display: flex;
  border-radius: 8px;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  background: white;

  .input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .item {
      display: flex;
      gap: 8px;
      padding: 8px;
      align-items: center;

      input {
        flex-grow: 1;
        border: 0;
        outline: 0;
      }
    }
  }
}

.link-floating-toolbar-input-wrapper {
  min-width: 300px;
}

.link-floating-toolbar-edit-wrapper {
  display: flex;
  border-radius: 8px;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  background: white;

  &.is-editing {
    min-width: 300px;
  }

  &:not(.is-editing) {
    padding: 8px;
  }

  .edit-wrapper {
    display: flex;
    gap: 8px;
    width: 100%;

    .item {
      display: flex;
      gap: 8px;
      align-items: center;

      button {
        border: 0;
        outline: 0;
        background: none;
        cursor: pointer;
        font-size: 14px;
        display: flex;
        align-items: center;
      }
    }
  }
}
