.task-title {
  font-size: 16px;
  font-family: 'Inter';
  position: relative;

  p {
    margin: 0;
    overflow-wrap: break-word;
    min-height: var(--checklist-block-title-font-size);
  }

  .a-div {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  span.variable.in-builder {
    border: 1px dashed;
    border-radius: 4px;
    padding: 2px 4px;
    margin: 0 2px;
  }
}
