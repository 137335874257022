.import-csv-identify-field {
  .field-header {
    border: 1px solid var(--n-300);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: var(--n-50);
    padding: 6px;
  }

  .field-preview {
    border-left: 1px solid var(--n-300);
    border-right: 1px solid var(--n-300);
    border-bottom: 1px solid var(--n-300);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    max-height: 240px;
    overflow-y: auto;
  }

  .preview-empty {
    padding: 16px;
    text-align: center;
    color: var(--n-500);
  }

  .preview-item {
    padding: 12px 16px;
    &:not(:first-child) {
      border-top: 1px solid var(--n-300);
    }

    span {
      margin-right: 16px;
      color: var(--n-600);
    }
  }

  .jimo-select__value-container {
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.import-csv-identify-field-control {
  i {
    font-size: 10px;
  }
  .isax-document-upload {
    padding-left: 12px;
    color: var(--n-600);
    font-size: 20px;
  }
  img {
    padding-left: 12px;
  }
}
