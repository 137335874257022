.block-settings.hint {
  .position-picker {
    .position-icon-wrapper {
      display: flex;
      margin: auto 4px;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;

      i {
        margin: auto 4px;
        font-size: 14px;
        width: 32px;
        box-sizing: border-box;
        border-radius: 12px;
        text-align: center;
      }
    }
  }

  .action-picker {
    .actions-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .add-action-element-placeholder-btn {
      .label-left {
        .action-icon-wrapper {
          display: flex;
          width: 32px;
          height: 32px;
          box-sizing: border-box;
          border: 1px solid var(--n-100);
          box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
          border-radius: 12px;
          background: var(--n-100);

          i {
            margin: auto;
            font-size: 18px;
            box-sizing: border-box;
            border-radius: 12px;
            text-align: center;
            color: var(--n-600);
          }
        }
      }
    }
  }

  .change-icon-type {
    .type-icon-select {
      .jimo-select__single-value {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }

  .item-icon-picker {
    .preview-icon {
      display: flex;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;

      svg {
        height: 24px;
        width: 24px;
        margin: auto;
      }
      img {
        margin: auto;
        width: 24px;
        height: 24px;
      }
    }
  }

  .pulsating {
    .section-item-title {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  .button-content-input {
    height: 40px;
  }
}

.type-icon-select {
  .jimo-select__option {
    display: flex;
    gap: 8px;

    i {
      font-size: 20px;
    }
  }
}

.pulse-info-tooltip-content {
  max-width: 320px;

  .tooltip-children {
    gap: 16px;
    display: flex;
    flex-direction: column;

    .tooltip-tag {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .tooltip-content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
    }
  }
}

.hint-position-popup-content {
  width: 320px;
}
