._dev-button {
  padding: 24px;

  main {
    max-width: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-wrap: wrap;
    gap: 24px;
  }
}
