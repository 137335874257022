@import '../../Variables';

.poke-block-body {
  font-size: 16px;
  font-family: 'Inter';
  cursor: default;
  animation: block-body-in $animationBlockInDuration ease-in-out
    $animationBlockInDelay both;
  &.is-animating-out {
    animation: block-body-out $animationBlockOutDuration ease-in-out both;
  }
  &.align-center {
    text-align: center;
  }
  &.align-right {
    text-align: right;
  }
  &.is-in-poke-banner {
    padding: 0;
    margin: 0;
  }
  &.is-cursor:not(.in-builder) {
    display: inline;

    .word {
      opacity: 0;
      animation: fadeIn 0.2s forwards;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  p {
    margin: 0;
    overflow-wrap: break-word;
    min-height: var(--poke-block-body-font-size);
  }

  .a-div {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  span.variable.in-builder {
    border: 1px dashed;
    border-radius: 4px;
    padding: 2px 4px;
    margin: 0 2px;
  }
}

@-webkit-keyframes block-body-in {
  0% {
    opacity: 0;
    // transform: matrix(1, 0, 0, 1, 50, 0);
  }
  100% {
    opacity: 1;
    // transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
@keyframes block-body-in {
  0% {
    opacity: 0;
    // transform: matrix(1, 0, 0, 1, 50, 0);
  }
  100% {
    opacity: 1;
    // transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
@-webkit-keyframes block-body-out {
  0% {
    opacity: 1;
    // transform: matrix(1, 0, 0, 1, 0, 0);
  }
  100% {
    opacity: 0;
    // transform: matrix(1, 0, 0, 1, 50, 0);
  }
}
@keyframes block-body-out {
  0% {
    opacity: 1;
    // transform: matrix(1, 0, 0, 1, 0, 0);
  }
  100% {
    opacity: 0;
    // transform: matrix(1, 0, 0, 1, 50, 0);
  }
}
