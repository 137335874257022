.post-audience-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .loader-wrapper {
    display: flex;
    flex: 1 auto;
    align-items: center;
    justify-content: center;
  }

  .post-audience {
    overflow-y: auto;
    flex: 1 auto;
    align-items: center;
    padding: 32px;

    .post-audience-customize {
      display: flex;
      align-items: center;
      max-width: 1084px;
      margin: 0 auto;
      margin-bottom: 16px;
    }

    .divider {
      max-width: 1042px;
      padding: 0 22px;
      margin: 0 auto;
      margin-bottom: 24px;
    }
  }
}
