.list-users-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;

  .no-users {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 16px;
    margin: 64px;
    color: var(--content-tertiary, #9ca1ad);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .users-list-users {
    width: 100%;
  }
}
