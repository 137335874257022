.poke-block {
  background: #fff;
  padding: 24px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 24px 94px 0px rgba(0, 0, 0, 0.07);
  flex: 0;
  display: flex;
  flex-direction: column;

  .block-header {
    align-self: stretch;

    .block-title {
      color: #000;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .divider {
    width: calc(100% + 48px);
    margin: 0 -24px;
  }

  .block-content {
    align-self: stretch;
  }
}
