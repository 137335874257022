.troubleshooting-menu {
  background: #fff7f5;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 392px;
  min-height: 0;
  padding: 12px;

  .troubleshooting-menu-header {
    display: flex;

    .troubleshooting-menu-title {
      margin: auto 0;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 16px;
    }

    i {
      cursor: pointer;
      margin-left: auto;
      color: #9598a9;
      padding: 4px;
      font-size: 12px;
    }
  }

  .issues-list {
    .issue {
      position: relative;
      display: flex;
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0);
      padding: 12px;
      align-items: center;
      cursor: pointer;
      transition: all 300ms ease-in-out;

      .issue-icon {
        margin-right: 12px;
        height: 24px;
        width: 24px;
        border-radius: 6px;
        background: #ffe7e2;
        display: flex;
        align-self: flex-start;
        flex-shrink: 0;

        i {
          margin: auto;
        }
      }

      .issue-content {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .issue-title {
          color: #000;
          font-size: 14px;
          font-family: Inter;
          font-weight: 500;
        }

        .issue-location {
          color: rgba(0, 0, 0, 0.4);
          font-size: 14px;
          font-family: Inter;
          font-weight: 500;
        }
      }

      .icon-arrow-right {
        font-size: 18px;
        position: absolute;
        margin: auto;
        right: 16px;
        color: #1260eb;
        display: none;
      }

      &:hover {
        border: 1px solid rgba(0, 0, 0, 0.06);
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);

        .issue-title {
          color: #1260eb;
        }

        .icon-arrow-right {
          display: block;
        }
      }
    }
  }
}
