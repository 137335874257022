.block-settings.checklist-block {
  .section-item.triggers {
    .items-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .task-completion-auto-expand-button {
        .checkbox-wrapper {
          display: flex;

          i {
            margin: auto;
            font-size: 18px;
          }
        }
      }

      .triggers-btn,
      .task-completion-auto-expand-button {
        &.with-conditions,
        &.checked {
          background: var(--b-400);
          color: white;
        }

        .label-left {
          background: #ffffff33;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 12px;
          box-sizing: border-box;
        }
      }
    }
  }

  .preview-shadow-color {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }

  .background-picker {
    .preview-background-color {
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
    }
  }

  .snippet-position-picker {
    .alignment-figure-wrapper {
      .alignment-figure {
        margin: 40px 0;
      }
    }
  }

  .position-picker {
    .position-icon-wrapper {
      display: flex;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;

      i {
        margin: auto 4px;
        font-size: 14px;
        width: 32px;
        box-sizing: border-box;
        border-radius: 12px;
        text-align: center;
      }
    }
  }
}
