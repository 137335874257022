.checklist-render-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .checklist-wrapper {
    position: relative;

    .checklist-render {
      position: relative;
      overflow: hidden;
      z-index: 1;
      display: flex;
      overflow: visible;
    }
  }
}
