.block-settings.checklist-item-checkbox {
  .section {
    .section-title {
      margin-bottom: 0;
    }
  }

  .section-title-extra {
    .block-group {
      .icon-wrapper {
        background-color: var(--g-100);
      }
    }
  }

  .item-icon-picker {
    .preview-icon {
      display: flex;
      margin: auto 4px;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      flex-shrink: 0;

      svg {
        height: 24px;
        width: 24px;
        margin: auto;
      }
      img {
        margin: auto;
        width: 24px;
        height: 24px;
      }
    }
  }
}
