.step-node-selector-preview-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: visible;
  max-width: 300px;
  margin: auto;
  cursor: pointer;
  position: relative;

  .image-not-found {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    border-radius: 8px;
    background: #ffffffb2;
  }

  img {
    max-height: 100px;
    max-width: 100%;
    object-fit: contain;
    margin: 8px;
    pointer-events: none;
  }

  .pinned-element-empty-state {
    display: flex;
    padding: 12px;
    gap: 12px;
    border-radius: 8px;
    border: 1px dashed var(--b-400);
    align-items: center;
    justify-content: center;
    background: #ffffffb2;

    i {
      font-size: 24px;
    }

    .divider {
      height: 24px;
    }
  }

  .uploading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0000004d;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .visual-cue-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    width: fit-content;
    height: fit-content;
  }
}
