.priority-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .priority-activate-wrapper {
    display: flex;
    gap: 8px;

    .poke-control-priority {
      display: flex;
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      background: rgba(255, 255, 255, 0.2);
      width: fit-content;

      .ignore-poke-control {
        height: 40px;
        cursor: pointer;
        display: flex;
        gap: 8px;
        border-radius: 12px;
        border: 1px solid rgba(18, 96, 235, 0.4);
        background: linear-gradient(
            0deg,
            rgba(18, 96, 235, 0.06) 0%,
            rgba(18, 96, 235, 0.06) 100%
          ),
          #fff;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.06);
        padding: 8px 12px;
        align-items: center;
        color: #1260eb;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 600;

        i {
          cursor: pointer;
          font-size: 24px;
        }
      }

      .priority-input-wrapper {
        padding: 0 12px;
        display: flex;
        align-items: center;
        gap: 24px;
        color: #000;
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;

        &.is-ignoring-poke-control {
          opacity: 0.4;
          pointer-events: none;
        }

        .priority-input {
          .input {
            border-radius: 12px;
            background: #fff;
            display: flex;
            padding: 8px;
            align-items: flex-start;
            gap: 10px;
            color: #000;
            font-family: Inter;
            font-size: 18px;
            font-weight: 500;
            min-width: 40px;
          }
        }
      }
    }

    .priority-tooltip-trigger {
      margin: auto 0;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .priority-list-wrapper {
    display: flex;
    padding: 24px;
    flex-direction: column;
    gap: 24px;
    border-radius: 16px;
    background: #f3f3f3;

    .poke-priority-row {
      display: flex;
      padding: 8px;
      align-items: center;
      gap: 8px;
      border-radius: 8px;

      &.selected {
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }

      .poke-priority-number {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
        width: 92px;
        height: 32px;
        color: #000;
        font-family: Inter;
        font-size: 14px;

        font-weight: 500;
      }
      .poke-name {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .poke-matching-values {
        color: rgba(0, 0, 0, 0.4);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

.priority-tooltip-content {
  background-color: #ffffff;
  padding: 12px;
  color: #000000;
  box-sizing: border-box;
  border-radius: 12px;
  font-size: 14px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  max-width: 400px;

  .tooltip-children {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.modal-confirm-ignore-rate-limiting {
  width: 600px;

  .modal-title {
    padding: 24px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 0;
  }

  .content {
    padding: 24px;

    .info {
      font-size: 18px;
      margin-bottom: 24px;
    }

    .rate-limiting-settings {
      .rate-limit-group {
        white-space: no-wrap;
        display: flex;
        width: fit-content;
        align-items: center;
        margin: 12px 0;
        padding: 18px 16px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 12px;
        gap: 12px;
        font-size: 18px;
        color: #000000;

        .input-group {
          input {
            border: 1px dashed rgba(0, 0, 0, 0.15);
            border-radius: 12px;
            text-align: center;
            width: 50px;
            font-weight: 500;
            font-size: 18px;
            color: rgba(0, 0, 0, 0.5);
          }
        }

        .jimo-select {
          .jimo-select__control {
            border-radius: 12px;
          }
        }
      }
    }
  }
}
