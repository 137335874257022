.events-list-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 12px;
  flex: 1 auto;
  overflow: hidden;

  .new-event-btn {
    justify-content: center;
    width: 100%;
  }

  .search-wrapper {
    display: flex;

    .search-input {
      flex: 1 0 0;
      border: 1px solid var(--border-minimal, #e5e5e5);
      border-radius: 12px;
      overflow: hidden;

      .input {
        padding-left: 0;
      }
    }
  }

  .no-events {
    display: flex;
    padding-top: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 36px;
    flex: 1 0 0;
    align-self: stretch;

    .content {
      color: var(--content-tertiary, #9ca1ad);
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }
  }

  .events-list {
    margin: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    overflow-y: auto;

    .category {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;
      align-self: stretch;

      .category-list {
        gap: 16px;
        display: flex;
        flex-wrap: wrap;
        align-self: stretch;
        align-items: flex-start;

        .list-add-cta {
          cursor: pointer;
          display: flex;
          height: 42px;
          padding: 8px 12px;
          align-items: center;
          gap: 12px;
          border-radius: 12px;

          &.segment {
            &:hover {
              background: var(--purple-200, #ece6fd);
            }
          }

          .segment-icon-wrapper,
          .event-icon-wrapper {
            height: 32px;
            width: 32px;
            display: flex;
            border-radius: 8px;
            font-size: 18px;
            i {
              margin: auto;
              font-size: 18px;
            }
          }

          .item-content {
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 2px;
            flex: 1 0 0;
          }

          &.segment {
            .icon-wrapper {
              background: var(--purple-400-main, #bb68ff);
            }
            .title {
              color: var(--purple-500, #590e95);
            }
          }

          .icon-wrapper {
            color: #fff;
          }

          .item-content {
            .title {
              overflow: hidden;
              text-overflow: ellipsis;
              font-family: Montserrat;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 125%; /* 17.5px */
            }

            .subtitle {
              overflow: hidden;
              color: var(--content-secondary, #4d637b);
              text-overflow: ellipsis;
              white-space: nowrap;
              font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 18px */
            }
          }
        }

        .event-item {
          display: flex;
          border-radius: 12px;
          padding: 8px 12px;
          gap: 12px;
          border: 1px solid var(--n-300);
          align-items: center;
          transition: all 300ms ease-in-out 0s;
          cursor: pointer;

          &:hover {
            border-color: var(--b-400);

            .event-name {
              color: var(--b-400);
            }
          }

          .segment-icon-wrapper,
          .event-icon-wrapper {
            color: #000;
            display: flex;
            border-radius: 8px;
            font-size: 18px;

            i {
              margin: auto;
              font-size: 20px;
            }

            .segmentio-icon {
              display: flex;

              img {
                height: 24px;
                width: 24px;
              }
            }
          }
          .posthog-icon-wrapper {
            img {
              height: 20px;
              width: 20px;
            }
          }
          .mixpanel-icon-wrapper {
            img {
              height: 18px;
              width: 18px;
            }
          }

          .item-content {
            .event-type {
              overflow: hidden;
              color: var(--content-secondary, #4d637b);
              text-overflow: ellipsis;
              white-space: nowrap;
              font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
            }

            .event-query-selector {
              overflow: hidden;
              color: var(--content-primary, #071331);
              text-overflow: ellipsis;
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
            }

            .event-name {
              overflow: hidden;
              text-overflow: ellipsis;
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              transition: all 300ms ease-in-out 0s;
            }
          }
        }
      }
    }
  }
}

.new-event-dropdown-content {
  width: 576px;

  .new-event-dropdown-menu {
    .new-event-dropdown-item {
      padding: 12px;
      display: flex;
      gap: 12px;

      .icon-wrapper {
        display: flex;
        height: 32px;
        width: 32px;
        border-radius: 8px;

        &.element-event {
          background: var(--g-200);
        }

        &.page-event {
          background: var(--b-200);
        }

        i {
          margin: auto;
          font-size: 20px;
          color: var(--n-800);
        }
      }
    }
  }
}
