.toolbar-button {
  display: flex;
  height: 24px;
  min-width: 24px;
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
  color: var(--n-600);

  &:hover {
    background: var(--n-100);
    color: var(--n-700);
  }

  &.pressed {
    background: var(--n-100);
    color: var(--b-300);
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}
