.conditions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .last-step-text {
    padding: 16px;
    border-radius: 8px;
    background-color: rgba(18, 96, 235, 0.1);
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 12px;
  }

  .jimo-select {
    .jimo-select__value-container {
      padding: 8px;
      font-size: 12px;
    }

    .jimo-select__placeholder {
      font-size: 12px;
    }
  }

  .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 8px;

    i {
      font-size: 12px;
    }
    .jimo-select {
      flex: 1 1 auto;
      width: 220px;
    }

    .jimo-select__menu {
      width: inherit;
    }
  }

  .default-wrapper {
    padding: 16px;
    border-radius: 12px;
    border: 1px solid #d9d9d9;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .condition {
    border-radius: 12px;
    border: 1px solid #d9d9d9;
    position: relative;

    .group-wrapper {
      display: flex;
      flex-direction: column;
      padding: 16px;
      gap: 16px;
      box-sizing: border-box;

      &.jump-group {
        background: #f4f4f4;
        border-radius: 8px;
        padding: 12px;
        margin: 16px;

        .jump-step-wrapper {
          display: flex;
          align-self: flex-start;

          i {
            margin: auto;
            margin-right: 12px;
          }
        }
      }

      .delete-condition-btn {
        margin-right: -12px;

        i {
          font-size: 16px;
        }
      }

      .answer-group {
        display: flex;
      }

      .condition-values-select {
        min-width: 0;

        .jimo-select__control {
          border-style: dashed;
        }
      }

      .value-input {
        height: 36px;
        width: 220px;

        .input {
          padding: 8px;
          font-size: 14px;
        }
      }

      .pixel-picker {
        width: 220px;

        .input-group {
          height: 36px;

          .input {
            padding: 8px;
            font-size: 14px;
          }
        }

        .pixel-picker-slider {
          flex-grow: 1;
        }
      }

      .opinion-scale-dropdown-btn {
        border-radius: 12px;
        font-weight: 500;

        i {
          margin-right: 4px;
        }
      }
    }

    .opinion-scale-pick {
      display: flex;
      width: 100%;
      justify-content: space-evenly;

      .opinion-item {
        margin: auto;
        cursor: pointer;

        &:not(.is-active) {
          filter: grayscale(1);
        }
      }

      .emoji-animated-wrapper {
        cursor: pointer;
      }
    }
  }

  .add-condition-btn {
    width: 100%;
    margin: 0;
    padding: 8px 12px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    justify-content: center;

    &:hover {
      background: #ffffff !important;
      border: 1px solid #1260eb;
      color: #1260eb;
    }

    i {
      margin-right: 12px !important;
      font-size: 18px !important;
    }
  }
}
