.poke-layout-cursor-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;

  .poke-layout-cursor {
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow-y: auto;

    .layout-cursor-content {
      display: flex;
      flex-direction: column;
      gap: 12px;
      flex-grow: 1;

      .layout-cursor-dismiss-cross {
        position: absolute;
        top: 8px;
        right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        flex: 0 0 0px;
      }
    }
  }

  .jimo-label-wrapper {
    height: unset !important;
    justify-content: flex-start !important;
    padding: 8px 12px;
    padding-top: 0;
    min-height: 0;

    .poke-renderer-poke-label {
      flex: unset;
      background: transparent !important;

      .poke-label-content {
        background: transparent !important;
        padding: 0;
      }
    }

    .poke-jimo-label {
      flex: unset;
      background: transparent !important;

      .poke-label-content {
        background: transparent !important;
        padding: 0;
      }
    }
  }
}
