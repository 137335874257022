.checklist-title {
  font-size: 16px;
  font-family: 'Inter';
  cursor: default;
  position: relative;
  margin-right: 24px;
  z-index: 12;
  line-height: 150%;

  p {
    margin: 0;
    overflow-wrap: break-word;
    min-height: var(--checklist-header-title-font-size);
  }

  .a-div {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  span.variable.in-builder {
    border: 1px dashed;
    border-radius: 4px;
    padding: 2px 4px;
    margin: 0 2px;
  }

  .checklist-expand {
    position: absolute;
    right: -24px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    height: 100%;
    height: 24px;
    width: 24px;

    &.is-mini {
      i {
        transform: rotate(180deg);
      }
    }

    i {
      margin: auto;
      font-size: 16px;
      transition: transform 0.2s;
    }
  }
}
