.block-arrow {
  content: '';
  position: absolute;
  height: 12px;
  width: 12px;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);

  &.is-hint {
    animation: poke-fade-in 100ms ease-in-out forwards;
  }
}

.block-arrow-border-blocker {
  content: '';
  position: absolute;
  z-index: 1;
  border-radius: 2px;

  &.horizontal {
    height: 1px;
    width: calc(16.970562748477143px - 1px);
  }

  &.vertical {
    height: calc(16.970562748477143px - 1px);
    width: 1px;
  }
}
