.audience-section.experience-trigger {
  .audience-section-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 24px;

    .builder-channel-interactive-option {
      border-radius: 16px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      box-sizing: border-box;
      min-width: 0;
      &.trigger-segmentio-track-event {
        .title-description-wrapper {
          overflow: unset;
        }
      }

      .label-segmentio {
        color: var(--p-400);
      }
      .icon {
        width: 20px;
        font-size: 20px;
        color: rgba(0, 0, 0);

        &.active-hovered-icon {
          display: none;
        }
      }
      img {
        margin-right: 16px;
      }

      &.is-active {
        background: rgba(18, 96, 235, 0.08);

        &:hover {
          img {
            display: none;
          }
          .icon {
            &.default-icon {
              display: none;
            }
            &.active-hovered-icon {
              display: flex;
            }
          }
        }
      }

      .title-description-wrapper {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
