@import '../../../../Colors';

.s-settings-plan {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 100px !important;
  &.extra-p-b {
    padding-bottom: 300px !important;
  }

  .settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--n-300);
    padding-bottom: 32px;

    a {
      color: var(--b-400);
      display: flex;
      align-items: center;
      gap: 4px;

      &:hover {
        span {
          text-decoration: underline;
          text-underline-offset: 3px;
        }
      }
    }
  }

  .plan-options {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 48px;
    gap: 12px;
    box-shadow: 0px 24px 94px 0px #00000012;
    border-radius: 16px;
    background-color: #fff;
    margin-top: 8px;

    .vertical-divider {
      height: 30px;
      width: 1px;
      background-color: var(--n-300);
    }

    .plan-period {
      padding: 12px;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      .react-toggle-track {
        border-color: var(--b-400) !important;
        background-color: var(--b-400) !important;
      }
    }

    .plan-mau-wrapper {
      padding: 12px;
    }
    .plan-mau {
      display: flex;
      align-items: center;
      padding: 6px;
      padding-left: 6px;
      gap: 47px;
      border-radius: 12px;
      background-color: var(--n-100);
      border: 1px solid var(--n-300);

      .btn {
        padding: 8px;

        i {
          font-size: 15px;
        }
      }
      .btn-del {
        color: var(--b-400);
        &.is-disabled {
          color: var(--n-500);
        }
      }
      .btn-add {
        color: var(--b-400);
        &.is-disabled {
          color: var(--n-500);
        }
      }
      .mau-value {
        display: flex;
        align-items: center;
        gap: 4px;

        i.isax.isax-info-circle5 {
          display: inline-block;
          transform: rotate(180deg);
          font-size: 16px;
          color: var(--n-600);
        }
      }
    }
  }
  .element-billing-period {
    .option {
      padding: 10px 0;
    }
    span {
      color: #fff;
      margin-left: 8px;
      background-color: var(--r-400);
      border-radius: 12px;
      padding: 4px 8px;
    }
  }

  .plan-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
    &.loading {
      display: flex;
      justify-content: center;
    }
  }
}
