.integration-zapier {
  .integration-label.is-beta {
    .title {
      display: flex;

      &::after {
        background-color: rgb(0, 132, 255);
        border-radius: 4px;
        padding: 4px 8px;
        right: 10px;
        z-index: 2;
        top: 8px;
        font-size: 8px;
        text-transform: uppercase;
        font-weight: 800;
        content: 'Beta';
        display: block;
        color: #000;
        margin: auto 8px;
      }
    }
  }

  .actions-btn-group {
    display: flex;

    .settings-btn {
      margin-right: 12px;

      i {
        margin-right: 0 !important;
        font-size: 16px;
      }
    }
  }
}

.zapier-settings-modal {
  .settings-content {
    display: flex;

    .apikey-group {
      width: 100%;
      display: block;
      padding: 24px;

      .label {
        margin-bottom: 16px;
      }

      .input-wrapper {
        display: flex;
        gap: 12px;

        .apikey-input {
          flex: 1;
        }

        .get-access-btn {
          font-size: 14px;
          font-weight: 500;

          i {
            font-size: 14px;
            margin-left: 12px;
          }
        }
      }
    }
  }
}
