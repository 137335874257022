@import '../../Variables';

.poke-block-cta {
  display: flex;
  gap: 8px;
  animation: block-cta-in $animationBlockInDuration ease-in-out
    $animationBlockInDelay both;
  &.is-animating-out {
    animation: block-cta-out $animationBlockOutDuration ease-in-out both;
  }
  &.is-in-poke-banner {
    display: flex;
    border-top: 0;

    .cta-left,
    .cta-center,
    .cta-right {
      padding: 0;
    }
  }
  &.has-fill {
    .cta-left:not(.has-fill),
    .cta-center:not(.has-fill),
    .cta-right:not(.has-fill) {
      flex-grow: 0;
    }
  }

  .cta-left,
  .cta-center,
  .cta-right {
    display: flex;
    gap: 8px;
    align-items: center;
    flex: 1 1 0px;

    &.is-empty {
      padding: 0;
      display: none;
    }

    &.has-fill {
      flex-grow: 1;
    }
  }
  .cta-center {
    justify-content: center;
  }
  .cta-right {
    justify-content: flex-end;
  }

  .cta-item {
    display: inline-flex;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    border: 1px solid transparent;
    white-space: nowrap;
    height: fit-content;
    justify-content: center;
    flex-basis: 0px;

    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 0.6;
    }
    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

@-webkit-keyframes block-cta-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes block-cta-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes block-cta-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes block-cta-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
