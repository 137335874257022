$opinion-start-size: 30px;
$opinion-start-lineThickness: 4px;

.opinion-star {
  position: relative;
  width: $opinion-start-size;
  height: $opinion-start-size;
  cursor: pointer;

  &.animate {
    .opinion-start-sparkle .opinion-start-sparkle-line::before {
      animation: opinion-star-line 1s ease both;
    }

    .opinion-start-star {
      animation: opinion-star-star 1s ease both;
    }
  }
  &.checked {
    .opinion-start-star {
      fill: #ffac33;
    }
  }
}

.opinion-start-sparkle-line {
  position: absolute;
  width: 100%;
  height: 100%;

  &::before {
    position: absolute;
    z-index: 1;
    top: 30%;
    width: $opinion-start-lineThickness;
    height: 0;
    left: calc(50% - 2px);
    border-radius: $opinion-start-lineThickness/2;
    background: #fa733e;
    will-change: top, height;
    content: '';
  }

  &:nth-child(1) {
    transform: rotate(40deg);
  }

  &:nth-child(2) {
    transform: rotate(110deg);
  }

  &:nth-child(3) {
    transform: rotate(180deg);
  }

  &:nth-child(4) {
    transform: rotate(250deg);
  }

  &:nth-child(5) {
    transform: rotate(320deg);
  }
}

.opinion-start-star {
  z-index: 1;
  width: 100%;
  height: 100%;
  fill: #dee0e0;
}

@keyframes opinion-star-line {
  0% {
    top: 30%;
    height: 0;
  }

  40% {
    opacity: 1;
    height: 14px;
  }

  100% {
    opacity: 0;
    top: 10%;
    height: 0;
  }
}

@keyframes opinion-star-star {
  0% {
    transform: scale(1);
  }

  20% {
    fill: #ffac33;
    transform: scale(0);
  }

  30% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.1);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    fill: #ffac33;
    transform: scale(1);
  }
}

@keyframes opinion-star-background {
  0% {
    border-width: $opinion-start-size/2;
    transform: scale(0);
  }

  90% {
    transform: scale(1);
  }

  100% {
    border-width: 0;
  }
}
