.all-people {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 32px;
  background-color: var(--b-100);
  flex-grow: 1;

  .segment-attributes-editor-wrapper {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 24px 94px 0px rgba(0, 0, 0, 0.07);
  }

  .list-users-wrapper {
    position: relative;
    padding: 24px;

    &.requires-lookup {
      .list-users-content {
        pointer-events: none;
        opacity: 0.2;
      }

      .list-users-refresh {
        display: block;
      }
    }

    .list-users-content {
      align-self: stretch;
    }
    .list-users-title {
      color: #000;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
    }

    .users-list-users {
      display: flex;
      flex-direction: column;

      .list {
        display: flex;
        flex-direction: column;

        .card-user {
          &:hover {
            background-color: white;
          }
        }

        .users-pagination {
          margin: auto;
        }
      }
    }

    .list-users-refresh {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
    }
  }
}

.s-user-attributes-editor-content {
  .dropdown-content {
    .block-categories {
      max-height: 400px;
      overflow-y: scroll;
    }
  }
}
