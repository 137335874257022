.checklist-task-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px;
  position: relative;
  border-bottom: 1px solid transparent;
  z-index: 12;

  &.clickable {
    cursor: pointer;
  }

  .task-header {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-grow: 1;
    z-index: 12;
  }

  .task-content {
    margin: auto 0;
    margin-left: 42px;
    z-index: 12;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
    align-self: stretch;

    &.has-paragraph {
      margin-top: 0;
    }

    &.has-additional-content:not(.has-paragraph) {
      margin-top: 6px;
    }

    .task-collapsable-content {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .task-ctas {
        display: flex;
        gap: 8px;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }
}
