.import-csv-map-field {
  &:not(.is-mapped) {
    opacity: 0.6;
  }
  &.is-disabled {
    background-color: var(--n-100);
    border-radius: 12px;

    .field-header {
      cursor: default;
    }
    .field-footer {
      background-color: var(--n-200);
    }
  }

  .field-header {
    border: 1px solid var(--n-300);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .name-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;

      .import-name {
        word-break: break-all;
      }
      .row-count {
        color: var(--n-700);
      }
    }
    .isax-document-upload {
      font-size: 18px;
    }
    .icon-checkbox-a {
      color: var(--b-400);
    }
  }

  .field-preview {
    border-left: 1px solid var(--n-300);
    border-right: 1px solid var(--n-300);
    border-bottom: 1px solid var(--n-300);
    max-height: 240px;
    overflow-y: auto;
  }

  .field-footer {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-left: 1px solid var(--n-300);
    border-right: 1px solid var(--n-300);
    border-bottom: 1px solid var(--n-300);
    background-color: var(--n-100);
    padding: 6px;
    color: var(--n-700);

    & > div {
      margin-bottom: 4px;
    }
  }

  .preview-empty {
    padding: 16px;
    text-align: center;
    color: var(--n-500);
  }

  .preview-item {
    padding: 12px 16px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    &:not(:first-child) {
      border-top: 1px solid var(--n-300);
    }

    span {
      margin-right: 16px;
      color: var(--n-600);
    }
  }

  .jimo-select__single-value {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }
  .jimo-select__value-container {
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .label-custom-attribute {
    background-color: var(--n-100);
    margin-left: auto;
    padding: 2px 6px;
    border-radius: 8px;
  }
}

.import-csv-map-field-control {
  i {
    font-size: 10px;
  }
  .icon-jimo {
    color: var(--n-800);
    padding-left: 12px;
    font-size: 20px;
  }
}
