.settings-tour-seen {
  .section-item {
    display: flex;
    gap: 12px;
    align-items: center;

    .section-item-content {
      width: 240px !important;
      margin-left: auto;
    }
  }
}
