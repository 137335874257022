.s-onboarding-waiting {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 24px 0;
  box-sizing: border-box;
  color: #fff;

  .cover-peoples {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: fade-in 200ms ease-in-out 500ms both;
    background-repeat: no-repeat;
    background-size: 20%;
    &.people-left {
      left: -35px;
      background-position: left center;
      background-image: url(./../../assets/people-left.png);
    }
    &.people-right {
      left: unset;
      right: -35px;
      background-position: right center;
      background-image: url(./../../assets/people-right.png);
    }
  }
  .main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 530px;
    position: relative;
    z-index: 2;
    @media (max-width: 450px) {
      width: 370px;
    }

    img {
      border-radius: 6px;
    }
    .title {
      font-size: 32px;
      font-weight: 500;
      margin: 32px 0 6px 0;
      font-family: 'Montserrat', sans-serif;
      animation: fade-in 200ms ease-in-out 500ms both;
    }
    .subtitle {
      font-size: 20px;
      font-weight: 200;
      text-align: center;
      max-width: 420px;
      animation: fade-in 200ms ease-in-out 500ms both;
    }
  }
}

.s-onboarding-calendly {
  height: 100%;

  .onboarding-calendly {
    height: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;

    .content {
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 530px;
      position: relative;
      z-index: 2;
      @media (max-width: 450px) {
        width: 370px;
      }

      .title {
        font-size: 32px;
        font-weight: 500;
        margin: 32px 0 6px 0;
        font-family: 'Montserrat', sans-serif;
        animation: fade-in 200ms ease-in-out 500ms both;
      }
      .subtitle {
        font-size: 20px;
        font-weight: 200;
        text-align: center;
        max-width: 420px;
        animation: fade-in 200ms ease-in-out 500ms both;
      }
    }

    .calendly-inline-widget {
      height: 100% !important;
    }
  }
}
