.poke-builder-layout {
  background: #f2f1f3;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  z-index: 0;

  &.transparent {
    background: transparent;
  }

  .poke-builder-component {
    flex: 1 auto;
    min-height: 0;
    display: flex;

    .builder-component-loader-wrapper {
      height: 100%;
      width: 100%;
      display: flex;

      .loader-wrapper {
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
      }
    }
  }

  .deprecated-builder-indicator {
    border-top: 1px solid #0000001a;
    background-color: var(--o-200);
    height: 34px;
    width: 100%;
    text-align: center;
    z-index: 1000000;
    overflow: hidden;
    display: flex;

    .deprecated-builder-content {
      margin: auto;
      display: flex;
      gap: 8px;

      .learn-more {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.post-builder-layout {
  background: #f2f1f3;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  z-index: 0;

  .post-builder-component {
    flex: 1 auto;
    min-height: 0;
    display: flex;

    .builder-component-loader-wrapper {
      height: 100%;
      width: 100%;
      display: flex;

      .loader-wrapper {
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
      }
    }
  }
}
