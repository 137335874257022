@import '../../../../Colors';

.list-evolution-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  color: black;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: #ffffff;
  font-family: 'Nunito', sans-serif;
  transition: all 400ms ease-in-out 0s;
  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.08);

    .title {
      text-decoration: underline;
    }
    .actions-wrapper {
      display: flex;
    }
  }

  .divider {
    background-color: rgba(0, 0, 0, 0.06) !important;
  }
  .actions-wrapper {
    display: none;
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 1;

    .btn {
      margin-left: 8px;
    }
  }

  img {
    max-width: 100%;
  }

  .top {
    width: 100%;
    box-sizing: border-box;

    .tag-wrapper {
      display: flex;

      .tag-button-wrapper {
        margin-right: 10px;
        .btn-tag {
          align-self: flex-start;
          margin-bottom: 8px;

          flex: 1 0 auto;
        }
      }
    }
    .infos {
      width: 100%;

      .title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 4px;
      }
      .description {
        width: 100%;
        font-size: 14px;

        img {
          max-width: 100%;
        }
        a {
          color: $colorPrimary;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }

        .iframe-wrapper {
          position: relative;
          padding-bottom: 56.25%; /* 16:9 */
          height: 0;

          &:hover {
            iframe {
              transform: scale(1.01);
            }
          }

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
            transition: all 100ms ease;
          }
        }
      }
    }

    .post-labels {
      display: flex;
      padding: 24px;
      padding-bottom: 0;
      gap: 8px;

      .label {
        padding: 4px 8px;
        border-radius: 16px;
        &.is-draft {
          background-color: var(--n-100);
          color: var(--n-800);
        }
        &.is-live {
          background-color: var(--g-100);
          color: var(--g-500);
        }
        &.is-scheduled {
          background-color: var(--p-100);
          color: var(--p-500);
        }
        &.is-expired {
          background-color: var(--o-100);
          color: var(--o-500);
        }
      }
    }

    .jimo-post {
      box-shadow: none;
    }
  }

  .statistics {
    display: flex;
    align-items: center;
    padding: 0 24px;
    padding-bottom: 24px;
    width: 100%;
    box-sizing: border-box;
    gap: 12px;

    i {
      font-size: 16px;
      margin-right: 8px;
    }

    .stat-item {
      border-radius: 16px;
      border: 1px solid var(--n-300);
      display: flex;
      align-items: center;
      padding: 8px 12px;
      font-size: 16px;

      i {
        font-size: 24px;
      }
    }

    .stats-segments {
      display: flex;
      align-items: center;
      font-weight: 600;

      i {
        font-size: 12px;
        margin-right: 8px;
      }

      .segments-list {
      }
    }
  }

  &.is-fake {
    opacity: 0.2;
  }
}
