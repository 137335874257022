.block-settings.general {
  .preview-shadow-color {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }

  .changelog-language-select {
    .jimo-select {
      &__single-value,
      &__option {
        display: flex;
        gap: 8px;
        align-items: center;
      }

      &__menu-list {
        overflow-x: hidden;
      }
    }
  }
}
