.checklist-progress {
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
  position: relative;
  margin-top: 16px;
  z-index: 12;

  .progress-bar-container {
    background-color: #3f87e8; // Progress BG
    border-radius: 32px;
    height: 10px;
    flex-grow: 1;

    .progress-bar {
      background-color: #ffffff; // Neutral/White
      border-radius: 26px;
      height: 100%;
      transition: width 0.3s ease-out;
    }
  }

  .progress-steps {
    display: flex;
    gap: 12px;
    flex-grow: 1;
    height: 10px;

    .progress-step {
      border-radius: 32px;
      height: 10px;
      width: 10px;
      flex: 1 1 auto;
      transition: all 0.3s ease-out;
      background-size: 200% !important;

      &.completed {
        background-position: left !important;
      }
    }
  }

  .progress-count {
    color: #ffffff; // Neutral/White
    font-family: 'Inter', sans-serif;
    font-weight: medium;
    font-size: 14px;
  }
}
