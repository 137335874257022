.poke-overview {
  .overview-content {
    display: flex;
    gap: 24px;
    overflow-x: auto;

    &.is-banner {
      .overview-item {
        width: 100%;
      }
    }

    &.is-checklist {
      .overview-item {
        flex: 1 1 0px;
      }
    }

    .empty-state-wrapper {
      display: flex;
      width: 320px;
      max-width: 320px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      margin: auto;
    }

    .overview-item {
      position: relative;
      display: flex;
      flex-flow: column;
      gap: 16px;

      &:hover {
        .step-preview {
          .btn-edit {
            display: flex;
          }
        }

        .step-name {
          color: #1260eb;
        }
      }

      .step-preview {
        position: relative;
        height: 180px;
        border-radius: 12px;
        overflow: hidden;
        border: 2px solid var(--border-minimal, #e5e5e5);
        background: var(--surface-secondary, #f0f2f4);
        box-sizing: border-box;
        min-width: 240px;

        .btn-edit {
          position: absolute;
          top: 6px;
          right: 6px;
          display: none;
          z-index: 1;
        }

        .label-hidden-wrapper {
          position: absolute;
          right: 6px;
          bottom: 6px;
          z-index: 1;
        }
      }

      .step-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;

        .step-group {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 4px;

          .step-index {
            color: var(--neutral-700, var(--neutral-neutral-700, #4d637b));
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 18px */
          }

          .step-name {
            overflow: hidden;
            color: var(
              --neutral-800-black,
              var(--neutral-neutral-800, #071331)
            );
            text-overflow: ellipsis;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 125%; /* 20px */
          }
        }

        .step-type {
          display: flex;
          padding: 4px 8px;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
          border: 1px solid var(--border-minimal, #e5e5e5);
          background: var(--surface-primary, #fff);
          color: var(--content-primary, #071331);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 21px */

          i {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.tooltip-hidden-step-content {
  width: 310px;

  .tooltip-children {
    display: flex;
    flex-direction: column;
  }
}
