.circular-progress {
  .base {
    stroke: #e5e5e5;
  }
  .tracking {
    stroke: #1260eb;
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
}

.line-progress {
  width: 100%;
  position: relative;

  .base {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .tracking {
    background-color: #409af8;
    transition: all ease-in-out 0.35s;
    width: 50%;
    position: relative;
    z-index: 1;
  }
}
