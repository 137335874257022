.effects-section {
  .select-group-wrapper {
    .effects-select.jimo-select__single-value {
      display: flex;
      align-items: center;
      gap: 8px;

      i {
        font-size: 20px;
      }
    }
  }
}

.effects-select {
  .jimo-select__option {
    display: flex;
    gap: 8px;

    i {
      font-size: 20px;
    }
  }
}
