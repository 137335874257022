.circular-progress {
  .circular-progress-track {
    transition: stroke 0.3s ease;
  }

  .circular-progress-bar {
    transition: stroke-dashoffset 0.5s ease;
  }

  text {
    font-size: 13px;
    fill: #ffffff;
  }
}
