.attribute-property {
  display: flex;
  align-items: center;
  border-radius: 16px;
  height: 40px;
  width: 160px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 12px;

  .icon-wrapper {
    height: 24px;
    width: 24px;
    border-radius: 8px;
    display: flex;
    margin-right: 8px;
    flex-shrink: 0;

    i {
      font-size: 16px;
      margin: auto;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    min-width: 0;

    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
