.s-import-csv {
  background-color: rgba(247, 249, 254, 1);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 48px;
  box-sizing: border-box;

  .header {
    display: flex;
    padding: 26px 48px;

    .header-title {
      flex: 1;
    }

    .actions-wrapper {
      display: flex;
      gap: 12px;

      .icon-arrow-right {
        font-size: 10px;
      }
    }
  }

  .loader-wrapper {
    display: flex;
    width: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .alert-in-progress,
  .alert-done,
  .alert-error {
    width: 90%;
    max-width: 1280px;
    margin: 0 auto;
  }

  section {
    width: 90%;
    max-width: 1280px;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0px 0px 94px 0px rgba(0, 0, 0, 0.04);
    margin: 0 auto;
    box-sizing: border-box;
    &.is-disabled {
      .section-header {
        cursor: not-allowed !important;
      }
      opacity: 0.4;
    }

    .section-header {
      padding: 24px;
      cursor: pointer;
      display: flex;
      gap: 12px;
      align-items: center;

      .header-texts-wrapper {
        display: flex;
        flex-direction: column;
      }

      .header-title {
        display: flex;
        align-items: center;
      }

      .number {
        display: inline-block;
        border: 2px solid #000;
        border-radius: 50%;
        width: 33px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .section-body {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 0 24px 24px 24px;
    }
  }

  .section-1 {
    .input-csv-wrapper {
      position: relative;
    }
    .actions-wrapper {
      position: absolute;
      right: 8px;
      top: 6px;
      display: flex;
      gap: 6px;

      .is-icon-only {
        padding: 8px;
        i {
          font-size: 20px;
        }
      }
    }

    .btn-choose-csv {
      justify-content: center;
    }

    .btn-next {
      align-self: flex-start;

      i.icon-arrow-right {
        transform: rotate(90deg);
        font-size: 10px;
      }
    }
  }

  .section-2 {
    .toggle-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .identify-fields {
      display: flex;
      gap: 12px;
      align-items: center;

      .import-csv-identify-field {
        flex: 1;
      }
      .icon-pause-rounded {
        transform: rotate(90deg);
        font-size: 20px;
      }
      .icon-forward {
        font-size: 20px;
        color: var(--n-700);
      }
    }

    .btn-next {
      align-self: flex-start;

      i.icon-arrow-right {
        transform: rotate(90deg);
        font-size: 10px;
      }
    }
  }

  .section-3 {
    .toggle-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .fields-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 24px;
    }

    .btn-next {
      align-self: flex-start;

      i.icon-arrow-right {
        transform: rotate(90deg);
        font-size: 10px;
      }
    }
  }
}
