.preview-wrapper {
  display: flex;
  flex: 1 auto;
  min-height: 0;

  .steps-list-wrapper {
    position: relative;
    width: 312px;
    padding: 24px 16px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    overflow: auto;
    background-color: #ffffff;

    .infos {
      padding: 16px;
      background: rgba(0, 0, 0, 0.03);
      border-radius: 8px;
      font-size: 16px;
      margin-bottom: 16px;
    }

    .list-wrapper {
      .block-wrapper {
        margin-bottom: 16px;

        .block-content {
          border-bottom: 0;

          .content {
            padding: 0;
          }
        }
      }
    }

    .tour-footer-preview {
      height: 57px;
      width: calc(100% - 32px);
      position: absolute;
      margin-bottom: 16px;
      bottom: 0;
      background: #ffffff;
      display: flex;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: 0px 9px 16px rgb(12 23 37 / 16%);
      border-radius: 16px;
      z-index: inherit;
      overflow-x: auto;

      .tour-steps-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: auto;

        .group-steps {
          display: flex;
          background: rgba(206, 206, 206, 0.2);
          border-radius: 16px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          padding: 4px;

          .tour-step-separator {
            color: rgba(0, 0, 0, 0.2);
            font-size: 8px;
          }

          .tour-step {
            position: relative;
            cursor: pointer;
            width: 40px;
            height: 40px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 12px;
            display: flex;

            &.selected {
              background: rgba(18, 96, 235, 0.1);
              border: 1px solid #1260eb;
            }

            i {
              margin: auto;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .preview-group-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 1 auto;

    .preview-group {
      z-index: 1;
      background-image: url(./../../../../imgs/bg-preview.jpg);
      background-color: #efefef;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;

      .main-wrapper {
        height: 100%;
        position: relative;
        display: flex;

        .poke-render-preview {
          margin: auto;
        }

        .preview-dot {
          height: 100%;

          .dot {
            top: 15%;
            left: 15%;
          }

          .preview-snippet {
            top: 18%;
            left: 20%;
          }
        }
      }

      .actions {
        display: flex;
        margin: auto;
        transition: all 400ms ease-in-out 0s;
        position: absolute;
        right: 8px;
        top: 8px;
        z-index: 2147483647;

        &.banner {
          top: 68px;
        }

        .btn {
          align-self: center;
          margin-right: 8px;
          &:nth-last-child(1) {
            margin-right: 0;
          }
        }
      }
    }

    .preview-footer {
      z-index: 2;
      padding: 16px;
      background-color: #ffffff;
      display: flex;

      .use-template-btn {
        margin-left: auto;
      }
    }
  }
}
