.s-builder-tracker {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f7f9fe;

  .loader {
    margin: auto;
  }

  .tracker-builder-content-wrapper {
    overflow-y: auto;
    display: flex;
    flex: 1 1 auto;

    .tracker-builder-content {
      display: flex;
      flex-flow: column;
      grid-gap: 24px;
      gap: 24px;
      padding: 72px;
      overflow-y: auto;
      overflow-x: hidden;
      flex: 1 1 auto;
      max-width: 1042px;
      margin: auto;
      margin-top: 0;

      & > .divider {
        margin: 0 12px;
        width: calc(100% - 24px);
      }
    }
  }
}
