.s-success-tracker {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-flow: column;
  background: white;

  .header {
    display: flex;
    margin: 26px;
    flex: 0 0 50px;
    align-items: center;

    .title {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 36px;
    }

    .actions-wrapper {
      margin-left: auto;
      display: flex;
    }
  }

  .loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 1 0 0;
  }

  .trackers-wrapper {
    min-height: 0;
    overflow-y: auto;
    flex: 1 auto;
  }

  .tabs-row {
    margin: 0;
    padding: 0 32px;

    .tabs {
      width: 100%;

      .tab-list {
        margin-bottom: 0;

        .tab-list-item {
          position: relative;
          align-items: center;
          padding: 0;
          transition: all 300ms ease;

          &:hover {
            .label-wrapper {
              background-color: rgba(18, 98, 235, 0.08);
              color: #1260eb;
            }
            .pokes-count {
              .count {
                display: none;
              }
              .evolution-view-menu {
                display: flex;
              }
            }
          }
          &.tab-list-active {
            font-weight: 400;

            .label-wrapper {
              background-color: rgba(18, 98, 235, 0.08);
            }
            .pokes-count {
              color: #000000;
            }
          }

          .label-wrapper {
            padding: 8px 8px;
            margin-bottom: 4px;
            border-radius: 6px;
          }
        }
      }
    }
  }

  .success-tracker-content {
    min-height: 0;
    display: flex;
    flex: 1 auto;
    background-color: var(--b-100);
  }
}
