.template-preview-wrapper {
  display: flex;
  padding: 36px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--b-100);
  overflow: hidden;

  &.template-steps-hidden {
    .preview-group {
      margin: 98px 0;
    }
    .template-steps {
      display: none;
    }
  }

  .preview-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    position: relative;
    z-index: 1;
    border-radius: 16px;
    box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.13);
    overflow: hidden;

    .preview-group-header {
      display: flex;
      padding: 8px 12px;
      align-items: flex-start;
      align-self: stretch;
      background: var(--white);
      display: flex;
      align-items: center;
      gap: 16px;
      flex: 1 0 0;

      .left-group {
        display: flex;
        align-items: flex-start;
        gap: 4px;

        .btn-1,
        .btn-2,
        .btn-3 {
          width: 8px;
          height: 8px;
          border-radius: 12px;
        }

        .btn-1 {
          border: 0.6px solid #c7493e;
          background: #ee6a5f;
        }

        .btn-2 {
          border: 0.6px solid #cd9b35;
          background: #f4be50;
        }

        .btn-3 {
          border: 0.6px solid #39a430;
          background: #5fc454;
        }
      }

      .center-group {
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        border-radius: 6px;
        border: 1px solid #efefef;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }
    }

    .main-wrapper {
      height: 100%;
      width: 100%;
      position: relative;
      display: flex;
      background-image: url(./../../../../../../imgs/bg-preview.jpg);
      background-color: #efefef;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;

      .poke-render-preview {
        margin: auto;
      }

      .preview-dot {
        height: 100%;

        .dot {
          top: 15%;
          left: 15%;
        }

        .preview-snippet {
          top: 18%;
          left: 20%;
        }
      }
    }

    .actions {
      display: flex;
      margin: auto;
      transition: all 400ms ease-in-out 0s;
      position: absolute;
      right: 8px;
      top: 8px;
      z-index: 2147483647;

      &.banner {
        top: 68px;
      }

      .btn {
        align-self: center;
        margin-right: 8px;
        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
  }

  .template-steps {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    overflow-x: auto;
    gap: 8px;
    align-items: center;

    .template-step {
      height: 180px;
      width: 120px;
      display: flex;
      padding: 8px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      border-radius: 8px;
      cursor: pointer;
      transition: all 200ms ease-in-out 0s;
      flex-shrink: 0;

      &:hover,
      &.selected {
        background: rgba(0, 0, 0, 0.03);
      }

      .preview {
        height: 110px;
        align-self: stretch;
        position: relative;
        background-image: url(./../../../../../../imgs/bg-preview.jpg);
        background-size: cover; /* <------ */
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 8px;
      }

      .type {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
      }
    }
  }
}
