.modal-install {
  max-height: 90%;
  overflow-y: auto;
  width: unset;
  box-sizing: border-box;
  padding: 0;

  .modal-title {
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 24px;
    font-family: 'Montserrat';
    margin-bottom: 0;
  }

  .content {
    margin: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .subtitle {
      color: #000;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .actions {
    margin: 0 24px 24px 24px;
    justify-content: flex-end;

    .btn {
      margin-left: 8px;
    }
  }
}
