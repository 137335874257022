.media-block-image-input-wrapper {
  display: flex;
  border-radius: 12px;
  border: 1px solid var(--n-100);
  position: relative;

  img {
    width: 100%;
    max-height: 100px;
    object-fit: cover;
  }

  .image-input-actions {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    gap: 8px;
  }
}
