.vote-wrapper {
  .face {
    height: 42px;
    width: 42px;
    border-width: 4px;
    border-style: solid;
    box-sizing: border-box;
    border-radius: 100px;
    border-color: white;
    position: absolute;
  }

  .face2 {
    margin-left: 24px;
  }

  .face3 {
    margin-left: 48px;
  }

  .number-vote {
    align-self: center;
    font-size: 16px;
    font-weight: 400;
  }

  .action-btn {
    margin-left: auto;
    background-color: #4099f7;
    color: white;
    border-color: transparent;

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0.2)
        ),
        #4099f7;
    }

    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        #4099f7;
      transform: scale(0.9);
    }

    &.disabled {
      background-color: #efefef;
      color: #a6a5a5;
    }
  }
}
