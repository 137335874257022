.triggers-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .empty-state-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .empty-state-block {
      width: 100%;
      max-width: 400px;
      text-align: center;
    }
  }

  .add-trigger-btn {
    margin-right: auto;
  }
}
