.content-manager-wrapper {
  display: flex;
  flex-direction: column;
  z-index: 2;
  min-height: 0;
  overflow: hidden;
  background: #fff;
  flex-grow: 1;
  position: relative;

  .drag-and-drop-help {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
    margin: 0 auto;

    .animation-drag {
      width: 33px;
      display: flex;
    }
  }

  .divider {
    margin: 0 16px;
    width: calc(100% - 32px);
  }

  .content-manager {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 0;
    padding: 16px;
    overflow-y: auto;
    padding: 0;
    overflow-y: auto;

    &.is-loading {
      visibility: hidden;
      height: 0;
    }

    .content-wrapper {
      padding: 16px;

      .sections {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .section {
          display: flex;
          flex-direction: column;
          gap: 12px;

          .section-list {
            max-width: 100%;
            box-sizing: border-box;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-gap: 8px;
            padding: 0;
            gap: 16px;

            .section-item {
              display: flex;
              flex-direction: column;
              gap: 8px;
              flex: 1 auto;

              &.is-disabled {
                .draggable-wrapper {
                  pointer-events: none;

                  .image-wrapper {
                    border: none;
                    background: transparent;
                    box-shadow: none !important;
                  }
                }
              }

              .draggable-wrapper {
                cursor: grab;

                &:hover {
                  .image-wrapper {
                    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
                    border-color: var(--b-400);
                    border-style: dashed;
                    transform: rotate(2deg);
                  }
                }

                .image-wrapper {
                  cursor: grab;
                  position: relative;
                  overflow: hidden;
                  transform: translate(0, 0);
                  background: white;
                  border: 1px solid var(--n-300);
                  border-radius: 12px;
                  overflow: hidden;
                  transition: transform 300ms ease-in-out,
                    box-shadow 300ms ease-in-out;
                  display: flex;

                  img {
                    height: 100%;
                    width: 100%;
                    transition: all 300ms ease-in-out;
                    object-fit: cover;
                  }
                }
              }

              .title {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
