.live-preview {
  width: 100%;
  height: 100%;

  &.hidden-loading {
    display: none;
  }

  &.is-scaled-down {
    iframe {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) !important;
      pointer-events: none;
      position: absolute;
      max-height: unset !important;
    }
  }

  // Top bar
  .jimo-topbar-iframe {
    position: absolute;
    border: none;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2147483636;
    &.hidden-loading {
      visibility: hidden;
    }
    &.concept-test-mode {
      background-color: transparent;
      backdrop-filter: blur(2em);
    }
  }

  // Pop in
  .jimo-pop-in-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2147483646;
    display: flex;
    align-items: center;
    justify-content: center;

    &.hidden-loading {
      visibility: hidden;
    }

    .jimo-modal-overlay {
      background-color: unset;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(17, 17, 17, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2147483646;

      &.hidden-loading {
        visibility: hidden;
      }
      &.soft {
        background-color: rgba(17, 17, 17, 0.5);
      }
      &.hard {
        background-color: rgba(17, 17, 17, 0.9);
      }
    }

    .jimo-popin-iframe {
      width: 40%;
      padding: 0;
      outline: none;
      background-color: transparent;
      border-radius: var(--widgetRoundnessCards);
      border: none;
      box-sizing: border-box;
      overflow-y: auto !important;
      overflow-x: hidden !important;
      color: #000;
      z-index: 2147483649;
      max-height: 60%;

      @media screen and (max-width: 600px) {
        width: 95%;
      }
      @media screen and (min-width: 601px) and (max-width: 1199px) {
        width: 80%;
      }
      &.concept-test-mode {
        width: 100%;
        backdrop-filter: blur(2em);
        max-height: unset;
        z-index: 2147483636 !important;
      }
    }
  }

  // Snippet
  .jimo-snippet-iframe {
    position: absolute;
    left: 16px;
    bottom: 16px;
    border: 0;
    background-color: transparent;
    border-radius: var(--widgetRoundnessCards);
    z-index: 2147483636;
    box-shadow: 0px 9px 16px rgb(12, 23, 37, 0.16);

    &.is-right {
      left: unset;
      right: 16px;
    }
    &.hidden-loading {
      visibility: hidden;
    }
    &.poke-animated {
      animation-timing-function: ease;
      animation-duration: 1000ms;
      animation-fill-mode: forwards;
    }
    &.concept-test-mode {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      backdrop-filter: blur(2em);
      z-index: 2147483636 !important;
    }
  }

  // Dot
  .dot {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    animation: dot-pulse 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    // when no colors provided to box-shadow, the property color is used
  }

  .jimo-dot-iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    border: 0;
    background-color: transparent;
    border-radius: var(--widgetRoundnessCards);
    z-index: 2147483;
    width: 60px;

    &.is-right {
      left: unset;
      right: 16px;
    }
    &.hidden-loading {
      visibility: hidden;
    }
    &.concept-test-mode {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      backdrop-filter: blur(2em);
      position: absolute !important;
      z-index: 2147483636 !important;
    }

    &.is-snippet {
      box-shadow: 0px 9px 16px rgb(12 23 37 / 16%);
      width: 100%;
      z-index: 2147483636;
    }
    &.target-not-identified {
      visibility: hidden;
    }
  }

  // Tooltip
  .jimo-tooltip-wrapper {
    &.hidden-loading {
      visibility: hidden;
    }
    &.target-not-identified {
      visibility: hidden;
    }
    .jimo-tooltip-backdrop {
      &.jimo-tooltip-backdrop-highlighted {
        border-radius: 4px;
        position: absolute;
        pointer-events: none;
        box-shadow: #1260eb 0px 0px 0px 3px, rgba(0, 0, 0, 0) 0px 0px 0px 9000px;

        &.soft {
          box-shadow: #1260eb 0px 0px 0px 3px,
            rgba(0, 0, 0, 0.5) 0px 0px 0px 9000px;
        }
        &.hard {
          box-shadow: #1260eb 0px 0px 0px 3px,
            rgba(0, 0, 0, 0.9) 0px 0px 0px 9000px;
        }
      }
    }
    .jimo-tooltip-iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) !important;
      border: 0;
      background-color: transparent;
      border-radius: var(--widgetRoundnessCards);
      z-index: 2147483;
      width: 60px;

      &.is-right {
        left: unset;
        right: 16px;
      }

      &.concept-test-mode {
        left: 0 !important;
        bottom: 0 !important;
        top: 0 !important;
        right: 0 !important;
        width: 100%;
        backdrop-filter: blur(2em);
        position: absolute !important;
        z-index: 2147483636 !important;
      }
      &.is-snippet {
        box-shadow: 0px 9px 16px rgb(12 23 37 / 16%);
        width: 100%;
        z-index: 2147483636;
      }
    }
  }
}

.jimo-loading-wrapper {
  margin: auto;
  flex: 1 0 auto;

  .loader {
    margin: auto;
  }
}
