.summary-infos {
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 400px;
  min-width: 0;

  &.is-post {
    min-height: unset;
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .section-value-wrapper {
      position: relative;
      display: flex;
      padding: 12px 16px;
      gap: 12px;
      border-radius: 16px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      transition: all 200ms ease-in;

      &.clickable {
        cursor: pointer;

        &:hover {
          border: 1px solid var(--b-400);
          box-shadow: 0px 0px 0px 2px #1260eb1a;

          .edit-btn {
            opacity: 1;
            transition: all 200ms ease-in;
          }
        }
      }

      .section-value-icon {
        font-size: 24px;
        display: flex;

        i {
          margin: auto;

          &.isax {
            font-size: 28px;
          }
        }
      }

      .section-value {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        margin: auto 0;
      }

      .edit-btn {
        height: calc(100% - 12px);
        opacity: 0;
        position: absolute;
        margin: 6px;
        right: 0;
        top: 0;
      }
    }
  }

  .poke-status-section {
    display: flex;
    flex-direction: column;
    margin: 36px auto;
    gap: 12px;

    .section-name {
      text-align: center;
      color: #000;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
    }

    .radio-group {
      .option {
        padding: 8px;
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .publish-btn {
    margin: auto;
  }
}
