.imports-wrapper {
  padding: 24px;
  overflow-y: auto;
  background-color: var(--b-100);
  flex-grow: 1;

  &.is-fetching {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
  }
  &.is-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
  }

  .cols-header,
  .item-import {
    display: grid;
    grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 24px;
  }

  .cols-header {
    padding: 0 12px;
    margin-bottom: 15px;

    .col-header {
      color: var(--n-500);
    }
  }

  .list-import {
    display: flex;
    flex-direction: column;
  }

  .item-import {
    align-items: center;
    padding: 30px 12px;
    &:hover {
      background-color: white;
    }
    &:not(:last-child) {
      border-bottom: 1px solid var(--n-300);
    }

    .import-icon {
      width: 40px;
      height: 40px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 21px;
      border-radius: 12px;
      border: 1px solid var(--n-300);
    }

    .import-created-at {
      display: flex;
      flex-direction: column;
    }

    .import-row-proceed {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .import-status {
      .label {
        padding: 6px;
        display: inline-block;
        border-radius: 6px;

        &.label-in-progress {
          background-color: var(--y-100);
          color: var(--y-500);
        }
        &.label-done {
          background-color: var(--g-100);
          color: var(--g-500);
        }
        &.label-draft {
          background-color: var(--n-100);
          color: var(--n-500);
        }
        &.label-error {
          background-color: var(--r-100);
          color: var(--r-500);
        }
      }
    }
  }

  .empty-state-imports {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;

    .title-3 {
      margin-bottom: 6px;
    }
  }
}
