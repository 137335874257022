.s-onboarding-details-step-3-0 {
  width: 670px;
  margin-left: auto;
  margin-right: auto;

  .onboarding-booking-wrapper {
    display: flex;
    flex-direction: column;
    gap: 36px;

    .onboarding-booking-content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      h1 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        text-align: center;
        margin: 0 auto;
        animation: onboarding-text-slide-right 600ms ease-in-out 600ms both;
      }

      .subtitle {
        text-align: center;
        animation: onboarding-content-slide-right 600ms ease-in-out 800ms both;
      }
    }

    .jimo-logo {
      height: 45px;
    }

    .calendar-icon {
      font-size: 40px;
      margin: 0 auto;
    }

    .book-onboarding-btn {
      display: flex;
      width: fit-content;
      justify-content: center;
      animation: onboarding-content-slide-right 600ms ease-in-out 1400ms both;
      margin: 0 auto;
      border-radius: 12px;
    }
  }

  .need-help {
    margin: 0 auto;
    cursor: pointer;
  }
}

.join-session-now-wrapper {
  position: absolute;
  bottom: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  &.is-hidden {
    opacity: 0;
  }

  .join-session-now-card {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    border-radius: 12px;
    box-shadow: 0px 16px 16px 0px #00000014;
    width: 258px;
    cursor: pointer;
    border: 1px solid var(--n-50);
    animation: onboarding-content-slide-right 400ms ease-in-out 2600ms both;

    .join-session-now-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .meet {
        height: 24px;
      }

      .founders {
        height: 29px;
      }
    }

    .join-now-cta {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .img-wrapper {
    margin-left: auto;

    img {
      height: 70px;
      width: 70px;
    }
  }
}
