.hubspot-integration {
  .actions-btn-group {
    display: flex;

    .settings-btn {
      margin-right: 12px;

      i {
        margin-right: 0 !important;
        font-size: 16px;
      }
    }
  }
}

.slack-settings-modal {
  .settings-content {
    display: block !important;

    .settings-labeled-input {
      display: block !important;
      margin-bottom: 28px;

      .input-wrapper {
        .subtitle {
          color: rgba(255, 255, 255, 0.3);
          font-size: 14px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
