.survey-details {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 auto;

  .loader-wrapper {
    margin: auto;
  }

  .survey-details-header {
    display: flex;
    padding: 24px;

    .back-btn {
      background-color: #f4f4f4;
      height: 40px;
      width: 40px;
      border-radius: 12px;
      padding: 0;
      margin: auto 0;
      margin-right: 16px;

      &:hover {
        background-color: #dddddd;
      }

      i {
        margin: auto !important;
        color: #000000;
      }
    }

    .icon-wrapper {
      height: 40px;
      width: 40px;
      background: #e5effa;
      border-radius: 8px;
      display: flex;
      margin: auto 0;
      margin-right: 8px;

      i {
        margin: auto;
        font-size: 20px;
      }
    }

    .title {
      margin: auto 0;
      .evolution-title {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }

      .jimer-username {
        font-weight: 400;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .see-all-btn {
      margin-left: auto;
    }
  }

  .survey-details-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    min-height: 0;
    overflow-y: auto;
    position: relative;

    .survey-trait {
      position: absolute;
      z-index: -1;
      top: -32px;
      left: 50%;
      transform: translateX(-50%);
      width: 4px;
      height: calc(100% - 16px);
      background-color: rgba($color: #000000, $alpha: 0.1);
    }

    .step-response {
      background: #ffffff;
      display: flex;
      padding: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      border-radius: 16px;
      border: 1px solid var(--border-minimal, #e5e5e5);
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.04);

      .step-header-wrapper {
        display: flex;
        align-self: stretch;

        .infos {
          display: flex;
          align-items: center;
          gap: 16px;

          &.opinion-scale {
            .icon-wrapper {
              background-color: #fdefe6;
            }
          }
          &.multiple-choice {
            .icon-wrapper {
              background-color: #e6f5fd;
            }
          }
          &.text-long {
            .icon-wrapper {
              background-color: #e6fde8;
            }
          }
          &.slider {
            .icon-wrapper {
              background-color: #e6fde8;
            }
          }
          &.nps {
            .icon-wrapper {
              background-color: #fdefe6;
            }
          }
          &.concept-test {
            .icon-wrapper {
              background-color: #e6f5fd;
            }
          }

          .icon-wrapper {
            display: flex;
            padding: 8px;
            align-items: flex-start;
            gap: 8px;
            height: 48px;
            width: 48px;
            box-sizing: border-box;
            border-radius: 12px;

            i {
              color: black;
              margin: auto;
              font-size: 24px;
            }
          }
          .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;

            .title {
              color: var(--content-secondary, #4d637b);
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 24px */
            }
            .question {
              color: var(--content-primary, #071331);
              font-family: Montserrat;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 125%; /* 25px */
            }
          }
        }
      }

      .step-response-content {
        align-self: stretch;

        .response-text-long {
          display: flex;

          img {
            height: 24px;
            width: 24px;
            margin-right: 8px;
          }
        }

        .response-nps {
          display: flex;

          .nps-values {
            display: flex;
            gap: 4px;

            .nps-value {
              padding: 10px 8px;
              opacity: 0.5;
              font-weight: 500;
              font-size: 16px;
              border-radius: 4px;

              &.selected {
                opacity: 1;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

                &.detractor-1 {
                  border: 0.5px solid #aa1616;
                }
                &.detractor-2 {
                  border: 0.5px solid #aa5d16;
                }
                &.passive {
                  border: 0.5px solid #1260eb;
                }
                &.promoter {
                  border: 0.5px solid #159201;
                }
              }

              &.detractor-1 {
                background: #fde6e6;
                color: #aa1616;
              }
              &.detractor-2 {
                background: #fdefe6;
                color: #aa5d16;
              }
              &.passive {
                background: #e6f5fd;
                color: #1260eb;
              }
              &.promoter {
                background: #e6fde8;
                color: #159201;
              }
            }
          }

          .divider {
            margin: auto 16px;
            height: 18px;
          }

          .nps-average-wrapper {
            display: flex;
            margin: auto 0;
            align-items: center;

            .nps-average {
              margin-left: 6px;
              padding: 10px 8px;
              font-weight: 500;
              font-size: 16px;
              border-radius: 4px;

              &.detractor-1 {
                background: #fde6e6;
                color: #aa1616;
              }
              &.detractor-2 {
                background: #fdefe6;
                color: #aa5d16;
              }
              &.passive {
                background: #e6f5fd;
                color: #1260eb;
              }
              &.promoter {
                background: #e6fde8;
                color: #159201;
              }
            }
          }
        }

        .response-qcm {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .option-bar {
            display: flex;
            padding: 16px;
            border-radius: 12px;
            border: 3px solid white;
            position: relative;

            &.active {
              border-color: #1260eb;
            }

            .label,
            .percentage,
            .response-number {
              font-size: 18px;
              font-weight: 500;
            }

            .label {
              margin-right: 24px;
            }

            .response-number {
              margin-left: auto;
              margin-right: 12px;
            }

            i {
              font-size: 10px;
              align-self: center;
            }
          }
        }

        .response-opinion-scale {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .opinion-item-wrapper {
            align-items: center;
            display: flex;
            gap: 8px;

            .opinion-label {
              font-size: 24px;
              margin-right: auto;
            }
          }
          .opinion-bar {
            position: relative;
            flex: 1;
            padding: 16px;
            border-radius: 12px;
            border: 3px solid white;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active {
              border-color: #1260eb;
            }

            .content {
              display: flex;

              .percentage {
                font-size: 18px;
                font-weight: 500;
                margin-right: 6px;
              }

              .count {
                display: flex;
                margin-left: 6px;

                .response-count {
                  font-size: 18px;
                  font-weight: 500;
                  margin-right: 12px;
                }

                i {
                  font-size: 10px;
                  align-self: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
