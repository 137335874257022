.checklist {
  width: 360px;
  transition: width 200ms ease, background-color 500ms ease;

  &.is-mini {
    width: 200px;
    background-color: transparent;

    &.is-float {
      width: unset !important;
    }
  }

  &.disable-elements-animations {
    * {
      animation: none !important;
    }
  }

  &.is-success {
    overflow: hidden;
  }

  .poke-block-clickable {
    cursor: pointer;
    position: relative;
  }
}
