.s-onboarding-details-step-1-2 {
  width: 546px;
  margin-left: auto;
  margin-right: auto;
  &.is-exiting {
    h1 {
      animation: onboarding-text-slide-right-out 600ms ease-in-out 500ms both;
    }
    p {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 300ms both;
    }
    form {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 100ms both;
    }
  }

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    width: 450px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    animation: onboarding-text-slide-right 600ms ease-in-out 600ms both;
  }
  p {
    text-align: center;
    font-size: 16px;
    animation: onboarding-content-slide-right 600ms ease-in-out 800ms both;
  }

  form {
    color: #071331;
    animation: onboarding-content-slide-right 600ms ease-in-out 1200ms both;

    .form-label {
      margin-bottom: 16px;
      margin-top: 32px;
      font-weight: 500;
    }

    .inputs-wrapper {
      display: flex;

      .input-group {
        flex-grow: 1;

        &.dashed {
          input {
            border-style: dashed;
          }
        }
        input {
          border-radius: 12px;
          &:focus {
            box-shadow: inset 0 0 0 2px #e9effd;
            border-color: #88abff;
          }
        }
      }
    }

    .jimo-select {
      .jimo-select__control {
        border-radius: 12px;
      }
      .jimo-select__control--is-focused {
        box-shadow: inset 0 0 0 2px #e9effd;
        border-color: #88abff;
      }
    }

    .btn {
      margin-top: 10%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
