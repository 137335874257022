.block-settings {
  .section {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;

    .section-title-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .section-title {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;

        .section-title-extra {
          display: flex;
          gap: 8px;
          align-items: center;
          margin-left: auto;

          .block-group {
            display: flex;
            gap: 4px;
            align-items: center;

            .icon-wrapper {
              height: 16px;
              width: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;

              i {
                font-size: 10px;
                color: var(--n-800);
              }
            }
          }
        }
      }
    }

    .section-content {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .section-item {
        display: flex;
        min-height: 40px;
        align-items: center;
        gap: 8px;

        .section-item-title {
          height: 40px;
          display: flex;
          align-items: center;
          align-self: flex-start;
        }
        .section-item-content {
          margin-left: auto;
          width: 176px;
          flex-shrink: 0;

          .jimo-select__control {
            border-radius: 12px;
            height: 40px;
            font-size: 14px;

            .jimo-select__value-container {
              padding: 0 12px;
            }
          }

          .jimo-select__menu {
            width: 176px;
          }

          .input-group {
            .input-main-wrapper {
              height: 40px;

              .input {
                background: #fff;
                border-radius: 12px;
                resize: none;
                padding: 8px 12px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
