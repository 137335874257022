.post-builder-header {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
  background: #fff;

  .left-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-start;

    .btn-exit-extension {
      margin-left: 12px;

      i {
        margin-right: 0 !important;
      }
    }

    .logo-wrapper {
      padding: 12px 12px;

      img {
        height: 40px;
        width: 40px;
      }

      &.with-border {
        // border-right: 1px solid #e5e5e5;
      }
    }

    .btn-back {
      margin: 12px;
    }

    .name-wrapper {
      display: flex;
      align-items: center;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.3);
      margin-left: 6px;
      flex: 1 auto;

      .input-name {
        flex: 1 auto;

        input {
          font-size: 18px;
          font-weight: 500;
          padding: 8px;
          border: 0;
        }
      }
    }
  }

  .center-wrapper {
    pointer-events: none;

    .tabs {
      height: 100%;
      .tab-list {
        margin: 0 auto;
        height: 100%;

        .tab-list-item {
          height: 100%;
          box-sizing: border-box;

          .label-wrapper {
            display: flex;
            height: fit-content;
            margin: auto;
            gap: 8px;
            align-items: center;

            i {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  .right-wrapper {
    margin-right: 8px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    position: relative;

    .btn-target {
      margin-right: 8px;
      font-weight: 600;
      padding: 8px 12px;
      align-items: center;

      &.is-disabled {
        opacity: 0.3;
      }
      &.is-invalid {
        background: #fff !important;
        color: rgba(0, 0, 0, 0.4) !important;

        &:hover {
          color: rgba(0, 0, 0, 0.3) !important;
        }
      }

      .icon-wrapper {
        margin-left: 8px !important;
        height: 24px;
        width: 24px;
        border-radius: 8px;
        background: #ffe7e2;
        display: flex;

        i {
          margin: auto;
          color: #000;
        }
      }
    }

    .last-auto-save {
      font-size: 14px;
      color: #159201;
      justify-content: center;
      font-size: 18px;
      opacity: 1;
      animation: post-last-auto-save-out 200ms ease-in-out 2000ms forwards;
      &:hover {
        animation-play-state: paused;
      }
    }
  }
}

.exit-modal {
  padding: 0;
  width: unset !important;

  .modal-title {
    padding: 24px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 0;
  }

  .content {
    padding: 24px;
    font-weight: 400;
    font-size: 18px;
  }

  .actions {
    margin-top: 0;
    gap: 8px;
    justify-content: flex-end;
  }
}

@-webkit-keyframes post-last-auto-save-out {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes post-last-auto-save-out {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
  }
}
