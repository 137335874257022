.app-loading {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  i {
    font-size: 108px;
  }
  .box {
    flex: 1;

    &:nth-child(1) {
      display: flex;
      align-items: flex-end;
    }
    &:nth-child(2) {
      flex: 0 0 200px;
      display: flex;
      align-items: center;
    }
  }
}
