.poke-block-gradient {
  z-index: -1;
  position: absolute;
  z-index: 0;
  top: 0;
  right: -30px;
  top: -35%;
  transition: all 300ms ease-in-out;
  z-index: -1;

  &.is-animated {
    animation: block-gradient-move 3.2s ease-in-out infinite;
    border-radius: 20000px;
    filter: blur(32px);
    &.gradient-big {
      width: 60%;
      height: 114%;
    }
    &.gradient-small {
      width: 43%;
      height: 86%;
    }
  }
  &.is-classic {
    top: 0;
    right: 0;
    width: 100%;
    filter: none;
    height: 100%;
  }
}

@-webkit-keyframes block-gradient-move {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes block-gradient-move {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
