@import './../../Colors';

.card {
  padding: 24px;
  background-color: #fbfbfb;
  border-radius: 8px;
  transition: all ease 100ms;

  &.is-interactive {
    background-color: #ffffff;
    cursor: pointer;
    &:hover {
      background-color: #fbfbfb;
    }
    &.is-active {
      background-color: $colorPrimary;
    }
  }
}

.card-selective {
  padding-left: 36px;
  position: relative;
  display: flex;
  align-items: center;
  &.is-active {
    .btn-select {
      background-color: white;
    }
  }

  .btn-select {
    flex: 0 0 16px;
    height: 16px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    box-sizing: content-box;
    box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.1);
    margin-right: 36px;
  }

  h3 {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 6px;
    font-weight: 500;
  }
}
