.s-join {
  height: 100%;
  background-color: #fff;
  color: #000;
  &.is-loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.has-invitation-not-found {
    display: flex;
    align-items: center;
    justify-content: center;

    .row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 250px;
      text-align: center;

      .btn {
        margin-top: 12px;
      }
    }
  }
  &.is-joining {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .text {
      font-weight: 500;
      margin-top: 24px;
      color: rgba(0, 0, 0, 0.5);

      .highlight {
        color: rgba(34, 40, 61, 1);
        font-weight: 600;
      }
    }
  }
}
