.segment-header {
  display: flex;
  padding: 24px;
  flex: 1;
  align-items: center;

  .back-btn {
    height: 40px;
    width: 40px;

    i {
      margin: auto;
    }
  }

  .btns-wrapper {
    display: flex;
    margin-left: auto;
    gap: 16px;
  }
}

.dropdown-segment-icon-content {
  margin-top: 8px;

  .icons-wrapper {
    .header {
      padding: 10px;
      display: flex;

      .title {
        font-weight: 600;
      }
    }

    .icons-list {
      padding: 0 4px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      .icon-option-wrapper {
        .icon-wrapper {
          margin: 12px 8px;
          height: 40px;
          width: 40px;
          display: flex;
          border-radius: 12px;
          flex: 0 0 auto;
          border: 2px solid rgba(0, 0, 0, 0);
          cursor: pointer;

          &.default {
            background-color: #fff;
          }
          &.evaluators {
            background-color: #f0f2f4;
            color: #7c8a99;
          }
          &.beginners {
            background-color: #e6f5fd;
            color: #2978a2;
          }
          &.regular {
            background-color: #e6fde8;
            color: #12be21;
          }
          &.champions {
            background-color: #fdefe6;
            color: #ffa15e;
          }

          i {
            margin: auto;
            font-size: 20px;
          }
        }

        &.selected {
          .icon-wrapper {
            border: 2px solid #1260eb;
          }
        }
      }
    }
  }
}
