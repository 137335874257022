.poke-builder-sidebar-wrapper {
  display: flex;
  z-index: 2;
  min-height: 0;
  transition: all 300ms ease-in-out;
  overflow: hidden;
  flex-grow: 1;
  height: 100%;

  .poke-builder-sidebar {
    flex-shrink: 0;
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 56px;
    border-right: 1px solid #dad9db;
    gap: 8px;
    padding-top: 16px;

    .sidebar-item {
      cursor: pointer;
      margin: 0 auto;
      height: 40px;
      width: 40px;
      display: flex;
      border-radius: 8px;
      transition: all 150ms ease-in-out;
      position: relative;

      &:hover {
        background: rgba(18, 96, 235, 0.1);
        color: #1260eb;
      }
      &.selected {
        background-color: #1260eb1a;
        color: var(--b-400);
      }
      &:not(.selected) {
        .triggers-count {
          background-color: var(--n-800);
          color: white;
        }
      }

      i {
        margin: auto;
        font-size: 24px;
      }

      .triggers-count {
        position: absolute;
        right: -4px;
        bottom: -4px;
        height: 16px;
        width: 16px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        color: var(--n-800);
      }
    }
  }

  .poke-builder-sidebar-content-wrapper {
    display: flex;
    flex-grow: 1;
    min-width: 0;
  }
}
