.alert {
  display: flex;
  padding: 12px 16px;
  box-sizing: border-box;
  align-items: center;
  background-color: var(--n-100);
  border: 1px solid var(--n-100);
  border-radius: 12px;
  &.info {
    background-color: var(--b-100);
    border-color: var(--b-400);
    .alert-title,
    .icon-wrapper {
      color: var(--b-400);
    }
  }
  &.success {
    background-color: var(--g-100);
    border-color: transparent;
    .alert-title,
    .icon-wrapper {
      color: var(--g-500);
    }
  }
  &.warning {
    background-color: var(--o-100);
    border-color: var(--o-400);
    .alert-title,
    .icon-wrapper {
      color: var(--o-500);
    }
  }
  &.danger {
    background-color: var(--r-100);
    border-color: var(--r-500);
    .alert-title,
    .icon-wrapper {
      color: var(--r-500);
    }

    a {
      color: #ffffffbe;
      &:hover {
        color: #ffffff;
      }
    }
  }

  .alert-body {
    margin-top: 4px;
  }

  .icon-wrapper {
    padding-top: 4px;
    padding-right: 16px;
    font-size: 24px;
  }
}
