.step-node-wrapper {
  &:not(.is-selected) {
    cursor: pointer;
  }

  .is-editing-content &:not(.is-selected) {
    .condition-icons:not(:hover),
    .step-node-poke-wrapper:not(:hover),
    .cursor-wrapper:not(:hover) {
      opacity: 0.3;
    }
  }

  .is-editing-triggers &:not(.is-selected) {
    .condition-icons:not(:hover),
    .step-node-poke-wrapper:not(:hover),
    .cursor-wrapper:not(:hover) {
      opacity: 0.3;
    }
  }

  .is-editing-content & {
    .condition-icons:not(:hover) {
      opacity: 0.3;
    }
  }

  .is-editing-triggers & {
    .step-node-poke-wrapper:not(:hover),
    .cursor-wrapper:not(:hover) {
      opacity: 0.3;
    }
  }
}
