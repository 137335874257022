.jimer-tag-selector {
  &.read-only {
    .tags-list-wrapper {
      border-color: transparent !important;
      cursor: default;
      i {
        opacity: 0;
      }
    }
  }

  .tags-list-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 4px;
    min-height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    transition: all 100ms ease-in-out;
    cursor: pointer;
    &:hover {
      i.icon-chevron-bottom {
        color: #7d7d7d;
      }
    }
    .tags-list {
      flex: 1;
      gap: 8px;
      max-width: 350px;
      display: flex;
      overflow: hidden;
      align-items: center;

      .item-tag {
        padding: 8px;
        border-radius: 8px;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 16px;
        white-space: nowrap;
      }
    }
    i.icon-chevron-bottom {
      color: #b6b6b6;
      font-size: 12px;
      justify-self: flex-end;
      padding: 0 4px;
      margin-left: 4px;
    }
  }
}

.dp-jimer-tag-selector-content {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  min-width: 236px;
  z-index: 1002 !important;

  .dropdown-content {
    border-color: rgba(0, 0, 0, 0.1);
  }

  .tag-selector-search-wrapper {
    background-color: #f2f2f2;
    font-size: 14px;
    border-bottom: 1px solid rgb(229 229 229);
    display: flex;
    width: 370px;
    flex-wrap: wrap;
    padding: 8px;
    gap: 8px;

    .tag-selected {
      padding: 4px;
      border-radius: 8px;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 12px;
      display: flex;
      align-items: center;
      align-self: center;

      i.icon-close {
        font-size: 8px;
        margin-left: 2px;
        padding: 4px;
        cursor: pointer;
      }
    }
    .input-group {
      .input {
        padding: 4px;
        border: none;
        font-weight: 600;
      }
    }
  }
  .tags-list-wrapper {
    max-height: 400px;
    overflow-y: auto;

    .list-header {
      padding: 12px;
    }
    .list-item {
      display: flex;
      padding: 8px 12px;
      transition: background-color 100ms ease-in-out;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      &:hover {
        background-color: #f2f2f2;

        .dp-trigger-tag-settings {
          visibility: visible;
        }
      }
      &:last-child {
        padding-bottom: 10px;
      }

      .dp-trigger-tag-settings {
        visibility: hidden;
      }
      .item-tag {
        margin-right: 12px;
      }
      .btn {
        padding: 6px;
        border-radius: 6px;
        i {
          font-size: 10px;
        }
      }
    }
  }
  .new-tag,
  .tags-list-wrapper .list-item .item-tag {
    padding: 8px;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
  }
  .new-tag-wrapper {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 12px;
    cursor: pointer;
    &:hover {
      background-color: #f2f2f2;
    }

    .new-tag {
      margin-left: 6px;
    }
  }
}

.dp-jimer-tag-settings-content {
  z-index: 1002 !important;
  .dropdown-content {
    border-color: rgba(0, 0, 0, 0.1);
  }

  .tag-name-wrapper {
    padding: 12px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .input-group {
      flex: 1;
      .input {
        padding: 8px;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .btn {
      padding: 8px;
      border-radius: 10px;
      margin-left: 12px;

      i.icon-trash {
        font-size: 12px !important;
      }
    }
  }
  .list-color-wrapper {
    .list-header {
      padding: 12px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
    }
    .list {
      .item-color {
        padding: 8px 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          background-color: rgba(18, 98, 235, 0.05);
        }
        &:last-child {
          padding-bottom: 10px;
        }

        .color-infos {
          flex: 1;
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
        }
        .color-preview {
          flex: 0 0 24px;
          height: 24px;
          margin-right: 12px;
          border-radius: 8px;
        }
        i.icon-tick {
          color: #1260eb;
          margin-right: 8px;
        }
      }
    }
  }
}
