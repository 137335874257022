.s-settings-notifications {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .title-3 {
    margin-bottom: 8px;
  }

  .card-main {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-wrapper,
      .right-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}
