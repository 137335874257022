.event-conditions-wrapper {
  display: flex;
  max-width: 1042px;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 24px 94px 0px rgba(0, 0, 0, 0.07);

  &.borderless {
    box-shadow: none;
    padding: 0;
  }

  .event-conditions-title {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
  }

  .logic-group {
    align-self: stretch;
  }

  .single-condition-wrapper {
    align-self: stretch;
  }
}

.add-event-condition-content {
  min-width: 158px;
  background: #ffffff;
  box-shadow: 0px 14px 19px rgb(0 0 0 / 3%);
  border-radius: 8px;
  margin: 4px auto;
  z-index: 1002 !important;
  animation: openAnimation 0.225s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

  .event-conditions-list {
    display: flex;
    flex-direction: column;
    padding: 4px;
    overflow-y: auto;

    .divider {
      margin: 4px;
      width: calc(100% - 8px);
    }

    .dropdown-menu-item {
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 12px;
      border-radius: 8px;
      font-family: 'Montserrat';
      color: #000;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;

      cursor: pointer;
      &:hover {
        background-color: #1260eb0f;
      }

      .icon-wrapper {
        display: flex;
        height: 32px;
        width: 32px;
        border-radius: 8px;

        &.element {
          background-color: var(--g-100);
        }

        &.input {
          background-color: var(--p-200);
        }

        &.url {
          background-color: var(--b-150);
        }

        &.delay {
          background-color: var(--n-200);
        }

        &.logic-group {
          background-color: var(--o-300);
        }

        &.tour-seen {
          background-color: var(--o-300);
        }

        i {
          margin: auto;
          font-size: 16px;

          &.isax {
            font-size: 20px;
          }
        }
      }
    }
  }
}
