.settings-appearance-saver {
  position: fixed;
  z-index: 99;
  display: flex;
  max-width: 640px;
  background-color: #fff;
  padding: 16px 24px;
  border-radius: 12px;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 40px;
  transition: all 200ms ease;
  animation: fade-in 200ms ease;

  &.shake {
    background-color: var(--r-400);
    color: #fff;

    .n-700 {
      color: var(--r-200);
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .buttons-wrapper {
    display: flex;
    align-self: center;
    gap: 12px;
  }
}
