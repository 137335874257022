.s-forgot-pass {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #fff;

  .wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 400px;
    text-align: center;
    background-color: #fff;
    border-radius: 16px;
    color: #000;
    padding: 24px;

    p {
      text-align: justify;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 18px;
    }

    .input-group {
      width: 100%;

      input {
        border-radius: 12px;
        padding: 17px 20px;
        font-size: 16px;
        margin-bottom: 24px;
        width: 100%;
        &:focus {
          box-shadow: inset 0 0 0 2px #e9effd;
          border-color: #88abff;
        }
      }
    }
    img {
      margin-bottom: 12px;
    }
    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      a {
        display: block;
      }
    }
  }
}
