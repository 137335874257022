.jimer-attributes {
  overflow-y: auto;

  .learn-section {
    display: flex;
    padding: 16px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #1260eb;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;

    i {
      font-size: 12px;
    }
  }
  .section {
    .section-title {
      display: flex;
      padding: 8px 40px;
      align-items: flex-start;
      gap: 10px;
      background: #f2f5f9;
      color: rgba(0, 0, 0, 0.4);
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .jimer-segments-list {
      display: flex;
      gap: 10px;
      padding: 16px 40px;
      flex-wrap: wrap;

      .segment {
        padding: 6px 12px;
        border-radius: 12px;
        align-items: center;
        gap: 4px;
      }
    }

    .jimer-attributes-list {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .jimer-attribute {
        display: flex;
        padding: 16px 40px;
        align-items: flex-start;
        gap: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .jimer-attribute-icon {
          display: flex;
          height: 26px;
          width: 26px;
          margin: auto 0;
          border-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0.2);

          &.segment {
          }
          &.jimo {
            color: #1260eb;
          }

          i {
            font-size: 16px;
            margin: auto;
          }
          img {
            height: 26px;
            width: 26px;
            margin: auto 0;
          }
        }

        .jimer-attribute-name {
          font-weight: 400;
          margin: auto 0;
          color: rgba(0, 0, 0, 0.4);
          font-family: Inter;
          font-size: 14px;
          font-weight: 600;
        }

        .jimer-attribute-value {
          margin: auto 0;
          color: #000;
          font-family: Inter;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}
