.modal-change-email {
  width: 528px !important;

  form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
  }
  .title {
    text-align: center;
  }
  .actions {
    margin: 0;
    justify-content: flex-end;
    margin-top: 14px;
  }
}
