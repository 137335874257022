.tip {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);

  &.tip-horz {
    display: grid;
    grid-template-columns: 1.5fr 7fr 2fr;
    padding-left: 48px;
    padding-right: 48px;

    .image-wrapper {
      position: relative;

      div {
        width: 100%;
        height: 70px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
        position: absolute;
        bottom: 0;
      }
    }
    .content-wrapper {
      padding: 24px 0;
      h1 {
        font-weight: 200;
        margin-block-end: 0;
      }
    }
    .actions-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  &.tip-vert {
    position: relative;
    padding: 24px 0;
    width: 250px;
    height: 230px - 48px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .main-wrapper {
      height: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title-wrapper {
        text-align: center;
        margin-bottom: 12px;
        min-height: 50px;
        font-size: 18px;
        padding: 0 34px;
      }
    }
    .actions-wrapper {
      height: 25%;
      .btn {
        font-size: 12px;
        padding: 12px 24px;
      }
    }
  }
}
