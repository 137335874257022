.trigger {
  border: 1px solid var(--n-300);
  border-radius: 12px;
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background: var(--surface-primary, #fff);
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;

  &.is-expanded {
    .trigger-header {
      i {
        transform: rotate(180deg);
      }
    }
  }

  &.is-invalid {
    border-color: var(--r-500);
  }

  .trigger-header {
    padding: 12px 16px;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 12px;
    box-sizing: border-box;

    .trigger-header-content {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .condition-icons {
        display: flex;
        gap: 12px;

        .condition-icon {
          display: flex;
          height: 32px;
          width: 32px;
          border-radius: 8px;

          &.element {
            background-color: var(--g-100);
          }

          &.input {
            background-color: var(--p-200);
          }

          &.url {
            background-color: var(--b-150);
          }

          &.delay {
            background-color: var(--n-200);
          }

          &.logic-group {
            background-color: var(--o-300);
          }

          &.survey-response {
            background-color: var(--b-150);
          }

          i {
            margin: auto;
            font-size: 16px;

            &.isax {
              font-size: 20px;
            }
          }
        }
      }
    }

    i.icon-chevron-bottom {
      padding: 4px;
      margin-left: auto;
      transition: all 200ms ease-in-out;
      color: #4d637b;
    }
  }

  .collapsable-content-wrapper {
    width: 100%;

    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .trigger-conditions {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      &.has-single-condition {
        flex-direction: row;
      }

      .custom-condition-empty-state {
        display: flex;
        align-items: center;
        gap: 8px;

        .add-condition-btn {
          background: var(--b-100);
          padding: 8px;
        }
      }

      .event-conditions-wrapper {
        margin-bottom: 0;
        padding: 0;
        width: 100%;
        min-width: 0;

        .logic-trait-wrapper {
          width: 45px;
        }
      }
    }
  }

  .trigger-actions-wrapper {
    align-self: stretch;
    display: flex;
    background: var(--b-100);
    padding: 8px;
    gap: 12px;

    &.is-expanded {
      padding: 16px;

      &.is-empty {
        padding: 16px;
      }

      &:not(.is-empty) {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      &.is-survey {
        flex-direction: row;

        .trigger-actions-content {
          width: 100%;
        }
      }
    }

    .trigger-actions-content {
      display: flex;
      gap: 12px;
      flex-grow: 1;

      .trigger-icon-wrapper {
        width: 45px;
        display: flex;
        justify-content: center;

        i {
          font-size: 24px;
        }
      }
    }
  }

  .trigger-delete-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px;
    width: 100%;
    cursor: pointer;

    &:hover {
      background: var(--r-100);
    }
  }
}

.custom-indicator-label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 0 0 8px;
  height: 28px;
  padding: 8px;
  padding-right: 0;
  box-sizing: border-box;
  min-width: 40px;
  width: fit-content;
  margin-right: 20px;

  &.if {
    background-color: #ebebeb;

    .label {
      color: var(--n-500);
    }

    .arrow-right {
      border-left: solid 14px #ebebeb;
    }
  }

  &.then {
    background-color: var(--b-200);

    .arrow-right {
      border-left: solid 14px var(--b-200);
    }
  }

  .label {
    font-size: 10px;
    font-weight: 700;
    font-family: 'Montserrat';
  }

  .arrow-right {
    position: absolute;
    border-bottom: solid 14px transparent;
    border-top: solid 14px transparent;
    right: -14px;
  }
}
