.success-tracker-empty-state {
  overflow-y: auto;
  flex: 1 1 auto;
  padding: 32px;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 1042px;
    margin: auto;
    height: 100%;
    justify-content: center;

    .tracker-modal-content {
      padding: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .title {
      text-align: center;
      color: var(--content-primary, #071331);
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 125%; /* 30px */
    }

    .subtitle {
      text-align: center;
      color: var(--content-secondary, #4d637b);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 30px */
    }
  }
  .pick-tracker-type {
    .tracker-types {
      justify-content: center;
      gap: 40px;

      .tracker-type-block {
        flex: 1 1 0;
        max-width: 345px;
        border: 0;
        box-shadow: 0px 5px 60px 0px rgba(0, 0, 0, 0.07);
        border-radius: 16px;
      }
    }
  }
}
