.s-onboarding-register-email-sent {
  animation: email-sent-entry 400ms ease-in-out;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1,
  p {
    width: 258px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  h1 {
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  .info-spam {
    position: absolute;
    bottom: 36px;
    border-radius: 16px;
    background-color: #f0f7ff;
    padding: 16px;
    display: flex;
    align-items: center;
    i {
      font-size: 18px;
      margin-right: 16px;
    }
  }
}

@-webkit-keyframes email-sent-entry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes email-sent-entry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
