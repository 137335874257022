.custom-slider {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;

  &.with-percentage {
    .rc-slider-handle {
      top: -10px;
    }
  }
  &:not(.default-handle) {
    .rc-slider-handle {
      background-color: unset;
      border: none;
      box-shadow: none !important;
      height: fit-content;
      width: fit-content;
      transform: translateY(-50%) translateX(-50%) !important;
      top: 10px;
      opacity: 1;

      .emoji-animated-wrapper {
        height: 42px;
        width: 42px;
      }

      .emoji {
        font-size: 42px;
        text-align: center;
      }

      .percentage {
        height: 20px;
        margin: 10px 0;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
      }
    }
  }

  .rc-slider-handle {
    cursor: unset;
    pointer-events: none;
  }
}
