.hint-alignment {
  .alignment-options {
    display: flex;
    gap: 8px;
    width: 100%;

    i {
      font-size: 18px;

      &.rotate-180 {
        transform: rotate(180deg);
      }
    }
  }
}
