.picker-icon-popup-content {
  .picker-icon-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    height: 300px;
    box-sizing: border-box;

    .built-in-list,
    .custom-list {
      align-items: flex-start;
      align-content: flex-start;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 16px 8px;
      align-self: stretch;
      flex-wrap: wrap;
      flex-grow: 1;
      min-height: 0;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 1px;

      .item-icon-wrapper {
        flex: 1 1 0;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        max-width: 72px;
        gap: 8px;

        &.selected {
          .item-icon {
            outline: 1px solid var(--b-300, #85b1ff);
          }

          .label {
            color: var(--b-400, #1260eb);
          }
        }

        .item-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 16px;
          background: var(--n-100, #f0f2f4);
          cursor: pointer;

          i {
            font-size: 24px;
          }

          img {
            width: 32px;
            height: 32px;
          }
        }

        .label {
          margin: auto;
          text-align: center;
        }
      }
    }

    .built-in-list {
      .item-icon {
        padding: 12px 16px;
      }
    }

    .custom-list {
      .item-icon {
        padding: 16px;
      }
    }

    .loading-wrapper {
      flex-grow: 1;
      display: flex;
      align-self: stretch;

      .loader {
        margin: auto;
      }
    }

    .empty-state-wrapper {
      flex-grow: 1;
      display: flex;
      text-align: center;
      align-items: center;
      margin: 24px;
    }

    .action-wrapper {
      display: flex;
      gap: 16px;
      align-self: stretch;

      .btn {
        flex: 1 1 0;
        justify-content: center;
      }
    }
  }
}
