.section-item.radius-item {
  .pixel-picker-input {
    .input-group {
      .label-left {
        padding: 12px 6px 12px 12px;

        .icon-wrapper {
          background: unset;
          margin: 0;
          height: unset;
          width: unset;

          i {
            font-size: 16px;
            color: var(--n-600);
          }
        }
      }

      .input {
        padding: 4px !important;
      }
    }
  }
}
