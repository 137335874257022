@import './../../../../Colors';

.navbar-dropdown-menu {
  border: 1px solid #ffffff;
  padding: 4px;

  .menu-item {
    @media screen and (max-height: 700px) {
      padding: 6px !important;
    }
    padding: 12px !important;
    font-size: 16px;
    border-radius: 16px !important;
    margin: 4px 0;
    max-width: 225px;

    &:hover {
      font-weight: 600;
    }

    &.logout-btn {
      color: #ec4d4d;

      &:hover {
        background-color: unset;
      }
    }

    .icon-chevron-right {
      font-size: 14px;
    }
  }

  .item-switch-project {
    display: flex;
    justify-content: unset !important;

    .side-content {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      padding-left: 16px;
      padding-right: 16px;

      small {
        font-weight: 500;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
        font-weight: 500;
      }
    }
    .avatar {
      flex: 0 0 46px;
      height: 46px;
      background-color: $colorPrimary;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
    }
  }
  .navbar-feedback-portal-link {
    .icon-goto {
      font-size: 10px;
    }
  }
  .navbar-dropdown-user-infos {
    .user-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 46px;
      height: 46px;
      margin-right: 12px;
      color: #ffffff;
      font-weight: 500;
      font-size: 18px;
    }
    .username {
      font-weight: 500;

      .email {
        margin-top: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .divider {
    box-sizing: border-box;
  }
  .project-lists {
    max-height: 500px;
    overflow-y: auto;
  }
}
