@import './Mixin';

.StripeElement {
  display: block;
  max-width: 500px;
  padding: 14px;
  font-family: 'Source Code Pro', monospace;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.my-stripe-element input:focus,
.StripeElement--focus {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.my-stripe-element,
.StripeElement {
  color: #424770;
  letter-spacing: 0.025em;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  outline: 0;
  font-size: 18px;
}

.my-stripe-element {
  margin-bottom: 16px;
  input {
    border-color: transparent !important;
    color: #424770 !important;
    font-family: 'Source Code Pro', monospace !important;
    font-size: 16px !important;
    padding: 14px !important;
    box-shadow: none !important;
    @include placeholder {
      color: #aab7c4 !important;
    }
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
  }
}
