.translation-manager-wrapper {
  background: #fff;
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1 1 auto;

  .translation-manager {
    display: flex;
    flex: 1 auto;
    flex-direction: column;
    gap: 16px;
    min-height: 0;
    padding: 16px 0;

    .translation-manager-header {
      display: flex;
      padding: 0 16px;

      .translation-manager-title {
        margin: auto 0;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
        min-height: 45px;
        line-height: 45px;
      }

      .smart-translate-btn {
        border-radius: 8px;
        margin-left: 8px;
        background-image: linear-gradient(
          to right,
          #000428 0%,
          #004e92 51%,
          #000428 100%
        );
        transition: 0.5s;
        background-size: 200% auto;
        color: white;
        box-shadow: 0 0 20px #eee;
        display: flex;
        align-items: center;
        &:hover {
          background-position: right center;
          text-decoration: none;
        }

        .badge-ai {
          margin-left: 8px;
        }
      }

      i {
        cursor: pointer;
        margin: auto 0;
        margin-left: auto;
        color: #9598a9;
        padding: 4px;
      }
    }

    .loader-wrapper {
      display: flex;
      flex: 1 auto;
      flex-direction: column;
      align-items: center;

      .loader {
        margin: 40px 0 12px 0;
      }
    }

    .translation-manager-blocks {
      overflow-y: auto;
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .translation-manager-block-title {
        margin-bottom: 8px;
      }

      .translation-manager-block-content {
        .markdown-editor {
          background: #fff;
          border-radius: 16px;
          border: 1px dashed #d9d9d9;

          .toolbar {
            border-radius: 16px 16px 0px 0px;
          }
        }
      }

      .input {
        border-radius: 16px;
      }

      .text-area {
        textarea {
          resize: none;
        }
      }

      .options,
      .slider-block-inputs {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}
