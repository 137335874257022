.report {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .insights-summary {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 24px;
    display: flex;

    .circular-progress-wrapper {
      height: 20px;
      width: 20px;
      margin-right: 16px;
    }
  }

  .survey-step {
    display: flex;
    padding: 28px;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;
    align-self: stretch;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 8px 60px 0px rgba(0, 0, 0, 0.05);

    .view-responses-btn {
      margin-left: auto;
    }

    &.has-nested-surveys {
      & > .infos {
        padding: 16px 16px 0 16px;
      }
      & > .results {
        padding: 0 16px 16px 16px;
      }

      .nested-surveys-wrapper {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        gap: 32px;
      }
    }
    &.is-nested {
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      align-self: stretch;
      border-radius: 12px;
      border: 1px solid var(--border-subtle, #ccc);
    }

    .step-header-wrapper {
      display: flex;
      align-self: stretch;

      .infos {
        display: flex;
        align-items: center;
        gap: 16px;

        .icon-wrapper {
          display: flex;
          padding: 8px;
          align-items: flex-start;
          gap: 8px;
          height: 48px;
          width: 48px;
          box-sizing: border-box;
          border-radius: 12px;

          i {
            color: black;
            margin: auto;
            font-size: 24px;
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 8px;

          .title {
            color: var(--content-secondary, #4d637b);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 24px */
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .question {
            color: var(--content-primary, #071331);
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 125%; /* 25px */
          }
        }
      }

      .step-stats {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-left: auto;

        .step-responses-count,
        .step-responses-average {
          display: flex;
          padding: 12px 16px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 12px;
          border: 1px solid var(--border-minimal, #e5e5e5);
          background: var(--neutral-neutral-0, #fff);
          margin-left: auto;
          cursor: pointer;

          .label {
            color: var(--content-secondary, #4d637b);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 21px */
          }

          .count {
            color: var(--content-primary, #071331);
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 125%; /* 30px */
          }
        }
      }
    }

    &.opinion-scale {
      .icon-wrapper {
        background-color: #fdefe6;
      }

      .stars-average-wrapper {
        margin: auto;

        .value {
          font-size: 36px;
          margin-bottom: 8px;
          text-align: center;
        }

        .count {
          justify-content: center;
          font-weight: 500;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.35);
          display: flex;
          margin: auto;

          i {
            margin-left: 8px;
            font-size: 20px;
          }
        }
      }

      .results {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        gap: 36px;

        .opinion-items-wrapper {
          display: flex;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;

          .opinion-item-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            flex: 1 0 0;

            .content {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 4px;

              .percentage {
                color: var(--content-primary, #071331);
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 30px */
              }

              .count {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 4px;
                color: var(--content-secondary, #4d637b);
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 24px */

                i {
                  align-self: center;
                }
              }
            }

            .opinion-bar {
              height: 304px;
              align-self: stretch;
              position: relative;
              border-radius: 8px;
              border: 2px solid white;
              background: var(--neutral-neutral-100, #f0f2f4);

              &:hover {
                border-color: rgba(18, 96, 235, 0.4);

                .actions {
                  opacity: 1 !important;
                }
              }

              &.active {
                border-color: rgba(18, 96, 235, 0.4);
              }

              .actions {
                transition: all 200ms ease-in-out 0s;
                opacity: 0;
                position: absolute;
                display: flex;
                max-width: 100%;
                height: 100%;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                gap: 12px;
              }
            }

            .opinion-label {
              display: flex;
              font-size: 40px;
              justify-content: center;
              align-items: center;

              &.star {
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    &.multiple-choice {
      & .infos > .icon-wrapper {
        background-color: #e6f5fd;
      }

      .results {
        display: flex;
        align-self: stretch;
        flex-direction: column;
        justify-content: space-between;
        gap: 16px;

        .option-bar-wrapper {
          display: flex;
          padding: 12px 0px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 16px;
          align-self: stretch;

          &:hover {
            .actions {
              opacity: 1 !important;
            }
          }

          .option-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;

            .option-info {
              display: flex;
              align-items: center;
              gap: 12px;

              .percentage {
                color: var(--content-primary, #071331);
                font-family: Montserrat;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 125%; /* 25px */
              }

              .label {
                color: var(--content-secondary, #4d637b);
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 30px */
              }
            }

            .count-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 4px;
              color: var(--content-secondary, #4d637b);
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 24px */
              position: relative;

              .actions {
                display: flex;
                opacity: 0;
                transition: all 200ms ease-in-out 0s;
                gap: 12px;
                position: absolute;
                right: 0;

                .btn {
                  text-wrap: nowrap;
                }
              }
            }
          }

          .option-bar {
            align-self: stretch;
            height: 12px;
            padding: 0;
            border-radius: 57px;
            background: var(--surface-secondary, #f0f2f4);
            border: 1px solid white;

            &.active {
              border-color: rgba(18, 96, 235, 0.4) !important;
            }
          }
        }
      }
    }

    &.text-long {
      & .infos > .icon-wrapper {
        background-color: #e6fde8;
      }

      .results {
        display: flex;
        align-self: stretch;
        flex-direction: column;
        justify-content: space-between;
        gap: 16px;

        .no-responses {
          display: flex;
          padding: 16px;
          justify-content: center;
          align-items: center;
          gap: 32px;
          align-self: stretch;
          border-radius: 12px;
          background: var(--neutral-neutral-50, #fbfbfb);
          color: var(--content-secondary, #4d637b);
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 24px */
        }

        .responses-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;
          align-self: stretch;

          .response {
            display: flex;
            padding: 16px;
            align-items: center;
            gap: 32px;
            align-self: stretch;
            border-radius: 12px;
            background: var(--neutral-neutral-50, #fbfbfb);
            border: 1px solid #fff;

            &:hover {
              border-color: rgba(18, 96, 235, 0.4);

              .actions {
                opacity: 1 !important;
              }
            }

            &.active {
              border-color: rgba(18, 96, 235, 0.4);
              background: var(--neutral-neutral-0, #fff);
            }

            .content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 12px;
              flex: 1 0 0;

              .value {
                align-self: stretch;
                color: var(--content-primary, #071331);
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 24px */
              }

              .info {
                display: flex;
                align-items: center;
                gap: 24px;
                align-self: stretch;
                position: relative;

                .info-content {
                  display: flex;
                  align-items: center;
                  gap: 24px;

                  .user-info,
                  .time-info {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    i {
                      color: #4d637b;
                      margin-right: 4px;
                    }

                    .username {
                      color: var(--content-secondary, #4d637b);
                      font-family: Inter;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 150%; /* 21px */
                    }

                    .anonymous-username {
                      color: var(--content-tertiary, #9ca1ad);
                      font-family: Inter;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 150%;
                    }

                    .email-info {
                      color: var(--content-secondary, #4d637b);
                      font-family: Inter;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 150%; /* 21px */
                    }

                    .time {
                      color: var(--content-secondary, #4d637b);
                      font-family: Inter;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 150%; /* 21px */
                    }
                  }
                }

                .actions {
                  display: flex;
                  opacity: 0;
                  transition: all 200ms ease-in-out 0s;
                  gap: 12px;
                  position: absolute;
                  right: 0;

                  .btn {
                    text-wrap: nowrap;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.slider {
      & .infos > .icon-wrapper {
        background-color: #e6fde8;
      }

      .results {
        display: flex;
        align-self: stretch;
        flex-direction: column;
        justify-content: space-between;
        gap: 16px;

        .no-responses {
          display: flex;
          padding: 16px;
          justify-content: center;
          align-items: center;
          gap: 32px;
          align-self: stretch;
          border-radius: 12px;
          background: var(--neutral-neutral-50, #fbfbfb);
          color: var(--content-secondary, #4d637b);
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 24px */
        }

        .slider-wrapper {
          .custom-slider {
            padding: 24px 0;

            .rc-slider {
              height: 20px;

              .rc-slider-track {
                height: 20px;
                background: #85b1ff;
                border-radius: 40px;
              }

              .rc-slider-rail {
                height: 20px;
                border-radius: 40px;
              }
            }
          }

          .labels {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .label {
              color: var(--content-secondary, #4d637b);
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 24px */
            }
          }
        }
      }
    }
    &.nps {
      & .infos > .icon-wrapper {
        background-color: #fdefe6;
      }

      .results {
        display: flex;
        align-self: stretch;
        flex-direction: column;
        justify-content: space-between;
        gap: 16px;
        transition: all 800ms ease-in-out 0s;

        .nps-group {
          display: flex;

          .chart-wrapper {
            margin: 40px 0;
            margin-left: auto;
            width: 250px;
            position: relative;

            .nps-metric-wrapper {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              display: flex;
              flex-direction: column;
              gap: 8px;
              cursor: pointer;

              .label {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                color: var(--content-secondary, #4d637b);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 21px */

                i {
                  font-size: 18px;
                }
              }

              .value {
                text-align: center;
                color: var(--content-primary, #071331);
                font-family: Montserrat;
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%; /* 48px */
                letter-spacing: -0.5px;
              }
            }
          }
          .group-list {
            display: flex;
            flex-direction: column;
            margin: auto;
            margin-left: 20px;
            margin-right: auto;
            gap: 16px;

            .nps-category-wrapper {
              display: flex;
              padding: 12px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 16px;
              align-self: stretch;
              border-radius: 12px;
              border: 1px solid var(--border-minimal, #e5e5e5);

              &:hover {
                .actions {
                  opacity: 1 !important;
                  display: flex !important;
                }
              }

              &.active {
                border: 1px solid rgba(18, 96, 235, 0.4);
              }

              .nps-category {
                display: flex;
                justify-content: space-between;
                align-items: center;
                align-self: stretch;
                width: 482px;

                &.promoter {
                  .category-dot {
                    background: #98d28f;
                  }

                  &.active {
                    border-color: #a6ee6e;
                  }
                }
                &.passive {
                  .category-dot {
                    background: #85b1ff;
                  }
                  &.active {
                    border-color: rgba(90, 112, 216, 1);
                  }
                }
                &.detractor {
                  .category-dot {
                    background: #fa7575;
                  }
                  &.active {
                    border-color: #ff7e9e;
                  }
                }

                .nps-category-content {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  gap: 2px;

                  .label-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .category-dot {
                      width: 16px;
                      height: 16px;
                      border-radius: 40px;
                    }

                    .label {
                      color: var(--content-primary, #071331);
                      font-family: Inter;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 150%; /* 24px */
                    }
                  }

                  .category-subtitle {
                    color: var(--content-secondary, #4d637b);
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%; /* 21px */
                  }
                }

                .nps-category-stats {
                  display: flex;
                  align-items: center;
                  gap: 12px;

                  .percentage {
                    color: var(--content-primary, #071331);
                    font-family: Montserrat;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 125%; /* 25px */
                  }

                  .count {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 4px;
                    color: var(--content-secondary, #4d637b);
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%; /* 21px */
                  }
                }
              }

              .actions {
                display: none;
                justify-content: center;
                align-items: flex-start;
                gap: 12px;
                align-self: stretch;

                .btn {
                  flex: 1 0 0;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
    &.concept-test {
      & .infos > .icon-wrapper {
        background-color: #e6f5fd;
      }
    }
  }
}

.tour-insights {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 20px;
  overflow-y: auto;

  .insights {
    overflow-y: unset;
  }
}

.empty-state-report {
  .subtitle {
    text-align: center;
    margin: 32px;
    font-weight: 500;
    font-size: 16px;
  }
}

.nps-metric-tooltip-content {
  background-color: #ffffff;
  padding: 12px;
  color: #000000;
  box-sizing: border-box;
  border-radius: 12px;
  font-size: 14px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  max-width: 400px;

  .tooltip-children {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
