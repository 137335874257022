.tracker-builder-header {
  display: flex;
  padding: 24px;
  align-items: flex-start;
  gap: 16px;

  .back-btn {
    background-color: #f4f4f4;
    height: 40px;
    width: 40px;
    border-radius: 12px;
    padding: 0;
    margin: auto 0;

    &:hover {
      background-color: #dddddd;
    }

    i {
      margin: auto !important;
      color: #000000;
    }
  }

  .tracker-name-input {
    .input {
      display: flex;
      max-width: 640px;
      padding: 4px 8px;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      border-radius: 8px;
      border: 0;
      overflow: hidden;
      color: var(--content-primary, #071331);
      text-overflow: ellipsis;
      font-family: Montserrat;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 38.4px */
      letter-spacing: -0.5px;
      transition: all 0.2s ease-in-out;

      &:hover,
      &:focus {
        background: var(--surface-secondary, #f0f2f4);
      }

      &::placeholder {
        font-size: 32px;
        font-weight: 700;
        line-height: 120%; /* 38.4px */
        letter-spacing: -0.5px;
      }
    }
  }

  .actions {
    margin-left: auto;
    display: flex;
    gap: 16px;
    align-items: center;

    .btn {
      i {
        font-size: 16px;
      }
    }
  }
}

.create-tracker-tooltip-content {
  width: 360px;
  padding: 12px;

  .tooltip-children {
    display: flex;
    gap: 12px;
    align-items: center;

    i {
      color: white;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}
