.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 40px 0;

  .icon-wrapper {
    margin-bottom: 22px;
  }
  .title-wrapper {
    font-size: 24px;
    margin-bottom: 6px;
  }
  .content-wrapper {
    color: #777da6;
    max-width: 50%;
    text-align: center;
    font-weight: 500;
  }
  .actions-wrapper {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
}

.empty-state-onboarding {
  display: flex;
  align-items: center;
  max-width: 870px;

  .content {
    max-width: 440px;
    padding: 24px;
    margin-right: 40px;
    font-size: 18px;
    box-sizing: border-box;

    .title {
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 24px;
      font-family: 'Montserrat', sans-serif;
    }
    .actions {
      margin-top: 24px;
    }
  }
}
