.picker-sticker-popup-content {
  min-width: 158px;
  background: #ffffff;
  box-shadow: 0px 14px 19px rgb(0 0 0 / 3%);
  margin: 4px auto;
  z-index: 1002 !important;

  .dropdown-content {
    border-radius: 12px;

    .sticker-picker-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow-y: auto;
      overflow-x: hidden;
      flex-grow: 1;
      min-height: 0;
      height: 300px;

      .section-grid {
        align-items: flex-start;
        align-content: flex-start;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 16px 8px;
        align-self: stretch;
        flex-wrap: wrap;

        .visual-cue {
          flex: 1 1 0;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          max-width: 78px;
          gap: 8px;
          border: 1px solid transparent;

          &.selected {
            .icon-wrapper {
              border: 1px solid var(--b-300, #85b1ff);
            }

            .label {
              color: var(--b-400, #1260eb);
            }
          }

          .icon-wrapper {
            height: 76px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 16px;
            background: var(--n-100, #f0f2f4);
            cursor: pointer;

            i {
              font-size: 24px;
            }

            img {
              width: 32px;
              height: 32px;
            }
          }

          .label {
            margin: auto;
            text-align: center;
          }
        }
      }
    }
  }
}

.sticker-picker {
  .dropdown-trigger {
    width: 100%;

    .preview-icon {
      height: 32px;
      width: 32px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
