.poke-builder-error-boundary,
.common-error-boundary {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  & > .row {
    flex-grow: 1;
    margin: 0;
    & > div {
      display: flex;
      align-items: center;
    }
  }

  .logo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    font-size: 78px;
  }
  .content {
    text-align: center;
    margin: 40px 0;

    h2 {
      font-weight: 100;
    }
    p {
    }
  }
  .actions {
    display: flex;
    justify-content: space-evenly;
    margin-top: 40px;

    a {
      color: inherit;
    }
  }
  .eb-delimiter {
    width: 50px;
    height: 1px;
    background-color: #e6e6e6;
    margin: 30px auto;
  }
}
