.audience-section.boost {
  .audience-section-content {
    display: flex;

    .boosted-content {
      display: flex;
      width: 100%;

      .boost-poke {
        height: 180px;
        flex: 1 auto;
        position: relative;
        display: flex;
        cursor: pointer;

        padding: 12px;
        box-sizing: border-box;

        &:hover {
          // animate background-color and box-shadow
          transition: all 300ms ease-in;

          .preview {
            transition: all 300ms ease-in;
            background: #b5bfd5;
            box-shadow: 0px 14px 8px rgba(0, 0, 0, 0.05);
          }
          .push-title {
            transition: all 300ms ease-in;
            color: #1260eb !important;
          }
        }

        .loader {
          margin: auto;
        }

        .tag-view {
          padding: 8px;
          border-radius: 6px;
          font-size: 16px;
          font-weight: 500;
        }

        .preview {
          display: flex;
          width: 310px;
          position: relative;

          border-radius: 12px;
          background: #e6e6e6;
          transition: all 100ms ease-in;
          transform: scale(1);

          .poke-preview-icon-wrapper {
            padding: 8px;
            position: absolute;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 8px;
            right: 8px;
            bottom: 8px;
            color: #fff;
            display: none;
          }

          .live-preview {
            position: absolute;
            margin: auto;
            transform: scale(0.8);
            width: 100%;
            height: 100%;
          }

          .deprecated-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 8px;
            background: rgba(0, 0, 0, 0.68);
            display: flex;
            padding: 6px 8px;
            gap: 4px;
            margin: 8px;
            z-index: 1;

            .deprecated-label {
              margin: auto;
              color: #fff;
              font-size: 16px;
              font-family: Inter;
              font-weight: 500;
            }
          }
        }

        .infos {
          margin: auto;
          margin-left: 24px;

          .push-title-wrapper {
            margin-bottom: 12px;

            .push-title {
              align-items: center;
              font-weight: 500;
              color: #000000;
              font-size: 20px;
              margin-bottom: 8px;
              display: flex;
              gap: 8px;

              .boosted-icon-wrapper {
                box-sizing: border-box;
                display: flex;
                width: 24px;
                height: 24px;
                padding: 4px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
                background: #f5f2fe;

                i {
                  font-size: 16px;
                  color: #bb68ff;
                }
              }
            }

            .created-at {
              color: rgba(0, 0, 0, 0.5);
              font-size: 14px;
            }
          }

          .poke-details-wrapper {
            display: flex;
            &.in-grid-layout {
              margin: 16px 0;
            }
            &.in-row {
              margin-right: 12px;
            }
            &.in-row-large {
              margin-top: 12px;
            }

            .tag-icon {
              display: flex;
              border-radius: 8px;
              border: 1px solid rgba(0, 0, 0, 0);
              padding: 8px;
              align-items: center;

              &.discovery {
                background-color: #e6f5fd;
              }
              &.adoption {
                background-color: #e6fde8;
              }

              i,
              img {
                margin: auto;
                margin-right: 8px;
                font-size: 14px;
              }

              span {
                font-size: 16px;
              }
            }

            .poke-type-wrapper {
              display: flex;
              flex-wrap: wrap;
              gap: 8px;

              .type-tag {
                display: flex;
                border-radius: 8px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                padding: 8px;
                font-size: 16px;

                i {
                  margin: auto;
                  margin-right: 8px;
                  font-size: 14px;
                }
              }
            }
          }
        }

        .audience-tags-wrapper {
          display: flex;
          & > * {
            margin-right: 12px;
          }

          .poke-stats-wrapper {
            align-items: center;
          }
          .tag-view {
            margin-right: 12px;
            margin-left: 0px;
          }
        }

        .audience {
          margin: auto;
          margin-right: 12px;

          .poke-stats-wrapper {
            display: flex;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            padding: 6px 8px;

            i {
              margin-right: 8px;
            }
          }
        }

        .actions {
          display: none;
          margin: auto;
          transition: all 400ms ease-in-out 0s;
          position: absolute;
          right: 8px;
          top: 8px;

          .menu-poke-top {
            display: flex;
            justify-content: flex-end;
          }
          .menu-poke-actions {
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            &.is-hidden {
              display: none;
            }
            .btn {
              align-self: flex-end;
              margin-right: 0 !important;
              margin-bottom: 12px;
            }
          }
          .btn {
            align-self: center;
            margin-right: 8px;
            &:nth-last-child(1) {
              margin-right: 0;
            }
          }
        }

        &:hover {
          .actions,
          .poke-preview-icon-wrapper {
            display: flex;
          }
        }
      }
    }
  }

  .not-boosted-content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .boost-title {
      color: #000;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
    }

    .boost-btn {
      width: fit-content;
    }
  }
}
