.variable-config-modal-content {
  .dropdown-content {
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;

    .variable-config-modal-header {
      display: flex;
      align-items: center;

      i {
        margin-left: auto;
        cursor: pointer;
      }
    }

    .item {
      display: flex;
      min-height: 40px;
      align-items: center;

      .item-title {
        height: 40px;
        display: flex;
        align-items: center;
        align-self: flex-start;
      }
      .item-content {
        margin-left: auto;
        width: 176px;

        .jimo-select__control {
          border-radius: 12px;
          height: 40px;

          .jimo-select__value-container {
            padding: 0 12px;

            .jimo-select__single-value {
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }
        }

        .input-group {
          height: 40px;
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
    }
  }
}

.variable-config-single-value {
  display: flex;
  gap: 8px;
  align-items: center;

  i {
    width: 18px;
    height: 18px;
    font-size: 18px;
    color: var(--n-700);
  }

  img {
    width: 18px;
    height: 18px;
  }

  .label {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.variable-config-option {
  display: flex;
  gap: 8px;
  align-items: center;

  i {
    width: 18px;
    height: 18px;
    font-size: 18px;
    color: var(--n-700);
  }

  img {
    width: 20px;
    height: 20px;
  }
}
