.adoption-steps-manager {
  margin: auto;
  bottom: 0;
  background: #ffffff;
  display: flex;
  border-radius: 16px;
  justify-content: center;
  min-width: 0;

  .tour-steps-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 4px;
    min-width: 0;

    .groups-wrapper {
      display: flex;
      min-width: 0;
      overflow: hidden;
      overflow-x: auto;

      .group-steps-wrapper {
        display: flex;
        background: rgba(206, 206, 206, 0.2);
        border-radius: 16px;
        align-items: center;
        gap: 3px;
        padding: 4px;
        min-width: 0;
        overflow: hidden;
        flex-shrink: 0;

        &.passive {
          opacity: 0.3;
        }

        .group-steps {
          display: flex;
          min-width: fit-content;
          overflow: hidden;

          .tour-step-separator {
            color: rgba(0, 0, 0, 0.2);
            font-size: 8px;
          }

          .group {
            display: flex;
            height: 42px;
            box-sizing: border-box;
            align-items: center;
            padding: 0 3px;

            &:not(.cannot-drag) {
              .dragger {
                display: flex !important;
              }
            }

            .dragger {
              margin-left: 6px;
              margin-right: 4px;
              display: none;

              i {
                color: gray;
                margin: 1px;
                font-size: 14px;
              }
            }

            &.is-group {
              background: #ffffff;
              border: 1px solid rgba(0, 0, 0, 0.05);
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
              border-radius: 12px;
              margin: 0 3px;

              .tour-step {
                height: 34px;
                width: 34px;

                &.is-dragging {
                  transform: none;
                }
              }
            }

            &.passive {
              opacity: 0.3;
            }
            &.active {
              .tour-step {
                border-radius: 12px !important;
              }
            }

            &.navigation {
              .tour-step {
                background: transparent;
                border-color: transparent;
              }
            }

            .tour-step {
              background: #fff;
              position: relative;
              cursor: pointer;
              width: 38px;
              height: 38px;
              border: 2px solid rgba(0, 0, 0, 0.07);
              border-radius: 12px;
              display: flex;

              &.selected {
                background: rgba(18, 96, 235, 0.1);
                border: 2px solid #1260eb;

                &.is-dragging {
                  background: #d1def6;
                }
              }

              &.removed {
                & > *:not(.delete-btn, .step-preview-wrapper, .unhide-btn) {
                  opacity: 0.3;
                }
              }

              &.invalid {
                background: #fff0f0;
                border-style: dashed !important;
                border-color: #ffa5a5 !important;
              }

              &.is-dragging {
                transform: rotate(3deg);
              }

              &.has-prev-step {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                margin-left: 1px;
              }

              &.has-next-step {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                margin-right: 1px;
              }

              i {
                margin: auto;
                font-size: 16px;

                &.icon-slot-dot {
                  font-size: 20px;
                }
              }

              .delete-btn,
              .unhide-btn {
                z-index: 1;
                display: none;
                position: absolute;
                top: -10px;
                right: -10px;
                padding: 6px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
                border-radius: 8px;

                i {
                  font-size: 12px;
                  margin-right: 0 !important;
                }
              }

              .step-preview-wrapper {
                display: none;
              }

              &:hover {
                .delete-btn,
                .unhide-btn {
                  display: flex;
                }

                .step-preview-wrapper {
                  display: flex;
                }
              }
            }
          }
        }
      }

      .group-separator {
        color: rgba(0, 0, 0, 0.2);
        font-size: 14px;
        margin: auto 10px;
      }
    }

    .dropdown-trigger {
      .add-step-btn-wrapper {
        margin: auto;

        .add-step-btn {
          height: 42px;
          width: 42px !important;
          background: white;
          border-radius: 12px;
          padding: 8px;
          width: 100%;
          transition: all 300ms ease;

          i {
            margin: auto;
            color: #1260eb;
            font-size: 24px;
            transition: all 300ms ease;
          }

          &:hover,
          &.active {
            background-color: rgb(32, 141, 255, 0.1) !important;
            box-shadow: none;
          }
        }
      }
    }
  }

  .edit-target {
    flex: 1;
    margin: auto;

    .change-target-btn {
      margin: auto;
      padding: 16px;
      border-radius: 12px;
      border: 1px solid;
      cursor: pointer;

      i {
        margin-left: 12px;
      }
    }
  }

  .action-btns {
    margin: auto;
    margin-right: 4px;
    display: flex;

    .btn {
      white-space: nowrap;
    }
  }
}

.dropdown-add-step-content {
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.06);
  border-radius: 12px;

  .dropdown-content {
    .steps-menu-builder {
      padding: 0px;

      .menu-title {
        padding: 0 16px;
        padding-bottom: 10px;

        &:nth-child(1) {
          padding-top: 16px;
        }
      }

      .poke-steps {
        padding: 16px;
        padding-top: 0;
      }
      .navigation-step-wrapper {
        padding: 16px;
        background: #f5f5f5;
      }
      .steps-menu-item {
        padding: 10px 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        margin-bottom: 16px;
        background: #ffffff;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }

        &:hover {
          background: rgba(18, 96, 235, 0.08);
          border: 1px solid rgba(18, 96, 235, 0.5);
          box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.06);

          .content {
            .title {
              color: #1260eb;
            }
          }
        }

        .icon-wrapper {
          margin-right: 20px;

          i {
            font-size: 24px;
            margin: 0;
          }
        }
      }
    }
  }
}
