.audience-section.when {
  .audience-section-content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .divider {
      margin: 8px 0;
    }
  }
}
