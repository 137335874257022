.integration-posthog {
  .posthog-logo {
    border-radius: 12px;
  }

  .actions-btn-group {
    display: flex;

    .settings-btn {
      margin-right: 12px;

      i {
        margin-right: 0 !important;
        font-size: 16px;
      }
    }
  }
}
