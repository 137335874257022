.clickable-input {
  display: flex;
  gap: 6px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  align-items: center;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;

  &.empty-left-label {
    padding: 4px 12px;
  }

  &.is-invalid {
    border-color: var(--r-500);
  }

  &:hover:not(.disabled),
  &.is-editing {
    box-shadow: 0px 0px 0px 4px rgba(18, 96, 235, 0.1);
    border-color: var(--b-400);
  }

  &.disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.1) !important;
    border-color: rgba(0, 0, 0, 0.15) !important;
    opacity: 0.5;
  }

  .label-left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 12px;
    overflow: hidden;
    flex-shrink: 0;
  }

  .content {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .label-right {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: auto;
    cursor: pointer;

    i {
      font-size: 12px;
    }
  }
}
