.release-empty-state {
  .content {
    .title {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 36px;
    }

    .actions {
      margin-top: 40px;
    }
  }
}
