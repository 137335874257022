.slack-connect {
  background-color: #ffffff;
  padding: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 60px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 200px;

  .slack-connect-header {
    width: 100%;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;

    .left-side {
      display: flex;
      gap: 24px;
      align-items: center;

      .left-side-logo {
        border-radius: 8px;
        border: 1px solid #0000001a;
        padding: 12px;
        img {
          width: 34px;
          height: 34px;
        }
      }

      .left-side-text {
        display: flex;
        flex-direction: column;
        gap: 8px;
        a {
          text-decoration: underline;
        }
        
        .header {
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;
          
          .label-connected {
            display: flex;
            align-items: center;
            font-weight: 500;
            gap: 4px;
            padding: 4px 8px;
            background-color: var(--g-100);
            color: var(--g-500);
            border-radius: 16px;

            i {
              font-size: 16px;
            }
          }
        }
      }
    }

    .right-side {
      display: flex;
      align-items: flex-end;
      justify-content: center;

      .action-button-group {
        display: flex;
        gap: 8px;
      }
    }
  }

  .slack-connect-loading-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .slack-connect-card {
    .select-group-wrapper {
      width: 100%;
    }
    .dropdown-trigger {
      width: 100%;
      .slack-connect-input {
        cursor: grab;
      }
    }

    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: left;

    .card-complete {
      width: 100%;
      display: flex;
      gap: 8px;
      justify-content: space-between;

      .left-side {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .right-side {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  border-radius: 16px;
}
