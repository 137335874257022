.evolution-list {
  display: flex;
  flex-flow: column;
  height: calc(100% - 42px);
  width: 100%;
  overflow: hidden;
  position: relative;

  .evolution-list-header {
    .filter-wrapper {
      display: flex;
      gap: 16px;

      .dropdown-trigger {
        &.is-set {
          .btn {
            border-color: #1260eb66;
            background-color: #1260eb0f;
            color: #1260eb;

            i {
              color: #1260eb !important;
            }
          }
        }

        i.icon-left {
          border-right: 1px solid rgba(0, 0, 0, 0.1);
          padding-right: 8px;
        }
        i.icon-right {
          font-size: 8px !important;
          color: #b6b6b6;
          margin-left: 10px !important;
        }
      }
    }
  }

  .search-wrapper {
    .input-group {
      display: flex;

      input {
        border-radius: 12px;
        border: none;
        font-size: 18px;
        color: white;
      }
    }
    .input-group.labeled .label.label-right {
      padding: 15px 24px;
      border-radius: 12px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border: none;
      background-color: rgba(255, 255, 255, 0.05);
      color: white;
    }
  }
  .scroll-wrapper {
    flex: 1 1 auto;
    transition: all 0.2s ease-in;
    padding-right: 8px;
    overflow-y: auto;
    display: flex;
    flex-flow: column;
    gap: 20px;

    &.dropdown-open {
      transform: scale(0.95);
    }

    .tags-wrapper {
      font-weight: 500;
      & > span {
        cursor: pointer;
      }

      .tags-manage-wrapper {
        display: flex;
        justify-content: space-between;
      }
      .icon-chevron-bottom {
        display: inline-block;
        font-size: 10px;
        margin-left: 8px;
        transform: rotate(0deg);
        transition: all 100ms ease-in-out;
        &.is-collapsed {
          transform: rotate(-90deg);
        }
      }
      .tag-list-wrapper {
        margin-top: 16px;
        min-height: 0;
        transition: all 100ms ease-in-out;

        .tag-limitation {
          padding: 12px;
          margin-bottom: 24px;

          a {
            color: #409af8;
            text-decoration: underline;
          }
        }
      }
      .manage-tags {
        margin-left: 22px;
        color: rgba(255, 255, 255, 0.3);
        cursor: pointer;
        &:hover {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
    .list-wrapper {
      flex: 1 1 auto;
      overflow-y: auto;

      &.no-padding-top {
        padding-top: 0;
      }

      .list {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .evolution {
          position: relative;
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          &.is-unread {
            .isax-link-2 {
              margin-left: 10px;
            }
          }

          .btn-tag {
            align-self: flex-start;
            margin-bottom: 16px;
          }
          .infos {
            display: flex;
            align-items: center;
            padding: 0 30px 24px 0;
            font-size: 18px;

            .icon-chevron-right {
              position: absolute;
              right: 24px;
              font-size: 12px;
            }
          }
          .extra-wrapper {
            display: flex;
            font-weight: 500;

            .vote-result {
              margin-right: 24px;
            }
          }
        }
      }
    }
  }
}

.new-post-dropdown {
  background-color: white;
}

.new-post-dropdown-content {
  background-color: transparent;
  padding: 12px;
  animation: 500ms fade-in-top;

  .new-push-item {
    &:first-child {
      margin-bottom: 0;
    }
    margin-bottom: 12px !important;
  }
}
