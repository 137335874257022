.poke-block-interview {
  display: flex;
  justify-content: space-between;
  margin: 24px;
  margin-bottom: 0;

  .btn-interview-book,
  .btn-interview-cancel {
    padding: 12px 18px;
    transition: all 200ms ease-in-out;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
