.modal-copy-settings-from {
  min-width: 760px;
  max-width: 80%;
  width: auto;
  border-radius: 16px;

  .modal-title {
    padding: 16px;
  }

  .copy-settings-preview {
    display: flex;
    gap: 24px;
    padding: 16px;

    & > div {
      flex: 1 1 0px;
    }

    .experience-preview {
      position: relative;
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      padding-bottom: 20px;
      min-height: 173px;
      border: 1px solid var(--n-100);
      border-radius: 12px;
      padding: 16px;
      gap: 16px;

      .preview {
        display: flex;
        width: 310px;
        position: relative;
        border-radius: 12px;
        border: 1px solid var(--n-300);
        background: var(--n-100);
        transform: scale(1);
        flex: 1 auto;
        width: 100%;
        overflow: hidden;

        .loader-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 24px auto;
        }

        .live-preview {
          position: absolute;
          margin: auto;
          transform: scale(0.8);
          width: 100%;
          height: 100%;
        }
      }

      .infos {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .push-title {
          align-items: center;
          font-weight: 500;
          color: #000000;
          font-size: 20px;
          display: flex;
          gap: 8px;
          overflow-wrap: anywhere;
        }

        .created-at {
          color: rgba(0, 0, 0, 0.5);
          font-size: 14px;
        }
      }
    }
  }

  .modal-footer {
    padding: 16px;
  }
}
