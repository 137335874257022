.block-settings.checklist-list-item {
  .section-title-extra {
    .block-group {
      .icon-wrapper {
        background-color: var(--o-500);

        i {
          font-size: 12px;
          color: white;
        }
      }
    }
  }

  .action-picker {
    .actions-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .add-action-element-placeholder-btn {
      .label-left {
        display: flex;
        width: 32px;
        height: 32px;
        box-sizing: border-box;
        border: 1px solid var(--n-100);
        border-radius: 12px;
        background: var(--n-100);

        i {
          margin: auto;
          font-size: 18px;
          box-sizing: border-box;
          border-radius: 12px;
          text-align: center;
          color: var(--n-600);
        }
      }
    }
  }

  .section-item.triggers {
    .triggers-btn {
      &.with-conditions {
        background: var(--b-400);
        color: white;

        .label-left {
          background: rgba(255, 255, 255, 0.2);
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 12px;
          box-sizing: border-box;

          i {
            color: white;
          }
        }
      }

      .label-left {
        display: flex;
        width: 32px;
        height: 32px;
        box-sizing: border-box;
        border: 1px solid var(--n-100);
        border-radius: 12px;
        background: var(--n-100);

        i {
          margin: auto;
          font-size: 18px;
          box-sizing: border-box;
          border-radius: 12px;
          text-align: center;
          color: var(--n-600);
        }
      }
    }
  }
}

.popup-setting-checklist-completed-content {
  .dropdown-content {
    overflow: hidden;
    .popup-setting-wrapper {
      padding: 0;

      .popup-setting-header {
        padding: 12px 12px 0 12px;
      }
    }
  }
}
