.block-settings.concept {
  .icons-brand-wrapper {
    margin-top: 4px;

    img {
      margin-right: 4px;
      height: 20px;
      width: 20px;
    }
  }

  .prototype-info-input {
    flex: 1 auto;

    .input {
      height: 40px;
      background: rgba(138, 138, 138, 0.1);
      border-radius: 12px;
      resize: none;
      padding: 8px 12px;
      font-size: 14px;
    }
  }

  .preview-prototype-media {
    margin: auto 4px;
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;

    img {
      width: 32px;
      height: 32px;
      border-radius: 8px;
    }
  }
  .placeholder-empty-media {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }
}
