.changelog-preview {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background: var(--n-400);
  border-radius: 12px;
  position: relative;
  overflow: hidden;

  .changelog-wrapper {
    position: absolute;
    top: 0;
    display: flex;
    flex: 1 auto;
    border-radius: 12px;
    overflow: hidden;
    width: 320px;
    height: 100%;

    &.is-right {
      right: 0;
    }
    &.is-iframe-hidden {
      opacity: 0;
    }

    .changelog-iframe {
      height: 100%;
      width: 100%;
      border: 0;
    }
  }

  .preview-widget-triggers {
    &.is-hidden {
      display: none !important;
    }
    &.mode-default {
      position: absolute;
      bottom: 12px;
      display: inline-flex;
      gap: 8px;
      align-items: center;
      border-radius: 100px;
      padding: 10px 16px;
      font-size: 18px;
      font-weight: 500;
      &.has-position-left {
        left: 12px;
        flex-flow: row-reverse;
      }
      &.has-position-right {
        right: 12px;

        .emoji-animated-wrapper {
          transform: scaleX(-1);
        }
      }

      .message-wrapper {
        max-width: 500px;
      }

      .icon-wrapper {
        svg {
          width: 40px;
          height: 30px;
        }
        img {
          height: 36px;
          width: 36px;
        }
      }
    }
    &.mode-custom {
      position: absolute;
      right: 95px;
      top: 244px;
    }
    &.mode-badge {
      position: absolute;
      top: 22px;
      right: 75px;
      color: #000;
      font-weight: 500;

      .preview-whats-new {
        display: flex;
        align-items: center;

        .whats-new-badge {
          margin-left: 8px;
          width: 18px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          border-radius: 50%;
          background-color: #ff4545;
          box-shadow: 0px 0px 4px 6px #ff454569;
          color: #fff;
          font-size: 12px;
          animation: trigger-animation-pulsating 2s ease-in-out both infinite;
        }
      }
    }
    &.mode-side-badge {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &.has-position-right {
        .preview-trigger-side-wrapper {
          right: 0;

          .preview-trigger-side {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
        }
      }
      &.has-position-left {
        left: 0;

        .preview-trigger-side {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }

      .preview-trigger-side-wrapper {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        .preview-trigger-side {
          background-color: var(--widgetThemeColor);
          color: #fff;
          cursor: pointer;
          display: flex;
          padding: 16px;
          font-size: 18px;
          align-items: center;
          flex-direction: column-reverse;

          .message {
            text-orientation: mixed;
            line-height: 18px;
            writing-mode: vertical-lr;
            transform: rotate(180deg);
          }
          .preview-trigger-side-notification {
            width: 21px;
            height: 21px;
            background-color: #ff4545;
            border-radius: 50%;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            font-weight: 500;
            color: #fff;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}
