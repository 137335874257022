.s-settings-control {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .settings-title .divider {
    margin-bottom: 0;
  }

  .card-limit {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex: 1;
    }
    .right-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;

      input {
        width: 60px;
      }
    }
  }
  .card-excluded-list {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .excluded-poke {
        display: grid;
        grid-template-columns: 20% auto 10%;
        align-items: center;
        padding: 16px;
        border: 1px solid var(--n-300);
        border-radius: 16px;

        .center-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
        .right-wrapper {
          display: flex;
          justify-content: flex-end;

          .btn {
            padding: 12px 16px;
          }
        }
      }
      .labels-wrapper {
        display: flex;
        gap: 8px;
      }
      .excluded-label {
        border-radius: 16px;
        padding: 4px 12px;
        border: 1px solid var(--n-300);
        display: inline-flex;
        align-items: center;
        flex-grow: 0;
        gap: 8px;

        i {
          font-size: 16px;
        }
      }
      .status-tag {
        padding: 4px 12px;
        border-radius: 16px;
        display: inline-block;
      }
      .tag-expired {
        color: var(--p-500);
        background-color: var(--p-100);
      }
      .tag-live {
        color: var(--g-500);
        background-color: var(--g-100);
      }
      .tag-paused {
        color: var(--o-500);
        background-color: var(--o-100);
      }
      .tag-draft {
        color: var(--n-700);
        background-color: var(--n-100);
      }
    }
  }
}
