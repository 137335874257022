.templates-sidebar {
  display: flex;
  width: 280px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 0 auto;
  align-self: stretch;
  justify-content: space-between;
  overflow: hidden;
  overflow-y: auto;

  .menu {
    padding: 24px 12px;
    box-sizing: border-box;
    width: 280px;
  }
  .menu-item {
    padding: 8px 12px;
    margin-bottom: 2px;
    border-radius: 12px;
    &:not(.is-active):hover {
      background-color: rgba(240, 242, 244, 1) !important;
      color: rgba(77, 99, 123, 1) !important;
    }
    &.is-active:hover {
      background-color: rgba(240, 242, 244, 1) !important;
      color: #000 !important;
    }
    &.is-active {
      background-color: rgba(240, 242, 244, 1);
      color: #000;

      .count {
        color: var(--n-900);
      }
    }

    i {
      font-size: 20px;
    }

    .count {
      margin-left: auto;
      color: var(--n-500);
    }
  }
  .menu-section {
    font-size: 14px;
    text-transform: none;
    margin-bottom: 8px;
    padding: 0;
    padding-left: 12px;
  }
  .divider {
    margin: 18px auto;
    width: 232px;
  }

  .templates-sidebar-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }

  .templates-sidebar-footer {
    align-self: stretch;
  }
}
