.checklist-cta {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  border: 1px solid transparent;
  white-space: nowrap;
  height: fit-content;

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}
