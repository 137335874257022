.tag-editor {
  margin-bottom: 24px;
  &:not(.is-edit-mode) {
    background-color: rgba(0, 0, 0, 0.08);
    font-size: 18px;
    font-weight: 400;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &.is-edit-mode {
    .input {
      font-size: 18px;
      padding: 16px;
      background-color: transparent;
    }
    .editor-hint {
      font-size: 10px;
      color: rgba(0, 0, 0, 0.2);
      text-align: right;
      font-weight: 500;
    }
  }
  &.is-delete-mode {
    .editor-actions {
      .btn {
        padding: 6px 12px;
        font-size: 12px;
        transition: background-color 100ms ease-in-out;
      }
    }
  }

  .content-wrapper {
    display: flex;
    align-items: center;
  }
  .color {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin-right: 16px;
  }
  .editor-actions {
    display: flex;

    .btn {
      padding: 6px;
      &:first-child {
        margin-right: 8px;
      }
    }
  }
}
