.s-get-started-try {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .gs-top {
    display: flex;
    align-items: center;

    .btn {
      margin-right: 24px;

      i {
        font-size: 14px !important;
      }
    }
  }

  .gs-body {
    padding: 26px;
    flex: 1;
    overflow: auto;
  }
  .gs-container-install-chrome-extension {
    max-width: 712px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;

    small {
      margin-top: 8px;
    }
    a {
      width: 100%;
    }
    .btn {
      width: 100%;
      justify-content: center;
      padding: 16px;
    }
    .extension-installed {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      background-color: #fff;
      padding: 16px;
      border-radius: 16px;
      border: 2px solid rgba($color: #159201, $alpha: 0.4);
      width: 100%;
      box-sizing: border-box;

      i {
        color: #159201;
        border-radius: 50%;
        border: 2px solid #159201;
        padding: 6px;
        font-size: 16px;
        margin-right: 8px;
      }
    }
    .confetti-wrapper {
      position: absolute;
      top: -70px;
    }
  }
  .gs-container-create-content {
    display: flex;
    background-color: #fff2e9;
    border-radius: 16px;

    .gs-container {
      width: 40%;
      max-width: unset;
      display: inline-flex;
      height: 608px;
      padding: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      flex-shrink: 0;

      .create-content {
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        margin-bottom: 16px;
        font-size: 16px;
      }

      .or-separator {
        color: #000;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    .gs-card-create-content {
      align-self: stretch;
      padding: 0 16px;
      margin-bottom: 16px;
      &.is-done {
        background-color: rgba(21, 146, 1, 0.02);
        border: 2px solid rgba(21, 146, 1, 0.4);
      }
      &:hover {
        .create-btn {
          opacity: 1;
        }
      }
    }
    .card-cc-top {
      padding: 16px 0;
      display: flex;
      align-items: center;
      cursor: pointer;

      .icon-wrapper {
        width: 40px;
        height: 40px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        margin-right: 8px;
      }
      .content-description {
        display: flex;
        flex-direction: column;
        font-weight: 600;
        flex: 1;
        font-size: 18px;

        small {
          font-weight: 400;
          font-size: 16px;
          margin-top: 4px;
        }
      }
      .create-btn {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: var(--content-accent-brand, #1260eb);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; /* 20px */

        i {
          font-size: 10px;
        }
      }
    }
    .card-cc-body {
      border-top: 1px solid rgba($color: #1260eb, $alpha: 0.1);
      padding: 16px 0;

      .body-item {
        display: flex;
        align-items: center;
        padding: 12px;
        cursor: pointer;
        border-radius: 12px;

        &:hover {
          background-color: rgba(18, 96, 325, 0.07);
          color: #1260eb;
        }
        .icon-wrapper {
          width: 40px;
          height: 40px;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          margin-right: 8px;
          color: #000 !important;
        }
        .content-description {
          display: flex;
          flex-direction: column;
          font-weight: 600;
          flex: 1;
          font-size: 16px;

          small {
            font-weight: 400;
            font-size: 12px;
            margin-top: 4px;
          }
        }
        .icon-arrow-up {
          transform: rotate(90deg);
          font-size: 10px;
        }
      }
    }
    .illustration-wrapper {
      width: 60%;
      box-sizing: border-box;
      padding: 62px;
      display: flex;
      align-items: center;
      & > div {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        max-height: 100%;
      }
      video {
        width: 100%;
        max-height: 420px;
      }
    }
  }
  .gs-try-cover {
    background-size: cover;
    width: 100%;
    height: 177px;
    background-color: #f2f2f2;
    border-radius: 16px;
    margin-bottom: 16px;
    overflow: hidden;
    iframe {
      width: 100%;
      height: calc(100% + 40px);
      position: relative;
      top: 20px;
    }
  }

  .gs-container-try-end {
    max-width: 712px;
    font-weight: 600;
    position: relative;
    font-size: 16px;

    p {
      font-weight: 400;
      font-size: 14px;
    }
    span {
      color: #1260eb;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
    .btn {
      margin-top: 16px;
    }
    .iframe-wrapper {
      position: absolute;
      top: -50px;
      right: -220px;
      overflow: hidden;
      width: 300px;
      height: 300px;
      iframe {
        position: relative;
        top: -200px;
        left: -170px;
      }
    }
  }
}

@-webkit-keyframes get-started-illu-in {
  0% {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
@keyframes get-started-illu-in {
  0% {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
