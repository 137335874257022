.builder-audience-path-selector {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .rules-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .path-list {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .path-list-wrapper {
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        border: 1px solid var(--n-300);

        .divider {
          margin: 0;
          padding: 0;
        }

        .builder-audience-boosted-path {
          padding: 12px;
          position: relative;

          &:hover {
            .actions-wrapper {
              opacity: 1;
            }
          }

          .jimo-select__control {
            box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
          }

          .path-input {
            .input-main-wrapper {
              border: 0;
            }
          }

          .actions-wrapper {
            opacity: 0;
            display: flex;
            gap: 8px;
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            align-items: center;
            transition: opacity 0.2s ease;

            i {
              font-size: 24px;
            }

            .action-btn {
              height: 34px;
              width: 34px;
              padding: 0;
              display: flex;
              border-radius: 8px;

              i {
                margin: auto;
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    .ai-settings {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .ai-input-group-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .input-group {
          .label-left {
            i {
              font-size: 24px;
            }
          }
        }
      }

      .ai-settings-buttons {
        display: flex;
        gap: 8px;
      }
    }

    .buttons-wrapper {
      display: flex;
      gap: 8px;

      .feedback-wrapper {
        display: flex;
        margin-left: auto;
        gap: 20px;

        .feedback-title {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .feedback-btns {
          display: flex;
          align-items: center;
          gap: 8px;

          i {
            font-size: 20px;
            cursor: pointer;

            &:hover {
              color: var(--n-700);
            }
          }
        }
      }
    }

    &.small {
      .jimo-select.operator-select {
        width: 100px;
        min-width: unset;

        .jimo-select__value-container {
          padding: 4px;
          height: 100%;

          .jimo-select__single-value {
            white-space: pre-wrap;
          }
        }
      }
    }

    .input-domain-filter {
      width: 100%;
    }
  }

  .domains-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .btn-add-domain {
      width: fit-content;
    }

    .input-domain-filter {
      width: 100%;
    }

    .domains-creatable {
      .jimo-creatable__control {
        border-radius: 12px;
        padding: 4px 12px;
        padding-right: 4px;
        flex-flow: nowrap;
        gap: 8px;
        min-height: 50px;

        i.isax-global {
          font-size: 18px;
          color: var(--n-600);
        }

        .control-wrapper {
          display: flex;
          flex-grow: 1;

          .jimo-creatable__value-container {
            padding: 0;
          }
        }

        .jimo-creatable__placeholder {
          font-family: 'Inter', sans-serif;
          line-height: 150%;
          font-weight: 500;
          font-size: 16px;
          color: var(--n-500);
        }

        .jimo-creatable__input-container {
          cursor: text;
        }

        .jimo-creatable__multi-value {
          border: 1px solid var(--n-300);
          background: var(--n-100);
          margin: 4px;
          gap: 4px;
          border-radius: 4px;
          padding: 4px 8px;

          .jimo-creatable__multi-value__label {
            color: var(--n-800);
            padding: 0;
          }

          .jimo-creatable__multi-value__remove {
            color: var(--n-600);
            padding: 0;
            cursor: pointer;
            font-size: 8px;
            background: transparent;

            &:hover {
              color: var(--n-700);
            }
          }
        }

        .jimo-creatable__clear-indicator {
          cursor: pointer;

          &:hover {
            i {
              color: var(--n-800);
            }
          }

          i {
            font-size: 20px;
          }
        }
      }
    }

    .filter-domain-info-wrapper {
      background-color: rgba(18, 98, 235, 0.05);
      padding: 8px;
      border-radius: 8px;
      margin: 12px 0;

      p {
        margin: 0;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
        &:first-child {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          color: rgb(18, 98, 235);
          i {
            margin-right: 4px;
          }
        }
      }
    }
    .filter-domain-info {
      align-items: center;

      i {
        position: relative;
        top: 2px;
        margin-right: 8px;
      }
      pre {
        display: inline-block;
        margin: 0 4px;
        color: rgb(18, 96, 235);
        background-color: rgba(18, 98, 235, 0.1);
        padding: 2px 4px;
        border-radius: 4px;
      }
    }
  }
}
.url-tester-wrapper {
  position: relative;
  background: #f4f4f4;
  border-radius: 24px;
  padding: 16px;

  &.invalid-url {
    background: #fde6e6;

    .url-tester-text {
      color: #c9242d;
    }
  }
  &.url-no-match {
    background: #fff8e6;
    .url-tester-text {
      color: orange;
    }
  }
  &.url-match {
    background: #e6fde8;

    .url-tester-text {
      color: #13805c;
    }
  }

  .url-tester-title {
    margin-bottom: 8px;
  }

  .url-tester {
    display: flex;

    .icon-wrapper {
      display: flex;
      transform: rotate(180deg);
      margin-right: 8px;

      i {
        margin: auto;
      }
    }

    .url-tester-input {
      flex: 1 1 auto;

      .input-main-wrapper {
        background: #ffffff;

        i {
          font-size: 24px;
          color: var(--n-600);
        }

        .input {
          font-size: 14px;
        }
      }
    }
  }

  .url-tester-text {
    margin-top: 8px;
    color: var(--n-700);
  }
}
.builder-audience-boosted-path {
  display: flex;
  gap: 8px;

  .jimo-select {
    min-width: 180px;
    .jimo-select__control {
      height: 52px;
    }
  }

  .label-left {
    color: var(--n-400);
  }

  .input-group {
    flex-grow: 1;
    align-self: stretch;

    .input-main-wrapper {
      height: 100%;
    }
  }
}

.ai-explanation-tooltip-content {
  width: 300px;
  padding: 16px;
  border-radius: 20px;
  border: 1px solid var(--n-300);

  .tooltip-children {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .loader-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
    }

    .content {
      display: flex;
      flex-direction: column;
    }
  }
}
