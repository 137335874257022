.timeline-wrapper {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 32px;

  &.no-padding {
    padding: 0;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;

    .timeline-trait {
      position: absolute;
      z-index: -1;
      top: -32px;
      left: 28px;
      width: 2px;
      height: calc(100% - 16px);
      background-color: rgba($color: #000000, $alpha: 0.1);
    }

    .timeline-item-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      position: relative;

      .sub-timeline-trait {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 64px;
        width: 2px;
        height: calc(100% - 16px);
        background-color: rgba($color: #000000, $alpha: 0.1);
      }
      .timeline-item {
        display: flex;
        flex-direction: column;
        padding: 8px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.06);
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.04);
        border-radius: 16px;

        &.interactive {
          cursor: pointer;
        }

        .item-infos {
          display: flex;
          flex: 1 auto;

          .icon-wrapper {
            margin-right: 12px;
            border-radius: 12px;
            height: 40px;
            width: 40px;
            display: flex;
            position: relative;

            &.scratch {
              background-color: #fff;
              border: 1px solid rgba(0, 0, 0, 0.1);
            }
            &.discovery {
              background-color: #e6f5fd;
            }
            &.adoption {
              background-color: #e6fde8;
            }

            i {
              margin: auto;
              font-size: 20px;
            }

            .count-wrapper {
              position: absolute;
              right: -6px;
              bottom: -6px;
              width: 24px;
              height: 24px;
              background: #111c39;
              box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
              border-radius: 11px;
              display: flex;

              .count {
                margin: auto;
                color: #fff;
                font-family: 'Inter';
                font-weight: 500;
                font-size: 14px;
              }
            }
          }

          .item-content-wrapper {
            display: flex;
            flex-direction: column;

            .content {
              display: flex;
              color: rgba(0, 0, 0, 0.5);

              .context {
                margin: auto 0;
              }

              .username,
              .evolution-title,
              .portal-count {
                color: #000000;
              }

              .evolution-title {
                &:hover {
                  text-decoration: underline;
                }
              }

              .evolution-title,
              .portal-count {
                &:hover {
                  cursor: pointer;
                  color: #1260eb;
                }
              }

              .comment {
                padding: 16px;
                margin: 10px 0;
                background-color: rgba(0, 0, 0, 0.04);
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 8px;

                &:hover {
                  cursor: pointer;
                }
              }

              &.vote {
                .vote-context-wrapper {
                  display: flex;

                  .context {
                    display: flex;
                  }
                }

                .emoji {
                  margin: 0 4px;
                }
              }

              &.comment {
                flex-direction: column;

                .comment-context-wrapper {
                  display: flex;
                }
              }

              .tag-button-wrapper {
                margin-left: 8px;

                .btn-tag {
                  padding: 2px 12px !important;
                  cursor: default;
                }
              }
            }

            .date {
              margin-top: auto;
              color: rgba(0, 0, 0, 0.5);
            }
          }

          .actions-wrapper {
            margin-left: auto;
            display: flex;

            .show-portal-details {
              border: 0;
              padding: 8px;

              i {
                margin: 0;
                transition: transform 0.3s ease-in-out;
              }

              &.open {
                i {
                  transform: rotate(90deg);
                }
              }
            }

            .tour-progress-wrapper {
              display: flex;
              margin: auto;

              .tour-progress {
                font-size: 16px;
              }

              .circular-progress-wrapper {
                height: 20px;
                width: 20px;
                margin-left: 8px;
              }
            }
          }
        }

        .extra {
          margin-top: 8px;
          .comment {
            font-weight: 300;
          }
        }
      }

      .sub-events-wrapper {
        width: 100%;
        .sub-events {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-left: 36px;

          .sub-event {
            .icon-wrapper {
              height: 32px;
              width: 32px;

              .icon {
                font-size: 16px;
              }
            }

            .content-wrapper {
              .content {
                margin: auto;
              }
            }
          }
        }

        &.stacked {
          position: absolute;
          top: 0;
          z-index: -1;

          .sub-events {
            margin-left: 0;
            position: relative;

            .timeline-item-wrapper {
              position: absolute;

              &:nth-child(1) {
                top: 19px;
                z-index: -1;
                width: 96%;
                transform: translateX(2%);
              }
              &:nth-child(2) {
                top: 22px;
                z-index: -2;
                width: 92%;
                transform: translateX(4%);
              }

              &:nth-child(n + 3) {
                display: none;
              }

              .sub-event {
                animation: none;

                .icon-wrapper {
                  height: 24px;
                  width: 24px;

                  .icon {
                    font-size: 12px;
                  }
                }

                .content-wrapper {
                  .content {
                    margin: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
