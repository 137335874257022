.changelog-drawer-preview {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 400px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding-bottom: 0;
  box-sizing: border-box;
  height: 100%;

  .post-preview-title {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
  }

  .changelog-wrapper {
    display: flex;
    flex: 1 auto;
    overflow: hidden;
    height: 100%;

    .changelog-iframe {
      height: 100% !important;
      width: 100%;
      border: 0;
    }
  }
}
