.s-event {
  overflow: hidden;
  height: 100%;
  background: #fcfbfb;
  display: flex;
  flex-direction: column;

  .loader-wrapper {
    display: flex;
    margin: auto;

    .loader {
      margin: auto;
    }
  }

  .event-header-wrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .event-content-wrapper {
    overflow-y: auto;
    flex: 1 auto;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
