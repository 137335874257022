.checklist-footer {
  display: flex;
  justify-content: center;
  padding: 12px 16px;
  align-items: center;

  &.dismissible {
    justify-content: space-between;
  }

  .poke-renderer-poke-label,
  .poke-jimo-label {
    background: transparent !important;

    span {
      padding: 0;
      background: transparent !important;
    }
  }
  .dismiss-checklist {
    margin-left: auto;
    color: #4d637b; // Neutral700
    font-size: 14px;
    font-family: Inter, ui-sans-serif, system-ui, sans-serif;
    font-weight: 500;
    line-height: 18px;
    cursor: pointer;
    position: relative;
  }
}
