.s-get-started-install {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .gs-top {
    display: flex;
    align-items: center;

    .btn {
      margin-right: 24px;

      i {
        font-size: 14px !important;
      }
    }
  }

  .gs-body {
    padding: 26px;
    flex: 1;
    overflow: auto;
  }

  .gs-card {
    margin-bottom: 24px;
    padding: 0 16px;

    .card-cc-top {
      padding: 16px 0;
      display: flex;
      align-items: center;
      cursor: pointer;

      .icon-wrapper {
        width: 40px;
        height: 40px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        margin-right: 8px;
      }
      .content-description {
        display: flex;
        flex-direction: column;
        font-weight: 600;
        flex: 1;
        font-size: 18px;

        small {
          font-weight: 400;
          font-size: 16px;
          margin-top: 4px;
        }
      }
      .icon-chevron-bottom {
        font-size: 10px;
        transition: all 100ms ease-in-out;
        &.rotated {
          transform: rotate(180deg);
        }
      }
    }
  }

  .has-jimo-installed {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    padding: 16px;
    background-color: rgba($color: #159201, $alpha: 0.1);
    border: 2px solid rgba($color: #159201, $alpha: 0.4);
    border-radius: 16px;
    overflow: hidden;

    i {
      color: #159201;
      border-radius: 50%;
      border: 2px solid #159201;
      padding: 6px;
      font-size: 16px;
      margin-bottom: 16px;
    }
    .confetti-wrapper {
      position: absolute;
      top: -200px;
    }
  }

  .gs-install-wrapper {
    display: flex;
  }
  .gs-install-choose {
    font-size: 16px;
    font-weight: 600;
  }
  .gs-identify-choose {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .gs-install-methods-wrapper,
  .gs-identify-methods-wrapper {
    flex: 0 0 65%;
    margin-right: 24px;
  }
  .gs-install-success-wrapper,
  .gs-identify-success-wrapper {
    flex: 1;
  }
  .gs-install-tutorial-wrapper {
    flex: 1;
    background-color: #fff;
    border: 1px solid rgba($color: #000, $alpha: 0.1);
    border-radius: 16px;
    position: relative;
    padding: 16px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    text-align: center;
    overflow: hidden;

    .tutorial-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 60%;
      position: relative;
      z-index: 2;
      iframe {
        border-radius: 16px;
        border: 1px solid rgba($color: #000, $alpha: 0.1);
        box-shadow: 0px 24px 94px rgba(0, 0, 0, 0.07);
      }
    }
    .install-tutorial-animation {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 300px;
      z-index: 1;
    }
  }

  .gs-install-methods {
    margin-top: 16px;

    .divider {
      margin-top: 24px;
    }
    .btn-check-installation {
      margin-top: 24px;
    }
  }

  .gs-identify-wrapper {
    display: flex;
  }
  .gs-identify-methods-wrapper {
    display: flex;
    flex-direction: column;

    .divider {
      margin-top: auto;
    }
  }
  .gs-identify-tutorial-wrapper {
    flex: 1;
    background-color: #e8f4fb;
    border: 1px solid rgba($color: #000, $alpha: 0.1);
    border-radius: 16px;
    position: relative;
    padding: 16px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    text-align: center;
    overflow: hidden;
    min-height: 600px;

    .tutorial-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 60%;
      position: relative;
      z-index: 2;
      iframe {
        border-radius: 16px;
        border: 1px solid rgba($color: #000, $alpha: 0.1);
        box-shadow: 0px 24px 94px rgba(0, 0, 0, 0.07);
      }
    }
    .identify-tutorial-animation {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 300px;
      z-index: 1;
    }
  }

  .identify-sdk-item {
    .sdk-label-name {
      font-size: 16px;
      font-weight: 600;
    }
    .sdk-command-wrapper {
      position: relative;

      pre {
        border-radius: 16px;
        border: 1px solid rgba($color: #000, $alpha: 0.1);
        padding: 16px;
      }
      .btn {
        position: absolute;
        right: 6px;
        top: 6px;
      }
    }
  }

  .card-identify-body {
    padding: 16px 0;

    .btn {
      width: 100%;
      justify-content: center;
    }
  }

  .go-to-documentation {
    font-weight: 500;
    padding: 16px 0;

    a {
      color: #1260eb;
      &:hover {
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
  }

  .btn-check-identification {
    margin-top: 24px;
    align-self: flex-start;
  }

  .gs-container-install-end {
    max-width: 467px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    position: relative;

    small {
      font-weight: 400;
      font-size: 16px;
      margin-top: 4px;
    }
    .btn {
      margin-top: 16px;
      align-self: flex-start;
    }
    .iframe-wrapper {
      position: absolute;
      top: 0px;
      right: -220px;
      overflow: hidden;
      width: 300px;
      height: 300px;
      iframe {
        position: relative;
        top: -200px;
        left: -170px;
      }
    }
  }
}
