.portal-channel-survey {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: inherit;

  .tags-wrapper {
    display: flex;

    .tag-button-wrapper {
      &:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
  .scrollable-wrapper {
    flex: 1;
    overflow: auto;
  }
  .question-wrapper {
    padding: 24px 24px 12px 24px;
    .progress-wrapper {
      margin-bottom: 24px;
      width: 100%;

      .progress-container {
        height: 4px;
        background: rgb(239, 239, 239);
        border-radius: 50px;
        width: 100%;

        div {
          height: 4px !important;
        }
      }
    }

    .tags-timestamp-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .tags-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        .btn-tag {
          margin-right: 4px;
          margin-bottom: 4px;
        }
      }

      .btn-extra-tag {
        margin-right: 8px;
      }

      .date {
        flex-shrink: 0;
        opacity: 0.5;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 500;
      }
    }

    .infos {
      width: 100%;

      .title-date-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          font-weight: 600;
          font-size: 18px;
          margin-bottom: 4px;
          padding-right: 12px;
        }
        .date {
          flex-shrink: 0;
          opacity: 0.5;
          font-size: 10px;
          text-transform: uppercase;
          font-weight: 500;
        }
      }
      .description {
        width: 100%;
      }
    }
  }

  .nps-wrapper {
    width: 100%;
    padding: 12px 24px 24px 24px;
    box-sizing: border-box;
  }

  .select-wrapper {
    width: 100%;
    padding: 0 24px 4px 24px;
    box-sizing: border-box;

    .survey-btn {
      display: flex;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 1px solid rgba(0, 0, 0, 0.2);
      text-align: left;
      padding: 0 32px;
      align-items: center;
      padding: 12px 24px;
      background: transparent;
      border-radius: 6px;

      .checkmark {
        height: 20px;
        flex: 0 0 20px;
        transition: all 100ms ease-in-out;
      }
    }

    .checkbox-btn {
      transition: none;
      &:hover {
        background: white;
        border: 1px solid rgba(32, 141, 255, 0.5);

        .checkmark {
          border: 2px solid rgba(32, 141, 255, 0.5);
        }
      }
      &.selected {
        background: rgba(32, 141, 255, 0.08);
        border: 1px solid rgba(32, 141, 255, 0.5);
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .icon-tick {
        position: relative;
        font-size: 17px;
        line-height: 17px;
        color: #eef6ff;
        background-color: #409af8;
        padding: 4px;
        border-radius: 4px;
        box-sizing: border-box;
        display: block;
      }
      .checkmark {
        border-radius: 4px;
        border: 2px solid #eee;
      }

      .content {
        color: black;
        font-size: 18px;
        margin: auto;
        margin-left: 20px;
      }
    }

    .select-btn {
      &:hover {
        background: white;
        border: 1px solid rgba(32, 141, 255, 0.5);

        .checkmark {
          background-color: rgba(32, 141, 255, 0.5);
        }
      }
      &.selected {
        background: rgba(32, 141, 255, 0.08);
        border: 1px solid rgba(32, 141, 255, 0.5);
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .checkmark {
        padding: 4.5px;
        background-color: #eee;
        border-radius: 50%;
      }
      .select-fill {
        border: 7px solid #409af8;
        border-radius: 50%;
        background-color: #fff;
        flex: 0 0 15px;
        height: 15px;
      }
    }

    .content {
      color: black;
      font-size: 18px;
      margin: auto;
      margin-left: 20px;
    }
  }

  .input-wrapper {
    width: 100%;
    padding: 0 24px 16px 24px;
    box-sizing: border-box;

    .text-long-textarea {
      textarea {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        box-shadow: none;
        font-size: 15px;
        color: black;
      }
    }
  }

  .vote-wrapper {
    padding: 16px 24px;
    display: flex;
    align-items: center;

    .face {
      height: 42px;
      width: 42px;
      border-width: 4px;
      border-style: solid;
      box-sizing: border-box;
      border-radius: 100px;
      border-color: white;
      position: absolute;
    }

    .face2 {
      margin-left: 24px;
    }

    .face3 {
      margin-left: 48px;
    }

    .number-vote {
      align-self: center;
      font-size: 16px;
      font-weight: 400;
    }

    .action-btn {
      margin-left: auto;
      background-color: #4099f7;
      color: white;
      border-color: transparent;
    }
  }

  .opinion-wrapper {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 16px;

    .opinion-scale {
      display: flex;

      .emoji-tooltip-trigger:not(:first-child),
      .emoji-animated-wrapper:not(:first-child),
      .emoji:not(:first-child) {
        margin-left: 36px;
      }

      .emoji-animated-wrapper {
        transition: 200ms all ease-out;
        &:hover {
          transform: scale(1.2);
        }
      }

      & > div {
        cursor: pointer;
      }
    }
  }

  .text-block-wrapper {
    padding: 0 24px 24px 24px;

    .markdown-content {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
    }
    .btns-wrapper {
      margin-top: 12px;
      color: #000 !important;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      display: flex;
      align-items: center;

      .btn {
        padding: 8px 12px;
        border-radius: 8px;
        border: 1px solid #dfdfdf;
        background-color: transparent;
        font-weight: 500;
        font-size: 14px;
        &:first-child {
          margin-right: 12px;
        }
        &:hover {
          background-color: transparent !important;
        }

        i {
          font-size: 14px;
        }
      }
    }
  }
  .text-block-actions-wrapper {
    width: 100%;
    flex: 0 1;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding: 12px 24px;
    box-sizing: border-box;
    justify-content: space-between;
    display: flex;

    .btn {
      padding: 12px 24px;
      margin-left: auto;
    }

    .cta-btn {
      margin: auto;
      font-weight: 400;
    }
  }
}
