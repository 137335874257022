.s-onboarding-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 337px;
  animation: onboarding-login-entry 500ms ease-in-out 800ms both;

  h1 {
    font-size: 24px;
    font-family: 'Montserrat', serif;
    font-weight: 500;
    text-align: center;
    margin: 42px 0 75px 0;

    small {
      font-size: 18px;
    }
  }

  .alert {
    margin-bottom: 24px;
    animation: fade-in 200ms ease-in-out;
    a {
      color: #1260eb;
      font-weight: 500;
      margin-top: 24px;
      &:hover {
        color: #4084f9;
      }
    }
  }

  .divider-wrapper {
    width: 30%;
    color: rgba(0, 0, 0, 0.16);
    margin-top: 12px;
  }

  form {
    margin-top: 12px;
    width: 100%;

    .email-label {
      margin: 8px 0;
    }
    .forgot-label {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 11px;
      margin-top: 4px;
      display: block;
    }
    .input-group input {
      border-radius: 12px;
      padding: 17px 20px;
      font-size: 16px;
      &:focus {
        box-shadow: inset 0 0 0 2px #e9effd;
        border-color: #88abff;
      }
    }
  }

  .btn-submit {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;
    font-weight: 600;
    font-size: 18px;
    margin-top: 16px;
  }

  a {
    color: #1260eb;
    font-weight: 500;
    margin-top: 24px;
    &:hover {
      color: #4084f9;
    }
  }
}

.s-onboarding-register.is-exiting {
  animation: onboarding-register-leave 500ms ease-in-out forwards;
}

@-webkit-keyframes onboarding-login-entry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes onboarding-login-entry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes onboarding-login-leave {
  0% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  100% {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 50, 0);
  }
}
@keyframes onboarding-login-leave {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
