.event-details {
  display: flex;
  max-width: 1042px;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 24px 94px 0px rgba(0, 0, 0, 0.07);

  .event-details-title {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
  }

  .input-section {
    display: flex;
    gap: 8px;
    width: 100%;

    .element {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex: 1 1 auto;

      &.code-name {
        flex: 1 1 100px;

        &.disabled {
          .input-label {
            color: var(--n-600);
          }
        }
      }

      .input-label {
        color: #000;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        gap: 8px;
        align-items: center;

        .error-message {
          margin-left: auto;
        }
      }

      .dropdown-trigger {
        cursor: pointer;
        margin: auto 0;

        .icon-wrapper {
          height: 40px;
          width: 40px;
          display: flex;
          border-radius: 12px;
          flex: 0 0 auto;
          border: 1px solid rgba(0, 0, 0, 0.1);
          align-items: center;
          justify-content: center;
          font-size: 24px;
        }
      }

      .input-group-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 2px;

        .input-group {
          flex: 1;

          .input-main-wrapper {
            .label-left {
              border: 0;
              padding: 0;
              margin: 6px;
            }

            .input {
              font-size: 16px;
            }
          }
        }

        .input-description {
          textarea {
            resize: none;
            padding: 12px;
            border-radius: 12px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
