.button-action {
  &.no-content {
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    cursor: default !important;
  }

  .icon-wrapper {
    display: flex;
    height: 32px;
    width: 32px;
    border-radius: 12px;

    &.go-to-step {
      background-color: var(--g-200);
    }

    &.dismiss {
      background-color: var(--r-200);
    }

    &.launch-experience {
      background-color: var(--b-150);
    }

    &.navigate-to {
      background-color: var(--n-200);
    }

    &.run-js-code {
      background-color: var(--y-300);
    }

    &.open-post {
      background-color: var(--b-150);
    }

    &.book-interview {
      background-color: var(--o-200);
    }
    &.snooze {
      background-color: var(--p-200);
    }

    &.skip-task {
      background-color: var(--y-200);

      i {
        transform: rotate(180deg);
      }
    }

    i {
      margin: auto;
      font-size: 16px;
    }
  }

  .input-main-wrapper {
    padding: 4px;
  }
}

.button-action-dropdown-content {
  .popup-setting-header {
    align-items: center;

    .popup-setting-title {
      display: flex;
      gap: 8px;
      align-items: center;
      margin: auto 0;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 16px;

      .icon-wrapper {
        display: flex;
        height: 24px;
        width: 24px;
        border-radius: 8px;

        &.go-to-step {
          background-color: var(--g-200);
        }

        &.dismiss {
          background-color: var(--r-200);
        }

        &.launch-experience {
          background-color: var(--b-150);
        }

        &.navigate-to {
          background-color: var(--n-200);
        }

        &.run-js-code {
          background-color: var(--y-300);
        }

        &.skip-task {
          background-color: var(--y-200);

          i {
            transform: rotate(180deg);
          }
        }

        &.snooze {
          background-color: var(--p-200);
        }

        i {
          margin: auto;
          font-size: 16px;
        }
      }
    }
  }
}
