.action-list {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .action-item {
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 8px;

    &.selected {
      background: rgba(18, 96, 235, 0.1);
      border: 2px solid #1260eb;
      font-weight: 500;
      color: #1260eb;
    }

    &:hover {
      background: rgba(18, 96, 235, 0.05);
      border: 2px solid #1260eb50;
      font-weight: 500;
      color: #1260eb;
    }

    i {
      margin-right: 8px;
      font-size: 24px;
    }
  }
}
