.remove-step-modal.modal-confirm {
  .modal-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px;
    border-bottom: 0;
    margin-bottom: 0;

    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      background: var(--r-100);
      border-radius: 16px;

      i {
        font-size: 28px;
        color: var(--r-400);
      }
    }
  }

  .content {
    padding-top: 0;
    text-align: center;
  }

  .modal-footer {
    border-top: 0;
    padding-top: 0;

    .btn {
      flex-grow: 1;
      flex-basis: 0;
      justify-content: center;
    }
  }
}
