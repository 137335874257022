.s-onboarding-details-step-2-3 {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: grid !important;
  grid-template-columns: 40% max(60%) !important;

  &.is-exiting {
    .details-customize-wrapper  {
      & > div > h1 {
        animation: onboarding-text-slide-right-out 600ms ease-in-out 500ms both;
      }
      & > div > p {
        animation: onboarding-content-slide-right-out 600ms ease-in-out 300ms
          both;
      }
    }
    .details-customize-wrapper {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 300ms both;
    }
    .details-customize-preview-wrapper {
      animation: details-customize-preview-wrapper-out 600ms ease-in-out 100ms
        both;
    }
    .customize-actions {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 100ms both;
    }
  }

  .details-customize-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: onboarding-content-slide-right 600ms ease-in-out 800ms both;
    padding: 56px 24px;
    & > div > h1 {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      width: 450px;
      animation: onboarding-text-slide-right 600ms ease-in-out 600ms both;
      margin-bottom: 12px;
    }
    & > div > p {
      margin-top: 0;
      font-size: 16px;
      margin-bottom: 75px;
      animation: onboarding-content-slide-right 600ms ease-in-out 800ms both;
    }

    .customize-main-wrapper {
      & > h3 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 18px;
      }
    }

    .onboarding-customize-tabs {
      display: flex;
      margin-bottom: 12px;
      background: rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      padding: 6px;
      gap: 6px;
      max-width: 380px;
      margin-bottom: 32px;

      .btn {
        flex: 1 1 auto;
        margin: auto;
        background-color: transparent;
        box-shadow: none;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        border-color: transparent;
        &:hover {
          box-shadow: 0px 3px 6px rgb(0 0 0 / 6%);
          background-color: rgba(255, 255, 255, 0.5);
        }

        &.selected {
          background-color: #fff;
          box-shadow: 0px 3px 6px rgb(0 0 0 / 6%);
        }
      }
    }

    .details-customize-inputs {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .customize-input-wrapper {
        position: relative;
        display: flex;
        width: 270px;
        align-items: center;

        & > div:nth-child(2) {
          margin-left: auto;
          width: 180px;
        }
        &.customize-accent-color {
          margin-right: 16px;

          .color-picker-wrapper {
            display: flex !important;
          }
        }

        .select-icon-option {
          display: flex;
          gap: 8px;

          &:hover {
            background: #f3f7fe;

            .icon-wrapper {
              border: 1px solid #1260eb;
            }
          }

          .icon-wrapper {
            display: flex;
            height: 46px;
            width: 46px;
            border-radius: 8px;
            border: 1px solid rgba(0, 0, 0, 0.1);

            & > div {
              margin: auto;
            }
          }
        }

        .selected-icon-option {
          display: flex;
          gap: 8px;
          padding: 6px 12px;
          flex: 1 auto;

          .jimo-select__value-container {
            padding: 0;
          }

          .icon-wrapper {
            & > div {
              box-sizing: border-box;
            }
          }
        }

        .circular-icon {
          box-sizing: border-box;
          height: 12px;
          width: 24px;
          border-radius: 16px;
          border: 3px solid #000;
          border-top: none;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        .line-icon {
          height: 3px;
          width: 24px;
          background: #000;
        }

        .gradient-icon {
          height: 24px;
          width: 24px;
          border-radius: 8px;
        }

        .solid-icon {
          height: 24px;
          width: 24px;
          border-radius: 8px;
        }
      }
      .customize-input-label {
        color: rgba(0, 0, 0);
      }

      .input-group {
        box-sizing: border-box;

        &.labeled .label.label-left {
          border-left: 1px solid #e5e5e5;
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
          padding: 12px 16px;
          & {
            border-color: #e5e5e5;
          }
        }
        &.labeled.labeled-left:not(.labeled-right) .input {
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
        }
        &.labeled.labeled-left.labeled-right {
          .label-left {
            font-size: 12px;
            border-color: #e5e5e5;
          }
          .label-right {
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
            border-color: #e5e5e5;
            color: rgba(0, 0, 0, 0.2);
            padding-top: 12.5px;
            padding-bottom: 12.5px;
            font-size: 14px;
            padding-left: 0;
          }
          .input {
            border-color: #e5e5e5;
            border-right-color: transparent;
          }
        }
        input {
          padding: 10px 14px;
        }
      }

      .input-group.input-with-blue-label {
        margin-right: 12px;

        .label-left {
          background-color: #e7effd;
          display: flex;
          align-items: center;
          color: #1260eb;
        }
        .label-right {
          padding-left: 0;
        }
      }

      .input-color {
        &.input-group.labeled .label {
          padding-right: 6px;
          display: flex;
          align-items: center;
        }
        input {
          padding-left: 6px;
          border-left: none;
          border-color: rgba(0, 0, 0, 0.1);
        }
      }
      .preview-color {
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 4px;
        border: 1px solid #e5e5e5;
      }

      .jimo-select {
        .jimo-select__value-container {
          padding: 8px 12px;
        }
        .jimo-select__control {
          border-radius: 16px;
        }
        .jimo-select__control--is-focused {
          box-shadow: inset 0 0 0 2px #e9effd;
          border-color: #88abff;
        }
      }
    }
  }

  .details-customize-preview-wrapper {
    position: relative;
    background-color: #f9f9f9;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    animation: details-customize-preview-wrapper-in 600ms ease-in-out 100ms both;
    &.is-in-app-preview {
      background: url(./imgs/bg-preview.jpg);
      background-size: cover;

      .onboarding-preview-dot {
        display: block !important;
      }
      .customize-preview-container {
        height: 100%;
      }
      .in-app-preview-wrapper {
        height: 100%;
        display: flex;
        background: transparent;
        flex-direction: column;

        .theme-preview {
          height: 100%;
          display: flex;
          background: transparent;
          box-shadow: none;
        }
        .radio-group {
          position: absolute;
          z-index: 2;
          background-color: rgba(0, 0, 0, 0.1);
          top: 24px;
          left: 50%;
          transform: translateX(-50%);

          .option:not(.selected):hover {
            background-color: rgba(255, 255, 255, 0.5);
          }
          .divider.is-dark {
            background-color: rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
    &.is-portal-preview {
      display: flex;

      .preview-portal {
        display: block !important;
      }
      .customize-preview-container {
        margin: auto;
        overflow: hidden;
        height: 60%;
        background-color: transparent;
        border-radius: 12px;
        width: 100%;
      }
    }
    &.is-widget-preview {
      display: flex;
      height: 100%;

      .preview-widget {
        display: block !important;
      }

      .customize-preview-container {
        margin: auto;
        overflow: hidden;
        height: 80%;
        background-color: transparent;
        border-radius: 12px;
        width: 400px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        box-shadow: 0px 0px 9.3973px rgba(0, 0, 0, 0.15);

        .preview-widget {
          height: 100%;
          iframe {
            width: 100%;
          }
        }
      }
    }

    .customize-preview-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    .in-app-preview-wrapper {
      display: none;
    }
    .onboarding-preview-dot {
      display: none;
    }
    .preview-widget {
      display: none;
    }
    .preview-portal {
      height: 100%;
      display: none;

      &.is-portal {
        .iframe-wrapper {
          width: 1350px;
          height: 163%;
          box-shadow: 0px 0px 9.3973px rgba(0, 0, 0, 0.15);
          border-radius: 14px;
          overflow: hidden;
          position: relative;
          top: 12px;
          left: 50%;
          transform: scale(0.6) translateX(-50%);
          transform-origin: top left;
          margin-left: auto;
          margin-right: auto;
        }
      }
      &.is-mobile {
        .iframe-wrapper {
          width: 375px;
          height: 180%;
          background: #1d1f2d;
          box-shadow: 0px 0px 9.3973px rgba(0, 0, 0, 0.15);
          border-radius: 12px;
          overflow: hidden;
          position: relative;
          top: 12px;
          left: 50%;
          transform: scale(0.52) translateX(-50%);
          transform-origin: top left;
        }
      }

      .iframe-wrapper {
        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .customize-actions {
    display: flex;
    animation: onboarding-content-slide-right 600ms ease-in-out 1000ms both;
    align-self: flex-start;

    .btn-continue {
      margin-left: 16px;
    }
  }
}

@-webkit-keyframes details-customize-preview-wrapper-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes details-customize-preview-wrapper-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes details-customize-preview-wrapper-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes details-customize-preview-wrapper-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
