.users-pagination {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;

  .btn {
    padding: 16px;
    justify-content: center;
    font-size: 12px;

    &:first-child {
      margin-right: 12px;
    }
    &:last-child {
      margin-left: 12px;
    }
  }
}
