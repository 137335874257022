._dev-card {
  padding-top: 25px;
  padding-left: 25px;
  .wrapper {
    width: 500px;

    .card {
      margin-bottom: 20px;
    }
  }
}
