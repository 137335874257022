.discovery-steps-manager {
  margin: auto;
  bottom: 0;
  background: #ffffff;
  display: flex;
  border-radius: 16px;
  min-width: 0;

  .step-block-list {
    flex: 0 0 auto;
    display: flex;
    margin: 4px 16px;
    background: rgba(206, 206, 206, 0.2);
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    padding: 4px;
    position: relative;

    & > div {
      display: flex;
    }
    .droppable-block-list {
      display: flex;

      .block-wrapper {
        display: flex;
        gap: 8px;
        margin-right: 8px;
        &.is-step-concept {
          grid-gap: 0;
          gap: 0;
          &.is-sub-concept-step-selected {
            .block-selector {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              border: 2px solid rgba(18, 96, 235, 0.2) !important;
            }
            .sub-blocks-wrapper {
              border: 2px solid rgb(18, 98, 235, 0.2);
              border-left: 0px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
          &.is-parent-concept-step-selected {
            .block-selector {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
            .sub-blocks-wrapper {
              border: 2px solid rgba(18, 98, 235, 0.2);
              border-left: 0px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }

        .block-selector {
          position: relative;
          cursor: pointer;
          display: flex;
          padding: 8px;
          border-radius: 12px;
          background: #ffffff;
          border: 2px solid rgba(0, 0, 0, 0.1);
          height: 44px;
          width: 44px;
          box-sizing: border-box;

          &.removed {
            & > *:not(.delete-btn, .step-preview-wrapper, .unhide-btn) {
              opacity: 0.3;
            }
          }
          &.selected {
            background: #e6f5fd;
            border: 2px solid rgba(18, 96, 235, 1) !important;
          }
          &.invalid {
            border-style: dashed !important;
            border-color: #ffa5a5 !important;
          }
          &.is-dragging {
            transform: rotate(3deg);
          }
          .block-type-icon {
            font-size: 24px;
            display: flex;

            i {
              margin: auto;
            }
          }

          .delete-btn,
          .unhide-btn {
            z-index: 1;
            display: none;
            position: absolute;
            top: -10px;
            right: -10px;
            padding: 6px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
            border-radius: 8px;

            i {
              font-size: 12px;
              margin-right: 0 !important;
            }
          }

          .step-preview-wrapper {
            display: none;
          }

          &:hover {
            .delete-btn,
            .unhide-btn {
              display: flex;
            }

            .step-preview-wrapper {
              display: flex;
            }
          }
        }

        .sub-blocks-wrapper {
          display: flex;
          border-radius: 16px;
          padding-right: 6px;
          align-items: center;
          &.is-sub-concept-step-selected {
            border: 2px solid rgba(18, 96, 235, 1);
            background-color: blue;
          }
          &.is-parent-concept-step-selected {
            border: 2px solid rgba(18, 96, 235, 1);
            background-color: red;
          }

          .sub-block-selector-wrapper {
            margin-left: 8px;
            height: 32px;
            width: 32px;

            .sub-block-selector {
              position: relative;
              cursor: pointer;
              display: flex;
              border-radius: 12px;
              background: #ffffff;
              border: 2px solid rgba(0, 0, 0, 0.1);
              height: 32px;
              width: 32px;
              box-sizing: border-box;

              &.selected {
                background: #e6f5fd;
                border: 2px solid rgba(18, 96, 235, 1) !important;
              }
              &.invalid {
                border-style: dashed !important;
                border-color: #ffa5a5 !important;
              }
              &.is-dragging {
                transform: rotate(3deg);
              }
              .block-type-icon {
                font-size: 18px;
                display: flex;
                margin: auto;

                i {
                  margin: auto;
                }
              }

              .delete-btn {
                z-index: 1;
                display: none;
                position: absolute;
                top: -12px;
                right: -12px;
                padding: 6px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
                border-radius: 8px;

                i {
                  font-size: 12px;
                  margin-right: 0 !important;
                }
              }

              &:hover {
                .delete-btn {
                  display: flex;
                }
              }
            }
          }

          .sub-list-dropdown-trigger {
            margin: auto;
            cursor: pointer;

            .btn-add-sub-block {
              margin-left: 8px;
              cursor: pointer;
              background: rgba(18, 96, 235, 0.1);
              border-radius: 12px;
              display: flex;
              height: 32px;
              width: 32px;
              box-sizing: border-box;

              i {
                margin: auto;
                font-size: 13px;
                color: #1260eb;
              }
            }
          }
        }
      }
    }

    .icon-wrapper {
      cursor: pointer;
      padding: 16px;
      background: rgba(18, 96, 235, 0.1);
      border-radius: 12px;
      display: flex;

      i {
        margin: auto;
        font-size: 13px;
        color: #1260eb;
      }
    }

    .list-dropdown-trigger {
      height: 44px;
    }
  }
}

.dropdown-add-step-content {
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.06);
  border-radius: 12px;

  .dropdown-content {
    .steps-menu {
      .menu-title {
        padding: 16px;
        padding-bottom: 0;
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 18px;
      }

      .poke-steps {
        padding: 16px;
      }
      .navigation-step-wrapper {
        padding: 16px;
        background: #f5f5f5;
      }
      .steps-menu-item {
        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        margin-bottom: 16px;
        background: #ffffff;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }

        &:hover {
          background: rgba(18, 96, 235, 0.08);
          border: 1px solid rgba(18, 96, 235, 0.5);
          box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.06);

          .content {
            .title {
              color: #1260eb;
            }
          }
        }

        .icon-wrapper {
          margin-right: 20px;

          i {
            font-size: 32px;
            margin: 0;
          }
        }

        .content {
          .title {
            font-weight: 500;
            font-size: 18px;
          }

          .description {
            font-weight: 500;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }
}

.delete-step-modal {
  padding: 0;
  width: unset !important;

  .modal-title {
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 24px;
  }

  .content {
    padding: 24px;
    font-weight: 400;
    font-size: 18px;
  }

  .actions {
    margin-top: 0;
    gap: 8px;
    justify-content: flex-end;
  }
}
