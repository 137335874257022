.s-settings-subscription {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 38px;
  box-sizing: border-box;

  .tabs {
    flex: 1 1 auto;
    min-height: 0px;
    display: flex;
    flex-direction: column;

    .tab-list {
      padding-left: 40px;
      margin-bottom: 0;
    }

    .tab-content {
      flex: 1 1 auto;
      min-height: 0px;
      display: flex;
    }
  }
}
