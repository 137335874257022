._dev-tip {
  padding-top: 25px;
  .wrapper {
    width: 60%;
    margin: 0 auto;

    .tip {
      margin-bottom: 20px;
    }
    .tips-vert-wrapper {
      display: flex;
      .tip {
        margin-right: 20px;
      }
      #tipv_with_image {
        background-image: url(./imgs/tip-img1.png);
        background-size: 60%;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: bottom;
      }
    }
  }
}
