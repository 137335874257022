.segment-attributes-editor-legacy {
  width: 100%;

  &.is-empty {
    &.global-add-attribute {
      margin-left: 24px;
      margin-top: 0;
      margin-bottom: 24px;
      max-width: 250px;
    }
  }
  &.is-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
  }

  .list-attributes {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &.is-empty {
      margin-top: 0;
    }

    .segment-attribute {
      min-width: 480px;
      display: flex;
      flex-direction: column;
      font-size: 18px;
      flex-wrap: wrap;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
      border-radius: 12px;
      padding: 16px;

      .header-row {
        display: flex;
        margin-bottom: 8px;

        .btn-delete {
          margin-left: auto;
          margin-right: 16px;
          padding: 0;
          border: 0;

          i {
            font-size: 12px;
            color: #9598a9;
          }
        }
      }

      .value-row {
        display: flex;
        gap: 12px 16px;
        flex-wrap: wrap;
        min-height: 50px;

        .jimo-select {
          display: flex;
          .jimo-select__control {
            border-radius: 16px;
          }
        }

        .input-value-wrapper {
          display: flex;
        }

        .input-group {
          .input {
            height: 100%;
            min-width: 200px;
            padding: 16px 20px;
            font-size: 18px;
            background: transparent;
            border-radius: 16px;
            border: 1px dashed rgba(0, 0, 0, 0.3);
          }
          .icon-close {
            font-size: 12px;
            cursor: pointer;

            &:hover {
              color: #000;
            }
          }

          &.labeled-right {
            .input {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              border-right: none;
            }

            .label-right {
              border: 1px dashed rgba(0, 0, 0, 0.3);
              border-left: none;
              border-top-right-radius: 16px;
              border-bottom-right-radius: 16px;
            }
          }
        }
        .react-datepicker-wrapper {
          width: unset;
          display: flex;

          .react-datepicker__input-container {
            display: flex;
          }
        }

        .btn-or {
          margin: auto 0;
          padding: 8px 12px;
          font-size: 12px;
          font-family: 'Montserrat';
          font-weight: 600;
          font-size: 18px;
        }
      }
    }

    .divider {
      margin-bottom: 8px;
    }
  }
  .select-add-attribute {
    font-size: 18px;

    &.global-add-attribute {
      margin-left: 24px;
      margin-top: 12px;
      margin-bottom: 24px;
      max-width: 250px;
    }
  }
  .select-add-attribute,
  .select-operand {
    .jimo-select__option {
      min-height: 24px;
    }
    .jimo-select .jimo-select__value-container {
      padding: 16px;
    }
  }
  .btns-wrapper {
    display: flex;
    margin: 16px 0;

    .btn-save {
      margin-right: 16px;
    }

    &.no-btns {
      margin: 0;
    }
  }

  .segment-filter-type {
    display: flex;
    align-items: center;

    .icon-wrapper {
      height: 40px;
      width: 40px;
      border-radius: 8px;
      display: flex;
      margin-right: 8px;

      i {
        font-size: 24px;
        margin: auto;
      }
    }
  }

  .dropdown-trigger {
    display: flex;

    .btn-add-filter {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 18px;

      i {
        font-size: 24px !important;
      }
    }
  }
}

.dropdown-add-segment-filter-content {
  // min-width: 589px;

  .divider {
    margin-top: 8px;
  }

  .dropdown-content {
    animation: fade-in 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }

  .search-wrapper {
    border-bottom: 1px solid #e5e5e5;

    .input-group.labeled {
      display: flex;
      .label {
        border-radius: 0;
      }

      input {
        font-size: 14px;
        border-radius: 0;
        padding-left: 0 !important;
      }
    }
  }
  .block-categories {
    padding-bottom: 8px;

    .category-title {
      padding: 20px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.6);
    }
    .items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      padding: 0px 8px;

      @media (max-width: 1500px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      .block {
        padding: 12px 14px;
        &.is-disabled {
          opacity: 0.2;
          cursor: not-allowed;
        }

        .icon-wrapper {
          height: 24px;
          width: 24px;
          min-width: 24px;
          border-radius: 8px;

          i {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.dropdown-custom-attribute-values-content,
.dropdown-segmentio-trait-values-content {
  z-index: 99999 !important;
  border-radius: 12px;
  max-height: 300px;
  overflow-y: hidden;

  .dropdown-content {
    background: #f2f2f2 !important;
    max-height: 300px;
    overflow-y: hidden;

    .suggestions-scrollable {
      overflow-y: auto;
      max-height: 300px;

      .suggestion {
        cursor: pointer;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.7);
        padding: 16px;
      }
    }
  }
}

.dropdown-add-segment-filter-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1002 !important;
}
