.post-preview {
  width: 439px;
  background: #f0f2f4;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 16px;
  padding-bottom: 0;
  box-sizing: border-box;

  .post-preview-title {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
  }

  .changelog-wrapper {
    display: flex;
    flex: 1 auto;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    overflow: hidden;

    .changelog-iframe {
      height: 100%;
      width: 100%;
      border: 0;
    }
  }
}
