.audience-section.where {
  .audience-section-content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .divider {
      margin: 8px 0;
    }

    .target-element-label {
      font-weight: 500;
      margin-bottom: 12px;
    }

    .sub-section-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .sub-section-title {
        font-weight: 500;
      }
    }

    .device-selection-wrapper,
    .url-selection-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: 100%;
      gap: 24px;

      .builder-channel-interactive-option {
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        box-sizing: border-box;
        display: flex;
        cursor: pointer;

        .option-main-wrapper {
          flex: 1 auto;
        }

        .icon {
          font-size: 20px;
          color: rgba(0, 0, 0);
        }

        .title-description-wrapper {
          display: flex;
        }

        &.is-active {
          background: rgba(18, 96, 235, 0.08);
        }
      }
    }

    .boosted-delay-wrapper {
      margin-top: 12px;

      .input-group {
        input {
          max-width: 100px;
        }
      }
    }

    .active-url-input {
      .input {
        padding-right: 160px;
        font-size: 14px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 12px;
      }
    }
  }
}

.tooltip-warning-device-content {
  background-color: #ffffff;
  padding: 12px;
  color: #000000;
  box-sizing: border-box;
  border-radius: 12px;
  font-size: 14px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
}
