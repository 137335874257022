.live-edit-warning-modal {
  width: 480px;

  .content {
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    gap: 16px;

    .list-elem {
      display: flex;
      gap: 12px;

      .list-elem-icon {
        display: flex;
        align-items: center;
        font-size: 20px;

        &.safe {
          color: var(--g-400);
        }

        &.warning {
          color: var(--o-500);
        }
      }

      .list-elem-content {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }
  }

  .modal-footer {
    border-top: 0;

    .btn {
      width: 100%;
      justify-content: center;
    }
  }
}
