.poke-block-animation {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;

  &.is-window-size {
    position: fixed;
    z-index: 2147483647;
  }
}
