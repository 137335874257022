.loader {
  display: block;
  height: 50px;
  width: 50px;
  border: 2px rgba(0, 0, 0, 0.7) solid;
  border-top: 2px #d0d0d0 solid;
  border-radius: 50%;
  -webkit-animation: loader-spin 650ms infinite linear;
  animation: loader-spin 650ms infinite linear;
  &.light {
    border: 2px rgba(156, 156, 156, 0.1) solid;
    border-top: 2px #ffffff solid;
  }
}

@-webkit-keyframes loader-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes loader-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.jimo-loader-wrapper {
  width: 40px;
  height: 40px;
  &.is-tiny {
    width: 15px;
    height: 15px;
  }
  &.is-medium {
    width: 100px;
    height: 100px;
  }
  &.is-large {
    width: 180px;
    height: 180px;
  }
  & > div {
    height: auto;
  }
}
