.plate-image-element {
  .image-wrapper {
    margin: 0;

    img {
      width: 100%;
      height: auto;
      padding: 0;
    }
  }
}
