.section-item.offset-item {
  .section-item-content {
    display: flex;
    gap: 8px;

    .input-group {
      flex-grow: 1;
      min-width: 0;

      .label-left {
        .icon-wrapper {
          i {
            font-size: 16px;
            color: var(--n-600);
          }
        }
      }

      .input {
        padding: 4px !important;
      }
    }
  }
}
