.radio-group {
  background: rgba(138, 138, 138, 0.1);
  border-radius: 12px;
  display: flex;
  gap: 6px;
  padding: 4px;
  flex: 1 auto;

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;

    .option {
      cursor: not-allowed;
    }
  }

  .option {
    display: flex;
    flex: 1 1 0px;
    padding: 6px 8px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    color: var(--n-700);
    &.selected {
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
      color: var(--n-800);
    }
    &:hover {
      color: var(--n-800);
    }

    & > * {
      margin: auto;
    }
  }

  .divider {
    height: 12px;
    margin: auto;
  }
}
