@import '../../Variables';

.poke-block-nps {
  &.is-animating-out {
    .btn-nps {
      animation: block-nps-out $animationBlockOutDuration ease-in-out both;
      animation-delay: 0ms !important;
    }
  }

  .nps-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .labels-wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    animation: block-nps-in $animationBlockInDuration ease-in-out
      $animationBlockInDuration both;
    &.is-animating-out {
      animation: block-nps-out $animationBlockOutDuration ease-in-out both;
    }
  }

  .btn-nps {
    flex: 0 0 30px;
    width: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    background-color: #fff;
    cursor: pointer;
    font-weight: 700;
    font-family: 'Inter';
    font-size: 20px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease-in-out;
    animation: block-nps-in $animationBlockInDuration ease-in-out both;
    animation-delay: $animationBlockInDelay;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: rgb(248, 248, 248);
    }
    &:active {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0);
    }
    @for $i from 2 through 11 {
      &:nth-child(#{$i}n) {
        animation-delay: #{($i - 1) * 100 + 700}ms;
      }
    }
  }

  .btn-nps-0 {
    &.active {
      background-color: var(--NPS-0);
    }
  }
  .btn-nps-1 {
    &.active {
      background-color: var(--NPS-1);
    }
  }
  .btn-nps-2 {
    &.active {
      background-color: var(--NPS-2);
    }
  }
  .btn-nps-3 {
    &.active {
      background-color: var(--NPS-3);
    }
  }
  .btn-nps-4 {
    &.active {
      background-color: var(--NPS-4);
    }
  }
  .btn-nps-5 {
    &.active {
      background-color: var(--NPS-5);
    }
  }
  .btn-nps-6 {
    &.active {
      background-color: var(--NPS-6);
    }
  }
  .btn-nps-7 {
    &.active {
      background-color: var(--NPS-7);
    }
  }
  .btn-nps-8 {
    &.active {
      background-color: var(--NPS-8);
    }
  }
  .btn-nps-9 {
    &.active {
      background-color: var(--NPS-9);
    }
  }
  .btn-nps-10 {
    &.active {
      background-color: var(--NPS-10);
    }
  }
}

@-webkit-keyframes block-nps-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes block-nps-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes block-nps-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes block-nps-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
