.modal-create-poke {
  max-height: 80%;
  overflow: hidden;
  width: unset;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;

  &.preview {
    max-height: unset;
    height: 80%;
    width: 70%;

    @media (max-height: 900px) {
      height: 95%;
    }
    @media (max-width: 1200px) {
      width: 90%;
    }
  }

  &.no-max-height {
    max-height: unset;
  }

  &.mode-pick-template {
    max-width: 1082px;
    max-height: 744px;
    width: 80%;
    height: 80%;

    .modal-title {
      display: none;
    }
  }

  &.mode-preview {
    max-width: 1082px;
    max-height: 744px;
    width: 80%;
    height: 80%;

    .modal-title {
      display: none;
    }
  }

  .modal-title {
    & > div {
      margin: auto 0;
    }

    .back-btn {
      background-color: #f4f4f4;
      height: 40px;
      width: 40px;
      border-radius: 12px;
      padding: 0;
      margin-right: 16px;

      &:hover {
        background-color: #dddddd;
      }

      i {
        margin: auto !important;
        color: #000000;
      }
    }

    .text {
      display: flex;
      align-items: center;

      i {
        margin-right: 16px;
      }
    }
  }

  .content-wrapper {
    flex: 1 1 auto;
    min-height: 0px;
    overflow-y: auto;
    overflow-x: hidden;

    &.scrollable {
      overflow: auto;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      padding: 24px;

      .tabs .tab-list {
        margin-top: 0;
        margin-bottom: 24px;

        .tab-list-item {
          padding: 0 4px;
          padding-top: 0;
          padding-bottom: 24px;
          font-weight: 400;
          &:not(.tab-list-active) {
            color: #000;
            &:hover {
              color: #1260eb;
            }
          }
        }
      }

      .section-title {
        margin-bottom: 24px;
        font-size: 18px;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 18px;
      }

      .inputs-wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .input-group {
          width: 480px;
        }

        .input-group-wrapper {
          display: flex;
          flex-direction: column;
          flex: 1;
          gap: 2px;

          .input-group {
            &.is-invalid {
              .input-main-wrapper {
                border-color: var(--r-400);

                i {
                  color: var(--r-400);
                  font-size: 16px;
                }
              }
            }
          }
        }
      }

      .in-app-toggle-wrapper {
        box-sizing: border-box;
        width: 480px;
        display: flex;
        padding: 16px;
        align-self: stretch;
        align-items: center;
        gap: 24px;
        border-radius: 16px;
        border: 1px solid var(--n-300, #e5e5e5);
        background: var(--white, #fff);

        .content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          flex: 1 0 0;
        }
      }

      .extension-alert,
      .attributes-alert {
        width: 480px;
        border-radius: 12px;
      }
    }
  }

  .actions {
    margin: 0 24px 24px 24px;
    justify-content: flex-end;

    .btn {
      margin-left: 8px;
    }

    .back-btn {
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

.dropdown-poke-modal-icon-content {
  margin-top: 8px;
  z-index: 99999 !important;

  .icons-wrapper {
    .header {
      padding: 10px;
      display: flex;

      .title {
        font-weight: 600;
      }
    }

    .icons-list {
      padding: 0 4px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      .icon-option-wrapper {
        .icon-wrapper {
          margin: 12px 8px;
          height: 40px;
          width: 40px;
          display: flex;
          border-radius: 12px;
          flex: 0 0 auto;
          border: 2px solid rgba(0, 0, 0, 0);
          cursor: pointer;

          &.scratch {
            background-color: #fff;
          }
          &.discovery {
            background-color: #e6f5fd;
          }
          &.adoption {
            background-color: #e6fde8;
          }

          i {
            margin: auto;
            font-size: 20px;
          }
        }

        &.selected {
          .icon-wrapper {
            border: 2px solid #1260eb;
          }
        }
      }
    }
  }
}

.dropdown-build-url-suggestions-content {
  z-index: 99999 !important;
  border-radius: 12px;
  width: 480px;
  max-height: 300px;
  display: flex;

  .dropdown-content {
    background: #fff !important;
    flex: 1 auto;
    overflow-y: auto;
    padding: 8px;
    margin-top: 6px;
    border-radius: 12px;

    .url-suggestion {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.7);
      padding: 16px;
      border-radius: 12px;
      transition: all 100ms ease-in-out;
      overflow: hidden;
      &:hover {
        background-color: #e3ecff;

        .btn-delete-url {
          opacity: 1;
        }
      }

      .btn-delete-url {
        position: absolute;
        right: 8px;
        top: 8px;
        transition: all 100ms ease-in-out;
        opacity: 0;
        padding: 8px 10px;
        border-radius: 6px;
        background-color: #fff;
        border: 1px solid #e5e5e5;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          background-color: rgb(255, 237, 237);
        }

        i {
          color: #ff3737;
        }
      }
    }
  }
}

.in-app-toggle-tooltip-content {
  background-color: #ffffff;
  padding: 12px;
  color: #000000;
  box-sizing: border-box;
  border-radius: 12px;
  font-size: 14px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  max-width: 600px;
  z-index: 1001 !important;
}
