._dev-icons {
  padding-top: 20px;
  i {
    display: inline-block;
    margin: 5px;
    padding: 15px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.05);
  }
}
