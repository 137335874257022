.segment-details {
  display: flex;
  max-width: 1042px;
  padding: 24px;
  margin: 0 auto 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 24px 94px 0px rgba(0, 0, 0, 0.07);

  .segment-details-title {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
  }

  .input-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .input-label {
      color: #000;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
    }

    .dropdown-trigger {
      cursor: pointer;
      margin: auto 0;

      .icon-wrapper {
        height: 40px;
        width: 40px;
        display: flex;
        border-radius: 12px;
        flex: 0 0 auto;
        border: 1px solid rgba(0, 0, 0, 0.1);
        align-items: center;
        justify-content: center;
        font-size: 24px;
      }
    }

    .input-group {
      flex: 1;

      .label-left {
        border: 0;
        padding: 0;
        margin: 6px;
      }

      .input {
        padding: 12px 8px;
        border: 0;
        font-size: 16px;
        font-weight: 500;
        font-family: Inter;
      }
    }

    .input-description {
      textarea {
        resize: none;
        padding: 12px;
        border-radius: 12px;
        font-size: 16px;
        font-weight: 500;
        font-family: Inter;
      }
    }
  }
}

.dropdown-segment-icon-content {
  margin-top: 8px;

  .icons-wrapper {
    .header {
      padding: 10px;
      display: flex;

      .title {
        font-weight: 600;
      }
    }

    .icons-list {
      padding: 0 4px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      .icon-option-wrapper {
        .icon-wrapper {
          margin: 12px 8px;
          height: 40px;
          width: 40px;
          display: flex;
          border-radius: 12px;
          flex: 0 0 auto;
          border: 2px solid rgba(0, 0, 0, 0);
          cursor: pointer;

          &.default {
            background-color: #fff;
          }
          &.evaluators {
            background-color: #f0f2f4;
            color: #7c8a99;
          }
          &.beginners {
            background-color: #e6f5fd;
            color: #2978a2;
          }
          &.regular {
            background-color: #e6fde8;
            color: #12be21;
          }
          &.champions {
            background-color: #fdefe6;
            color: #ffa15e;
          }

          i {
            margin: auto;
            font-size: 20px;
          }
        }

        &.selected {
          .icon-wrapper {
            border: 2px solid #1260eb;
          }
        }
      }
    }
  }
}

.segment-loading {
  max-width: 1090px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  .icon-wrapper {
    margin-right: 16px;
    padding-right: 0;
    transform: rotate(180deg);
    animation: icon-refresh-animation 10s infinite linear;
  }
}

@keyframes icon-refresh-animation {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
