.modal-environment {
  max-height: 80%;
  padding: 0;
  width: 480px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;

  .modal-environment-header {
    display: flex;
    align-items: center;
    gap: 16px;

    .icon-wrapper {
      display: flex;
      height: 48px;
      width: 48px;
      border-radius: 12px;
      align-items: center;
      justify-content: center;
      font-size: 24px;
    }

    .actions-wrapper {
      margin-left: auto;
    }
  }

  .modal-environment-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-height: 0;

    .section {
      display: flex;
      flex-direction: column;
      gap: 4px;
      min-height: 0;

      .domains-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        min-height: 0;
        overflow-y: auto;

        .domain {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px 12px;
          border-radius: 12px;
          border: 1px solid var(--n-300);

          i {
            font-size: 18px;
          }
        }
      }
    }
  }
}
