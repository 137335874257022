.s-changelog-post {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  background: #f7f9fe;

  &.is-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  &.is-empty {
    display: flex;
    align-items: center;
    justify-content: center;

    .main {
      flex: 0 0 235px;
      display: flex;
      flex-flow: column;
      align-items: center;
      text-align: center;
      font-size: 24px;
      font-weight: 500;
    }
  }

  .poke-header {
    display: flex;
    align-items: center;
    position: relative;
    flex: 0 1 auto;
    padding: 20px 24px;
    transition: all 0.2s ease-in-out;
    border-bottom: 1px solid rgba(0, 0, 0, 0);

    &.is-scrolled {
      background-color: var(--white);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
    }

    .infos {
      flex: 1 1 auto;
      font-size: 20px;
      font-weight: 500;
      display: flex;
      align-items: center;

      .push-title-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .push-title {
          font-size: 20px;
        }
      }
    }

    .actions {
      display: flex;
      gap: 16px;

      .btn {
        margin: 0px;
      }
      .delete-btn {
        display: flex;

        i {
          font-size: 24px;
          color: var(--r-400);
        }
      }
    }
  }

  .changelog-content-wrapper {
    overflow-y: auto;
    display: flex;
    flex: 1 auto;
    height: calc(100% - 144px);
    width: 100%;

    .loader-wrapper {
      margin: auto;
    }

    .changelog-content {
      display: flex;
      flex-flow: column;
      gap: 56px;
      padding: 72px;
      padding-top: 36px;
      overflow-y: auto;
      overflow-x: hidden;
      flex: 1 auto;
      max-width: 1042px;
      margin: auto;
      margin-top: 0;

      .section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        .section-title {
          display: flex;
          align-self: stretch;
          align-items: center;
          color: var(--neutral-800-black, var(--neutral-neutral-800, #071331));
          font-family: Montserrat;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 125%; /* 30px */

          .dropdown-trigger {
            margin-left: auto;
          }
        }

        .tabs-wrapper {
          display: flex;
          gap: 20px;
          align-self: stretch;
        }

        .section-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 32px;
          align-self: stretch;

          & > div {
            display: flex;
            gap: 20px;
            align-self: stretch;
          }
        }

        .tabs-wrapper {
          .tabs {
            width: 100%;

            .tab-list-item {
              .issues-count {
                display: flex;
                height: 25px;
                padding: 6px 8px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                box-sizing: border-box;
                align-self: center;
                border-radius: 27px;
                background: var(--surface-accent-primary-negative, #ff4646);

                .count {
                  color: var(--neutral-white, var(--neutral-neutral-0, #fff));
                  font-family: Inter;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 150%; /* 24px */
                }
              }
            }
          }
        }
      }
    }
  }
}
