@mixin bar-animation-width($time) {
  visibility: hidden;
  width: 0;
  -webkit-animation: animate-width;
  -moz-animation: animate-width;
  animation: animate-width;
  animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  -webkit-animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  -moz-animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  animation-duration: $time * 2;
  -webkit-animation-duration: $time * 2;
  -moz-animation-duration: $time * 2;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

@-webkit-keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    visibility: visible;
  }
}
@-moz-keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    visibility: visible;
  }
}
@keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    visibility: visible;
  }
}

.trackers {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  grid-gap: 24px;
  padding: 24px;
  overflow-y: auto;
  height: calc(100% - 48px);
  align-content: flex-start;

  .tracker {
    height: 340px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 24px 94px 0px rgba(0, 0, 0, 0.07);
    cursor: pointer;
    transition: all 0.2s ease;
    border: 1px solid transparent;
    position: relative;

    &:hover {
      border: 1px solid var(--b-400);
      box-shadow: 0px 0px 0px 8px #1260eb28;

      .open-label {
        opacity: 1;
      }

      .info {
        .tracker-type {
          color: var(--border-active, #1260eb);
        }
      }
    }

    .open-label {
      position: absolute;
      right: 16px;
      top: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      color: var(--content-accent-brand, #1260eb);
      background: var(--b-100);
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 125%; /* 20px */
      border-radius: 12px;
      padding: 8px 12px;
    }

    .tracker-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      width: 100%;

      .tracker-name {
        color: #000;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; /* 25px */
        transition: all 0.2s ease-in-out;
      }

      .tracker-metric {
        display: flex;
        gap: 8px;
        color: #000;
        font-family: Montserrat;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 48px */
        // letter-spacing: -0.5px;
      }
    }

    .tracker-preview {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      flex: 1 0 0;
      align-self: stretch;

      .tracker-preview-step {
        display: flex;
        gap: 8px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        .tracker-preview-step-name {
          display: flex;
          gap: 8px;
          align-items: center;
          color: #000;
          font-weight: 500;
          line-height: 125%; /* 25px */
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .index-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0);
            font-size: 10px;
            flex-shrink: 0;
          }

          span {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .tracker-preview-step-conversion {
          flex-shrink: 0;
          display: flex;
          width: 50%;
          height: 11px;
          border-radius: 12px;
          background: rgba(0, 0, 0, 0.1);

          .tracker-preview-step-conversion-bar {
            @include bar-animation-width(0.2s);
            animation-delay: 0.2s;
            -webkit-animation-delay: 0.2s;
            opacity: 0.6;
            background: #1260eb;
            border-radius: 12px;
          }
        }
      }
    }
  }
}
