.modal-add-edit-request {
  .alert {
    margin-bottom: 36px;
  }
  input {
    margin-bottom: 16px;
  }
  .text-area textarea {
    min-height: 150px;
  }
}
