.users-list-segments {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  overflow-y: auto;
  background-color: var(--b-100);

  &.is-empty {
    width: 100%;
    margin: 0;

    .empty-state-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto;
    }
  }

  .loader {
    margin: auto;
  }

  .segments-table {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: white;

    .top {
      flex: 0 1 auto;
      display: grid;
      grid-template-columns: 10% 30% 10% 20% 20% 10%;
      padding: 16px 28px 12px 28px;
      box-sizing: border-box;
    }

    .loader-wrapper {
      display: flex;
      justify-content: center;
      padding-top: 24px;
    }
    .list {
      padding: 16px;
      flex: 1 0 auto;
      overflow: auto;
      flex: 1 1 100%;
      box-sizing: border-box;

      .card-segment {
        border-radius: 12px;
        padding: 12px;
        display: grid;
        grid-template-columns: 10% 30% 10% 20% 20% 10%;
        align-items: center;
        transition: all 300ms ease-in-out 0s;
        cursor: pointer;

        &:hover {
          background-color: #f3f7fe;

          .segment-actions {
            opacity: 1;
          }
        }

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        .segment-icon-wrapper {
          .segment-icon {
            height: 40px;
            width: 40px;
            display: flex;
            border-radius: 12px;
            margin-right: 8px;
            flex: 0 0 auto;
            border: 1px solid rgba(0, 0, 0, 0.1);
            align-items: center;
            justify-content: center;
            font-size: 24px;
          }
        }

        .segment-avatar-wrapper {
          .segment-avatar {
            flex: 0 0 40px;
            height: 40px;
            width: 40px;
            line-height: 40px;
            font-size: 18px;
            margin-right: 16px;
            background-color: #fdefe6;
            color: #000;
            font-weight: 400;
          }
        }

        .segment-name {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;

          .name {
            font-weight: 500;
            font-size: 16px;
          }

          .description {
            color: rgba(0, 0, 0, 0.5);
          }
        }

        .segment-populating {
          display: inline-flex;
          align-items: center;
          gap: 8px;
          background-color: var(--b-200);
          padding: 4px 12px;
          border-radius: 16px;
          color: var(--b-500);

          .loader {
            margin: unset;
          }
        }
        .segment-created,
        .segment-last-edited {
          display: flex;
          flex-direction: column;
          gap: 2px;
        }
        .segment-actions {
          opacity: 0;
          display: flex;
          gap: 8px;

          .btn {
            padding: 8px;
          }
        }
        .user-activity {
          display: flex;
          flex-direction: column;
        }
        .user-btn-profile {
          display: flex;
          justify-content: center;
        }
        .icon-close {
          opacity: 0.1;
        }
      }
    }
  }
}
