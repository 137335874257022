.changelog-navigator-wrapper {
  flex-grow: 1;
  padding: 12px;
  background: #fff;

  .navigator {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .section-title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }

    .blocks-wrapper {
      &.empty,
      &.in-app:not(.expanded) {
        .blocks-title {
          border-radius: 12px;
        }
      }

      &.expanded {
        .icon-chevron-right {
          transform: rotate(90deg) !important;
        }
      }

      .blocks-title {
        cursor: pointer;
        display: flex;
        background: #f0f5fd;
        padding: 0;
        border-radius: 12px 12px 0px 0px;
        transition: border-radius 0.3s ease-in-out;
        &.disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }

        i {
          font-size: 16px;
          padding: 12px;
          margin: 0;
        }

        .icon-expand-wrapper {
          margin-right: 8px;
          padding: 8px;
          font-size: 12px;
          display: flex;

          i {
            padding: 0;
            margin: auto;
            font-size: 12px;
            display: block;
            transition: 0.3s;
          }
        }

        &.selected {
          background: #1260eb;
          color: #fff;
        }

        .step-name-input {
          padding: 12px 0;
          line-height: 16px;
          margin-right: auto;

          .input {
            color: #fff;
            padding: 0;
            border: 0;
            border-radius: 0;
            font-size: 14px;
          }
        }
        .step-name {
          padding: 12px 0;
          height: 17px;
          flex: 1 auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: auto;

          &.is-empty {
            color: #b3b3b3;
          }
        }
      }

      .blocks {
        border-radius: 0 0 12px 12px;
        overflow: hidden;

        .navigator-item {
          cursor: pointer;
          background: #f0f5fd;
          display: flex;
          padding: 12px 12px 12px 40px;

          .icon-wrapper {
            height: 16px;
            width: 16px;
            margin-right: 12px;
            display: flex;
            background: #e6fde8;
            border-radius: 4px;
            padding: 2px;

            i {
              margin: auto;
              font-size: 16px;
              color: #000;
            }
          }

          &.selected {
            background: #1260eb;
            color: #fff;
          }

          &.invalid {
            .icon-wrapper {
              background: #fdefe6;
            }
          }

          &:nth-last-child(1) {
            border-radius: 0px 0px 12px 12px;
          }

          &:hover {
            .delete-icon-wrapper {
              display: flex;
            }
          }

          .delete-icon-wrapper {
            display: none;
            margin-left: auto;

            i {
              margin: auto;
              font-size: 12px;
            }
          }
        }

        .add-block-wrapper {
          display: flex;
          padding: 12px;
          align-items: center;
          gap: 12px;
          border: 2px solid rgba(18, 96, 235, 0);
          background: #f4f8ff;
          justify-content: center;
          cursor: pointer;

          .add-block-content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
            color: var(--states-active-surface, #1260eb);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }
  }
}
