@import './../../../../Colors';

.draft-request-detail {
  height: 100%;
  border-left: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  flex-direction: column;
  flex: 1;

  .main {
    flex: 1 1 235px;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    font-size: 24px;
    font-weight: 500;

    .loader {
      margin: auto;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: initial !important;
    padding: 24px 32px !important;
    padding-bottom: 0;
    height: unset !important;
    flex: unset !important;

    .infos-row {
      display: flex;
      position: relative;
      flex: 0 1 auto;

      .infos {
        flex: 1 1 auto;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 12px;
        display: flex;
        align-items: center;

        .user-avatar {
          flex: 0 0 40px;
          height: 40px;
          background-color: $colorPrimary;
          text-transform: uppercase;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin-right: 12px;
          font-weight: 300;
        }

        .from {
          font-size: 18px;
          font-weight: 600;
          margin: auto 0;

          .muted {
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
      .dropdown-trigger {
        align-self: flex-end;

        i {
          font-size: 16px;
        }
      }
    }

    .tags-row {
      display: flex;
      flex-wrap: wrap;

      .tag-button-wrapper,
      .btn-add-tag {
        margin-right: 8px;
        margin-bottom: 8px;
      }

      .filter-tag {
        margin: 4px;
        padding: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        color: rgba(0, 0, 0, 0.4);
        font-weight: 600;
        text-transform: uppercase;

        i {
          margin-left: 10px;
          font-size: 12px;
          cursor: pointer;
          transition: all 300ms ease-in-out 0s;

          &:hover {
            color: #000000;
          }
        }
      }
    }
  }
  .tags-wrapper {
    display: flex;
    padding: 12px 24px 0px 48px;

    .btn-tag {
      margin-right: 8px;
    }
  }
  .content-wrapper {
    padding: 24px 32px 32px 32px;

    .title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 16px;
    }
    .content {
      font-size: 16px;
    }
    .time {
      font-weight: 500;
      margin-top: 16px;
      color: rgba(0, 0, 0, 0.4);
    }
    .content__empty {
      display: block;
      text-align: center;
      color: rgba(255, 255, 255, 0.3);
    }
  }

  .divider {
    margin: 0 32px;
    width: calc(100% - 64px);
  }
}
