.block-settings .stepper {
}

.type-icon-select {
  .type-option-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
