.menu {
  a {
    outline: none;
  }

  .menu-item {
    position: relative;
    display: flex;
    align-items: center;
    transition: all ease-in 100ms;
    padding: 18px 24px;
    border-radius: 8px;
    &.is-interactive {
      cursor: pointer;
    }
    &:not(.is-active).is-interactive:active {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
    &.is-interactive:hover,
    &.is-active {
      color: #1260eb;
      background: rgba(18, 96, 235, 0.07);
    }
    &.is-highlight {
      color: #1260eb !important;
    }
    &.has-icon-left {
      i,
      .emoji {
        margin-right: 10px;
      }
    }
    &.has-icon-right {
      justify-content: space-between;
    }

    i.isax-export-3 {
      font-size: 16px;
      margin-left: auto;
    }
    .notification-badge {
      position: absolute;
      right: 24px;
      width: 23px;
      height: 23px;
      background-color: #2a344e;
      border-radius: 50%;
      text-align: center;
      line-height: 23px;
    }
  }
  .menu-section {
    text-transform: uppercase;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
    padding: 18px;
  }
}
