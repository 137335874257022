.poke-layout-middle {
  z-index: 1;
  overflow: visible;
  flex-grow: 1;

  &.has-custom-height {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
