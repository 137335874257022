.audience-section {
  display: flex;
  max-width: 1042px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 24px 94px 0px rgba(0, 0, 0, 0.07);
  margin: 0 auto;
  margin-bottom: 24px;

  &:not(.expanded) {
    cursor: pointer;
  }

  & > div {
    width: 100%;
  }

  .audience-section-header {
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
    margin: 2px;
    height: 34px;

    .icon-wrapper {
      display: flex;
      align-items: center;

      i {
        font-size: 24px;

        &.isax {
          font-size: 28px;
        }
      }
    }

    .audience-section-title {
      color: #000;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .audience-section-expand {
      margin-left: auto !important;
      cursor: pointer;
      padding: 8px;
      margin: -8px;

      i {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .btn {
      margin-left: auto;
    }
  }

  .audience-section-content {
    margin: 2px;
    margin-top: 20px;
  }
}
