.goal-chart-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  height: 100%;
  width: calc(100% - 30px);
  margin: 10px 30px 10px 0;

  &.empty {
    width: 100%;
    margin: 0;

    .empty-state-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto;
    }
  }

  .goal-chart {
    flex: 1 1 auto;
    min-height: 0;

    &.empty {
      display: flex;
    }

    .no-data {
      margin: auto;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
