.edit-segmentation-drawer {
  overflow: hidden;
  margin: 8px;
  right: 0 !important;
  top: 0 !important;
  left: unset !important;
  position: absolute;
  height: calc(100% - 16px);
  width: 756px !important;
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 12px;

  .modal-title {
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 24px;
    font-family: 'Montserrat';
    margin-bottom: 0;
    min-height: 0;
    flex: 0 0 auto;
  }

  .content-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 24px;
    background: #f8f8f8;

    .poke-segment-editing-wrapper {
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 24px;
      box-sizing: border-box;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 24px 94px 0px rgba(0, 0, 0, 0.07);

      .section-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0;

        .section-title {
          margin-bottom: 24px;
          font-size: 18px;
          font-family: 'Montserrat';
          font-weight: 500;
          font-size: 18px;
        }

        .alert {
          margin-bottom: 8px;
          border-radius: 12px;
          font-size: 16px;
        }

        .list-segment {
          margin-bottom: 6px;
          gap: 16px;
          display: flex;
          flex-wrap: wrap;

          .item-segment {
            display: flex;
            align-items: center;

            &.selected {
              background-color: #e7effd;
              border-color: #4782e9;
              color: #1260eb;
            }
          }
        }
        .segment-attributes-editor {
          padding: 0;
        }
        .list-attributes {
          padding: 0;

          .jimo-select .jimo-select__value-container {
            padding: 4px 6px;
          }
          .input {
            padding: 8px 6px !important;
          }
        }
      }

      .selected-users-wrapper {
        display: flex;
        padding: 12px 16px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--states-disabled-border, #e5e5e5);
        height: 48px;
        box-sizing: border-box;

        &.requires-lookup {
          .selected-users {
            filter: blur(4px);
          }

          .selected-users-actions {
            display: flex;
          }
        }

        .selected-users {
          display: flex;
          align-items: center;
          gap: 12px;

          .avatar-wrapper {
            display: flex;
            align-items: flex-start;
            gap: 2px;

            .avatar {
              height: 24px;
              width: 24px;
            }
          }

          .selected-users-count {
            color: var(--border-dark, #071331);
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 125%; /* 20px */
          }
        }

        .selected-users-actions {
          display: none;
          align-items: center;
          gap: 16px;

          .cancel-btn {
            color: var(--neutral-700, var(--neutral-neutral-700, #4d637b));
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 125%; /* 20px */
            cursor: pointer;
            padding: 4px;
          }

          .apply-btn {
            color: var(--content-accent-brand, #1260eb);
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 125%; /* 20px */
            cursor: pointer;
            padding: 4px;

            &.disabled {
              color: var(--states-disabled-text, #9ca1ad);
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }

  .actions {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 16px 24px;
    justify-content: flex-end;
    min-height: 0;
    flex: 0 0 auto;

    .btn {
      margin-left: 8px;
    }

    .cancel-btn {
      border: 0 !important;
      box-shadow: none !important;
    }
  }
}

.dropdown-add-segment-filter-content.in-builder-content {
  z-index: 9999 !important;

  .block-categories {
    max-height: 240px;
    overflow-y: auto;

    .items {
      grid-template-columns: 1fr 1fr 1fr;

      .block {
        padding: 6px 7px;
      }
    }
    .category-title {
      padding: 12px 20px 6px 20px;
    }
  }
}
