.hint-element-editor {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;

  .section-item {
    display: flex;
    min-height: 40px;
    align-items: center;

    .section-item-title {
      height: 40px;
      display: flex;
      align-items: center;
      align-self: flex-start;
    }
    .section-item-content {
      margin-left: auto;
      width: 176px;
      flex-shrink: 0;

      .jimo-select__control {
        border-radius: 12px;
        height: 40px;
        .jimo-select__value-container {
          padding: 0 12px;
        }
      }

      .jimo-select__menu {
        width: 176px;
      }
    }
  }

  .section-item {
    .section-item-content {
      width: 176px !important;
    }
  }

  .section-item.z-index {
    .input-group {
      height: 40px;

      .input {
        border-radius: 12px 0 0 12px;
      }
      .label-left {
        border-radius: 12px 0 0 12px;
        padding: 4px;
        display: flex;
        margin-left: 4px;

        .icon-wrapper {
          margin: auto;
          height: 32px;
          width: 32px;
          display: flex;
          border-radius: 12px;
          background: rgba(18, 96, 235, 0.1);

          i {
            margin: auto;
            font-size: 10px;
            color: #1260eb;
          }
        }
      }
    }
  }

  .section-item.target-picker {
    .input-group {
      height: 40px;

      .input {
        border-radius: 12px 0 0 12px;
      }
      .label-left {
        border-radius: 12px 0 0 12px;
        display: flex;

        .position-icon-wrapper {
          display: flex;
          margin: auto 4px;
          width: 32px;
          height: 32px;
          box-sizing: border-box;
          border: 1px solid rgba(0, 0, 0, 0.1);
          box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
          border-radius: 12px;

          i {
            margin: auto 4px;
            font-size: 14px;
            width: 32px;
            box-sizing: border-box;
            border-radius: 12px;
            text-align: center;
          }
        }
      }
    }
  }
}
