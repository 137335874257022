.btn.btn-tag {
  position: relative;
  border-radius: 25px;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 6px 12px !important;
  cursor: pointer;
  color: #000;
  outline: none;
  font-size: 14px;
  overflow: hidden;
  font-weight: 500;
  border: 1px solid transparent;
  &:not(.is-interactive) {
    cursor: default;
  }
  &.is-active {
    border: 1px solid rgba(0, 0, 0, 0.7);
    background: rgba(0, 0, 0, 0.05);
    &.has-color {
      border: none;
      background: #000;

      .bg-color {
        opacity: 0.4;
      }
    }
  }
  &.is-active:focus {
    &.has-color {
      .bg-color {
        opacity: 0.4;
      }
    }
  }
  &.is-interactive:not(.has-color) {
    background: rgba(0, 0, 0, 0.05);
    color: #000;
  }
  &:not(.is-interactive):hover {
    &:not(.has-color) {
      background-color: transparent;
      border: 1px solid rgba(0, 0, 0, 0.2);
      color: #000;
    }
  }
  &.has-color {
    background-color: #fff;
    border: none;
    &:not(.is-interactive):hover {
      .bg-color {
        opacity: 0.3;
      }
    }
  }

  .bg-color {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.1;
    transition: all ease-in-out 200ms;
  }
  .count {
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.3);
  }
  .icon-close {
    font-size: 8px;
    margin-right: 14px;
    cursor: pointer;
    padding: 6px 0;
  }
  .icon-chevron-bottom {
    font-size: 12px;
    margin-left: 6px;
  }
}

.input-add-tag {
  .input {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 6px 12px;
    border-radius: 25px;
    outline: none;
    max-width: 200px;
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.7);
    }
  }
}

.input-add-tag-dropdown {
  min-width: 375px;
  margin-top: 6px;
}

.input-add-tag-dropdown-content {
  cursor: default;

  .top {
    display: flex;
    align-items: center;
    padding: 0 24px;
    position: relative;
    min-height: 54px;
    justify-content: space-between;

    .tag-name {
      .placeholder {
        font-style: italic;
        color: rgba(0, 0, 0, 0.2);
      }
    }
    .action-description {
      position: absolute;
      right: 16px;
      color: rgba(0, 0, 0, 0.2);
      font-style: italic;
      transition: all 100ms ease-in-out;
      &.has-limitation {
        justify-content: space-between;
        color: #ffffff;
        font-style: normal;

        a {
          text-decoration: underline;
          font-weight: 400;
        }
        .icon-lock {
          margin-right: 4px;
          text-decoration: unset;
        }
      }
    }
  }
  .dropdown-tag-list {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    .dropdown-tag-item {
      padding: 16px 24px;
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}

.tag-button-wrapper {
  &.is-dropdown {
    display: flex;
  }

  i {
    margin: auto;
    font-size: 10px;
    &.icon-chevron-bottom {
      margin-left: 12px;
    }
  }
  i.icon-edit {
    margin-left: auto;
    font-size: 14px;
  }
}
