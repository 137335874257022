.hint-activity {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;

  .empty-state-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .hint-activity-header {
    display: flex;
    padding: 0px 12px;
    align-items: center;
    align-self: stretch;
    display: grid;
    grid-template-columns: 6fr 2fr 1fr 1fr;
    gap: 24px;

    & > div {
      color: var(--neutral-500, var(--neutral-neutral-500, #9ca1ad));
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      display: flex;
      align-items: center;
      gap: 8px;

      i {
        cursor: pointer;
      }
    }
  }

  .hint-activity-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    .hint-activity-item {
      position: relative;
      padding: 8px 12px;
      align-items: center;
      // gap: 24px;
      align-self: stretch;

      .hint-activity-item-content {
        display: grid;
        grid-template-columns: 6fr 2fr 1fr 1fr;
        gap: 24px;

        .hint-info {
          position: relative;
          z-index: 2;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          align-self: center;

          .hint-basic-info {
            color: var(--neutral-700, var(--neutral-neutral-700, #4d637b));
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 18px */
          }

          .hint-name {
            color: var(
              --neutral-800-black,
              var(--neutral-neutral-800, #071331)
            );
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 125%; /* 20px */
          }

          .chart-wrapper {
            position: absolute;
            top: -12px;
            left: -12px;
            height: 100%;
            border-radius: 8px;
            background: var(--surface-accent-secondary-brand, #f3f7fe);
            z-index: -1;
            width: 50%;
            padding: 12px;
          }

          .label-hidden-wrapper {
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
          }
        }

        .hint-action {
          color: var(--neutral-700, var(--neutral-neutral-700, #4d637b));
          justify-self: right;
          align-items: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 24px */
          align-self: center;
          display: flex;
          gap: 8px;
        }

        .hint-views {
          color: var(--neutral-700, var(--neutral-neutral-700, #4d637b));
          text-align: right;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 24px */
          align-self: center;
        }

        .hint-users {
          color: var(--neutral-700, var(--neutral-neutral-700, #4d637b));
          text-align: right;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 24px */
          align-self: center;

          &.clickable {
            text-decoration-line: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}
