.calendly-overlay {
  position: fixed !important;
  z-index: 9999999999 !important;
  background-color: rgba(0, 0, 0, 0.75) !important;

  .calendly-popup {
    position: absolute !important;
    overflow: hidden !important;
  }
}

.booking-google-wrapper {
  z-index: 9999999999;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;

  .booking-google-iframe {
    height: 70%;
    width: 60%;
    margin: auto;
    border: 1px solid #fff;
    border-radius: 4px;
    background-color: #fff;
  }
}
