.tracker-audience {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  align-self: stretch;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 5px 60px 0px rgba(0, 0, 0, 0.08);
  margin: 0 12px;
  width: calc(100% - 24px);

  &.is-open {
    .tracker-audience-header {
      border-radius: 16px 16px 0px 0px;
      border-bottom: 1px solid var(--states-disabled-border, #e5e5e5);
    }
  }

  .tracker-audience-header {
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 16px;
    background: #fff;
    cursor: pointer;

    .left-side {
      display: flex;
      align-items: center;
      gap: 8px;

      .icon-wrapper {
        width: 32px;
        height: 32px;
        border-radius: 38px;
        border: 1px solid var(--border-moderate, #9ca1ad);
        display: flex;

        i {
          margin: auto;
        }
      }

      .title-wrapper {
        display: flex;
        padding: 8px 12px;
        padding-left: 0;
        align-items: center;
        gap: 8px;

        .title {
          color: var(--content-primary, #071331);
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 30px */
        }

        .optional-tag {
          display: flex;
          padding: 4px 8px;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          background: var(--surface-secondary, #f0f2f4);
          color: var(--content-primary, #071331);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 21px */
        }
      }
    }

    .right-side {
      display: flex;
      align-items: center;
      gap: 8px;

      .users-count {
        color: var(--content-tertiary, #9ca1ad);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }

      i {
        color: #4d637b;
      }
    }
  }

  .collapsable-content-wrapper {
    width: 100%;
    .content-wrapper {
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      flex-shrink: 0;
      align-self: stretch;

      .section-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0;

        .section-title {
          margin-bottom: 24px;
          font-size: 18px;
          font-family: 'Montserrat';
          font-weight: 500;
          font-size: 18px;
        }

        .alert {
          margin-bottom: 8px;
          border-radius: 12px;
          font-size: 16px;
        }

        .list-segment {
          margin-bottom: 6px;
          gap: 16px;
          display: flex;
          flex-wrap: wrap;

          .item-segment {
            display: flex;
            align-items: center;

            &.selected {
              background-color: #e7effd;
              border-color: #4782e9;
              color: #1260eb;
            }
          }
        }
        .segment-attributes-editor {
          padding: 0;
        }
        .list-attributes {
          padding: 0;

          .jimo-select .jimo-select__value-container {
            padding: 4px 6px;
          }
          .input {
            padding: 8px 6px !important;
          }
        }
      }

      .selected-users-wrapper {
        display: flex;
        padding: 12px 16px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--states-disabled-border, #e5e5e5);
        height: 48px;
        box-sizing: border-box;

        &.requires-lookup {
          .selected-users {
            filter: blur(4px);
          }

          .selected-users-actions {
            display: flex;
          }
        }

        .selected-users {
          display: flex;
          align-items: center;
          gap: 12px;

          .avatar-wrapper {
            display: flex;
            align-items: flex-start;
            gap: 2px;

            .avatar {
              height: 24px;
              width: 24px;
            }
          }

          .selected-users-count {
            color: var(--border-dark, #071331);
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 125%; /* 20px */
          }
        }

        .selected-users-actions {
          display: none;
          align-items: center;
          gap: 16px;

          .cancel-btn {
            color: var(--neutral-700, var(--neutral-neutral-700, #4d637b));
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 125%; /* 20px */
            cursor: pointer;
            padding: 4px;
          }

          .apply-btn {
            color: var(--content-accent-brand, #1260eb);
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 125%; /* 20px */
            cursor: pointer;
            padding: 4px;

            &.disabled {
              color: var(--states-disabled-text, #9ca1ad);
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}
