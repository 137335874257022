.md-renderer-wrapper {
  &.has-content-truncated {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  &.has-read-more {
    margin-bottom: 12px;

    .md-read-more {
      position: absolute;
      margin-top: 4px;
      right: 24px;
      color: var(--boostedSecondaryColor);
      cursor: pointer;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      &:hover {
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
  }
}

.markdown-content {
  font-weight: 400;
  font-size: 16px;
  overflow-wrap: break-word;

  img {
    max-width: 100%;
  }
  a {
    color: #0063cc;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  .iframe-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    &:hover {
      iframe {
        transform: scale(1.01);
      }
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      transition: all 100ms ease;
    }
  }
}
