.block-settings.profile {
  .section-title-extra {
    .block-group {
      .icon-wrapper {
        background-color: var(--g-100);
      }
    }
  }

  .preview-profile-media {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    overflow: hidden;

    img {
      width: 32px;
      height: 32px;
    }
  }
  .placeholder-empty-media {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }
}
