.block-settings.multiple-choice {
  .options {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .choice-wrapper {
      width: 100%;
      position: relative;

      .choice-label {
        width: 100%;

        .input-main-wrapper {
          margin-bottom: 12px;
          background: rgba(138, 138, 138, 0.1);
          border-radius: 12px;
          resize: none;
          position: relative;
          font-size: 16px;
          border-style: dashed !important;
        }
        &.invalid {
          .input-main-wrapper {
            border-color: #ffa5a5;

            &:focus {
              border-color: #ffd7d7;
            }
          }
        }

        .input {
          border-right: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        .label-right {
          display: flex;
          border-radius: 0 12px 12px 0;
          height: 40px;
          padding: 0;
          box-sizing: border-box;
          border-left: 0;

          .icon-trash {
            font-size: 14px;
            margin: auto;
            padding: 8px;
            cursor: pointer;
          }
        }
      }
      .add-option-btn {
        position: absolute;
        left: 50%; /* position the left edge of the element at the middle of the parent */
        transform: translate(-50%, -50%);
        padding: 8px 12px;
        bottom: -20px;

        i {
          margin-right: 0 !important;
          font-size: 16px;
        }
      }
    }
  }

  .preview-shadow-color {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }
}
