.logic-manager-wrapper {
  background: #fff;
  display: flex;
  flex-direction: column;

  .logic-manager {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 392px;
    min-height: 0;
    padding: 16px 0;

    .logic-manager-header {
      display: flex;
      padding: 0 16px;

      .logic-manager-title {
        margin: auto 0;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
      }

      i {
        cursor: pointer;
        margin-left: auto;
        color: #9598a9;
        padding: 4px;
      }
    }

    .conditions-wrapper {
      padding: 0 16px;
      min-height: 0;
      overflow-y: scroll;
    }
  }
}
