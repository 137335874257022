.settings-snooze {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .subtitle {
    display: flex;
    gap: 8px;
    align-items: center;
    .action-group {
      display: flex;
      gap: 4px;
      align-items: center;
      .icon-wrapper {
        padding: 3px;
        border-radius: 4px;
        background-color: var(--p-200);
      }
    }
  }

  .field-group {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .field {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;

      .input-group,
      .select-group-wrapper {
        width: 176px;
      }
      .input-option {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: left;
      }
    }
  }
}
