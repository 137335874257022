.modal-extension-trial__overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-extension-trial {
  background: #ffffff;
  color: #000000;
  width: 400px;
  box-sizing: border-box;
  font-size: 18px;
  & > p {
    padding: 0 32px;
    text-align: center;
  }

  .main-wrapper {
    padding: 8px;
    padding-bottom: 0;
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 32px;
    background: url(https://assets.usejimo.com/banner-trial.svg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 300px;
    box-sizing: border-box;

    .title-3 {
      text-align: center;
    }
    p {
      text-align: center;
      margin: 0;
    }
    a {
      width: 100%;
    }
    .btn {
      justify-content: center;
      width: 100%;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 12px;

    .btn {
      flex: 1;
      justify-content: center;
    }
  }
}
