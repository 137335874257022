.animation-heart-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.animation-heart {
  position: relative;
  flex: 0 0 80px;
  height: 80px;
  animation: jimo-animation-heart-in 1s forwards,
    jimo-animation-heart 2s 500ms both, jimo-animation-heart-out 1s 2s forwards;
}

.animation-heart:before,
.animation-heart:after {
  position: absolute;
  content: '';
  left: 40px;
  top: 0;
  width: 50px;
  height: 80px;
  background: #ff3333;
  -moz-border-radius: 50px 50px 0 0;
  border-radius: 50px 50px 0 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.animation-heart:after {
  left: -10px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

@keyframes jimo-animation-heart {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}
@keyframes jimo-animation-heart-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes jimo-animation-heart-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
