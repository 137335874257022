.modal-experience-event {
  overflow: hidden;
  margin: 8px;
  right: 0 !important;
  top: 0 !important;
  left: unset !important;
  position: absolute;
  height: calc(100% - 16px);
  width: 756px !important;
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 12px;

  .modal-title {
    display: flex;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 24px;
    font-family: 'Montserrat';
    margin-bottom: 0;

    i {
      margin-right: 16px;
    }
  }

  .event-modal-content-wrapper {
    flex: 1 auto;
    display: flex;
    padding: 16px;
    gap: 67px;
    align-self: stretch;
    min-height: 0;

    .event-selector {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      background: #fff;
      box-shadow: 0px 5px 60px 0px rgba(0, 0, 0, 0.08);
      width: 100%;
      border-radius: 16px;
      padding: 16px;

      .divider {
        width: calc(100% - 32px);
        margin: 0 auto;
      }

      .event-selector-header {
        display: flex;
        padding: 8px 12px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;

        .event-selector-title {
          color: var(--content-primary, #071331);
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 30px */
        }

        .event-selector-description {
          color: #000;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 24px */
        }
      }

      .event-conditions-wrapper {
        align-self: stretch;
        margin: unset;
      }

      .event-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-self: stretch;

        .event-wrapper {
          display: flex;
          gap: 16px;
          width: 100%;

          .or {
            margin: auto 0;
          }

          .event-item {
            cursor: pointer;
            display: flex;
            flex: 1 auto;
            height: 42px;
            padding: 8px 12px;
            align-items: center;
            gap: 12px;
            border-radius: 16px;
            border: 1px solid var(--n-300);
            position: relative;
            transition: all 300ms ease-in-out 0s;

            &:hover {
              border-color: var(--b-400);

              .actions-wrapper {
                opacity: 1;
              }
            }

            .icon-wrapper {
              height: 40px;
              width: 40px;
              display: flex;
              border-radius: 12px;

              &.element-event {
                background: var(--green-200, #c4e9bf);

                i {
                  color: #000;
                }
              }

              &.page-view-event {
                background: var(--blue-200, #c4e9f1);

                i {
                  color: #000;
                }
              }

              &.segment-event {
                background: var(--purple-400-main, #bb68ff);
              }

              i {
                margin: auto;
                font-size: 24px;
              }
            }

            .content {
              padding: 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 2px;
              flex: 1 0 0;
            }

            .actions-wrapper {
              position: absolute;
              right: 8px;
              display: flex;
              gap: 8px;
              opacity: 0;
              transition: all 300ms ease-in-out 0s;
            }
          }
        }
      }
    }
  }
}
