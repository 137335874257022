.mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #1d1f2d;
  @media (min-width: 750px) {
    display: none;
  }

  .wrapper {
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    .title {
      font-size: 24px;
      margin: 24px 0;
    }
  }
}
