.jimo-navbar-settings {
  height: 100%;
  overflow: auto;

  .menu {
    padding: 24px 12px;
    box-sizing: border-box;
    width: 280px;
  }
  .menu-item {
    padding: 8px 12px;
    margin-bottom: 2px;
    border-radius: 12px;
    &:not(.is-active):hover {
      background-color: rgba(240, 242, 244, 1) !important;
      color: rgba(77, 99, 123, 1) !important;
    }
    &.is-active:hover {
      background-color: rgba(240, 242, 244, 1) !important;
      color: #000 !important;
    }
    &.is-active {
      background-color: rgba(240, 242, 244, 1);
      color: #000;
    }

    .isax {
      font-size: 20px;
    }

    .settings-project-logo {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border: 1px solid var(--n-300);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      margin-right: 10px;
      font-size: 12px;
    }
    .settings-user-avatar {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-size: cover;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      margin-right: 10px;
      font-size: 12px;
    }
  }
  .menu-section {
    font-size: 14px;
    text-transform: none;
    margin-bottom: 8px;
    padding: 0;
    padding-left: 12px;
  }
  .divider {
    margin: 18px auto;
    width: 232px;
  }
}
