.popup-setting-content {
  width: 282px;
  &.medium-content {
    width: 320px;
  }
  &.large-content {
    width: 420px;
  }

  .dropdown-content {
    overflow: unset;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
    border: 0;
  }

  .popup-setting-wrapper {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .popup-setting-header {
      display: flex;

      .popup-setting-title {
        margin: auto 0;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
      }

      i {
        cursor: pointer;
        margin-left: auto;
        color: #9598a9;
        font-size: 12px;
        padding: 4px;
      }
    }

    .popup-setting-items {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .section-item {
        display: flex;

        .section-item-title {
          margin: auto 0;
        }
        .section-item-content {
          margin-left: auto;
          width: 150px;

          .jimo-select__control {
            border-radius: 12px;
            height: 40px;
            .jimo-select__value-container {
              padding: 0 12px;
            }
          }

          .input-group {
            height: 40px;
            width: 100%;
            display: inline-flex;

            .input {
              flex: 1 auto;
              border-radius: 12px;
            }

            &.labeled-left {
              .input {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }
            }

            &.labeled-right {
              .input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: 0;
              }

              .label-right {
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
                padding: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.popup-setting-trigger-wrapper {
  &.disabled {
    cursor: not-allowed !important;
    opacity: 0.3;
  }

  &:hover,
  &.active {
    .input-main-wrapper {
      box-shadow: 0 0 2px 1px rgba(18, 96, 235, 0.4);
      border-radius: 12px;
    }
  }

  &.invalid {
    .input-main-wrapper {
      box-shadow: 0 0 2px 1px rgba(255, 0, 0, 0.4);
      border-radius: 12px;
    }
  }

  .input-group {
    height: 40px;

    .input-main-wrapper {
      height: 100%;

      .label-left {
        padding-left: 0 !important;
      }
    }
  }
}
