.template-details {
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  height: 100%;
  width: 100%;

  .details-wrapper {
    display: flex;
    max-width: 336px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    padding: 36px 28px;
    justify-content: space-between;

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;

      .main-infos {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        .experience-type {
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;

          i {
            font-size: 16px;
          }
        }
      }

      .secondary-infos {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;

        .when-to-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
        }
      }
    }

    .btn-actions {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
    }
  }
}
