.s-requests {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  height: 100%;

  &.is-loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.has-empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .empty-state-block {
    margin: auto;
  }

  .tip-welcome {
    margin-bottom: 24px;
  }
  .main {
    overflow: hidden;
    flex: 1 1 auto;
    justify-content: center;

    .requests-wrapper {
      display: flex;
      overflow: hidden;
      height: 100%;
      margin: 0;

      & > div {
        padding: 0;
      }
    }
  }
  .request-list-wrapper {
    display: flex;
    overflow: hidden;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  .request-details-wrapper {
    flex: 1 0;
    display: flex;
    // align-items: center;
    // justify-content: center;
    padding: 0;
    height: 100%;
  }
}
