.writer-wrapper {
  flex: 1 auto;
  display: flex;
  background: #fff;

  .writer {
    max-width: 600px;
    margin: 24px auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1 auto;
    justify-content: center;

    .markdown-editor-advanced {
      box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      border: 1px dashed #e5e5e5;
      background: #fff;
      width: 100%;
      flex: 0 1 800px;
      min-height: 0;
      display: flex;
      flex-direction: column;

      .toolbar {
        border-radius: 12px;
        margin: 8px;
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background: #fff;
      }

      .editor-wrapper {
        flex: 1 auto;
        display: flex;
        min-height: 0;
        overflow-y: scroll;
        overflow-x: hidden;
      }
    }
  }
}
