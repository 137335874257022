.segment-attribute {
  min-width: 480px;
  display: flex;
  font-size: 18px;
  flex-wrap: wrap;
  gap: 12px;

  .header-row {
    display: flex;

    .btn-delete {
      margin-left: auto;
      margin-right: 16px;
      padding: 0;
      border: 0;

      i {
        font-size: 12px;
        color: #9598a9;
      }
    }
  }

  .value-row {
    display: flex;
    gap: 12px 16px;
    flex-wrap: wrap;
    min-height: 50px;

    .jimo-select {
      display: flex;
      .jimo-select__control {
        border-radius: 16px;
      }
    }

    .input-value-wrapper {
      display: flex;
    }

    .input-group {
      .input {
        height: 100%;
        min-width: 200px;
      }
      .icon-close {
        font-size: 12px;
        cursor: pointer;

        &:hover {
          color: #000;
        }
      }

      &.labeled-right {
        .input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: none;
        }

        .label-right {
          border: 1px dashed rgba(0, 0, 0, 0.3);
          border-left: none;
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      }
    }
    .react-datepicker-wrapper {
      width: unset;
      display: flex;

      .react-datepicker__input-container {
        display: flex;
      }
    }

    .btn-or {
      margin: auto 0;
      padding: 8px 12px;
      font-size: 12px;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 18px;
    }
  }

  .jimo-select {
    height: 50px;
    display: flex;

    .jimo-select__control {
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);

      .jimo-select__value-container {
        height: 40px;
        padding: 0 12px;
      }
    }
  }

  .delete-attribute {
    display: flex;
    margin: auto;
    margin-right: 8px;
    padding: 8px;
    border: 0;
    cursor: pointer;

    i {
      font-size: 12px;
      color: #9598a9;
    }
  }
}
