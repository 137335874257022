.target-not-selected {
  padding: 12px;
  gap: 12px;
  position: absolute;
  bottom: 16px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #000;
  left: 50%;
  transform: translateX(-50%);

  .icon-wrapper {
    background: #ffe6e6;
    border-radius: 12px;
    padding: 8px;
    i {
      font-size: 22px;
    }
  }
}
