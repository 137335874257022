.audience-section.who {
  .audience-section-content {
    position: relative;
    display: flex;

    .who-editor-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;

      span {
        color: #000;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
      }

      .builder-channel-interactive-option {
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        box-sizing: border-box;
        display: flex;

        .option-main-wrapper {
          flex: 1 auto;

          .icon {
            width: 20px;
            font-size: 20px;
            color: rgba(0, 0, 0);

            &.active-hovered-icon {
              display: none;
            }
          }

          .title-description-wrapper {
            display: flex;
            flex-direction: column;
          }

          .icon-checkbox {
            margin-left: auto;
          }
        }

        &.is-active {
          background: rgba(18, 96, 235, 0.08);

          &:hover {
            .icon {
              &.default-icon {
                display: none;
              }
              &.active-hovered-icon {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  &.is-boosted {
    .audience-section-content {
      justify-content: center;
      align-items: center;

      .who-editor-wrapper {
        pointer-events: none;
        opacity: 0.3;
      }

      .boosted-info-wrapper {
        max-width: 587px;
        position: absolute;
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        border-radius: 12px;
        background: #fff;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.06);

        .boosted-info-text {
          color: #000;
          text-align: center;
          font-family: Inter;
          font-size: 18px;
          font-weight: 400;
        }

        .boosted-info-actions {
          display: flex;
          gap: 16px;
        }
      }
    }
  }
}
