.poke-render-preview-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  pointer-events: none;
  overflow: visible;

  .poke-render-preview {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    &:not(.auto-height) {
      .jimo-poke {
        height: 100%;
      }
    }

    &.is-checklist {
      .poke-render-preview-content {
        overflow: visible;
      }
    }

    &.is-banner {
      .jimo-poke-wrapper {
        width: 100%;
      }
    }

    .poke-render-preview-content {
      overflow: hidden;
      position: relative;
      z-index: 1;
      display: flex;
    }

    .cursor-element {
      position: absolute;
      top: -32px;
      left: -28px;
    }
  }

  .poke-render-preview-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    z-index: 0;
  }
}
