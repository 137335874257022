.checkbox {
  width: 24px;
  height: 24px;
  border: 1px solid transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 12;
  flex-shrink: 0;

  &.completed {
    .completed-icon {
      display: flex;
      height: 100%;
      width: 100%;

      svg {
        height: 16px;
        width: 16px;
        margin: auto;
      }
      img {
        margin: auto;
        width: 16px;
        height: 16px;
      }
    }
  }
}
