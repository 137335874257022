.zapier-settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  gap: 24px;

  .zapier-settings-header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .left-side {
      display: flex;
      gap: 16px;
      align-items: center;
    }
    .right-side {
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }

  .zapier-settings-content {
    display: flex;
    gap: 24px;
    align-self: stretch;

    .left-side {
      flex: 1;
    }
    .right-side {
      flex: 0 0 360px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .alert-body span {
        color: var(--b-400);
        font-weight: 600;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          text-underline-offset: 3px;
        }
      }
    }
  }
}
