.checklist-steps-manager {
  margin: auto;
  bottom: 0;
  background: #ffffff;
  display: flex;
  border-radius: 16px;
  justify-content: center;

  .step-block-list {
    flex: 0 0 auto;
    display: flex;
    margin: 4px 16px;
    background: rgba(206, 206, 206, 0.2);
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    padding: 4px;
    position: relative;

    & > div {
      display: flex;
    }
    .droppable-block-list {
      display: flex;

      .block-wrapper {
        display: flex;
        gap: 8px;
        margin-right: 8px;

        &.is-last {
          margin-right: 0;
        }

        .block-selector {
          position: relative;
          cursor: pointer;
          display: flex;
          padding: 8px;
          border-radius: 12px;
          background: #ffffff;
          border: 2px solid rgba(0, 0, 0, 0.1);
          height: 44px;
          width: 44px;
          box-sizing: border-box;

          &.selected {
            background: #e6f5fd;
            border: 2px solid rgba(18, 96, 235, 1) !important;
          }
          &.invalid {
            border-style: dashed !important;
            border-color: #ffa5a5 !important;
          }
          .block-type-icon {
            font-size: 24px;
            display: flex;

            i {
              margin: auto;
            }
          }
        }
      }
    }
  }
}
