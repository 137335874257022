.width-picker {
  .pixel-picker {
    width: 100%;

    .pixel-picker-input {
      .input {
        width: 80px;
      }
    }

    .pixel-picker-slider {
      width: 100px;
    }
  }
}
