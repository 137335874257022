.tracker-users {
  background-color: #ffffff;
  flex: auto;

  .loader {
    margin: auto;
  }
  .block-header {
    .title {
      display: flex;
      gap: 8px;
      align-items: center;
      color: var(--content-primary, #071331);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 30px */
    }
  }

  .block-content {
    display: flex;
    min-height: 0;

    .tracker-users-content-wrapper {
      min-width: 0;
      flex: 1 1 auto;
    }
  }
}
