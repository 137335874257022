.s-posts {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-flow: column;
  background-color: var(--b-100);

  &.is-loading {
    justify-content: center;
    align-items: center;
  }
  & > .row {
    margin: 0;

    & > div:not(:first-child) {
      padding: 0;
    }
  }
  & > .main-row {
    height: 100%;
    overflow: hidden;

    & > div:last-child {
      height: 100%;
    }
  }

  .evolution-sub-menu-wrapper {
    padding: 0;
  }
  .all-empty-state-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tip-welcome-live-for-vote {
    margin-bottom: 42px;
  }

  .content-wrapper {
    display: flex;
    flex: 1;
    max-width: 1200px;
    margin: auto;

    .evolution-list-wrapper {
      overflow: hidden;
      height: 100%;
      display: flex;
      max-width: 740px;
      padding: 22px;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 16px;
      margin: 0 auto;
      margin-bottom: 24px;
      width: 100%;
    }
    .comments-list-wrapper {
      padding: 42px 24px !important;
    }
  }

  .empty-state-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .empty-state {
      width: 100%;
      max-width: 400px;
      text-align: center;
    }
  }
}
