.support-event-mouse-down-content {
  z-index: 10000 !important;
  max-width: 300px;
  &.dark-content {
    font-size: 12px;
  }

  span {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 1px 4px;
    border-radius: 4px;
    line-height: 20px;
  }
}
