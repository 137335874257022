.block-settings.animation {
  .label-left {
    .animation-icon-wrapper {
      display: flex;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      background: var(--b-100);

      i {
        margin: auto;
        font-size: 18px;
        box-sizing: border-box;
        border-radius: 12px;
        text-align: center;
      }
    }
  }
  .play-animation-button {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    i {
      font-size: 20px;
    }
  }
}
