.gs-step-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  &.opacity-on-hover {
    &:hover {
      .step-top {
        color: rgba($color: #000000, $alpha: 1);
      }
      .step-body {
        opacity: 1;
      }
    }
    .step-top {
      color: rgba($color: #000000, $alpha: 0.5);
    }
    .step-body {
      opacity: 0.5;
      transition: all 100ms ease-in-out;
    }
  }

  .step-top {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  .step-number-and-progress {
    position: relative;
    top: 3px;
    margin-right: 24px;
    z-index: 2;
    &.is-one {
      span {
        left: 16px;
        top: 8px;
      }
    }

    .step-progress-bg {
      position: absolute;
      z-index: 1;
      background-color: #fff;
      border-radius: 50%;
      width: 38px;
      height: 38px;
      top: 1px;
      left: 1px;
    }
    .circular-progress {
      position: relative;
      z-index: 2;
    }
    span {
      position: absolute;
      left: 14px;
      top: 8px;
      z-index: 2;
    }
  }
  .step-body {
    position: relative;
    z-index: 2;
  }
}
