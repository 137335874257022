.settings-body.s-settings-theme {
  padding: 0 !important;
  width: 100% !important;
  display: flex;
  overflow: hidden;
  max-width: unset !important;
  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .theme-editor-wrapper {
    display: flex;
    flex-direction: column;
    flex: 0 0 420px;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    border-left: 1px solid var(--n-300);
    border-right: 1px solid var(--n-300);
    box-sizing: border-box;

    .editor-header-wrapper {
      flex: 0 1;
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 20px;
      padding-top: 32px;
      border-bottom: 1px solid var(--n-300);

      .editor-header-top {
        display: flex;
        gap: 16px;

        .btn {
          padding: 8px 16px;
        }
        .input-group {
          flex: 1;
        }
      }
      .editor-header-actions {
        display: flex;
        gap: 12px;

        .btn-save-theme {
          flex: 1;
          justify-content: center;
        }
      }
    }
    .scrollable-wrapper {
      flex: 1;
      overflow-y: auto;
    }
  }
  .theme-preview-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: var(--b-100);
    padding: 28px 40px;
    box-sizing: border-box;
    gap: 24px;
  }
}
