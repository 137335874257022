.step-preview-content-wrapper {
  position: absolute;
  top: 52px;
  left: 50%;
  transform: translate(-50%, 0);
  transform-origin: top center;
  background-color: #fff;
  overflow: hidden;
  z-index: 10;
  border-radius: 12px;
  border: 1px solid var(--n-300);

  .step-preview-info {
    padding: 16px;
    display: flex;
    gap: 12px;
    margin: auto 0;

    .icon {
      height: 40px;
      width: 40px;
      display: flex;
      border: 1px solid var(--n-300);
      border-radius: 12px;

      i {
        margin: auto;
        font-size: 18px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
  .step-preview-content {
    background: var(--n-200);
    padding: 16px;

    .step-preview-poke-render {
      overflow: hidden;
      transform: scale(1);

      &:not(.auto-height) {
        .jimo-poke {
          height: 100%;
        }
      }
    }
  }
}
