.poke-header {
  display: flex;
  align-items: center;
  position: relative;
  flex: 0 1 auto;
  padding: 20px 24px;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid rgba(0, 0, 0, 0);

  &.scrolled {
    background-color: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
  }

  .infos {
    flex: 1 1 auto;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;

    .back-btn {
      background-color: #f4f4f4;
      height: 40px;
      width: 40px;
      border-radius: 12px;
      padding: 0;
      margin-right: 16px;

      &:hover {
        background-color: #dddddd;
      }

      i {
        margin: auto !important;
        color: #000000;
      }
    }

    .icon-wrapper {
      padding: 12px;
      display: flex;
      border-radius: 12px;
      margin-right: 16px;

      &.adoption {
        background-color: #c4e9bf;
      }
      &.discovery {
        background-color: #bcddff;
      }
      &.is-checklist {
        background-color: var(--y-300);
      }

      i,
      img {
        margin: auto;
        font-size: 24px;
      }
    }

    .push-title-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .push-title {
        font-size: 20px;
      }
    }

    .poke-tag-selector {
      margin-left: 12px;
    }
  }
  .actions {
    display: flex;
    margin-bottom: auto;
    gap: 16px;
    align-items: center;

    .btn-export-data {
      margin-right: 8px !important;

      i {
        font-size: 18px;
        color: #000000 !important;
      }
    }
    .btn {
      transition: all 300ms ease-in-out 0s;
      align-self: center;
      height: 38px;
    }
    .menu-btn {
      padding: 8px;
      height: 38px;
      width: 38px;

      i {
        margin: auto;
      }
    }
  }
}

.dropdown-more-actions-content {
  .menu-item {
    display: flex;
    gap: 8px;
    font-size: 16px;
  }
}
