.preview-stacked-line-chart {
  height: 100%;
  width: 100%;
  position: relative;
}

.tracker-preview-tooltip-wrapper {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(5px);
  padding: 0;
  position: absolute;
  pointer-events: none;

  .tooltip {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 180px;

    .title {
      color: #000;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 18px */
    }

    .groups {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .group {
        display: flex;
        padding: 4px;
        align-items: flex-start;
        gap: 12px;

        .color-indication {
          width: 6px;
          align-self: stretch;
          border-radius: 2px;
          background: #e5efff;
        }

        .data {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .group-pct {
            color: #000;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 24px */
          }

          .group-value {
            color: var(--neutral-600, var(--neutral-neutral-600, #7c8a99));
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 18px */
          }
        }
      }
    }
  }
}
