.settings-card.billing-card-trial {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 32px 24px;
  // background-color: var(--o-100) !important;
  background-color: #fffae1 !important;
  width: 100%;
  box-sizing: border-box;
  background-image: url(./imgs/bg.svg);
  background-position: right;
  background-size: contain;
  border-radius: 16px;
  background-repeat: no-repeat;

  .line-progress {
    max-width: 550px;

    .base {
      background-color: var(--o-300) !important;
      border-radius: 18px;
    }
    .tracking {
      background-color: var(--o-400) !important;
      border-radius: 18px;
    }
  }
}
