.modal-edit-environment {
  max-height: 80%;
  padding: 0;
  width: 480px;
  display: flex;
  flex-direction: column;

  .modal-edit-environment-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 16px;
    min-height: 0;

    .color-and-icon-wrapper {
      display: flex;
      gap: 16px;
      align-items: center;

      .icon-wrapper {
        display: flex;
        height: 84px;
        width: 84px;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        font-size: 36px;
        cursor: pointer;
      }

      .color-picker-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .color-picker {
          display: flex;
          gap: 8px;

          .color {
            display: flex;
            height: 32px;
            width: 32px;
            border-radius: 100%;
            border: 1px solid transparent;
            cursor: pointer;
            transition: border-color 0.2s;

            &:hover,
            &.selected {
              border-color: var(--n-800);
            }
          }
        }
      }
    }

    .form-section {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &.domains-section {
        min-height: 0;
      }

      .label {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .input-group {
        height: 40px;
      }

      .domains-creatable {
        min-height: 0;
        display: flex;

        .jimo-creatable__control {
          border-radius: 12px;
          padding: 4px 12px;
          padding-right: 4px;
          flex-flow: nowrap;
          gap: 8px;
          min-height: 50px;
          max-height: 100%;
          width: 100%;

          i.isax-global {
            font-size: 18px;
            color: var(--n-600);
          }

          .control-wrapper {
            display: flex;
            max-height: 100%;
            flex-grow: 1;

            .jimo-creatable__value-container {
              padding: 0;
              overflow-y: auto;
            }
          }

          .jimo-creatable__placeholder {
            font-family: 'Inter', sans-serif;
            line-height: 150%;
            font-weight: 500;
            font-size: 16px;
            color: var(--n-500);
          }

          .jimo-creatable__input-container {
            cursor: text;
          }

          .jimo-creatable__multi-value {
            border: 1px solid var(--n-300);
            background: var(--n-100);
            margin: 4px;
            gap: 4px;
            border-radius: 4px;
            padding: 4px 8px;

            .jimo-creatable__multi-value__label {
              color: var(--n-800);
              padding: 0;
            }

            .jimo-creatable__multi-value__remove {
              color: var(--n-600);
              padding: 0;
              cursor: pointer;
              font-size: 8px;
              background: transparent;

              &:hover {
                color: var(--n-700);
              }
            }
          }

          .jimo-creatable__clear-indicator {
            cursor: pointer;

            &:hover {
              i {
                color: var(--n-800);
              }
            }

            i {
              font-size: 20px;
            }
          }
        }
      }

      .input-description {
        textarea {
          resize: none;
          padding: 12px;
          border-radius: 12px;
          font-size: 16px;
          font-weight: 500;
          font-family: Inter;
          height: 85px;
        }
      }
    }
  }
}

.environment-tooltip-domains-content {
  padding: 0;

  .filter-domain-info-wrapper {
    background-color: rgba(18, 98, 235, 0.05);
    padding: 12px;
    border-radius: 8px;

    p {
      margin: 0;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      &:first-child {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        color: rgb(18, 98, 235);
        i {
          margin-right: 4px;
        }
      }
    }
  }

  .filter-domain-info {
    align-items: center;

    i {
      position: relative;
      top: 2px;
      margin-right: 8px;
    }
    pre {
      display: inline-block;
      margin: 0 4px;
      color: rgb(18, 96, 235);
      background-color: rgba(18, 98, 235, 0.1);
      padding: 2px 4px;
      border-radius: 4px;
    }
  }
}
