.modal-add-step-event {
  min-height: 560px;
  max-height: 80%;
  width: 600px;
  display: flex;
  flex-direction: column;

  &.mode-empty-state {
    min-height: unset;
  }

  .modal-title {
    display: flex;
    gap: 16px;
    align-items: center;

    .icon-wrapper {
      display: flex;
      align-items: center;
      border-radius: 12px;
      background: var(--surface-accent-secondary-positive, #e6fde8);

      i {
        padding: 8px;
      }
    }
  }

  .loader-wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .content {
    padding: 20px;
    display: flex;

    .event-options {
      display: flex;
      gap: 12px;

      .option {
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;
        border-radius: 12px;
        border: 1px solid var(--border-minimal, #e5e5e5);
        background: var(--surface-primary, #fff);
        cursor: pointer;

        .option-icon-wrapper {
          display: flex;
          padding: 8px;
          justify-content: center;
          align-items: flex-start;
          gap: 8px;
          border-radius: 8px;

          &.click {
            background: var(--surface-accent-secondary-positive, #e6fde8);
          }

          &.page-view {
            background: var(--surface-accent-secondary-brand, #f3f7fe);
          }

          i {
            font-size: 24px;
          }
        }

        .option-title {
          color: var(--content-primary, #071331);
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
        }

        .option-description {
          color: var(--content-secondary, #4d637b);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
        }

        .btn {
          i {
            font-size: 16px;
          }
        }
      }
    }
  }
}
