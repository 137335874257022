.block-settings.group {
  .preview-shadow-color {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }

  .background-picker {
    .preview-background-color {
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
    }
  }

  .snippet-position-picker {
    .alignment-figure-wrapper {
      .alignment-figure {
        margin: 40px 0;
      }
    }
  }

  .position-picker {
    .position-icon-wrapper {
      display: flex;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;

      i {
        margin: auto 4px;
        font-size: 14px;
        width: 32px;
        box-sizing: border-box;
        border-radius: 12px;
        text-align: center;
      }
    }
  }

  .animation-in-picker,
  .animation-out-picker {
    .animation-icon-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      background: var(--b-100);
      overflow: hidden;

      i {
        margin: auto;
        font-size: 18px;
        box-sizing: border-box;
        border-radius: 12px;
        text-align: center;
      }

      .animation-icon {
        width: 32px;
        height: 32px;
      }
    }
  }

  .alert-animation-in-app-builder {
    border-radius: 8px;
    margin-bottom: 12px;

    i {
      padding: 6px;
      background-color: rgba(18, 98, 235, 0.15);
      border-radius: 4px;
      font-size: 10px;
    }
  }

  .dismiss-option-select-component.jimo-select__menu {
    max-width: 157px !important;
  }

  i {
    &.icon-slot-dot {
      font-size: 20px;
    }
  }

  .section-item.triggers {
    .triggers-btn {
      &.with-triggers {
        background: var(--b-400);
        color: white;
      }

      .label-left {
        background: #ffffff33;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        box-sizing: border-box;
      }
    }
  }

  .section-item.to-progress {
    .progress-on-target-click {
      display: flex;
      gap: 8px;
      align-items: center;

      i {
        cursor: pointer;

        &.icon-checkbox-a {
          color: var(--b-400);
        }
      }
    }
  }
}
