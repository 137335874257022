.advanced-editor-toolbar {
  display: flex;
  gap: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 3.30233px 3.30233px rgba(0, 0, 0, 0.05);
  padding: 6px;
  margin: 8px;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  background: #fff;

  .divider {
    height: 16px;
    margin: auto 4px;
  }
}
