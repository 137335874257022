.feature-usage-event-selector-wrapper {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 67px;
  align-self: stretch;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 5px 60px 0px rgba(0, 0, 0, 0.08);
  margin: 0 12px;

  .feature-usage-event-selector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    min-width: 0;

    .event-selector-header {
      display: flex;
      padding: 8px 12px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;

      .event-selector-title {
        color: var(--content-primary, #071331);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 30px */
      }

      .event-selector-description {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
      }
    }

    .event-conditions-wrapper {
      align-self: stretch;
      margin: unset;
    }

    .event-list {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-self: stretch;

      .event-wrapper {
        display: flex;
        gap: 16px;
        width: 100%;

        .or {
          margin: auto 0;
        }
      }
    }

    .add-event-btn {
      padding: 12px;
      box-sizing: border-box;
      margin-right: auto;
      display: flex;
      color: #1260eb;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      align-items: center;
      background: var(--surface-accent-secondary-brand, #f3f7fe);
    }
  }
}

.tracker-event-item {
  display: flex;
  flex: 1 auto;
  height: 42px;
  padding: 8px 12px;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  border: 1px solid var(--n-300);
  position: relative;
  transition: all 300ms ease-in-out 0s;

  &:hover {
    .actions-wrapper {
      opacity: 1;
    }
  }

  .segment-icon-wrapper {
    height: 40px;
    width: 40px;
    display: flex;
    border-radius: 12px;

    i {
      margin: auto;
      font-size: 24px;
    }
  }

  .posthog-icon-wrapper {
    img {
      height: 32px;
      width: 32px;
    }
  }

  .mixpanel-icon-wrapper {
    img {
      height: 26px;
      width: 26px;
    }
  }

  .event-icon-wrapper {
    height: 40px;
    width: 40px;
    display: flex;
    border-radius: 12px;
    font-size: 24px;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--n-300);
  }

  .content {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
  }

  .actions-wrapper {
    position: absolute;
    right: 8px;
    display: flex;
    gap: 8px;
    opacity: 0;
    transition: all 300ms ease-in-out 0s;
  }
}
