.sharable-link-modal {
  max-height: 700px;
  max-width: 638px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  padding: 0;

  .modal-title {
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 24px;
    font-family: 'Montserrat';
    margin-bottom: 0;
    display: flex;
    gap: 16px;

    i {
      font-weight: 400;
      align-self: center;
    }
  }

  .content {
    margin: 24px;
  }
  .inputs-wrapper {
    display: flex;
    flex-direction: column;
    padding: 24px;

    .section-title {
      margin-bottom: 24px;
      font-size: 18px;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 18px;
    }

    .event-url {
      width: 100%;
      margin-bottom: 12px;

      .input-main-wrapper {
        align-items: stretch;

        .input {
          min-width: 280px;
          flex-shrink: 0;
        }
      }

      .label-right {
        border-radius: 0px 12px 12px 0px;
        background: #f0f2f4;
        min-width: 0;
        display: flex;

        .parameter-to-append {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: auto 0;
        }
      }
    }
  }

  .actions {
    margin: 0 24px 24px 24px;
    justify-content: flex-end;
    display: flex;
    gap: 8px;

    .cancel-btn {
      border: 0 !important;
      box-shadow: none !important;
    }

    .btn-copy {
      border: 1px solid rgba(255, 255, 255, 0);
      background: #071331;
      color: #fff;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.dropdown-share-url-suggestions-content {
  z-index: 99999 !important;
  border-radius: 12px;
  width: 280px;
  max-height: 300px;
  display: flex;

  .dropdown-content {
    background: #fff !important;
    flex: 1 auto;
    overflow-y: auto;
    padding: 8px;
    margin-top: 6px;
    border-radius: 12px;

    .url-suggestion {
      position: relative;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.7);
      padding: 8px 12px;
      border-radius: 12px;
      transition: all 100ms ease-in-out;
      overflow: hidden;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background-color: #e3ecff;

        .btn-delete-url {
          opacity: 1;
        }
      }

      span {
        font-weight: 700;
      }
    }
  }
}
