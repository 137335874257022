.modal-segmentation {
  height: 75%;
  width: 60%;
  min-width: 840px;
  max-width: 1200px;
  max-height: 1200px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .modal-title {
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 24px;
    font-family: 'Montserrat';
    margin-bottom: 0;
    min-height: 0;
    flex: 0 0 auto;
  }

  .content-wrapper {
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;

    .poke-segment-editing-wrapper {
      overflow: auto;
      width: 70%;
      display: flex;
      flex-direction: column;
      padding: 24px;
      box-sizing: border-box;

      .section-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin-bottom: 24px;

        .section-title {
          margin-bottom: 24px;
          font-size: 18px;
          font-family: 'Montserrat';
          font-weight: 500;
          font-size: 18px;
        }

        .alert {
          margin-bottom: 8px;
          border-radius: 12px;
          font-size: 16px;
        }

        .list-segment {
          margin-bottom: 6px;
          gap: 16px;
          display: flex;
          flex-wrap: wrap;

          .item-segment {
            display: flex;
            align-items: center;

            &.selected {
              background-color: #e7effd;
              border-color: #4782e9;
              color: #1260eb;
            }
          }
        }
        .segment-attributes-editor {
          padding: 0;
        }
        .list-attributes {
          padding: 0;

          .jimo-select .jimo-select__value-container {
            padding: 4px 6px;
          }
          .input {
            padding: 8px 6px !important;
          }
        }
      }
    }

    .poke-segment-users-wrapper {
      overflow: hidden;
      width: 30%;
      display: flex;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      padding-top: 24px;
      position: relative;

      &.need-refresh {
        .poke-segment-users-content {
          transition: opacity 150ms ease-in;
          opacity: 0.3;
        }

        .list-users {
          overflow-y: hidden;
        }
      }

      .poke-segment-users-content {
        width: 100%;
        display: flex;
        flex-direction: column;

        & > div {
          padding: 0 24px;
        }

        .empty-segmentation {
          display: flex;
          flex-direction: column;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          text-align: center;

          .jimer-avatar {
            margin: auto;
            height: 40px;
            width: 40px;
            margin-bottom: 8px;
          }
        }

        .loader-wrapper {
          margin: auto;
        }

        .count-users {
          margin-bottom: 24px;
          font-family: 'Montserrat';
          font-weight: 600;
          font-size: 18px;

          i {
            margin-right: 8px;
          }
        }

        .list-users {
          overflow-y: auto;
          overflow-x: hidden;
          padding-bottom: 24px;
          display: flex;
          flex-direction: column;
          gap: 24px;
          position: relative;

          .jimer {
            display: flex;

            .jimer-avatar {
              height: 40px;
              width: 40px;
              margin-right: 16px;
            }

            .infos {
              display: flex;
              flex-direction: column;
              min-width: 0;

              .name {
                font-weight: 500;
                font-size: 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .email {
                margin-top: auto;
                font-size: 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }

      .refresh-wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .actions {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 16px 24px;
    justify-content: flex-end;
    min-height: 0;
    flex: 0 0 auto;

    .btn {
      margin-left: 8px;
    }

    .cancel-btn {
      border: 0 !important;
      box-shadow: none !important;
    }
  }
}

.dropdown-add-segment-filter-content.in-builder-content {
  z-index: 9999 !important;

  .block-categories {
    max-height: 240px;
    overflow-y: auto;

    .items {
      grid-template-columns: 1fr 1fr 1fr;

      .block {
        padding: 6px 7px;
      }
    }
    .category-title {
      padding: 12px 20px 6px 20px;
    }
  }
}
