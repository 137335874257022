.changelog-builder {
  height: 100%;
  position: relative;
  display: grid;
  flex: 1 auto;

  &.grid-a-b {
    grid-template-columns: 25% 75%;
  }
  &.grid-a-b-a {
    grid-template-columns: 25% 50% 25%;
  }
  @media screen and (min-width: 1600px) {
    &.grid-a-b {
      grid-template-columns: 450px auto 350px;
    }
    &.grid-a-b-a {
      grid-template-columns: 450px auto 350px;
    }
  }

  .changelog-preview-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 12px;
    padding: 32px;
  }
}
