.gs-step-container {
  position: relative;

  .gs-step-trait {
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 20px;
    width: 1px;
    height: calc(100% - 48px);
    background-color: rgba($color: #000000, $alpha: 0.1);
  }
}
