.dropdown-visual-cue-picker-content {
  min-width: 158px;
  background: #ffffff;
  box-shadow: 0px 14px 19px rgb(0 0 0 / 3%);
  margin: 4px auto;
  z-index: 1002 !important;

  .dropdown-content {
    border-radius: 12px;

    .dropdown-menu {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      max-height: 500px;

      .visual-cue-picker-header {
        padding: 12px;
      }

      .visual-cue-picker-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 12px;
        flex-grow: 1;
        min-height: 0;

        .section-grid {
          align-items: flex-start;
          align-content: flex-start;
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));
          gap: 16px 8px;
          align-self: stretch;
          flex-wrap: wrap;

          .visual-cue {
            flex: 1 1 0;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            max-width: 78px;
            gap: 8px;

            &.selected {
              .icon-wrapper {
                outline: 1px solid var(--b-300, #85b1ff);
              }

              .label {
                color: var(--b-400, #1260eb);
              }
            }

            .icon-wrapper {
              height: 76px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 16px;
              background: var(--n-100, #f0f2f4);
              cursor: pointer;

              i {
                font-size: 24px;
              }

              img {
                width: 32px;
                height: 32px;
              }
            }

            .label {
              margin: auto;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.visual-cue-picker {
  .dropdown-trigger {
    width: 100%;

    .visual-cue-picker-trigger {
      display: flex;
      gap: 8px;
      align-items: center;
      padding: 8px 12px 8px 8px;
      gap: 8px;
      border-radius: 12px;
      border: 1px solid var(--n-300);
      cursor: pointer;

      .icon-wrapper {
        height: 32px;
        width: 32px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--b-150);

        svg {
          width: 24px;
          height: 24px;
        }
      }

      i.icon-chevron-bottom {
        margin-left: auto;
        font-size: 10px;
      }
    }
  }
}
