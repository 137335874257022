.zoom-manager {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  gap: 8px;

  .zoom-manager-value {
    align-self: center;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.show {
      opacity: 1;
    }
  }

  .zoom-manager-item {
    padding: 4px;
    cursor: pointer;
  }
}
