.code-snippet-wrapper {
  position: relative;
  padding: 15px 20px 15px 20px;
  background: #2b344e;
  max-height: 325px;
  border-radius: 6px;
  font-size: 14px;
  text-align: left;
  overflow: auto;

  .btn-copy-wrapper {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  pre {
    // font-family: inherit;
    font-weight: 200;
    max-height: 325px;
    overflow-y: auto;
  }
}
