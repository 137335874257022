.success-tracker-events {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  overflow-y: auto;

  &.is-empty {
    width: 100%;
    margin: 0;

    .empty-state-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto;
    }
  }

  .loader {
    margin: auto;
  }

  .new-event-btn {
    width: fit-content;
  }

  .events-table {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: white;

    .top {
      flex: 0 1 auto;
      display: grid;
      grid-template-columns: 10fr 40fr 20fr 20fr 10fr;
      padding: 16px 28px 12px 28px;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.4);
    }

    .loader-wrapper {
      display: flex;
      justify-content: center;
      padding-top: 24px;
    }
    .list {
      padding: 16px;
      flex: 1 0 auto;
      flex: 1 1 100%;
      box-sizing: border-box;

      .card-event {
        border-radius: 12px;
        padding: 12px;
        display: grid;
        grid-template-columns: 10fr 40fr 20fr 20fr 10fr;
        align-items: center;
        transition: all 300ms ease-in-out 0s;
        cursor: pointer;

        &:hover {
          background-color: #f3f7fe;

          .event-actions {
            opacity: 1;
          }
        }

        .event-icon-wrapper {
          .event-icon {
            height: 40px;
            width: 40px;
            display: flex;
            border-radius: 12px;
            margin-right: 8px;
            flex: 0 0 auto;
            border: 1px solid rgba(0, 0, 0, 0.1);
            align-items: center;
            justify-content: center;
            font-size: 24px;
          }
        }

        .event-name {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;

          .name {
            font-weight: 500;
            font-size: 16px;
            display: flex;
            align-items: center;
          }

          .description {
            color: rgba(0, 0, 0, 0.5);
          }
        }
        .event-source {
          display: flex;
          gap: 8px;
          align-items: center;

          .source-icon {
            &.is-mixpanel {
              width: 18px;
            }
            &.is-posthog {
              width: 20px;
            }
            &.is-segment {
              width: 20px;
            }
          }

          i {
            font-size: 20px;
          }
        }
        .event-actions {
          display: flex;
          justify-content: flex-end;
          opacity: 0;

          .btn {
            margin-left: 8px;
          }
        }
        .user-activity {
          display: flex;
          flex-direction: column;
        }
        .user-btn-profile {
          display: flex;
          justify-content: center;
        }
        .icon-close {
          opacity: 0.1;
        }
      }
    }
  }
}

.delete-event-modal {
  padding: 0;
  width: unset !important;

  .modal-title {
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 24px;
  }

  .content {
    padding: 24px;
    font-weight: 400;
    font-size: 18px;
  }

  .actions {
    margin-top: 0;
    gap: 8px;
    justify-content: flex-end;
  }
}
