// to prevent iframe error from showing in development
body > iframe {
  display: none;
}

.logic-view {
  height: 100%;
  width: 100%;
  position: relative;

  &.is-editing-content,
  &.is-dragging {
    &:not(.is-editing-navigation-content) {
      .react-flow__handle {
        opacity: 0;
        pointer-events: none;
      }
      .react-flow__edge {
        &.default-edge,
        &.trigger-edge {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
    .react-flow__edge:not(.node-selected) {
      &.default-edge,
      &.trigger-edge {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &.is-editing-triggers {
    .react-flow__edges {
      z-index: 2 !important;
    }
  }

  &:not(.is-editing-navigation-content) {
    .react-flow__handle.is-secondary-cta {
      opacity: 0;
      pointer-events: none;
    }
    .react-flow__edge.default-edge.secondary_cta {
      opacity: 0;
      pointer-events: none;
    }
  }

  .react-flow__node-stepNode {
    z-index: 1 !important;
  }

  .react-flow__edge {
    transition: all 0.3s ease;

    &:hover {
      .react-flow__edge-path,
      .react-flow__connection-path {
        stroke: var(--b-400) !important;
      }
    }

    &.trigger-selected,
    &.block-selected {
      .react-flow__edge-path,
      .react-flow__connection-path {
        stroke: var(--b-400) !important;
      }
    }

    .react-flow__edge-path,
    .react-flow__connection-path {
      // transition: all 0.3s ease;
      transition: none;
    }
  }

  .react-flow__edges {
    z-index: 2 !important; /* Ensure this is higher than the z-index of your card */

    &:hover {
      z-index: 2 !important;
    }
  }

  .react-flow__handle {
    transition: all 0.3s ease;
  }
  .react-flow__handle-right.source,
  .react-flow__handle-left.source {
    background: white !important;
    height: 10px;
    width: 10px;
    right: -5px;
    border: 1px solid var(--b-400);
  }

  .react-flow__handle-left.target {
    visibility: hidden;
    top: 55px;
  }
  .react-flow__node {
    &.dragging {
      z-index: 100 !important;
    }
  }
}
