.post-block-profile {
  height: 40px;
  width: 100%;

  .input-group {
    width: 100%;

    .input {
      border-left: 0 !important;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      padding-left: 0;
    }

    .label-left {
      border-top-left-radius: 12px !important;
      border-bottom-left-radius: 12px !important;
      align-items: center;

      .icon-wrapper {
        height: 20px;
        width: 20px;
        border: 2px solid #000;
        border-radius: 20px;
        display: flex;

        i {
          margin: auto;
          font-size: 10px;
          font-weight: 600;
        }
      }
    }
  }

  .preview-profile-media {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    overflow: hidden;

    img {
      width: 32px;
      height: 32px;
    }
  }

  .placeholder-empty-media {
    display: flex;
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }
}

.post-block-profile-popup-content {
  .input-group {
    width: 100%;

    .input {
      display: flex;
      width: 100%;
      padding: 8px 4px;
      align-items: flex-start;
      border-radius: 12px;
      background: rgba(138, 138, 138, 0.1);
    }
  }
}
