.chart-time-frame-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 16px;

  .time-frame-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--states-disabled-border, #e5e5e5);
    cursor: pointer;

    &:nth-child(1) {
      border-radius: 16px 0px 0px 16px;
    }

    &:nth-last-child(1) {
      border-radius: 0px 16px 16px 0px;
    }

    &.selected {
      border: 1px solid rgba(18, 96, 235, 0.6);
      background: rgba(18, 96, 235, 0.1);
    }
  }
}
