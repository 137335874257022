.attribute-evolution-step-value {
  display: flex;
  height: 50px;
  gap: 8px;

  .input-group {
    display: flex;

    .input {
      min-width: 200px;
      padding: 16px 20px;
      font-size: 18px;
      background: transparent;
      border-radius: 16px;
      border: 1px dashed rgba(0, 0, 0, 0.3);
    }
  }

  .react-datepicker-wrapper {
    display: flex;

    .react-datepicker__input-container {
      display: flex;
    }
  }
}
