@import '../../Variables';

.poke-block-label {
  display: flex;
  margin-bottom: 0px;
  animation: block-label-in $animationBlockInDuration ease-in-out
    $animationBlockInDelay both;
  &.is-animating-out {
    animation: block-label-out $animationBlockOutDuration ease-in-out both;
  }

  .label-wrapper {
    display: inline-flex;
    font-weight: 600;
    border: 1px solid transparent;
  }
}

@-webkit-keyframes block-label-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes block-label-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes block-label-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes block-label-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
