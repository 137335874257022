.s-portal {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-flow: column;
  background-color: white;

  &.push-details-open {
    display: none;
  }

  .header {
    display: flex;
    padding: 32px 26px;
    padding-bottom: 0;
    margin: 0;
    flex: 0 0 50px;
    align-items: center;

    .actions-wrapper {
      margin: auto 0;
      margin-left: auto;
      display: flex;
      gap: 12px;

      .changelog-activation {
        display: flex;
        align-items: center;
        gap: 12px;

        &.disabled {
          color: var(--n-500);
        }
      }

      .divider {
        height: 42px;
        margin: auto;
      }

      .btns-wrapper {
        display: flex;
        gap: 12px;

        .btn {
          height: max-content;
        }

        .recent-comments-trigger {
          position: relative;

          .unread-comments-indicator {
            width: 12px;
            height: 12px;
            position: absolute;
            right: -2px;
            top: -2px;
            background-color: var(--r-400);
            border-radius: 50%;
          }
        }
      }
    }
  }

  .tabs-row {
    margin: 0;
    padding: 0 32px;

    .tabs {
      width: 100%;

      .tab-list {
        margin-bottom: 0;

        .tab-list-item {
          position: relative;
          align-items: center;
          padding: 0;
          transition: all 300ms ease;

          &:hover {
            .label-wrapper {
              background-color: rgba(18, 98, 235, 0.08);
              color: #1260eb;
            }
            .pokes-count {
              .count {
                display: none;
              }
              .evolution-view-menu {
                display: flex;
              }
            }
          }
          &.tab-list-active {
            font-weight: 400;

            .label-wrapper {
              background-color: rgba(18, 98, 235, 0.08);
            }
            .pokes-count {
              color: #000000;
            }
          }

          .label-wrapper {
            padding: 8px 8px;
            margin-bottom: 4px;
            border-radius: 6px;
          }
        }
      }
    }
  }
}

.deactivate-changelog-modal {
  .modal-title {
    border-bottom: 0;
    text-align: center;
  }
  .content {
    padding: 0 24px;
    text-align: center;
  }
  .modal-footer {
    border-top: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    .btn {
      flex: 1 0 0;
      justify-content: center;
    }
  }
}

.recent-comments-dropdown-content {
  display: flex;
  width: 360px;
  background: #fff;

  /* Elevation from settings */
  box-shadow: 0px 0px 94px 0px rgba(0, 0, 0, 0.04);

  & > div {
    width: 100%;
  }
}
