.poke-block-slider {
  .rc-slider-rail {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  }
  .rc-slider-handle {
    background-color: unset;
    border: none;
    box-shadow: none !important;
    height: fit-content;
    width: fit-content;
    transform: translateY(-50%) translateX(-50%) !important;
    top: 10px;
    opacity: 1;
    font-size: 24px;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .emoji-animated-wrapper {
      height: 32px;
      width: 32px;
    }
  }
  .rc-slider-handle.slider-custom-emoji {
    position: relative;
    top: 4px;

    .slider-custom-emoji-extra-wrapper {
      position: absolute;
      top: -35px;
      transform: scale(3);
      width: 20px;
      height: 20px;

      .slider-custom-emoji-extra {
        animation: slider-custom-emoji-extra 2000ms infinite;
      }
    }
  }
  .labels-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    font-size: 16px;
  }
}

@-webkit-keyframes slider-custom-emoji-extra {
}

@keyframes slider-custom-emoji-extra {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  50% {
    transform: matrix(1, 0, 0, 1, 0, 3);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
