$colorLight: rgba(255, 255, 255, 0.1);
$colorDark: var(--n-300);

.divider {
  width: 100%;
  &:not(.has-content) {
    width: 100%;
    height: 1px;
    min-height: 1px;
    background-color: $colorLight;
  }
  &.has-content {
    display: flex;
    align-items: center;
    .content {
      padding: 0 12px;
    }
    .left-divider,
    .right-divider {
      height: 1px;
      min-height: 1px;
      flex: 1 0 auto;
      background-color: $colorLight;
    }
  }
  &.is-dark {
    &:not(.has-content) {
      background-color: $colorDark;
    }
    &.has-content {
      .left-divider,
      .right-divider {
        background-color: $colorDark;
      }
    }
  }
  &.is-vertical {
    width: 1px;
    min-width: 1px;
    height: 100%;
  }
}
