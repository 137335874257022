.settings-go-to-step {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .go-to-step-select {
    .option-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;

      .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;

        i {
          font-size: 14px;

          &.isax {
            font-size: 18px;
          }
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }
    }

    .jimo-select__value-container {
      display: flex;
      height: 46px;
      padding: 0 12px;

      .single-value {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0;

        .icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 20px;
          width: 20px;

          i {
            font-size: 14px;

            &.isax {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
