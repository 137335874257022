.builder-mode-switch {
  display: flex;
  gap: 12px;

  .btn {
    padding: 8px;
    border-radius: 12px;
    box-shadow: none;
  }
}
