.edit-tracker-drawer {
  overflow: hidden;
  margin: 8px;
  right: 0 !important;
  top: 0 !important;
  left: unset !important;
  position: absolute;
  height: calc(100% - 16px);
  width: 756px !important;
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 12px;

  .modal-title {
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 24px;
    font-family: 'Montserrat';
    margin-bottom: 0;
    min-height: 0;
    flex: 0 0 auto;
  }

  .edit-tracker-drawer-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background: #f8f8f8;
    overflow-y: auto;

    .feature-usage-event-selector-wrapper,
    .tracker-builder-steps-wrapper {
      margin: 24px;
    }
  }
}
