.segment-attributes-editor {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  &.is-empty {
    &.global-add-attribute {
      margin-left: 24px;
      margin-top: 0;
      margin-bottom: 24px;
      max-width: 250px;
    }
  }
  &.is-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
  }

  .list-attributes {
    margin-top: 16px;

    &.is-empty {
      margin-top: 0;
    }

    .divider {
      margin-bottom: 8px;
    }
  }
  .select-add-attribute {
    font-size: 18px;

    &.global-add-attribute {
      margin-left: 24px;
      margin-top: 12px;
      margin-bottom: 24px;
      max-width: 250px;
    }
  }
  .select-add-attribute,
  .select-operand {
    .jimo-select__option {
      min-height: 24px;
    }
    .jimo-select .jimo-select__value-container {
      padding: 16px;
    }
  }
  .btns-wrapper {
    display: flex;
    gap: 16px;
  }

  .dropdown-trigger {
    display: flex;

    .btn-add-filter {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 18px;

      i {
        font-size: 24px !important;
      }
    }
  }
}

.dropdown-add-segment-filter-content {
  width: 90vw;
  max-width: 1000px;

  .divider {
    margin-top: 8px;
  }

  .dropdown-content {
    animation: fade-in 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }

  .search-wrapper {
    border-bottom: 1px solid #e5e5e5;

    .input-group {
      display: flex;

      .input-main-wrapper {
        border: 0;
      }
    }
  }
  .block-categories {
    padding-bottom: 8px;

    .category-title {
      padding: 20px;
      color: rgba(0, 0, 0, 0.6);
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
    }
    .items {
      display: grid;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      padding: 0px 8px;
      gap: 8px;

      @media (max-width: 1500px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      @media (max-width: 1200px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      .block {
        padding: 12px 14px;
        &.is-disabled {
          opacity: 0.2;
          cursor: not-allowed;
        }

        &:hover {
          background-color: #1260eb0f;

          .block-content {
            background-color: unset;

            .icon-wrapper {
              background-color: unset;
            }
          }
        }

        .block-content {
          .content {
            .title {
              word-break: break-word;
            }
          }
        }

        .icon-wrapper {
          height: 24px;
          width: 24px;
          min-width: 24px;
          border-radius: 8px;

          i {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.dropdown-custom-attribute-values-content,
.dropdown-segmentio-trait-values-content {
  z-index: 99999 !important;
  border-radius: 12px;
  max-height: 300px;
  overflow-y: hidden;

  .dropdown-content {
    background: #f2f2f2 !important;
    max-height: 300px;
    overflow-y: hidden;

    .suggestions-scrollable {
      overflow-y: auto;
      max-height: 300px;

      .suggestion {
        cursor: pointer;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.7);
        padding: 16px;
      }
    }
  }
}

.dropdown-add-segment-filter-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1002 !important;
}

.icon-posthog {
  padding: 4px;
  box-sizing: border-box;
}
