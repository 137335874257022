.onboarding-progression-tracker {
  height: 100%;
  position: relative;
  z-index: 3;

  &.anim-in {
    .progression-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      z-index: 2;
      background-color: #fff;
      animation: progression-tracker-entry 500ms ease-in-out both 1000ms;
    }

    .step-item {
      animation: step-item-entry 500ms ease-in-out 1200ms both;
      &:nth-child(2) {
        animation-delay: 1400ms;
      }
      &:nth-child(3) {
        animation-delay: 1600ms;
      }
    }
  }
  &.label-exiting {
    .step-label {
      animation: step-label-leave 500ms ease-in-out both;
    }
  }
  &.label-entering {
    .step-label {
      animation: step-label-entry 500ms ease-in-out both;
    }
  }
  &.label-not-visible {
    .step-label {
      opacity: 0;
    }
  }
  .progression-line {
    position: absolute;
    left: 18px;
    width: 1px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .steps-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .step-item {
    display: flex;
    align-items: center;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    margin: 28px 0;
    color: rgba(0, 0, 0, 0.5);
    &.active {
      color: #000;
      font-size: 20px;
    }
    &:nth-child(2) {
      .step-number {
        left: 13px;
      }
    }
    &:nth-child(3) {
      .step-number {
        left: 13px;
      }
    }

    .step-number {
      position: absolute;
      left: 14px;
      color: #000;
      font-size: 16px;
    }
  }
  .circular-progress {
    margin-right: 24px;
    background-color: #fff;
    border-radius: 50%;
  }
  .circular-progress .tracking {
    stroke: #0047ff;
  }
}

@-webkit-keyframes progression-tracker-entry {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}
@keyframes progression-tracker-entry {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}
@-webkit-keyframes steps-wrapper-entry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes steps-wrapper-entry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes step-item-entry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes step-item-entry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes step-label-entry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes step-label-entry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes step-label-leave {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes step-label-leave {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
