.element-targeting-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .content-settings {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    flex: 1 auto;
    overflow-y: auto;

    .section {
      padding: 0;
    }
  }

  .footer-wrapper {
    display: flex;
    gap: 12px;
    padding: 12px;
    margin-left: auto;
  }
}
