.input-editor {
  background: white;
  border-radius: 12px;
  resize: none;
  padding: 0;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  .editor-wrapper {
    position: relative;
    border-radius: 8px;
    border: 1px solid var(--n-300);
    padding: 8px 12px;
    box-sizing: border-box;

    & > div {
      cursor: text;
      flex: 1 auto;
      min-width: 0;
      line-height: 30px;
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }

  .variable-icon-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    height: 100%;

    i {
      font-size: 20px;
      padding: 8px;
      cursor: pointer;
    }
  }
}

// Can't add any class or id to the div rendered by EmojiToolbarDropdown, so we are using the beginning
// of the generated class to target the element and remove the left offset. This is to prevent the dropdown
// to disappear in the in-app builder where the width is too small to allow a left offset
div[class^='EmojiToolbarDropdownRoot'] {
  left: unset !important;
}
