.basic-mark-toolbar {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;

  .divider {
    height: 16px;
    margin: auto 4px;
  }

  .variable-button {
    gap: 4px;

    svg {
      width: 24px;
      height: 24px;
    }

    .c-label {
      padding: 2px 8px;
      color: var(--r-400) !important;
    }
  }
}
