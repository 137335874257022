@import '../../Variables';

.poke-block-stepper {
  position: relative;
  z-index: 1;
  display: flex;
  animation: block-stepper-in $animationBlockInDuration ease-in-out
    $animationBlockInDelay both;
  &.is-animating-out {
    animation: block-stepper-out $animationBlockOutDuration ease-in-out both;
  }
  &.align-center {
    justify-content: center;
  }
  &.align-right {
    justify-content: flex-end;
  }

  .item-step {
    background-color: var(--blockStepperSecondaryColor);
    margin-right: 16px;
    transition: all 200ms ease-in-out;
    &.active {
      background-color: var(--blockStepperPrimaryColor);
    }
  }
}
.poke-block-stepper.type-dot-line {
  padding: 5px 0;

  .item-step {
    padding: 2px;
    border-radius: 4px;
    &.active {
      border-radius: 4px;
      padding: 2px 8px;
    }
  }
}
.poke-block-stepper.type-dot {
  .item-step {
    padding: 2px;
    border-radius: 4px;
  }
}
.poke-block-stepper.type-text {
  font-weight: 600;
  color: var(--blockStepperPrimaryColor);
}
.poke-block-stepper.type-line {
  padding: 5px 0;

  .stepper-line {
    flex: 1 auto;

    .stepper-line-container {
      height: 4px;
      background: var(--blockStepperSecondaryColor);
      border-radius: 50px;
      width: 100%;

      div {
        height: 4px !important;
      }
    }
  }
}

@-webkit-keyframes block-stepper-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes block-stepper-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes block-stepper-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes block-stepper-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
