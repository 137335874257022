.s-onboarding-details-step-3-1 {
  width: 670px;
  margin-left: auto;
  margin-right: auto;
  &.is-exiting {
    h1 {
      animation: onboarding-text-slide-right-out 600ms ease-in-out 500ms both;
    }
    & > p {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 300ms both;
    }
    .install-collapses-wrapper {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 100ms both;
    }
    .details-actions {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 100ms both;
    }
    .list-modes {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 100ms both;
    }
    .install-mode-extension {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 100ms both;
    }
  }

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    width: 450px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    animation: onboarding-text-slide-right 600ms ease-in-out 600ms both;
  }
  & > p {
    text-align: center;
    font-size: 16px;
    margin-bottom: 45px;
    animation: onboarding-content-slide-right 600ms ease-in-out 800ms both;
  }

  .details-actions {
    display: flex;
    margin-top: 10%;
    justify-content: center;
    animation: onboarding-content-slide-right 600ms ease-in-out 1400ms both;

    .btn-continue {
      margin-left: 24px;
    }
  }

  .install-extension-wrapper {
    p {
      text-align: center;
      margin-top: 16px;
    }

    .card-install-extension-wrapper {
      display: flex;
      flex-direction: column;
      margin: auto;
      margin-top: 62px;
      animation: onboarding-content-slide-right 600ms ease-in-out 800ms both;
      width: 440px;
      padding: 32px;
      gap: 32px;
      border-radius: 16px;
      border: 1px solid #0000001a;
      box-shadow: 0px 24px 94px 0px #00000012;
      box-sizing: border-box;

      .content {
        .list-elem {
          display: flex;
          align-items: center;
          gap: 16px;
          margin-bottom: 16px;

          i {
            color: var(--g-400);
            font-size: 18px;
          }

          .list-elem-content {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
        }
      }

      .card-install-extension-footer {
        display: flex;
        flex-direction: column;
        gap: 8px;

        a {
          .btn-install-extension {
            width: 100%;
            display: flex;
            justify-content: center;
          }
        }

        .card-install-extension-footer-text {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          font-size: 14px;

          img {
            height: 18px;
          }
        }
      }
    }

    .confetti-wrapper {
      position: absolute;
      top: -200px;
    }
    .extension-installed-wrapper {
      .extension-installed {
        font-size: 24px;
        text-align: center;
        margin: 78px 0 0 0;
        background-color: rgba($color: #159201, $alpha: 0.1);
        padding: 16px;
        border-radius: 16px;
        border: 1px solid rgba($color: #159201, $alpha: 0.4);

        small {
          font-size: 12px;
        }
        i {
          color: #159201;
          border-radius: 50%;
          border: 2px solid #159201;
          padding: 6px;
          font-size: 16px;
          margin-right: 8px;
        }
      }
    }
  }
}

.install-collapses-wrapper {
  animation: onboarding-content-slide-right 600ms ease-in-out 1200ms both;
  display: flex;
  flex-direction: column;
  gap: 24px;
  &.without-animation {
    animation: unset;
  }

  .item {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.06);
    transition: all 100ms ease-in-out;

    &:hover {
      box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.1);
    }
    &.is-expanded {
      .item-header {
        .icon-chevron-bottom {
          transform: rotate(180deg);
        }
      }
    }

    .item-header {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      transition: all 100ms ease-in-out;
      cursor: pointer;
      padding: 16px;

      .item-header-texts {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        flex: 1;
        padding-left: 16px;

        small {
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-weight: 400;
        }
      }
      .icon-chevron-bottom {
        font-size: 11px;
      }
      .icon-code,
      .icon-email {
        font-size: 22px;
      }
    }
    .item-body {
      padding: 16px;
      border-radius: 16px;
      padding: 0px 16px 16px 16px;
    }

    .item-snippet-wrapper {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 16px;

      .code-snippet-wrapper {
        background: transparent;
        overflow: none;
        padding-right: 0;
        pre {
          margin: 0;
        }
      }
    }

    .item-email-label-wrapper {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      padding: 16px;

      .input-btn-wrapper {
        display: flex;
        align-items: center;
        margin-top: 8px;

        .input-group {
          flex: 1;
          margin-right: 16px;
        }
      }
    }
  }

  .onboarding-install-tabs {
    display: flex;
    margin-bottom: 12px;
    background: rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 6px;
    gap: 6px;

    .btn {
      flex: 1 1 auto;
      margin: auto;
      background-color: transparent !important;
      box-shadow: none;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      border-color: transparent;
      color: var(--n-700) !important;
      &:hover {
        color: var(--n-800) !important;
      }

      &.selected {
        color: var(--n-800) !important;
        background-color: #fff !important;
      }
    }
  }
}
