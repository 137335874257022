.s-settings-team {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .setting-sub-nav {
    margin-top: 0;

    .tab-list {
      padding-left: 0;

      .tab-list-item {
        &:hover {
          .label-wrapper {
            background: transparent !important;
          }
        }
      }
      .tab-list-active {
        .label-wrapper {
          background: transparent;
        }
      }
    }
  }

  .team-allow-email-domain {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    pre {
      display: inline-block;
      color: var(--n-800);
      font-weight: bold;
      margin: 0;
    }
  }

  .team-invite-wrapper {
    .title {
      font-size: 18px;
      font-weight: 400;
    }
    .input-group {
      flex: 1 0 auto;
      margin-right: 12px;
    }
    .alert {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      .emoji {
        margin-right: 16px;
      }
    }
    .has-reach-limit {
      background-color: rgba(0, 0, 0, 0.01);
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 16px;
      border-radius: 6px;
      margin-top: 8px;

      i {
        font-size: 8px;
        margin-right: 8px;
      }
      .plan-name {
        text-transform: capitalize;
      }
      span {
        color: #4099f7;
        opacity: 0.9;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          text-underline-offset: 2px;
          opacity: 1;
        }
      }
    }
    .jimo-select {
      max-width: 40%;

      .jimo-select__control {
        height: 100%;
      }
    }
    .btn.is-primary {
    }
    .flex-wrapper {
      display: flex;
      justify-self: flex-end;
      margin: 12px 0;
    }
  }

  .member-list-wrapper {
    flex-direction: column;
    align-items: stretch;

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .card-title {
        flex: 1;
      }
      form {
        display: flex;
        gap: 8px;
        align-items: stretch;
      }
    }
  }

  .list-header {
    display: grid;
    margin-top: 24px;
    margin-bottom: 8px;
  }

  .list-header,
  .member-list-item {
    grid-template-columns: 40% 30% 30%;
  }

  .team-member-list {
    &.is-loading {
      display: flex;
      color: rgba(0, 0, 0, 0.3);
      align-items: center;

      .loader {
        margin-right: 18px;
      }
    }

    .member-list-item {
      display: grid;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      font-weight: 400;
      padding: 24px 0;
      & > div {
        display: flex;
        align-items: center;
      }
      &.its-you {
        .member-name {
          color: rgba(18, 96, 235, 1);
        }
      }
      &.member {
        .member-status {
          background-color: var(--g-100);
          color: var(--g-400);
        }
      }
      &.invitation {
        .member-status {
          background-color: rgba(253, 242, 230, 1);
          color: rgba(221, 127, 20, 1);
        }
      }

      .member-infos-wrapper {
        flex: 0 0 50%;

        .member-infos {
          display: flex;
          flex-direction: column;

          small {
            margin-left: 4px;
            font-size: 12px;
            opacity: 0.4;
            font-weight: 600;
          }
          .member-email {
            margin-top: 4px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.5);
          }
        }
        .member-avatar {
          width: 40px;
          height: 40px;
          font-size: 18px;
          line-height: 43px;
          background-color: rgba(240, 242, 244, 1);
          font-family: 'Montserrat', sans-serif;
          font-weight: 500;
          position: relative;
          border-radius: 50%;
          text-align: center;
          text-transform: uppercase;
          margin-right: 24px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
      }
      .member-status {
        justify-self: self-start;
        font-size: 12px;
        padding: 4px 8px;
        border-radius: 16px;
        text-transform: capitalize;
      }
      .invite-pending {
        display: inline-block;
        color: rgba(0, 0, 0, 0.3);
        margin-left: 24px;
        font-size: 12px;
      }
      .actions {
        display: flex;
        justify-content: space-between;

        .jimo-select--is-disabled {
          .jimo-select__indicators {
            padding: 0;
          }
        }
        .my-role {
          .jimo-select__indicators {
            padding: 0;
          }
        }
        .btn {
          padding: 10px;
          font-size: 16px;
          transition: background-color 100ms ease-in-out;
        }
        .btn-remove-member {
          margin-left: auto;
        }
      }
      .you {
        display: inline-block;
        color: rgba(0, 0, 0, 0.3);
        margin-left: 24px;
        font-size: 12px;
        font-style: italic;
      }
      .admin {
        display: inline-block;
        color: rgba(0, 110, 255, 0.8);
        margin-left: 8px;
        font-size: 12px;
      }
    }
  }
}
