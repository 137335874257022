@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&family=Nunito:wght@200;300;400;500;600;700&display=swap');
// @import './shared/front/icons/Icons';
@import './shared/front/scss/Animations';
@import './Layouts';
@import './Colors';
@import './Stripe';
@import './Utils';
@import './assets/iconsax/styles';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  cursor: default;
  overflow: hidden;
}
body {
  box-sizing: border-box;
  background-color: #ffffff;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: var(--n-800);
  transition: all 200ms ease-in-out;
  &[data-shaking] {
    animation: shake 200ms backwards;
  }
  &.hasOnboardingBgColor {
    background-color: #071331;
  }
  &.hasSettingsBgColor {
    background-color: #ffffff;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--n-400);
  }
}

#root,
#root > div:not(.mobile-overlay),
#root > div:not(.error-boundary):not(.app-loading) > div:not(.stage-indicator) {
  height: 100%;
  position: relative;
  overflow-x: hidden;

  .common-error-boundary {
    .body {
      text-align: center;
      align-content: center;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

/** Stage indicator */
.stage-indicator {
  position: fixed;
  top: 0;
  left: 0;
  background-color: orange;
  height: 5px;
  width: 100%;
  color: #071331;
  font-weight: 700;
  text-align: center;
  line-height: 38px;
  z-index: 1000000;
  overflow: hidden;
  transition: all ease 100ms;
  &:hover {
    height: 38px;
  }
}

.stage-not-available {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  i {
    font-size: 78px;
    margin-bottom: 14px;
  }

  .delimiter {
    width: 50px;
    flex: 0 0 1px;
    background-color: #e6e6e6;
    margin: 30px;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
