.tab-list {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  gap: 16px;

  .tab-list-item {
    display: inline-flex;
    gap: 8px;
    list-style: none;
    padding: 4px;
    font-size: 16px;
    cursor: pointer;
    color: var(--n-600);
    border-style: solid;
    border-width: 0 0 2px 0;
    border-color: transparent;
    padding-bottom: 12px;

    align-items: center;

    &:hover {
      color: rgba(0, 0, 0, 0.8);
    }

    &.tab-list-active {
      color: #1260eb;
      border-style: solid;
      border-width: 0 0 2px 0;
      border-color: #1260eb;
      font-weight: 400;

    }

    .left-icon-wrapper {
      font-size: 20px;
    }

    .text-wrapper {
      display: inline-flex;
      gap: 8px;
    }
  }

}
