.segmentio-settings-modal {
  .settings-content {
    display: flex;
    padding: 24px;

    .apikey-group {
      width: 100%;
      display: block;

      .label {
        margin-bottom: 16px;
      }

      .input-wrapper {
        display: flex;
        .apikey-input {
          flex: 1;
          margin-right: 12px;
        }
      }
    }
  }
}
