.manual-selector-editor {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border-radius: 6px;
  background-color: var(--n-100);

  .element-count {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid var(--n-300);
    border-radius: 12px;

    &.multiple {
      background: var(--o-200);
      color: var(--o-500);
    }

    &.empty {
      background: var(--r-100);
      color: var(--r-500);
    }

    &.perfect {
      background: var(--g-100);
      color: var(--g-500);
    }

    i {
      font-size: 20px;
    }
  }

  .element {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .label {
      display: flex;
      align-items: center;

      i {
        margin-left: auto;
      }
    }

    .input-group {
      height: 40px;

      .input-main-wrapper {
        overflow: hidden;

        .input {
          background: white;
        }
      }
    }

    textarea {
      resize: none;
      overflow: hidden;
    }
  }

  .switch-to-auto {
    display: flex;
    gap: 8px;
    align-items: center;
    margin: auto;
    padding: 4px;
    cursor: pointer;
  }
}

.tooltip-manual-selector-content {
  max-width: 320px;
}
