.s-users {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-flow: column;
  background-color: white;
  &.scene-imports {
    // background-color: var(--b-100);
  }

  .header {
    display: flex;
    padding: 26px;
    padding-bottom: 0;
    flex: 0 0 50px;
    align-items: center;
    background-color: #fff;

    .actions-wrapper {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  .tabs-wrapper {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 24px;
    background-color: #fff;

    .tabs {
      .tab-list {
        padding-left: 16px;
        margin-bottom: 0;
        border: none;
        margin-top: 0;

        .tab-list-item {
          position: relative;
          align-items: center;
          padding: 0;
          margin-right: 12px;
          transition: all 300ms ease;
          &.tab-list-active {
            font-weight: 400;

            .label-wrapper {
              background-color: rgba(18, 98, 235, 0.08);
            }
            .segments-count {
              color: #000000;
            }
          }
          &.tab-segments {
            margin-right: 48px;
          }

          .segments-count {
            padding: 8px;
            width: 25px;
            height: 25px;
            right: -40px;
            top: -3px;
            position: absolute;

            .count {
              align-items: center;
              justify-content: center;
              border-radius: 6px;
              font-size: 12px;
              background-color: rgba(0, 0, 0, 0.06);
              height: 100%;
            }

            .count {
              display: flex;
            }
          }
          .label-wrapper {
            padding: 8px 8px;
            margin-bottom: 4px;
            border-radius: 6px;
          }
        }
      }
    }
  }
}
