.hubspot-settings-setup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  .hubspot-match-field {
    .card-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .card-body {
      display: flex;
      gap: 24px;
      align-items: center;
      & > i {
        transform: rotate(90deg);
        font-size: 24px;
      }
    }
  }

  .hubspot-synced-fields {
    .card-header {
      display: flex;
      gap: 8px;
      align-items: center;

      .left-side {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 1;
      }
      .right-side {
        flex: 0;
      }
    }
    .card-body {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .synced-field {
        display: flex;
        align-items: center;
        gap: 24px;
        & > i {
          font-size: 24px;
        }
      }
    }
  }

  .settings-card {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &.section-coming-soon {
      opacity: 0.3;
    }

    .select-group-wrapper {
      flex: 1;
      .jimo-select {
        flex-grow: 1;
        flex-basis: 0;

        .jimo-select__control {
          border-radius: 16px;
          box-shadow: 0;
        }

        .icon-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 40px;
          margin-left: 4px;
        }

        .jimo-select__single-value,
        .jimo-select__option {
          display: flex;
          gap: 8px;
          align-items: center;
        }

        .object-type-tag {
          background: #f3f7fe;
          color: #1260eb;
          padding: 4px 8px;
          border-radius: 4px;
        }
      }
    }
  }
}

.hubspot-help-tooltip-content {
  max-width: 400px;

  .tooltip-children {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
