.modal-ignore-rate-limiting {
  max-height: 80%;
  padding: 0;
  width: 800px;
  display: flex;
  flex-direction: column;

  .modal-custom-header {
    padding: 24px 32px;
    display: flex;
    justify-content: space-between;
    flex: 0;
    .header-actions {
      display: flex;
      gap: 12px;
    }
  }
  .filter-wrapper {
    flex: 0;
    padding-top: 0;

    .input-group {
      margin: 0 32px;
    }

    .tab-list {
      padding: 0 32px;
      margin-bottom: 0;
      margin-top: 16px;
    }
  }
  .experiences-wrapper {
    flex: 1;
    padding: 24px;
    padding-top: 0;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: var(--n-50);
    padding-top: 24px;
    &.empty {
      align-items: center;
      justify-content: center;
      gap: 8px;

      .empty-list {
        max-width: 344px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        & > div {
          text-align: center;
        }

        img {
          margin-bottom: 12px;
        }
      }
    }

    .item {
      border-radius: 16px;
      border: 1px solid var(--n-300);
      padding: 20px;
      display: grid;
      grid-template-columns: 20% 40% 40%;
      align-items: center;
      background-color: #fff;
      cursor: pointer;
      transition: all 100ms ease;
      &.is-selected {
        border: 2px solid var(--b-500);
        background-color: var(--b-100);
      }

      .right-wrapper {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        .labels-wrapper {
          display: flex;
          gap: 8px;
        }
        .excluded-label {
          border-radius: 16px;
          padding: 4px 12px;
          border: 1px solid var(--n-300);
          display: inline-flex;
          align-items: center;
          flex-grow: 0;
          gap: 8px;

          i {
            font-size: 16px;
          }
        }
      }
      .status-tag {
        padding: 4px 12px;
        border-radius: 16px;
        display: inline-block;
      }
      .tag-expired {
        color: var(--p-500);
        background-color: var(--p-100);
      }
      .tag-live {
        color: var(--g-500);
        background-color: var(--g-100);
      }
      .tag-paused {
        color: var(--o-500);
        background-color: var(--o-100);
      }
      .tag-draft {
        color: var(--n-700);
        background-color: var(--n-100);
      }
    }
  }
  .actions {
    .btn.is-default {
      margin-right: 8px;
    }
  }
}
