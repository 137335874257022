.card-create-tracker {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
  max-width: 1042px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: white;
  background-image: url(./imgs/create_tracker.svg);
  background-position: right;
  background-size: contain;
  border-radius: 16px;
  background-repeat: no-repeat;
  box-shadow: 0px 24px 94px 0px #00000012;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-right: 400px;

    .card-description {
      max-width: 550px;
    }
    .actions-wrapper {
      display: flex;
      gap: 12px;
      margin-top: 8px;

      .btn {
        &.is-primary.is-default {
          &:not(.is-disabled) {
            background-color: var(--b-400);
            color: #fff;
            &:hover {
              background-color: var(--b-400);
              color: var(--n-400);
            }
            &:active {
              background-color: var(--b-400);
              color: var(--n-500);
            }
          }
        }
      }
    }
  }
}
