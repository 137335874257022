.react-datepicker__header {
  background-color: #fbfbfb;
}

.react-datepicker__day {
  width: 40px;
  line-height: 40px;
  border-radius: 8px;
  font-size: 14px;
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-evenly;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__day--keyboard-selected {
  background-color: #e6f5fd !important;
  color: #1260eb;
  font-weight: 600;
}

.react-datepicker__day--in-range,
.react-datepicker__day--selected {
  background-color: #1260eb;
  font-weight: 600;

  &:hover {
    background-color: #e6f5fd;
    color: #1260eb;
  }
}
