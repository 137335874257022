.writer-post-block-cta {
  height: 40px;
  width: 100%;

  .input-group {
    width: 100%;

    .input {
      border-left: 0 !important;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      padding-left: 0;
    }

    .label-left {
      border-top-left-radius: 12px !important;
      border-bottom-left-radius: 12px !important;
      padding: 10px !important;
      align-items: center;

      .icon-wrapper {
        height: 20px;
        width: 20px;
        border: 2px solid #000;
        border-radius: 20px;
        display: flex;

        i {
          margin: auto;
          font-size: 10px;
          font-weight: 600;
        }
      }
    }
  }
}

.cta-modal-content {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.writer-post-block-cta-dropdown-content {
  .text-input {
    width: 100%;
    .input {
      height: 40px;
      border-radius: 12px;
      background: rgba(138, 138, 138, 0.1);
    }
  }
}
