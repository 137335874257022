.theme-editor {
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 0;
  padding: 20px;

  .style-groups {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: overlay;

    .style-section-title {
      font-weight: 500;
    }

    .collapsible-style-group {
      border: 1px solid var(--n-300);
      border-radius: 16px;

      &.is-expanded {
        border: 1px solid var(--b-400);

        .collapsible-style-group-header {
          i {
            transform: rotate(90deg) !important;
          }
        }
      }

      .collapsible-style-group-header {
        display: flex;
        padding: 16px;
        cursor: pointer;

        .collapsible-style-group-title {
          margin: auto 0;
          font-family: 'Montserrat';
          font-weight: 500;
          font-size: 16px;
        }

        i {
          margin-left: auto;
          padding: 4px;
          transition: 0.3s;
        }
      }

      input {
        width: 100%;
      }

      .block-settings {
        .section {
          padding: 16px;
          padding-top: 0;

          & .section {
            padding: 0;
          }

          .section-title {
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 16px;
          }

          .section-content {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .section-item {
              display: flex;
              .section-item-title {
                margin: auto 0;
              }
              .section-item-content {
                margin-left: auto;
                width: 176px;
              }
            }
          }
        }
      }
    }
  }

  .save-button-wrapper {
    display: flex;
    padding: 0 16px;
    margin-left: auto;
    gap: 8px;
  }
}

.exit-theme-modal {
  padding: 0;
  width: unset !important;

  .modal-title {
    padding: 24px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 0;
  }

  .content {
    padding: 24px;
    font-weight: 400;
    font-size: 18px;
  }

  .actions {
    padding: 24px;
    margin-top: 0;
    gap: 8px;
    justify-content: flex-end;
  }
}
