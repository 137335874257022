.modal-plan-downgrade {
  padding: 24px 34px;
  min-height: 220px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  width: 440px;
  box-sizing: border-box;

  .modal-main {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .downgrade-title {
    text-align: left;
    margin-bottom: 8px;
  }

  .plan-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &.plan-startup {
      .plan-details {
        .plan-details-wrapper {
          color: var(--g-500);
        }
      }
    }
    &.plan-growth {
      .plan-details {
        .plan-details-wrapper {
          color: var(--o-500);
        }
      }
    }
    &.plan-scale {
      .plan-details {
        .plan-details-wrapper {
          color: var(--b-500);
        }
      }
    }

    .plan-details {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      border: 1px solid var(--n-300);
      border-radius: 12px;
      align-items: center;

      .left-side {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .plan-details-wrapper {
        display: flex;
        gap: 4px;
        text-transform: capitalize;
        flex-direction: column;
      }
    }
  }

  .downgrade-description {
    margin: 8px 0;
  }

  .actions-wrapper {
    display: flex;
    gap: 20px;
    align-self: stretch;

    .btn {
      flex: 1;
      justify-content: center;
    }
  }
}
