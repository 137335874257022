.s-onboarding-details-step-2-1 {
  width: 546px;
  margin-left: auto;
  margin-right: auto;
  &.is-exiting {
    h1 {
      animation: onboarding-text-slide-right-out 600ms ease-in-out 500ms both;
    }
    p {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 300ms both;
    }
    .icon-container {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 300ms both;
    }
    form {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 100ms both;
    }
  }

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    width: 450px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    animation: onboarding-text-slide-right 600ms ease-in-out 600ms both;
    margin-bottom: 0;
    margin-top: 0;
  }
  p {
    text-align: center;
    font-size: 16px;
    margin-bottom: 24px;
    animation: onboarding-content-slide-right 600ms ease-in-out 800ms both;
  }

  .icon-container {
    display: flex;
    justify-content: center;

    .no-favicon {
      width: 54px;
      height: 54px;
      padding: 16px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.05);
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.3);
      transition: all 200ms ease-in-out;
      &:hover {
        border: 1px solid rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 1);
      }

      i {
        font-size: 38px;
      }
    }
  }

  .icon-wrapper {
    display: inline-flex;
    position: relative;
    animation: onboarding-content-slide-right 600ms ease-in-out 1000ms both;

    &.is-loading {
      padding: 32px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.05);
      border-radius: 16px;
    }
    .btn {
      position: absolute;
      top: -10px;
      right: -10px;
      padding: 4px;
      border-radius: 6px;
      i {
        font-size: 12px !important;
      }
    }
    img {
      display: block;
      padding: 16px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.05);
      border-radius: 16px;
    }
    margin-bottom: 32px;
  }

  form {
    color: #071331;
    animation: onboarding-content-slide-right 600ms ease-in-out 1200ms both;

    .form-label {
      margin-bottom: 16px;
      margin-top: 32px;
      font-weight: 500;
    }

    .inputs-wrapper {
      display: flex;

      .input-group {
        flex-grow: 1;

        &.dashed {
          input {
            border-style: dashed;
          }
        }
        input {
          border-radius: 12px;
          &:focus {
            box-shadow: inset 0 0 0 2px #e9effd;
            border-color: #88abff;
          }
        }
      }
    }

    .btn {
      margin-top: 10%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
