.settings-run-js-code {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .settings-run-js-code-content {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .editor-wrapper {
      background-color: #f5f5f5;
      border-radius: 12px;
      border: 1px solid var(--n-300);
      overflow: hidden;
      min-height: 160px;
      display: flex;

      & > div {
        flex: 1 auto;
        min-height: 0;
      }

      textarea:focus {
        outline: none; /* Remove the default blue outline */
      }
    }
  }

  .settings-run-js-code-alert {
    border: 1px solid var(--n-300);
  }
}
