.modal-publish-success-with-post {
  .confetti-wrapper {
    position: absolute;
    top: -100px;
    z-index: -1;
  }

  .content {
    padding: 24px;
    font-size: 16px;
  }
}
