.modal-create-theme {
  max-height: 700px;
  width: 560px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;

  .modal-title {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid var(--n-300);
    font-weight: 600;
    font-size: 20px;
    font-family: 'Montserrat';
    margin-bottom: 0;
  }

  .inputs-wrapper {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    gap: 12px;

    .alert {
      margin-bottom: 12px;
    }
  }

  .actions {
    padding: 16px;
    margin: 0;
    justify-content: flex-end;
    border-top: 1px solid var(--n-300);
    display: flex;
    gap: 12px;

    .btn {
      margin: 0;
    }
  }
}
