.tracker-milestone-overview {
  background-color: #ffffff;
  flex: auto;

  .loader {
    margin: auto;
  }
  .block-header {
    display: flex;

    .title {
      display: flex;
      gap: 8px;
      align-items: center;
      color: var(--content-primary, #071331);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 30px */
    }

    .actions {
      margin-left: auto;

      .chart-type-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-radius: 16px;

        .chart-type {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          display: flex;
          padding: 8px 12px;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--states-disabled-border, #e5e5e5);
          cursor: pointer;

          &:nth-child(1) {
            border-radius: 16px 0px 0px 16px;
          }

          &:nth-last-child(1) {
            border-radius: 0px 16px 16px 0px;
          }

          &.selected {
            border: 1px solid rgba(18, 96, 235, 0.6);
            background: rgba(18, 96, 235, 0.1);
          }
        }
      }
    }
  }

  .block-content {
    display: flex;
    min-height: 0;

    .tracker-steps-activity-wrapper {
      min-width: 0;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .right-statistics-wrapper {
      min-width: 0;
      display: flex;
      gap: 24px;
    }
  }
}
