.dropdown-add-block-content {
  .search-wrapper {
    border-bottom: 1px solid #e5e5e5;

    .input-group.labeled {
      display: flex;
      .label {
        border-radius: 0;
      }

      input {
        font-size: 14px;
        border-radius: 0;
        padding-left: 0 !important;
      }
    }
  }
  .block-categories {
    padding-bottom: 8px;

    .category-title {
      padding: 20px 20px 0 20px;
    }
    .items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 0px 8px;

      .block {
        padding: 12px 14px;
      }
    }
  }
}
