.media-embed-wrapper {
  .media-embed {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
  }

  .actions {
    padding: 12px;
    display: flex;
    gap: 12px;
    justify-content: end;

    .btn-cancel {
      box-shadow: none !important;
      border: 0 !important;
    }

    .btn-add {
      background: #f3f7fe;
      color: #1260eb;
      border: 0 !important;
    }
  }
}
