@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap');

.event-properties {
  display: flex;
  max-width: 1042px;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 24px 94px 0px rgba(0, 0, 0, 0.07);

  .event-properties-title {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
  }

  .event-properties-table {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: white;

    .top {
      flex: 0 1 auto;
      display: grid;
      grid-template-columns: 40fr 60fr;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.4);
    }

    .loader-wrapper {
      display: flex;
      justify-content: center;
      padding-top: 24px;
    }
    .list {
      flex: 1 0 auto;
      flex: 1 1 100%;
      box-sizing: border-box;

      .card-property {
        border-radius: 12px;
        padding: 12px 0;
        display: grid;
        grid-template-columns: 40fr 60fr;
        align-items: center;
        transition: all 300ms ease-in-out 0s;

        .property-name {
          display: flex;
          padding: 4px 8px;
          border-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          background-color: var(--n-100);
          width: fit-content;

          .name {
            font-weight: 700;
            font-size: 14px;
            display: flex;
            align-items: center;
            font-family: 'Inconsolata', monospace;
          }
        }
      }
    }
  }
}
