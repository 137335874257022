.language-selector {
  display: flex;
  overflow: auto;
  border-bottom: 1px solid #e5e5e5;
  flex-shrink: 0;

  .language-list {
    display: flex;
    padding: 0 24px 0 18px;

    .language {
      font-size: 16px;
      padding: 18px 0;
      cursor: pointer;
      text-transform: capitalize;
      border-bottom: 1px solid transparent;
      transition: all ease-in-out 100ms;
      &:not(:first-child) {
        margin-left: 24px;
      }
      &:not(.active):hover {
        color: #1260eb;
      }
      &.active {
        color: #1260eb;
        border-bottom: 2px solid #1260eb;
      }

      i {
        font-size: 10px;
        margin-left: 16px;
        margin-bottom: 5px;
      }
    }
  }
  .dropdown-trigger {
    align-self: center;

    .add-translation-btn {
      white-space: nowrap;
    }
  }
}
.dropdown-add-languages-content {
  z-index: 8000 !important;

  .dropdown-content {
    .menu {
      max-height: 400px;
      overflow-y: auto;

      .menu-item {
        font-size: 18px;
        padding: 12px 24px;
        text-transform: capitalize;

        i {
          font-size: 12px;
          border-radius: 3px;
          margin-top: 4px;
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(90, 90, 90);
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.delete-language-modal {
  padding: 0;
  width: unset !important;

  .modal-title {
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 24px;
  }

  .content {
    padding: 24px;
    font-weight: 400;
    font-size: 18px;
  }

  .actions {
    padding: 24px;
    margin-top: 0;
    gap: 8px;
    justify-content: flex-end;
  }
}
