.event-conditions-empty-state {
  display: flex;
  gap: 24px;
  align-self: stretch;

  .option {
    flex: 1 1 0;
    display: flex;
    padding: 8px 12px;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid var(--n-100);
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      border: 1px solid var(--b-400);
      box-shadow: 0px 0px 0px 4px #1260eb29;
    }

    &.element {
      .icon-wrapper {
        background-color: var(--g-100);
      }
    }

    &.input {
      .icon-wrapper {
        background-color: var(--p-200);
      }
    }

    &.url {
      .icon-wrapper {
        background-color: var(--b-150);
      }
    }

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      margin: auto 0;
      flex-shrink: 0;

      i {
        font-size: 16px;

        &.isax {
          font-size: 20px;
        }
      }
    }
  }
}
