.dropdown-content > .dropdown-content {
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);

  .menu {
    padding: 4px;
    padding: 4px;

    .menu-item {
      padding: 8px;
      border-radius: 8px;
    }
  }
  .dp-menu {
    .dp-menu-item {
      padding: 8px 12px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      transition: all 100ms ease;
      cursor: pointer;
      &:hover {
        background-color: var(--b-100);
      }
      &.danger:hover {
        background-color: var(--r-100);
      }

      i {
        margin-right: 8px;
        &.isax {
          font-size: 18px;
        }
      }
    }
  }
}

.dropdown-with-overlay-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
