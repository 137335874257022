.s-onboarding-details-step-2-2 {
  width: 100%;
  height: 100%;
  display: grid !important;
  grid-template-columns: 60% 40% !important;

  &.is-exiting {
    h1 {
      animation: onboarding-text-slide-right-out 600ms ease-in-out 500ms both;
    }
    p {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 300ms both;
    }
    .details-main-wrapper {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 300ms both;
    }
    .details-illustration-wrapper {
      animation: details-illustration-wrapper-out 600ms ease-in-out 100ms both;
    }
  }
  .usage-category-wrapper,
  .usage-category-label {
    animation: onboarding-content-slide-right 600ms ease-in-out 1400ms both;
  }

  .details-main-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 24px;

    .usage-category-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 750px;

      .usage-item {
        display: flex;
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding: 16px;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        margin-bottom: 16px;
        transition: all 100ms ease-in-out;
        &:nth-child(odd) {
          margin-right: 8px;
        }
        &:nth-child(even) {
          margin-left: 8px;
        }
        &:hover {
          border-color: rgba(#1260eb, 0.5);
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
        }
        &.active {
          border-color: rgba(#1260eb, 0.5);
          background-color: rgba(#1260eb, 0.05);
          color: #1260eb;

          i,
          small {
            color: #000;
          }
          &.usage-1 {
            .usage-icon-wrapper {
              background-color: #e6fde8;
            }
          }
        }

        .usage-icon-wrapper {
          padding: 8px;
          background-color: #f1f1f1;
          border-radius: 12px;
          margin-right: 12px;
          display: flex;
          align-items: center;

          i {
            font-size: 24px;
          }
        }
        .usage-content {
          display: flex;
          flex-direction: column;

          small {
            margin-top: 4px;
            font-size: 12px;
          }
        }
      }
    }
  }
  .usage-category-label {
    margin-bottom: 8px;
    font-weight: 500;
  }

  .details-illustration-wrapper {
    background-color: #fef1e9;
    padding: 44px;
    display: flex;
    align-items: center;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    animation: details-illustration-wrapper-in 600ms ease-in-out 800ms both;
    & > div,
    & > video {
      width: 100%;
      animation: details-illu-in 500ms ease-in-out both;
    }
  }

  .btn-continue {
    align-self: flex-start;
    animation: onboarding-content-slide-right 600ms ease-in-out 1900ms both;
    margin-top: 75px;
  }

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    width: 450px;
    margin-bottom: 0;
    animation: onboarding-text-slide-right 600ms ease-in-out 600ms both;
  }
  p {
    margin-top: 12px;
    font-size: 16px;
    margin-bottom: 75px;
    animation: onboarding-content-slide-right 600ms ease-in-out 800ms both;
  }
}

@-webkit-keyframes details-illu-in {
  0% {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
@keyframes details-illu-in {
  0% {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
@-webkit-keyframes details-illustration-wrapper-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes details-illustration-wrapper-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes details-illustration-wrapper-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes details-illustration-wrapper-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
