.s-onboarding-register {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 337px;
  animation: onboarding-register-entry 500ms ease-in-out 800ms both;

  h1 {
    font-size: 24px;
    font-family: 'Montserrat', serif;
    font-weight: 500;
    text-align: center;
    margin: 42px 0 75px 0;

    span {
      color: #1260eb;
    }
  }

  .alert {
    margin-bottom: 24px;
    animation: fade-in 200ms ease-in-out;
    a {
      color: #1260eb;
      font-weight: 500;
      margin-top: 24px;
      &:hover {
        color: #4084f9;
      }
    }
  }

  .btn-sign-up {
    margin-top: 75px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .divider-wrapper {
    width: 30%;
    color: rgba(0, 0, 0, 0.16);
    margin-top: 12px;
  }

  form {
    margin-top: 12px;
    width: 100%;

    .email-label {
      margin: 8px 0;
    }
    .input-group input {
      border-radius: 12px;
      padding: 17px 20px;
      font-size: 16px;
      &:focus {
        box-shadow: inset 0 0 0 2px #e9effd;
        border-color: #88abff;
      }
    }
  }

  a {
    color: #1260eb;
    font-weight: 500;
    margin-top: 24px;
    &:hover {
      color: #4084f9;
    }
  }

  .tos {
    position: fixed;
    bottom: 24px;
    max-width: 324px;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;

    a {
      color: rgba(0, 0, 0, 0.6);
      &:hover {
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
  }
}

.s-onboarding-register.is-exiting {
  animation: onboarding-register-leave 500ms ease-in-out forwards;
}

@-webkit-keyframes onboarding-register-entry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes onboarding-register-entry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes onboarding-register-leave {
  0% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  100% {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 50, 0);
  }
}
@keyframes onboarding-register-leave {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
