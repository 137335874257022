.tracker-builder-steps-wrapper {
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 5px 60px 0px rgba(0, 0, 0, 0.08);
  margin: 0 12px;

  .tracker-builder-steps-header {
    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    .tracker-builder-steps-title {
      color: var(--content-primary, #071331);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 30px */
    }

    .tracker-builder-steps-description {
      color: #000;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
    }
  }

  .tracker-builder-steps {
    align-self: stretch;
    overflow-y: auto;
    flex: 1 1 auto;
    margin-bottom: -12px;

    .tracker-steps {
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      flex: 1 auto;
      overflow-y: auto;

      .tracker-step {
        border: 1px solid var(--n-300);
        border-radius: 12px;
        display: flex;
        padding: 0;
        flex-direction: column;
        align-items: center;
        border-radius: 16px;
        background: var(--surface-primary, #fff);
        overflow: hidden;

        &.is-selected {
          .step-events-info {
            i {
              transform: rotate(180deg);
            }
          }
        }

        .step-header {
          padding: 12px 16px;
          height: 75px;
          width: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          gap: 12px;
          box-sizing: border-box;

          .step-index {
            display: flex;
            width: 32px;
            height: 32px;
            padding: 6px 0px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 69px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            box-sizing: border-box;
            color: var(--content-primary, #071331);
            text-align: center;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 125%; /* 20px */
            background: var(--surface-primary, #fff);
            text-align: center;
          }

          .step-name {
            .step-name-input {
              flex: 1 auto;

              .input-main-wrapper {
                border: 0;

                .input {
                  font-size: 20px;
                  font-weight: 500;
                  border: 0;
                  display: flex;
                  padding: 4px 8px;
                  align-items: center;
                  gap: 10px;
                  border-radius: 8px;
                  background: #f5f5f5;
                }
              }
            }
          }

          .step-events-info {
            margin-left: auto;
            display: flex;
            gap: 8px;
            align-items: center;
            color: #9ca1ad;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;

            i {
              transition: all 200ms ease-in-out;
              color: #4d637b;
            }
          }

          .add-event-trigger {
            display: flex;
            padding: 12px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 12px;
            background: var(--surface-accent-secondary-brand, #f3f7fe);
            margin-left: auto;
            color: var(--content-accent-brand, #1260eb);
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 125%; /* 20px */
          }
        }

        .collapsable-content-wrapper {
          width: 100%;

          & > div {
            width: 100%;
            display: flex;
            flex-direction: column;
          }

          .step-events {
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex: 1 auto;
            width: 100%;
            padding: 12px;
            box-sizing: border-box;

            .tracker-event-wrapper {
              display: flex;
              gap: 8px;
              align-items: center;

              .dropdown-trigger {
                flex: 1 auto;
              }

              .or-label {
                color: #4d637b;
                font-family: Inter;
                font-size: 12px;
                font-weight: 400;
              }

              .tracker-event {
                position: relative;
                display: flex;
                padding: 8px 12px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 12px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                background: #fff;
                width: 100%;
                justify-content: flex-start;
                cursor: pointer;
                box-sizing: border-box;

                &.selected,
                &:hover {
                  box-shadow: 0 0 2px 0 rgba(18, 96, 235, 0.4);
                  border-color: #85b1ff;
                }

                .segment-icon-wrapper {
                  height: 40px;
                  width: 40px;
                  display: flex;
                  border-radius: 12px;
                  background: var(--purple-400-main, #bb68ff);

                  i {
                    margin: auto;
                    font-size: 24px;
                  }
                }

                .event-icon-wrapper {
                  height: 40px;
                  width: 40px;
                  display: flex;
                  border-radius: 12px;
                  font-size: 24px;
                  align-items: center;
                  justify-content: center;
                  border: 1px solid var(--n-300);
                }

                .event-info-wrapper {
                  display: flex;
                  flex-direction: column;
                  height: 32px;

                  .event-name {
                    margin: auto;
                    overflow: hidden;
                    color: var(--content-primary, #071331);
                    text-overflow: ellipsis;
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%; /* 24px */
                  }

                  .event-type {
                    color: #9ca1ad;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    flex-shrink: 0;
                  }

                  .event-info {
                    color: #071331;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                  }
                }

                .event-actions {
                  position: absolute;
                  display: flex;
                  gap: 12px;
                  right: 12px;

                  i {
                    color: #9ca1ad;
                    font-size: 12px;
                  }
                }
              }
            }
          }

          .add-event-btn {
            padding: 12px;
            box-sizing: border-box;
            margin-right: auto;
            display: flex;
            gap: 4px;
            color: #1260eb;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 600;
            align-items: center;
            cursor: pointer;
          }
        }

        .on-the-fly-event {
          display: flex;
          flex-direction: column;
          padding: 24px;
          gap: 16px;

          .remove-event-btn {
            width: fit-content;
          }
        }

        .step-events {
          padding: 24px;
          gap: 16px;
        }

        .add-event-btn {
          padding: 24px;
          font-size: 16px;
          gap: 8px;
        }

        .trigger-delete-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          padding: 12px 0;
          width: 100%;
          cursor: pointer;

          &:hover {
            background: var(--r-100);
          }
        }
      }
    }

    .add-step-btn {
      margin-right: auto;
    }
  }
}
