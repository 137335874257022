.s-switch {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &.has-project-not-found {
    align-items: center;
    .btn {
      margin-top: 24px;
    }
  }

  .loader {
    margin-bottom: 24px;
  }
  .highlight {
    color: rgb(18, 96, 235);
  }
}
