.date-selection-content {
  min-width: 158px;
  background: #ffffff;
  box-shadow: 0px 14px 19px rgb(0 0 0 / 3%);
  border-radius: 8px;
  margin: 4px auto;

  .date-list {
    max-height: 200px;
    padding: 4px;
    overflow-y: auto;

    .date-item {
      display: flex;
      align-items: center;
      padding: 8px;
      font-weight: 400;
      font-size: 14px;
      border-radius: 8px;
      font-family: 'Montserrat';
      font-weight: 500;
      cursor: pointer;
      &:hover {
        background-color: #1260eb0f;
      }
      &.is-set {
        color: #1260eb;
      }

      i {
        margin-right: 8px;
        font-size: 14px;
      }
    }
  }
}
