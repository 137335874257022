.intercom-settings-setup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  .alert {
    width: 100%;
    box-sizing: border-box;
  }

  .settings-card {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
