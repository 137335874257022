.block-settings.navigation {
  .section-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    .subtitle {
      font-weight: 400;
    }
  }

  .navigation-option {
    padding: 12px 16px;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--n-300, #e5e5e5);
    display: flex;
    gap: 12px;
    cursor: pointer;

    &.selected {
      border: 1px solid var(--b-400, #1260eb);
      background: var(--b-100, #f3f7fe);

      .navigation-option-label {
        color: var(--b-400, #1260eb);
      }

      .navigation-option-icon {
        i {
          opacity: 1;
        }
      }
    }

    .navigation-option-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }

    .navigation-option-icon {
      i {
        font-size: 20px;
        opacity: 0;
      }
    }
  }

  .navigation-redirect-input {
    height: 40px;
    width: 100%;
    display: inline-flex;

    .input {
      flex: 1 auto;
      border-radius: 12px;
      padding: 15px 12px;
    }
  }

  .url-tester-wrapper {
    position: relative;
    background: #f4f4f4;
    border-radius: 24px;
    padding: 16px;

    &.invalid-url {
      background: #fde6e6;

      .url-tester-text {
        color: #c9242d;
      }
    }
    &.url-no-match {
      background: #fff8e6;
      .url-tester-text {
        color: orange;
      }
    }
    &.url-match {
      background: #e6fde8;

      .url-tester-text {
        color: #13805c;
      }
    }

    .url-tester-title {
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 8px;
    }

    .url-tester {
      display: flex;

      .icon-wrapper {
        display: flex;
        transform: rotate(180deg);
        margin-right: 8px;

        i {
          margin: auto;
        }
      }

      .url-tester-input {
        flex: 1 1 auto;

        .input {
          font-size: 14px;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 12px;
        }
      }
    }

    .url-tester-text {
      margin-top: 8px;
    }
  }
}

.rules-popup-setting-content {
  width: 560px !important;
}
