.s-settings-integrations {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;

  .settings-title {
    .divider {
      margin: 32px 0 0 0;
    }
  }
  .integrations-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  }
  .settings-card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    transition: all 300ms ease;
    &:hover {
      box-shadow: 0px 0px 0px 0px transparent;
      .logo-action-wrapper {
        .btn {
          opacity: 1;
        }
      }
    }

    .logo-action-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 40px;
        height: 40px;
      }
      .btn {
        opacity: 0;
        transition: all 100ms ease;
      }
    }
    .integration-details-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .integration-title {
        display: flex;
        gap: 8px;
        align-items: center;

        .label-connected {
          display: flex;
          align-items: center;
          font-weight: 500;
          gap: 4px;
          padding: 4px 8px;
          background-color: var(--g-100);
          color: var(--g-500);
          border-radius: 16px;
        }
      }
    }
  }
}
