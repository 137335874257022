.segment-users-wrapper {
  display: flex;
  max-width: 1042px;
  padding: 24px;
  margin: 0 auto 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 24px 94px 0px rgba(0, 0, 0, 0.07);
  position: relative;

  &.requires-lookup {
    .segment-users-content {
      opacity: 0.2;
      pointer-events: none;
    }

    .segment-users-refresh {
      display: block;
    }
  }

  .segment-users-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .segment-users-title {
      color: #000;
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
    }

    .segment-loader {
      flex: 1;
      margin: auto;
      text-align: center;

      .loader {
        margin: 20px auto;
      }
    }

    .users-title {
      margin: 20px;
      display: flex;
      font-weight: 600;
      font-size: 20px;
      align-items: center;

      .icon-wrapper {
        margin-right: 12px;
        width: 36px;
        height: 36px;
        background: #071331;
        border-radius: 33px;
        display: flex;

        i {
          color: white;
          margin: auto;
          font-size: 14px;
        }
      }
    }

    .users-list-users {
      width: 100%;

      .top {
        border-top: none;
      }
    }

    .users-pagination {
      justify-content: center;
    }
  }

  .segment-users-refresh {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
}
