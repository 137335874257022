.poke-layout-concept {
  display: flex;
  padding: 24px;
  height: 100%;
  position: relative;
  // &.in-builder {
  //   padding: 0;
  //   width: 100%;

  //   .concept-prototype {
  //     display: none;
  //   }
  //   .concept-sidebar {
  //     margin-left: 0;
  //     width: 100%;
  //   }
  // }
  &.is-exiting {
    animation: layout-concept-out 500ms ease-in-out forwards;
  }

  .concept-prototype {
    width: 70%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.9);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin-right: 12px;
    border-radius: 12px;
    overflow: hidden;
  }
  .concept-sidebar {
    position: relative;
    width: 30%;
    height: auto;
    margin-left: 12px;
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #e6e6e6;
    align-self: flex-end;
    box-shadow: 0px 4px 10px 10px rgb(0 0 0 / 6%);
    overflow: hidden;

    &.in-builder {
      cursor: pointer;
    }

    .poke-layout-bottom {
      margin-bottom: 24px;
    }

    .poke-block-gradient.is-classic {
      border-radius: 12px;
    }
  }
  .concept-iframe-figma {
    // 101% because having weird behavior on 100% where it doesn't take the whole width for some reason
    width: 101%;
    height: 101%;
  }

  .concept-close {
    position: absolute;
    top: 32px;
    right: 24px;
    z-index: 1;

    i {
      padding: 12px;
      border-radius: 12px;
      cursor: pointer;
      transition: all 100ms ease-in-out;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
      &:active {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}

@-webkit-keyframes layout-concept-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes layout-concept-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
