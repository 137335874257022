.post-thumbnail {
  display: flex;
  gap: 16px;
  align-items: center;

  padding: 12px 16px;
  border-radius: 12px;
  border: 1px solid var(--n-300);
  box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.04);

  .logo-section {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--b-100);
    border-radius: 8px;

    i {
      font-size: 24px;
    }

    img {
      width: 44px;
      height: 44px;
      border-radius: 8px;
    }
  }

  .text-section {
    flex: 1;

    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;

    color: var(--n-600);

    .title {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.5px;
      text-align: left;
      display: flex;
      gap: 4px;

      text {
        color: var(--n-800);
      }

      span {
        color: var(--n-600);
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    gap: 12px;
  }

  .btn {
    i {
      font-size: 20px;
    }
  }
}
