.section-item.banner-alignment-item {
  .banner-alignment-select {
    .jimo-select__menu {
      width: 176px;
    }

    .jimo-select__single-value,
    .jimo-select__option {
      display: inline-flex;
      align-items: center;
      gap: 8px;
    }

    .jimo-select__single-value {
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
