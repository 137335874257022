.pokes-empty-state-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--surface-primary, #fff);
  box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 1042px;
  margin: auto;
  overflow: hidden;

  .section {
    align-self: stretch;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .section-title {
        color: #000;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; /* 20px */
      }

      .templates-navigator {
        display: flex;
        margin-left: auto;
        gap: 8px;

        .btn {
          height: 40px;
          width: 40px;
          display: flex;
          padding: 0;
          border-radius: 12px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          background: #fff;
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.06);

          i {
            margin: auto;
          }
        }
      }
    }

    .section-content {
      align-self: stretch;
    }

    &.arcade-wrapper {
      background: #fafafa;
      height: 450px;
      background-size: 150% 120%;

      .section-content {
        overflow: hidden;

        & > div {
          display: flex;
        }

        .arcade-iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          color-scheme: light;

          &.active {
            z-index: 1001;
            position: fixed;
            height: 80%;
            width: 80%;
            max-width: 1200px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .arcade-overlay-close {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1000;
          background: rgba(0, 0, 0, 0.4);

          i {
            position: absolute;
            top: 24px;
            right: 24px;
            font-size: 24px;
            color: #fff;
            cursor: pointer;
          }
        }

        .arcade-overlay {
          background: rgba(0, 0, 0, 0.3);
          z-index: 1;
          border-radius: 8px;
          cursor: pointer;
          display: flex;

          .arcade-overlay-content {
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: center;
            margin: auto;

            .icon-wrapper {
              height: 72px;
              width: 72px;
              display: flex;
              color: #fff;
              background: #00000066;
              border-radius: 48px;

              i {
                font-size: 24px;
                margin: auto;
              }
            }

            .title {
              display: inline-flex;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              border-radius: 24px;
              background: rgba(0, 0, 0, 0.4);
              color: #fff;
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
        }
      }
    }

    &.create-wrapper {
      .section-content {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;

        .create-from-scratch {
          flex: 0 0 auto;
          cursor: pointer;
          display: flex;
          height: 206px;
          justify-content: center;
          align-items: center;
          width: 232px;
          border-radius: 16px;
          background: var(--surface-secondary, #f0f2f4);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 12px;

          .icon-wrapper {
            height: 56px;
            width: 56px;
            display: flex;
            align-items: flex-start;
            gap: 8px;
            border-radius: 24px;
            background: var(--surface-primary-inverted, #071331);

            i {
              margin: auto;
              font-size: 24px;
              color: #fff;
            }
          }

          .title {
            display: flex;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 24px;
            background: #071331;
            color: #fff;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        .templates-list {
          display: flex;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;
          overflow-x: auto;
          scroll-behavior: smooth;
          -ms-overflow-style: none;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }

          .template-card {
            cursor: pointer;
            display: flex;
            width: 232px;
            height: 206px;
            flex-direction: column;
            align-items: flex-start;
            flex: 0 0 auto;
            border-radius: 12px;
            border: 1px solid rgba(0, 0, 0, 0.08);
            background: var(--surface-primary, #fff);
            overflow: hidden;

            .template-header {
              height: 120px;
              align-self: stretch;
              overflow: hidden;
              flex: 0 0 auto;

              .img-wrapper {
                display: flex;
                flex: 1 1 auto;
                min-height: 0px;

                img {
                  margin: auto;
                  position: relative;
                  z-index: 1;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }

            .content {
              display: flex;
              padding: 16px;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;

              .title {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                align-self: stretch;
                overflow: hidden;
                color: var(--content-primary, #071331);
                text-overflow: ellipsis;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 125%; /* 17.5px */
              }

              .description {
                color: #000;
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
}
