.install-jimo {
  width: 610px;

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    width: 450px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    animation: onboarding-text-slide-right 600ms ease-in-out 600ms both;
  }

  p {
    text-align: center;
    font-size: 16px;
    margin-bottom: 45px;
    animation: onboarding-content-slide-right 600ms ease-in-out 800ms both;
  }

  .mode-text {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 24px;
  }

  .choose-mode {
    .list-modes {
      display: flex;
      justify-content: space-between;
      gap: 24px;
      animation: onboarding-content-slide-right 600ms ease-in-out 800ms both;
      margin-bottom: 24px;

      .item-mode {
        flex: 1;
        max-width: 292px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.06);
        border-radius: 16px;
        padding: 16px;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        cursor: pointer;
        &:hover {
          background-color: rgba($color: #1260eb, $alpha: 0.08);
          & > i {
            color: #1260eb;
          }
          & > h3 {
            color: #1260eb;
          }
        }
        & > i {
          display: block;
          font-size: 30px;
          margin-bottom: 16px;
        }
        h3 {
          margin: 0;
          font-weight: 500;
        }
        p {
          margin: 0;
          margin-bottom: 12px;
          text-align: start;
        }
      }
    }

    .actions {
      margin: 0;
    }
  }

  .install-mode-extension {
    animation: onboarding-content-slide-right 600ms ease-in-out 800ms both;

    p {
      text-align: center;
    }
    small {
      display: block;
      text-align: center;
      margin-top: 8px;
      margin-bottom: 124px;
    }

    .continue-btn {
      margin-left: auto;
    }
  }
  .btn-install-extension {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 18px;
    margin: 24px 0 0 0;
    i {
      font-size: 24px !important;
      color: #1260eb;
    }
  }

  .install-subtext {
    margin-top: 12px;
    text-align: center;
  }

  .confetti-wrapper {
    position: absolute;
    top: -200px;
  }
  .extension-installed {
    font-size: 24px;
    text-align: center;
    margin: 78px 0 0 0;
    background-color: rgba($color: #159201, $alpha: 0.1);
    padding: 16px;
    border-radius: 16px;
    border: 1px solid rgba($color: #159201, $alpha: 0.4);

    small {
      font-size: 12px;
    }
    i {
      color: #159201;
      border-radius: 50%;
      border: 2px solid #159201;
      padding: 6px;
      font-size: 16px;
      margin-right: 8px;
    }
  }

  .actions {
    margin: 0;
    margin-top: 24px;
  }
}
