.checklist-header {
  padding: 16px;
  border-bottom: 1px solid transparent;
  position: relative;

  &.is-minimized {
    border: none;

    &.is-float {
      padding: 0;
    }
  }
}
