.checklist-task-activity {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;

  .empty-state-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .checklist-task-activity-header {
    display: flex;
    padding: 0px 12px;
    align-items: center;
    align-self: stretch;
    display: grid;
    grid-template-columns: 5.5fr 1fr 1fr 1fr 1.5fr;
    gap: 24px;

    & > div {
      color: var(--neutral-500, var(--neutral-neutral-500, #9ca1ad));
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      display: flex;
      align-items: center;
      gap: 8px;
      text-wrap: nowrap;

      i {
        cursor: pointer;
      }
    }
  }

  .checklist-task-activity-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    overflow-y: auto;
    flex-grow: 1;

    .checklist-task-activity-item {
      position: relative;
      padding: 8px 12px;
      align-items: center;
      // gap: 24px;
      align-self: stretch;

      .checklist-task-item-content {
        display: grid;
        grid-template-columns: 5.5fr 1fr 1fr 1fr 1.5fr;
        gap: 24px;

        .checklist-task-info {
          position: relative;
          z-index: 2;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          align-self: center;

          .chart-wrapper {
            position: absolute;
            top: -12px;
            left: -12px;
            height: 100%;
            border-radius: 8px;
            background: var(--surface-accent-secondary-brand, #f3f7fe);
            z-index: -1;
            width: 50%;
            padding: 12px;
          }

          .label-hidden-wrapper {
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
          }
        }

        .checklist-task-clicks,
        .checklist-task-skipped,
        .checklist-task-completed,
        .checklist-task-completion-rate {
          justify-self: right;
          align-items: center;
          align-self: center;
        }
      }
    }
  }
}
