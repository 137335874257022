.block-settings.slider {
  .clickable-input {
    .input {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .emoji-icon-wrapper {
      display: flex;

      .emoji-icon {
        margin: auto 4px;
        width: 32px;
        height: 32px;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
      }
    }
  }
}

.emoji-picker-dropdown-content {
  .dropdown-content {
    background-color: transparent;
    border: none;
    overflow: unset;
  }
}
