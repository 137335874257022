.modal-addon-checkout {
  display: flex;
  flex-direction: column;
  width: 800px;
  padding: 24px;
  box-sizing: border-box;
  gap: 24px;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal-content {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  .left-side,
  .right-side {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .subscription-info {
    border: 1px solid var(--n-300);
    border-radius: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .card-brand {
      text-transform: capitalize;
    }

    .plan-name {
      text-transform: capitalize;
    }
  }

  .proration-resume {
    border: 1px solid var(--n-300);
    border-radius: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &.loading {
      align-items: center;
      justify-content: center;
      min-height: 144px;
      box-sizing: border-box;
    }
    &.centered {
      text-align: center;
    }

    .item-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      animation: fade-in 1000ms ease;
      &.line-subtotal {
        border-top: 1px solid var(--n-300);
        padding-top: 20px;
      }
      .title-wrapper {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .total-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
  }

  .proration-description {
    text-align: justify;
  }

  .btn-addon-checkout {
    justify-content: center;
  }
}
