.changelog-block-editor-wrapper {
  height: 100%;
  background: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 1;

  .block-settings {
    .section {
      padding: 16px;

      .section-title {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 16px;
      }

      .section-content {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .section-item {
          display: flex;
          .section-item-title {
            margin: auto 0;
          }
          .section-item-content {
            margin-left: auto;
            width: 176px;

            .jimo-select__control {
              border-radius: 12px;
              height: 40px;
              .jimo-select__value-container {
                padding: 0 12px;
              }
            }
          }
        }

        .section-item-toggle {
          display: flex;
          align-items: center;
          gap: 24px;
          align-self: stretch;

          .section-item-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            flex: 1 0 0;
          }
        }
      }
    }
  }
}
