.users-list-users {
  height: 100%;
  display: flex;
  flex-direction: column;

  .top {
    flex: 0 1 auto;
    display: grid;
    grid-template-columns: 10fr 25fr 25fr 17fr 17fr 6fr;
    padding: 16px 12px 12px 12px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--n-500);
    gap: 8px;
  }

  .loader-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 24px;
  }
  .list {
    flex: 1 0 auto;
    overflow: auto;
    flex: 1 1 100%;
    box-sizing: border-box;

    .card-user {
      border-radius: 12px;
      padding: 12px;
      display: grid;
      grid-template-columns: 10fr 25fr 25fr 17fr 17fr 6fr;
      align-items: center;
      transition: all 300ms ease 0s;
      cursor: pointer;
      gap: 8px;

      &:hover {
        background: #f3f7fe;

        .avatar {
          scale: 1.25;
        }

        .username-wrapper {
          .username {
            color: #1260eb;
          }
        }
        .user-tags {
          .tags-list-wrapper {
            border-color: rgba(0, 0, 0, 0.1);
          }
          .icon-chevron-bottom {
            color: #b6b6b6;
          }
        }
        .user-actions {
          opacity: 1;
        }
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      .user-avatar-wrapper {
        .avatar {
          height: 40px;
          width: 40px;
          transition: all 300ms ease-in-out 0s;
        }
      }

      .username-wrapper {
        display: flex;
        flex-direction: column;

        .username {
          text-align: left;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          color: #000;
          font-family: Inter;
          font-size: 16px;
          font-weight: 500;
        }

        .anonymous-name {
          color: rgba(0, 0, 0, 0.5);
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .user-tags {
        display: flex;

        .tags-list-wrapper {
          border-color: transparent;
          transition: all 300ms ease 0s;
        }
        .icon-chevron-bottom {
          color: transparent;
          transition: all 300ms ease 0s;
        }
      }
      .user-activity {
        display: flex;
        flex-direction: column;
      }
      .user-btn-profile {
        display: flex;
        justify-content: center;
      }
      .icon-close {
        opacity: 0.1;
      }
      .user-actions {
        opacity: 0;
      }
    }
  }
}
