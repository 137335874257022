.modal-page-opening {
  overflow: hidden;
  width: 548px;
  box-sizing: border-box;
  padding: 0;

  .modal-title {
    display: flex;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 24px;
    font-family: 'Montserrat';
    margin-bottom: 0;

    i {
      margin-right: 16px;
    }
  }

  .content {
    margin: 24px;

    .delay-input-wrapper {
      display: flex;
      gap: 16px;
      align-items: center;
      color: #000;
      font-family: Inter;
      font-size: 18px;
      font-weight: 500;

      .input-group {
        margin: 0 8px;
        width: 80px;

        input {
          border: 1px dashed rgba(0, 0, 0, 0.5);
          border-radius: 12px;
          padding: 16px;
          text-align: center;
          color: rgba(0, 0, 0, 0.5);
          font-family: Inter;
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }
}
