@import '../../../../Colors';

.s-settings-general {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .title-3 {
    margin-bottom: 8px;
  }

  .card-main {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .card-delete-project {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .general-project-logo {
    display: flex;

    .left-wrapper,
    .right-wrapper {
      display: flex;
    }

    .right-wrapper {
      flex: 1;
      gap: 8px;
      align-items: center;
      justify-content: flex-end;
    }

    .project-logo {
      width: 52px;
      height: 52px;
      border-radius: 12px;
      font-size: 30px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin-right: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border: 1px solid var(--n-300);
    }

    .label-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .general-project-name {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input-group {
      width: 100%;
    }
  }

  .card-white-label {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .settings-labeled-input {
    justify-content: space-between;

    .label {
      flex: 1 0 auto;
    }
    .input-wrapper {
      flex: unset;
    }
  }
  .input-project-name {
    .input-wrapper {
      display: flex;

      .alert {
        margin-left: 12px;
        max-width: 400px;
      }
      .input-group {
        width: 324px;
      }
    }
  }
  .input-jimo-label {
    .label-wrapper {
      span {
        display: inline-flex;
        border-radius: 6px;
        font-weight: 800;
        color: orange;
        font-size: 12px;
      }
    }
    .input-wrapper {
      display: flex;
      align-items: center;

      .jimo-toggle-wrapper {
        margin-right: 12px;
      }
    }
  }
  a {
    display: inline-block;
  }
  .btn-general-update {
    position: absolute;
    bottom: 24px;
  }
}
