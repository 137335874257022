.summary-preview {
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: var(--n-100);
  border-radius: 16px;
  border: 1px solid var(--n-400);
  padding: 24px;
  justify-content: space-evenly;
  min-width: 0;
  overflow: hidden;

  .steps-list-wrapper {
    flex: 1 auto;
    display: flex;
    overflow-x: auto;
    margin: auto;
    max-width: 100%;

    .steps-list-adoption,
    .steps-list-discovery {
      margin-top: auto;
    }
  }
  .poke-preview-wrapper {
    position: relative;
    flex: 1 1 70%;
  }
}
