.poke-layout-banner {
  display: flex;
  margin: auto 0;
  padding: 8px 0;
  z-index: 1;

  .layout-banner-center-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    .layout-banner-center-col-content {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .layout-banner-center-col-content-top,
      .layout-banner-center-col-content-bottom {
        display: flex;
        align-items: center;
      }
    }
  }

  .layout-banner-dismiss-cross-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }
}
