.advanced-editor {
  background: white;
  resize: none;
  padding: 0;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  border: 1px solid var(--n-300);
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  border: 1px dashed #e5e5e5;
  width: 100%;
  flex: 0 1 800px;
  min-height: 0;
  display: flex;
  flex-direction: column;

  .toolbar {
    padding: 0;
    margin: 0;
    border: 0;

    .slate-toolbar-button {
      border-radius: 8px;

      &:hover {
        background: rgba(7, 19, 49, 0.1);
      }
    }

    .slate-toolbar-button-active {
      background: rgba(7, 19, 49, 0.1);
      color: var();
    }
  }

  .editor-wrapper {
    padding: 8px 12px;
    cursor: text;
    flex: 1 auto;
    min-width: 0;
    outline: 0;
    overflow-y: auto;

    & > div {
      align-items: center;
    }

    .PlateFloatingLink___StyledFloatingLinkInsertRoot-sc-1bralnd-8 {
      position: absolute;
      min-height: unset;
      padding: 0;
      border-radius: 8px;
      top: 8px !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }

    .PlateFloatingLink___StyledFloatingLinkEditRoot-sc-1bralnd-9 {
      padding: 0;
      border-radius: 8px;
      min-height: unset;
    }
  }

  &.disabled {
    border-color: transparent !important;
    background: #f0f0f0 !important;
    color: #d0d0d0;
    cursor: not-allowed;

    .toolbar {
      border-color: transparent !important;
      background: #f0f0f0 !important;
      color: #d0d0d0;
      cursor: not-allowed;
      pointer-events: none;
    }

    .editor-wrapper {
      pointer-events: none;
    }
  }
}

.tippy-box {
  padding: 8px 12px;
  background-color: rgb(33, 33, 33);
  color: #ffffff;
  border-radius: 4px;
}

// Can't add any class or id to the div rendered by EmojiToolbarDropdown, so we are using the beginning
// of the generated class to target the element and remove the left offset. This is to prevent the dropdown
// to disappear in the in-app builder where the width is too small to allow a left offset
div[class^='EmojiToolbarDropdownRoot'] {
  left: unset !important;
}
