.recent-comments {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 360px;
  width: 100%;

  .title {
    display: flex;
    padding: 16px 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--n-100, #f0f2f4);
  }

  .loader-wrapper {
    margin: auto;
    padding: 32px;
  }

  .empty-state-wrapper {
    display: flex;
    padding: 32px 48px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    .icon-wrapper {
      display: flex;
      padding: 12px;
      align-items: flex-start;
      gap: 8px;
      border-radius: 16px;
      background: var(--n-100, #f0f2f4);

      i {
        font-size: 28px;
      }
    }
  }

  .comments-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .comment {
      display: flex;
      padding: 16px 20px;
      align-items: flex-start;
      gap: 14px;
      align-self: stretch;
      border-bottom: 1px solid var(--n-100, #f0f2f4);
      cursor: pointer;

      &:hover {
        background: var(--n-50);
      }

      .comment-author-wrapper {
        width: 40px;
        height: 40px;
        border-radius: 55px;
        background: var(--y-200, #fdf8c9);
        position: relative;

        .message-badge-wrapper {
          position: absolute;
          bottom: -3px;
          right: -3px;
          display: inline-flex;
          padding: 4px;
          align-items: flex-start;
          gap: 8px;
          border-radius: 35px;
          border: 3px solid #fff;
          background: var(--Blue-150, #def);

          i {
            font-size: 10px;
          }
        }
      }

      .comment-content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;

        .comment-date {
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        }
      }
    }
  }
}
