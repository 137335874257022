.last-users-reached {
  .loader-wrapper {
    display: flex;

    .loader {
      margin: 72px auto;
    }
  }

  .block-header {
    .block-title {
      display: flex;
      align-items: center;

      .users-actions {
        margin-left: auto;
      }
    }
  }

  .list-users-table {
    height: 100%;
    display: flex;
    flex-direction: column;

    .top {
      flex: 0 1 auto;
      display: grid;
      grid-template-columns: 10fr 22fr 22fr 25fr 15fr 6fr;
      padding: 16px 12px 12px 12px;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.4);
      gap: 8px;
    }

    .loader-wrapper {
      display: flex;
      justify-content: center;
      padding-top: 24px;
    }
    .list-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      .list {
        align-self: stretch;
        overflow: auto;
        flex: 1 1 100%;
        box-sizing: border-box;

        .card-user {
          border-radius: 12px;
          padding: 12px;
          display: grid;
          grid-template-columns: 10fr 22fr 22fr 25fr 15fr 6fr;
          align-items: center;
          transition: all 300ms ease 0s;
          cursor: pointer;
          gap: 8px;
          word-break: break-word;

          &.completed {
            background: linear-gradient(
              270deg,
              rgba(235, 255, 237, 0) -6.67%,
              #ebffed 106.49%
            );

            .username-wrapper {
              .username {
                color: #167322;
              }
            }

            .user-last-activity {
              .content {
                .step-name {
                  color: var(--content-accent-positive, #167322);
                }
              }
            }
          }

          &:hover {
            background: #f3f7fe;

            .avatar {
              scale: 1.25;
            }

            .username-wrapper {
              .username {
                color: #1260eb;
              }
            }
            .user-tags {
              .tags-list-wrapper {
                border-color: rgba(0, 0, 0, 0.1);
              }
              .icon-chevron-bottom {
                color: #b6b6b6;
              }
            }
            .user-actions {
              opacity: 1;
            }
          }

          &:not(:last-child) {
            margin-bottom: 16px;
          }

          .user-avatar-wrapper {
            .avatar {
              height: 40px;
              width: 40px;
              transition: all 300ms ease-in-out 0s;
            }
          }

          .username-wrapper {
            display: flex;
            flex-direction: column;

            .username {
              text-align: left;
              display: flex;
              flex-direction: column;
              overflow: hidden;
              color: #000;
              font-family: Inter;
              font-size: 16px;
              font-weight: 500;
            }

            .anonymous-name {
              color: rgba(0, 0, 0, 0.5);
              font-family: Inter;
              font-size: 14px;
              font-weight: 400;
            }
          }
          .user-last-activity {
            display: flex;
            align-items: center;
            gap: 12px;

            &.completed {
              .content {
                .step-name {
                  color: var(--content-accent-positive, #167322);
                }
              }
            }

            .circular-progress-wrapper {
              height: 24px;
              width: 24px;
              flex: 0 0 auto;
            }

            .content {
              display: flex;
              flex-direction: column;
              gap: 2px;

              .step-name {
                color: #000;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .event-date {
                color: rgba(0, 0, 0, 0.5);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                align-items: center;
                gap: 8px;

                i {
                  font-size: 18px;
                }
              }
            }
          }
          .user-tags {
            display: flex;

            .tags-list-wrapper {
              border-color: transparent;
              transition: all 300ms ease 0s;
            }
            .icon-chevron-bottom {
              color: transparent;
              transition: all 300ms ease 0s;
            }
          }
          .user-activity {
            display: flex;
            flex-direction: column;
          }
          .user-btn-profile {
            display: flex;
            justify-content: center;
          }
          .icon-close {
            opacity: 0.1;
          }
          .user-actions {
            opacity: 0;
          }
        }
      }
    }
  }
}
