.block-settings.opinion-scale {
}

.steps-picker {
  display: flex;
  justify-content: space-between;

  .steps-picker-input {
    width: 60px;

    .input-group {
      height: 40px;

      .input {
        height: 40px;
        width: 42px;
        border-radius: 12px;
      }
    }
  }

  .steps-picker-slider {
    width: 72px;
    display: flex;

    .rc-slider {
      margin: auto;

      .rc-slider-rail {
        height: 2px;
        background: rgba(0, 0, 0, 0.1);
      }

      .rc-slider-track {
        height: 2px;
        background: #1260eb;
      }

      .rc-slider-handle {
        width: 16px;
        height: 16px;
        margin-top: -8px;
        border-radius: 50%;
        border: 0;
        opacity: 1;
        background: #ffffff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
      }
    }
  }
}
