.integration-setting-cio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  .settings-header {
    display: flex;
    padding: 24px;
    gap: 16px;
    color: #000;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 38.4px */
    letter-spacing: -0.5px;

    .back-btn {
      background-color: #f4f4f4;
      height: 40px;
      width: 40px;
      border-radius: 12px;
      padding: 0;
      margin-right: 16px;

      &:hover {
        background-color: #dddddd;
      }

      i {
        margin: auto !important;
        color: #000000;
      }
    }
  }

  .settings-integration-content {
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    display: grid;
    gap: 24px;
    grid-template-columns: auto 600px;

    .left-col {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .setup-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      flex: 1 auto;
    }
  }

  .setting-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--border-minimal, #e5e5e5);
    background: #fff;
    box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.05);

    &.section-coming-soon {
      opacity: 0.3;
    }

    .section-title {
      display: flex;
      padding: 12px 24px;
      align-items: center;
      gap: 8px;
      color: var(--content-primary, #071331);
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 125%; /* 20px */
      border-bottom: 1px solid #e5e5e5;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 24px;

      i {
        color: #1260eb;
      }
    }

    .section-body {
      display: flex;
      padding: 0px 24px 24px 24px;
      align-items: center;
      gap: 16px;
      align-self: stretch;

      .jimo-select {
        flex: 0 0 200px;

        .jimo-select__control {
          border-radius: 16px;
          box-shadow: 0;
        }

        .icon-wrapper {
          display: flex;
          align-items: center;
          height: 40px;
          width: 40px;
          margin-left: 4px;
        }

        .object-type-tag {
          background: #f3f7fe;
          color: #1260eb;
          padding: 4px 8px;
          border-radius: 4px;
        }
      }
    }
  }

  .coming-soon {
    font-size: 12px;
    color: orange;
  }

  .section-match-id {
    max-width: 900px;

    a {
      color: #1260eb;
      font-weight: 500;
      &:hover {
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
  }
  .section-reporting-webhook {
    .section-title {
      i {
        font-size: 14px !important;
      }
    }
    .section-item {
      h1 {
        font-size: 18px;
        margin: 0;
        font-family: 'Montserrat', sans-serif;

        span {
          color: rgba(21, 146, 1, 1);
          font-size: 14px;

          i {
            font-size: 10px;
          }
        }
      }
      .webhook-details {
        span {
          text-decoration: underline;
          text-underline-offset: 3px;
          font-weight: 600;
          cursor: pointer;
        }
        a {
          color: #1260eb;
          font-weight: 600;
          &:hover {
            text-decoration: underline;
            text-underline-offset: 3px;
          }
        }
      }
      .loader {
        margin-top: 12px;
      }
    }
  }
}

.cio-help-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--border-minimal, #e5e5e5);
  background: #fff;
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.05);
  flex-shrink: 0;

  .cio-help-section-header {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 50%;
    }
  }

  .cio-help-section-content {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-top: 1px solid var(--border-minimal, #e5e5e5);

    .section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      .section-title {
        color: var(--content-primary, #071331);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; /* 20px */
      }

      .section-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        .how-it-works {
          color: var(--content-primary, #071331);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 24px */

          span {
            font-size: 16px;

            i {
              font-size: 12px;
              color: #1260eb;
            }
          }
        }
      }
    }

    .section.cio-workspace {
      .parameter-item {
        span {
          font-weight: 600;
        }
      }
    }
  }
}

.tooltip-cio-listeners-content {
  border: 1px solid #e5e5e5;
  max-width: 600px;
  font-family: 'Montserrat', sans-serif;

  span {
    font-weight: 600;
  }
  pre {
    white-space: pre-wrap;
  }
}
