.modal-addon-purchased {
  padding: 24px 34px;
  min-height: 220px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  width: 440px;
  box-sizing: border-box;

  .modal-main {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .cancel-title {
    text-align: center;
    & > div {
      text-transform: capitalize;
    }
  }

  .cancel-description {
    text-align: center;
  }

  .btn {
    width: 100%;
    justify-content: center;
    position: relative;
  }
}
