.line-chart {
  .tooltip-with-bounds {
    min-width: 60;
    padding: 12px !important;
    background: #ffffff !important;
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08) !important;
    border-radius: 16px !important;
    display: flex;
    flex-direction: column;

    .date {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
      margin-bottom: 8px;
    }

    .tooltip-value {
      font-weight: 600;
      font-size: 16px;
      display: flex;
      color: #000000;
      margin: 4px 0;
      margin-right: auto;

      .line-indicator {
        width: 15px;
        height: 2px;
        margin: auto;
        margin-right: 10px;
      }

      .tooltip-ylabel {
        margin-left: 4px;
        text-transform: lowercase;
      }
    }
  }

  .legend {
    margin: 10px 20px;
  }
}
