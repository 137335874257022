.tracker-block {
  background: #fff;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 24px 94px 0px rgba(0, 0, 0, 0.07);
  flex: 0;
  display: flex;
  flex-direction: column;

  .block-header,
  .block-content {
    padding: 24px;
  }
}
