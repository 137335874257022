.block-settings.title {
  .section-title-extra {
    .block-group {
      .icon-wrapper {
        background-color: var(--g-100);

        i {
          font-size: 10px;
          color: var(--n-800);
        }
      }
    }
  }

  .editor-wrapper {
    min-height: 50px;
  }
}
