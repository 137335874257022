.block-settings.checklist-item-button {
  .section-title.actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .section-title-extra {
    .block-group {
      .icon-wrapper {
        background-color: var(--g-100);
      }
    }
  }

  .navigation-picker {
    .navigation-icon-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      background: var(--b-100);
      overflow: hidden;

      i {
        margin: auto;
        font-size: 18px;
        box-sizing: border-box;
        border-radius: 12px;
        text-align: center;
      }

      .navigation-icon {
        width: 32px;
        height: 32px;
      }
    }
  }

  .action-picker {
    .actions-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .add-action-element-placeholder-btn {
      .label-left {
        .action-icon-wrapper {
          display: flex;
          width: 32px;
          height: 32px;
          box-sizing: border-box;
          border: 1px solid var(--n-100);
          box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
          border-radius: 12px;
          background: var(--n-100);

          i {
            margin: auto;
            font-size: 18px;
            box-sizing: border-box;
            border-radius: 12px;
            text-align: center;
            color: var(--n-600);
          }
        }
      }
    }
  }
}
