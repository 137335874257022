.tracker-completion-legend {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  flex-wrap: wrap;
  margin: auto;

  .legend-item {
    display: flex;
    align-items: center;
    gap: 12px;

    .legend-color {
      width: 24px;
      height: 24px;
      border-radius: 8px;
    }

    .legend-label {
      color: #000;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
    }
  }
}
