.preset-selector {
  height: 100%;
  background: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .preset-selector-header {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
    box-sizing: border-box;

    .title {
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 24px */
      letter-spacing: -0.5px;
    }
  }

  .presets-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    flex-wrap: wrap;
    padding: 16px;
    overflow-y: auto;

    .preset {
      height: 292px;
      box-sizing: border-box;
      display: flex;
      padding: 20px 16px;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      flex: 1 0 0;
      border-radius: 16px;
      background: var(--Neutral-neutral-100, #f0f2f4);
      cursor: pointer;
      box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.04);
      transition: all 200ms ease-in-out 0s;
      cursor: pointer;
      outline: 2px solid transparent;
      animation: fade-in 400ms ease-in-out both;

      &:hover {
        outline: 2px solid var(--Blue-300, #85b1ff);
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
      }

      .preset-preview {
        display: flex;
        align-self: stretch;
        height: 126px;
        align-items: flex-start;
        gap: 6px;
        position: relative;
        flex-shrink: 0;

        &.is-hint {
          height: 106px;
        }
      }

      .preset-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
      }
    }

    .start-from-scratch {
      display: flex;
      height: 292px;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      flex: 1 0 0;
      position: relative;
      overflow: hidden;
      border-radius: 16px;
      background: #fff;
      transition: all 200ms ease-in-out 0s;
      cursor: pointer;
      outline: 2px solid transparent;
      animation: fade-in 400ms ease-in-out both;
      padding: 0px 16px;
      outline: 1px solid var(--border-minimal, #e5e5e5);
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);

      &:hover {
        outline: 2px solid var(--Blue-300, #85b1ff);
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
      }

      img {
        z-index: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
      }

      .icon-wrapper {
        z-index: 1;
        display: flex;
        padding: 16px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 16px;
        background: var(--surface-primary, #fff);
        box-shadow: 0px 5px 28px 0px rgba(0, 0, 0, 0.09);

        .icon-plus-wrapper {
          display: flex;
          width: 24px;
          height: 24px;
          justify-content: center;
          align-items: center;
          border-radius: 41px;
          background: var(--b-400);
          margin: auto;

          i {
            color: var(--white);
            font-size: 14px;
          }
        }
      }

      .title {
        z-index: 1;
        text-align: center;
      }
    }
  }
}
