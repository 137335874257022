.s-settings-subscription-coupon {
  padding-top: 38px;
  padding-bottom: 38px;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;

  .settings-title {
    margin-bottom: 24px;
  }
  .coupon-form-wrapper {
    background-color: #fbfbfb;
    border-radius: 8px;
    padding: 24px;
    box-sizing: border-box;
    width: 530px;

    .title {
      font-size: 18px;
      font-weight: 400;
    }
    .input-group {
      margin: 24px 0;
    }
    .alert {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      .emoji {
        margin-right: 16px;
      }
    }
  }
  .coupon-list {
    margin-top: 16px;

    .item {
      background-color: rgba(0, 0, 0, 0.05);
      padding: 24px;
      margin-bottom: 12px;
    }
    .title {
      font-size: 16px;
      font-weight: 500;
    }
    p {
      margin-top: 12px;
      margin-bottom: 0;

      img {
        display: block;
        margin-top: 24px;
      }
    }
  }
}
