.logic-view-empty-state {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;

  img {
    background: white;
    border-radius: 12px;
    z-index: 1;
  }
}
