.s-onboarding-project {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 24px 0;
  box-sizing: border-box;
  color: #fff;

  .cover-peoples {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: fade-in 200ms ease-in-out 500ms both;
    background-repeat: no-repeat;
    background-size: 20%;
    &.people-left {
      left: -35px;
      background-position: left center;
      background-image: url(./../../assets/people-left.png);
    }
    &.people-right {
      left: unset;
      right: -35px;
      background-position: right center;
      background-image: url(./../../assets/people-right.png);
    }
  }
  .main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 530px;
    position: relative;
    z-index: 2;
    @media (max-width: 450px) {
      width: 370px;
    }

    img {
      border-radius: 6px;
    }
    .title {
      font-size: 32px;
      font-weight: 500;
      margin: 32px 0 6px 0;
      font-family: 'Montserrat', sans-serif;
      animation: fade-in 200ms ease-in-out 500ms both;
    }
    .subtitle {
      font-size: 20px;
      font-weight: 200;
      text-align: center;
      max-width: 360px;
      animation: fade-in 200ms ease-in-out 500ms both;
    }
    form {
      width: 100%;
      padding: 24px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 12px;
      color: #000;
      animation: fade-in-bottom 500ms ease-in-out 1000ms both;
      margin-top: 16px;

      .alert {
        margin-bottom: 24px;
      }
      .divider {
        font-weight: 700;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.3);
        text-transform: uppercase;
        font-family: 'Montserrat';
        margin: 24px 0;
      }
      .inputs-wrapper {
        display: flex;
        flex-wrap: wrap;

        .item {
          flex: 1;
          &.first-name {
            margin-right: 12px;
          }
          &.last-name {
            margin-left: 12px;
          }
        }
      }
      .input-item {
        &.password {
          margin-top: 24px;
          position: relative;

          .show-password {
            position: absolute;
            padding: 8px;
            right: 6px;
            top: 26px;
            color: #b6b6b6;
            cursor: pointer;
            &:hover {
              color: #7b7b7b;
            }
          }
        }
        .input-legend {
          margin-bottom: 4px;
          font-weight: 400;
        }
      }
      .input-select {
        margin-top: 24px;

        .imo-select__value-container {
          padding: 15px 20px;
        }
        .jimo-select__menu {
          color: #000;
        }
        .jimo-select__control {
          box-shadow: inset 0 0 0 1px #b6b6b6, 0 0 0 4px transparent;
          border: none;
          color: #000 !important;
          &:hover {
            box-shadow: inset 0 0 0 3px rgba(18, 96, 235, 0.8),
              0 0 0 2px transparent;
            border: none;
          }
          &:focus {
            box-shadow: inset 0px 0px 0px 3px rgba(18, 96, 235, 0.4),
              0px 0px 0px 2px rgba(18, 96, 235, 0.1);
            border: none;
          }
        }
        .jimo-select {
          color: #fff;
        }
        .input-legend {
          margin-bottom: 4px;
          font-weight: 400;
        }
      }
      .btn-submit {
        margin-top: 24px;
        padding: 12px;
        display: flex;
      }
    }
  }
}
