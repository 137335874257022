.steps-activity-new {
  display: flex;
  flex: 1 1 auto;
  gap: 32px;

  .loader-wrapper {
    display: flex;
    flex: 1 auto;

    .loader {
      margin: auto;
    }
  }

  .steps-activity-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap: 40px;

    .hidden-step-warning {
      display: flex;
      box-sizing: border-box;
      background: var(--n-100);
      border-radius: 12px;
      padding: 12px 16px;
      gap: 16px;
      border: 1px solid var(--n-400);

      i {
        font-size: 16px;
        margin: auto 0;
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }

    .steps-list {
      display: flex;
      flex: 1 1 auto;
      gap: 32px;

      .step-activity-item {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 14px;
        flex: 1 1 100px;
        min-width: 100px;

        &.is-removed {
          .step-stats,
          .chart,
          .step-info {
            opacity: 0.3;
          }
        }

        .step-stats {
          display: flex;
          flex-direction: column;
          margin: 0 auto;

          .percentage {
            color: #000;
            font-family: Montserrat;
            font-size: 20px;
            font-weight: 500;
            margin: 0 auto;
          }
          .users-count {
            color: rgba(0, 0, 0, 0.4);
            font-family: Inter;
            font-size: 12;
            font-weight: 400;
            margin: 0 auto;
          }
        }

        .chart-wrapper {
          flex: 1 1 auto;
          border-radius: 20px;
          background: #fbfbfb;
          display: flex;
          position: relative;
          box-sizing: border-box;
          border: 2px solid transparent;

          &:hover {
            border: 2px solid rgba(18, 96, 235, 0.5);

            .btn-see-users {
              opacity: 1;
            }
          }

          .chart {
            border-radius: 18px;
            border: 1px solid #89b6ff;
            background: linear-gradient(
              180deg,
              #e5efff 0%,
              rgba(229, 239, 255, 0) 100%
            );
            margin-top: auto;
            width: 100%;
          }

          .btn-see-users {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            white-space: nowrap;
            opacity: 0;
            transition: all 0.2s ease-in-out;
          }
        }

        .step-info-wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .step-info {
            display: flex;
            justify-content: center;
            align-items: center;

            .step-icon {
              display: flex;
              margin-right: 8px;
              font-size: 14px;
              padding: 10px;
              border-radius: 12px;
              border: 1px solid rgba(0, 0, 0, 0.08);
            }

            .step-name {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .label-hidden-wrapper {
            align-self: center;
          }

          .label-placeholder {
            height: 29px;
          }
        }
      }
    }
  }
}
