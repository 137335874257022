.modal-subscription-checkout-overlay {
  background-color: transparent;
}

.modal-subscription-checkout {
  width: 100vw;
  height: 100vh;
  border-radius: 0;
  animation: fadeInScaleIn 700ms;
  display: grid;
  grid-template-columns: 50% 50%;

  .btn-modal-close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .left-side-wrapper {
    padding: 48px;
    display: flex;
    align-items: center;
  }
  .right-side-wrapper {
    padding: 48px;
    background-color: var(--b-100);
    display: flex;
    align-items: center;
  }

  .left-side {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 480px;
    margin-left: auto;
  }
  .right-side {
    flex: 1;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  // Left Side
  .interval-wrapper {
    display: flex;
    gap: 12px;
    margin-top: 8px;
  }
  .item-interval {
    flex: 1;
    padding: 12px 16px;
    border: 1px solid var(--n-300);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    cursor: pointer;
    transition: all 200ms ease;
    &:hover {
      background-color: var(--b-100);
    }
    &.active {
      background-color: var(--b-100);
      border-color: var(--b-400);

      .interval-header {
        i {
          opacity: 1;
        }
      }
    }

    .interval-header {
      display: flex;
      justify-content: space-between;

      i {
        font-size: 20px;
        transition: all 200ms ease;
        opacity: 0;
      }
    }
    .interval-body {
      display: flex;
      gap: 8px;

      .yearly-discount {
        border-radius: 16px;
        padding: 4px 8px;
        background-color: var(--r-400);
        color: #fff;
      }
    }
  }
  .input-card-element {
    margin-top: 8px;
    background-color: transparent;
    border: 1px solid var(--n-300);
    transition: all 200ms ease;
    border-radius: 12px;
    &:hover {
      border-color: var(--n-500);
    }
  }
  .input-cardholder-name {
    margin-top: 8px;
  }

  .inputs-address-lines-wrapper {
    display: flex;
    gap: 16px;
    margin-top: 16px;

    .element-zip {
      flex: 0 1 50%;
    }
    .element-select-wrapper {
      max-width: 50%;
      position: relative;
      &::before {
        right: 16px;
        top: 20px;
        position: absolute;
        display: inline-block;
        font-family: 'jimo-icons' !important;
        content: '\e95b';
        font-size: 12px;
        z-index: 2;
      }
      select {
        font-family: 'Inter', sans-serif;
      }
    }
    .element-country {
      max-width: 100%;
      flex: 1;
      border: 1px solid var(--n-300);
      border-radius: 12px;
      padding: 14px 16px;
      font-size: 16px;
      outline: none;
      cursor: pointer;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      transition: all 200ms ease;
      &:hover {
        border-color: var(--n-500);
      }
      &.is-empty {
        color: var(--n-500);
      }
    }
  }

  .element-vat {
    margin-top: 16px;
  }

  .btn-upgrade {
    justify-content: center;
  }

  .amount-saved {
    text-align: center;
    align-self: stretch;
    position: relative;
    top: -12px;
  }

  // Right Side

  .card-order-summary {
    background-color: #fff;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0px 0px 94px 0px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .summary-plan {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .plan-interval-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .plan-period-mau {
      display: flex;
      gap: 4px;

      span {
        cursor: pointer;
        &:hover {
          color: var(--n-800);
        }
      }
      i {
        font-size: 10px;
        margin-left: 8px;
      }
    }
  }

  .summary-addons {
    display: flex;
    flex-direction: column;

    .item-addon {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .addon-interval-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  .summary-divider {
    width: 100%;
    height: 1px;
    background-color: var(--n-300);
  }

  .summary-tax {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .addon-interval-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .summary-coupon {
    display: flex;
    justify-content: space-between;

    .input-button-wrapper {
      display: flex;
      gap: 12px;
      flex: 1;
      align-items: center;

      .input-group {
        flex: 1;
      }
    }
    .coupon-interval-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .summary-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card-addon {
    position: relative;
    background-color: #fff;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0px 0px 94px 0px rgba(0, 0, 0, 0.04);
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    align-items: center;
    cursor: pointer;
    &.included {
      cursor: not-allowed;
    }

    .addon-included {
      border-radius: 16px;
      padding: 4px 8px;
      background-color: var(--b-400);
      color: #fff;
      align-self: flex-start;
      margin-bottom: 8px;
    }
    .addon-gradient {
      position: absolute;
      right: -39.426px;
      bottom: -51.5px;
      width: 190px;
      height: 113px;
      background-color: rgba(221, 238, 255, 1);
      filter: blur(50px);
      z-index: 1;
    }
    .text-wrapper {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .action-wrapper {
      position: relative;
      z-index: 2;
    }
  }
}

.modal-exit-checkout {
  width: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 24px 32px;
  box-sizing: border-box;

  .modal-description {
    text-align: center;
  }

  .actions-wrapper {
    display: flex;
    gap: 12px;
    align-self: stretch;

    .btn {
      flex: 1;
      justify-content: center;
    }
  }
}

.toast-subscription-checkout-error {
  width: 100%;
}

.checkout-mau-dropdown-content {
  z-index: 1100 !important;
}

@keyframes fadeInScaleIn {
  0% {
    opacity: 0; /* Start from fully transparent */
    transform: scale(0.9); /* Start from half size */
  }
  25% {
    transform: scale(0.9); /* Start from half size */
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1); /* Scale to original size */
  }
}
