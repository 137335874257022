.shape-selector-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.theme-preview {
  flex: 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: rgb(7 19 49 / 50%);
  border-radius: 12px;
  box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.13);

  .poke-wrapper {
    position: relative;

    &.is-snippet {
      position: absolute !important;
      left: 16px;
      bottom: 16px;
      transform-origin: left bottom;
    }
    &.is-checklist {
      .poke-render {
        overflow: visible;
        background-color: unset;
      }
    }
    .poke-render {
      position: relative;
      background-color: #fff;
      overflow: hidden;
      z-index: 1;

      &:not(.auto-height) {
        .jimo-poke {
          height: 100%;
        }
      }
    }
  }

  .hotspot-preview {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    animation: dot-pulse 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    // when no colors provided to box-shadow, the property color is used
  }

  .poke-render-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  /* classes for animations */
  /* fade */
  .poke-animation-fade-in {
    animation: poke-fade-in 1000ms ease-in-out forwards;
  }
  .poke-animation-fade-out {
    animation: poke-fade-out 1000ms ease-in-out backwards;
  }
  /* slide */
  .poke-animation-slide-right-in {
    animation: poke-slide-right-in 1000ms ease-in-out forwards;
  }
  .poke-animation-slide-right-out {
    animation: poke-slide-right-out 1000ms ease-in-out backwards;
  }
  .poke-animation-slide-left-in {
    animation: poke-slide-left-in 1000ms ease-in-out forwards;
  }
  .poke-animation-slide-left-out {
    animation: poke-slide-left-out 1000ms ease-in-out backwards;
  }
  .poke-animation-slide-down-in {
    animation: poke-slide-down-in 1000ms ease-in-out forwards;
  }
  .poke-animation-slide-down-out {
    animation: poke-slide-down-out 1000ms ease-in-out backwards;
  }
  .poke-animation-slide-up-in {
    animation: poke-slide-up-in 1000ms ease-in-out forwards;
  }
  .poke-animation-slide-up-out {
    animation: poke-slide-up-out 1000ms ease-in-out backwards;
  }

  /** For poke **/
  /* Slide */
  @-webkit-keyframes poke-slide-left-in {
    0% {
      opacity: 0;
      transform: matrix(0.85, 0, 0, 0.85, -200, 0);
    }
    50% {
      opacity: 1;
      transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    }
    100% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
  }
  @keyframes poke-slide-left-in {
    0% {
      transform: matrix(0.85, 0, 0, 0.85, -200, 0);
      opacity: 0;
    }
    50% {
      transform: matrix(0.85, 0, 0, 0.85, 0, 0);
      opacity: 1;
    }
    100% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes poke-slide-left-out {
    0% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
    50% {
      transform: matrix(0.85, 0, 0, 0.85, 0, 0);
      opacity: 1;
    }
    100% {
      transform: matrix(0.85, 0, 0, 0.85, -200, 0);
      opacity: 0;
    }
  }
  @keyframes poke-slide-left-out {
    0% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
    50% {
      transform: matrix(0.85, 0, 0, 0.85, 0, 0);
      opacity: 1;
    }
    100% {
      transform: matrix(0.85, 0, 0, 0.85, -200, 0);
      opacity: 0;
    }
  }
  @-webkit-keyframes poke-slide-right-in {
    0% {
      opacity: 0;
      transform: matrix(0.85, 0, 0, 0.85, 200, 0);
    }
    50% {
      opacity: 1;
      transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    }
    100% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
  }
  @keyframes poke-slide-right-in {
    0% {
      transform: matrix(0.85, 0, 0, 0.85, 200, 0);
      opacity: 0;
    }
    50% {
      transform: matrix(0.85, 0, 0, 0.85, 0, 0);
      opacity: 1;
    }
    100% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes poke-slide-right-out {
    0% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
    50% {
      transform: matrix(0.85, 0, 0, 0.85, 0, 0);
      opacity: 1;
    }
    100% {
      transform: matrix(0.85, 0, 0, 0.85, 200, 0);
      opacity: 0;
    }
  }
  @keyframes poke-slide-right-out {
    0% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
    50% {
      transform: matrix(0.85, 0, 0, 0.85, 0, 0);
      opacity: 1;
    }
    100% {
      transform: matrix(0.85, 0, 0, 0.85, 200, 0);
      opacity: 0;
    }
  }
  @-webkit-keyframes poke-slide-up-in {
    0% {
      opacity: 0;
      transform: matrix(0.85, 0, 0, 0.85, 0, -200);
    }
    50% {
      opacity: 1;
      transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    }
    100% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
  }
  @keyframes poke-slide-up-in {
    0% {
      transform: matrix(0.85, 0, 0, 0.85, 0, -200);
      opacity: 0;
    }
    50% {
      transform: matrix(0.85, 0, 0, 0.85, 0, 0);
      opacity: 1;
    }
    100% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes poke-slide-up-out {
    0% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
    50% {
      transform: matrix(0.85, 0, 0, 0.85, 0, 0);
      opacity: 1;
    }
    100% {
      transform: matrix(0.85, 0, 0, 0.85, 0, -200);
      opacity: 0;
    }
  }
  @keyframes poke-slide-up-out {
    0% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
    50% {
      transform: matrix(0.85, 0, 0, 0.85, 0, 0);
      opacity: 1;
    }
    100% {
      transform: matrix(0.85, 0, 0, 0.85, 0, -200);
      opacity: 0;
    }
  }
  @-webkit-keyframes poke-slide-down-in {
    0% {
      opacity: 0;
      transform: matrix(0.85, 0, 0, 0.85, 0, 200);
    }
    50% {
      opacity: 1;
      transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    }
    100% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
  }
  @keyframes poke-slide-down-in {
    0% {
      transform: matrix(0.85, 0, 0, 0.85, 0, 200);
      opacity: 0;
    }
    50% {
      transform: matrix(0.85, 0, 0, 0.85, 0, 0);
      opacity: 1;
    }
    100% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes poke-slide-down-out {
    0% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
    50% {
      transform: matrix(0.85, 0, 0, 0.85, 0, 0);
      opacity: 1;
    }
    100% {
      transform: matrix(0.85, 0, 0, 0.85, 0, 200);
      opacity: 0;
    }
  }
  @keyframes poke-slide-down-out {
    0% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
    50% {
      transform: matrix(0.85, 0, 0, 0.85, 0, 0);
      opacity: 1;
    }
    100% {
      transform: matrix(0.85, 0, 0, 0.85, 0, 200);
      opacity: 0;
    }
  }
  @-webkit-keyframes poke-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes poke-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes poke-fade-out {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes poke-fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
