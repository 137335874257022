.logic-group {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & .logic-group {
    padding: 16px;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    position: relative;
    transition: all 300ms ease-in-out 0s;

    &:hover:not(:has(.logic-group:hover)) {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);

      & > .delete-btn {
        display: flex;
      }
    }
  }

  .content {
    display: flex;
    gap: 16px;

    .logic-trait-wrapper {
      display: flex;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 30%;
        height: 100%;
        box-sizing: border-box;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        transform: translateX(-50%);
      }

      &.and {
        &::before {
          border-color: #c4e9bf;
        }
        .logic-trait {
          background-color: #c4e9bf;
        }
      }

      &.or {
        &::before {
          border-color: #fce4c8;
        }
        .logic-trait {
          background-color: #fce4c8;
        }
      }

      .logic-trait {
        position: absolute;
        width: 3px;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        left: 50%;
        top: 0;
      }

      .logic-operator-wrapper {
        margin: auto;
        display: flex;
        flex-direction: column;
        border: 4px solid #fff;
        z-index: 1;

        .logic-operator {
          cursor: pointer;
          padding: 8px 4px;
          border-radius: 16px;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 600;
          font-family: 'Montserrat';
          text-align: center;
          background-color: #f5f8fb;
          color: #c6d4df;

          &.and.selected {
            background-color: #c4e9bf;
            color: #167322;
          }
          &.or.selected {
            background-color: #fce4c8;
            color: #f88601;
          }

          &:nth-child(1) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          &:nth-last-child(1) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }

    .logic-conditions {
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex: 1 auto;
      min-width: 0;

      .logic-actions {
        display: flex;
        gap: 8px;

        .add-segment-element-btn {
          border-radius: 12px;
          border: 1px solid #f3f7fe;
          background: #f3f7fe;
          color: #1260eb;
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 500;

          i {
            font-size: 24px !important;
          }
        }
      }
    }
  }

  .delete-btn {
    z-index: 1;
    display: none;
    position: absolute;
    height: 40px;
    width: 40px;
    top: -20px;
    right: -20px;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    i {
      font-size: 18px !important;
      margin-right: auto !important;
      margin: auto;
    }
  }
}

.add-segment-element-content {
  min-width: 158px;
  background: #ffffff;
  box-shadow: 0px 14px 19px rgb(0 0 0 / 3%);
  border-radius: 8px;
  margin: 4px auto;
  z-index: 1002 !important;
  animation: openAnimation 0.225s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

  .add-segment-element-list {
    max-height: 200px;
    padding: 4px;
    overflow-y: auto;

    .add-segment-element-item {
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 8px;
      font-family: 'Montserrat';
      color: #000;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;

      cursor: pointer;
      &:hover {
        background-color: #1260eb0f;
      }

      i {
        margin-right: 8px;
        font-size: 16px;
      }
    }
  }
}

@keyframes openAnimation {
  0% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1.1);
  }
}
