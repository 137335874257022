.jimo-toggle {
  &:hover {
    // Hover when not checked
    &:not(.react-toggle--checked) {
      .react-toggle-track {
        background-color: var(--n-500) !important;
        border-color: var(--n-500);
      }
    }
  }

  &:not(.react-toggle--checked) {
    &.react-toggle--disabled {
      .react-toggle-track {
        border-color: var(--n-500);
        background-color: var(--n-500);
      }
      .react-toggle-thumb {
        background-color: var(--n-400);
      }
    }

    .react-toggle-thumb {
      left: 2px;
    }
  }
  &.react-toggle--checked {
    &:hover:not(.react-toggle--disabled) {
      .react-toggle-track {
        background-color: var(--b-400);
        &:hover {
          background-color: var(--b-400);
        }
      }
    }
    &.react-toggle--disabled {
      .react-toggle-track {
        border-color: var(--b-200);
        background-color: var(--b-200);
      }
      .react-toggle-thumb {
        background-color: var(--b-100);
        &::after {
          color: var(--b-200);
        }
      }
    }

    .react-toggle-thumb {
      left: 22px;
      border-color: transparent;
      &::after {
        opacity: 1;
        color: var(--b-400);
      }
    }
    .react-toggle-track {
      border-color: var(--b-400);
      background-color: var(--b-400);
    }
  }
  &.react-toggle--focus {
    .react-toggle-thumb {
      box-shadow: none;
    }
  }

  .react-toggle-track {
    width: 44px;
    height: 24px;
    background-color: var(--n-400);
    border: 2px solid var(--n-400);
    border-radius: 1000px;
  }
  .react-toggle-thumb {
    width: 24px;
    height: 24px;
    border: none;
    top: 2px;
    &::after {
      width: 100%;
      height: 100%;
      content: '\e950';
      font-family: 'jimo-icons' !important;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: all 200ms ease;
    }
  }
}
