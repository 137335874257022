.poke-block-concept {
  .concept-thumbnail {
    position: relative;
    width: 100%;
    height: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    &:hover {
      .concept-btn-play {
        background-color: rgba($color: #fff, $alpha: 0.3);
        animation: unset;
      }
    }

    .concept-thumbnail-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.08);
    }
  }
  .concept-btn-play {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 76px;
    height: 76px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: rgba($color: #fff, $alpha: 0.2);
    font-size: 20px;
    transition: all 200ms ease-in-out;
    animation: concept-btn-play-pulse 1500ms ease-in-out infinite;

    i {
      position: relative;
      color: #fff;
    }
  }
}

@-webkit-keyframes concept-btn-play-pulse {
  0% {
    box-shadow: 0px 0px 0px 0px #fff;
  }
  100% {
    box-shadow: 0px 0px 0px 30px transparent;
  }
}
@keyframes concept-btn-play-pulse {
  0% {
    box-shadow: 0px 0px 0px 0px #fff;
  }
  100% {
    box-shadow: 0px 0px 0px 30px transparent;
  }
}
