.s-users-segment {
  overflow: hidden;
  height: 100%;
  background: #fcfbfb;
  display: flex;
  flex-direction: column;

  .loader-wrapper {
    display: flex;
    margin: auto;

    .loader {
      margin: auto;
    }
  }

  .segment-header-wrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .segment-content-wrapper {
    overflow-y: auto;
    flex: 1 auto;
    padding: 32px;

    .alert-segment-from-import {
      max-width: 1024px;
      margin: 0 auto;
      margin-bottom: 24px;
    }

    .segment-attributes-editor-wrapper {
      margin: 16px;
      border-radius: 16px;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 24px 94px 0px rgba(0, 0, 0, 0.07);

      .conditions-title {
        margin: 20px;
        display: flex;
        font-weight: 600;
        font-size: 20px;
        align-items: center;

        .icon-wrapper {
          margin-right: 12px;
          width: 36px;
          height: 36px;
          background: #071331;
          border-radius: 33px;
          display: flex;

          i {
            color: white;
            margin: auto;
            font-size: 14px;
          }
        }
      }

      .segment-attributes-editor-content-wrapper {
        margin: 0 20px;
      }
    }
  }
}

.s-user-segment-attributes-editor-content {
  .dropdown-content {
    .block-categories {
      max-height: 400px;
      overflow: scroll;
    }
  }
}
