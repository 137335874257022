.jimer-surveys {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;

  .jimer-surveys-list {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 32px;

    .jimer-survey {
      display: flex;
      padding: 12px 16px;
      align-items: flex-start;
      gap: 10px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.04);
      border-radius: 16px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        i {
          display: flex;
        }
      }

      .jimer-survey-info {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .jimer-survey-title {
          color: #000;
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
        }

        .jimer-survey-date {
          color: rgba(0, 0, 0, 0.5);
        }
      }

      i {
        margin: auto 8px;
        margin-left: auto;
        display: none;
      }
    }
  }
}
