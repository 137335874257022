.settings-content-toggler {
  padding: 0 16px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.03);

  & > .divider {
    margin: 0 !important;
  }

  .settings-ct-item {
    flex: 1 1 auto;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }

    .content-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name-wrapper {
        flex: 1 0 auto;
      }
      .jimo-toggle-wrapper {
        display: flex;
      }
      .toggle-collapse-wrapper {
        display: flex;
        align-items: center;

        .icon-chevron-bottom {
          margin-left: 8px;
          transition: all 100ms ease-in-out;
          transform: rotate(-180deg);
          cursor: pointer;
          &.is-collapsed {
            transform: rotate(0deg);
          }
          &.is-disabled {
            opacity: 0.2;
            cursor: default;
          }
        }
      }
    }
    .children-wrapper {
      .settings-ct-item {
        padding: 4px 0 4px 12px;
        &:first-child {
          margin-top: 6px;
        }
      }
    }
  }

  .section-divider {
    padding: 0;
    margin: 0 !important;
  }
}

.section-wrapper {
  padding: 24px 0;
  display: flex;

  .dragger {
    align-self: center;
    margin-right: 12px;
  }

  .dragger > i {
    color: gray;
    margin: 1px;
    font-size: 14px;
  }

  &.is-dragging {
    .settings-ct-item {
      display: block !important;
      width: 100% !important;

      .content-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name-wrapper {
          flex: 1 0 auto;
        }
        .jimo-toggle-wrapper {
          display: flex;
        }
        .toggle-collapse-wrapper {
          display: flex;
          align-items: center;

          .icon-chevron-bottom {
            margin-left: 8px;
            transition: all 100ms ease-in-out;
            transform: rotate(-180deg);
            cursor: pointer;
            &.is-collapsed {
              transform: rotate(0deg);
            }
            &.is-disabled {
              opacity: 0.2;
              cursor: default;
            }
          }
        }
      }
      .children-wrapper {
        .settings-ct-item {
          padding: 4px 0 4px 12px;
          &:first-child {
            margin-top: 6px;
          }
        }
      }
    }
  }
}
