@import '../../Colors';
@import '../../Mixin';

.text-area {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 4px;

  textarea {
    @include placeholder {
      color: var(--n-500);
      font-family: 'Inter', sans-serif;
      line-height: 150%;
      font-size: 16px;
    }
    position: relative;
    width: 100%;
    border: 1px solid var(--n-300);
    color: #000;
    padding: 12px 16px;
    border-radius: 12px;
    box-sizing: border-box;
    font-family: inherit;
    transition: all 150ms ease-in-out;
    font-size: 16px;
    outline: none;
    &:hover {
      border-color: var(--n-500);
    }
  }

  textarea:focus {
    @include placeholder {
      color: var(--n-500);
    }

    border: 1px solid var(--b-400);
  }

  &.disabled {
    textarea {
      @include placeholder {
        color: #d0d0d0;
      }
      border-color: transparent;
      background: #f0f0f0;
      color: #d0d0d0;
      cursor: not-allowed;
    }
  }
}
