.event-condition {
  cursor: pointer;
  display: flex;
  flex: 1 auto;
  height: 42px;
  padding: 8px 12px;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  border: 1px solid var(--n-300);
  position: relative;
  transition: all 300ms ease-in-out 0s;

  &.is-invalid {
    border-color: var(--r-500);
  }

  &.disabled {
    pointer-events: none;
  }

  &.selected {
    border-color: var(--b-400);
    box-shadow: 0px 0px 0px 4px #1260eb33;
  }

  &.compact {
    .icon-wrapper {
      height: 32px;
      width: 32px;

      i {
        font-size: 18px;
      }
    }

    .event-content {
      gap: 0;
    }
  }

  &:hover {
    border-color: var(--b-400);
    box-shadow: 0px 0px 0px 4px #1260eb33;

    .actions-wrapper {
      opacity: 1;
    }
  }

  .icon-wrapper {
    height: 40px;
    width: 40px;
    display: flex;
    border-radius: 12px;
    flex-shrink: 0;

    &.element {
      background-color: var(--g-100);
    }

    &.input {
      background-color: var(--p-200);
    }

    &.url {
      background-color: var(--b-150);
    }

    &.delay {
      background-color: var(--n-200);
    }

    &.tour-seen {
      background-color: var(--o-300);
    }
    &.survey-response {
      background-color: var(--n-100);

      &.filled {
        background-color: var(--b-150);
      }
    }

    i {
      margin: auto;
      font-size: 24px;
    }
  }

  .event-content {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    min-width: 0;

    .condition-type {
    }

    .condition-details {
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }

  .element-image-wrapper {
    height: 100%;
    border-radius: 8px;
    border: 1px solid var(--n-300);
    overflow: hidden;

    img {
      height: 100%;
      max-width: 120px;
      object-fit: contain;
    }
  }

  .actions-wrapper {
    position: absolute;
    right: 8px;
    display: flex;
    gap: 8px;
    opacity: 0;
    transition: all 300ms ease-in-out 0s;
  }
}
