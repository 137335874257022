@import '../../Variables';

.poke-block-user {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  animation: block-user-in $animationBlockInDuration ease-in-out
    $animationBlockInDelay both;
  font-size: 14px;
  font-family: 'Inter', sans-serif;

  &.is-animating-out {
    animation: block-user-out $animationBlockOutDuration ease-in-out both;
  }

  .pb-user-avatar {
    width: 50px;
    height: 50px;
    border-radius: 12px;
    background-size: cover;
    background-position: center;
    margin-right: 12px;
  }
  .pb-user-infos {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #797979;

    strong {
      color: #000;
    }
    span {
      opacity: 0.7;
    }
  }
}

.poke-block-user-cursor {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  gap: 8px;

  .pb-user-avatar {
    border-radius: 50% !important;
    background-size: cover;
    background-position: center;
  }

  .pb-user-infos {
    flex-direction: row;
    align-items: center;
  }
}

@-webkit-keyframes block-user-in {
  0% {
    opacity: 0;
    // transform: matrix(1, 0, 0, 1, 50, 0);
  }
  100% {
    opacity: 1;
    // transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
@keyframes block-user-in {
  0% {
    opacity: 0;
    // transform: matrix(1, 0, 0, 1, 50, 0);
  }
  100% {
    opacity: 1;
    // transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
@-webkit-keyframes block-user-out {
  0% {
    opacity: 1;
    // transform: matrix(1, 0, 0, 1, 0, 0);
  }
  100% {
    opacity: 0;
    // transform: matrix(1, 0, 0, 1, 50, 0);
  }
}
@keyframes block-user-out {
  0% {
    opacity: 1;
    // transform: matrix(1, 0, 0, 1, 0, 0);
  }
  100% {
    opacity: 0;
    // transform: matrix(1, 0, 0, 1, 50, 0);
  }
}
