body {
  &.transparent-mode {
    background-color: transparent;

    &.target-not-found-mode {
      background: #00000033;
    }
  }
}

.embedded-poke-builder {
  height: 100%;
  width: 100%;

  .builder-channel-customize-options {
    &.with-saver {
      padding-bottom: 96px;
    }
  }

  .loading-overlay {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    flex-direction: column;
    gap: 12px;
  }
}
