.tracker-header {
  display: flex;
  align-items: center;
  position: relative;
  flex: 0 1 auto;
  padding: 24px;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid rgba(0, 0, 0, 0);

  &.scrolled {
    background-color: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
  }

  .infos {
    flex: 1 1 auto;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;

    .back-btn {
      background-color: #f4f4f4;
      height: 40px;
      width: 40px;
      border-radius: 12px;
      padding: 0;
      margin-right: 16px;

      &:hover {
        background-color: #dddddd;
      }

      i {
        margin: auto !important;
        color: #000000;
      }
    }

    .tracker-name-input {
      .input {
        display: flex;
        max-width: 640px;
        padding: 4px 8px;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 0;
        overflow: hidden;
        color: var(--content-primary, #071331);
        text-overflow: ellipsis;
        font-family: Montserrat;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 38.4px */
        letter-spacing: -0.5px;
        transition: all 0.2s ease-in-out;

        &:hover,
        &:focus {
          background: var(--surface-secondary, #f0f2f4);
        }

        &::placeholder {
          font-size: 32px;
          font-weight: 700;
          line-height: 120%; /* 38.4px */
          letter-spacing: -0.5px;
        }
      }
    }
  }
  .actions {
    display: flex;
    margin-bottom: auto;
    gap: 8px;

    .edit-group {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      border: 1px solid var(--border-minimal, #e5e5e5);
      background: var(--surface-primary, #fff);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
      overflow: hidden;

      .divider {
        height: 60%;
      }

      .edit-group-item {
        cursor: pointer;
        display: flex;
        gap: 8px;
        color: #000;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 20px */
        align-items: center;
        padding: 12px;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: var(--b-100);
          color: var(--b-400);
        }

        i {
          font-size: 18px;
        }

        span {
          max-width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .btn {
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
      border-radius: 12px;
      transition: all 300ms ease-in-out 0s;
      align-self: center;
      font-weight: 500;
      font-size: 18px;
      height: 44px;

      i {
        font-size: 18px;
      }
    }

    .dropdown-trigger {
      .btn {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
      }
    }
  }
}

.dropdown-tracker-more-actions-content {
  .menu-item {
    display: flex;
    gap: 8px;
    font-size: 16px;
  }
}
