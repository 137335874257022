.modal-launch-builder {
  max-height: 80%;
  overflow: hidden;
  width: unset;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;

  &.preview {
    max-height: unset;
    height: 80%;
    width: 70%;

    @media (max-height: 900px) {
      height: 95%;
    }
    @media (max-width: 1200px) {
      width: 90%;
    }
  }

  &.no-max-height {
    max-height: unset;
  }

  &.pending {
    width: 560px;

    .actions {
      margin: 0 24px 24px 24px;
      justify-content: center;
    }
  }

  .modal-title {
    display: flex;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 24px;
    font-family: 'Montserrat';
    margin-bottom: 0;

    & > div {
      margin: auto 0;
    }

    .back-btn {
      background-color: #f4f4f4;
      height: 40px;
      width: 40px;
      border-radius: 12px;
      padding: 0;
      margin-right: 16px;

      &:hover {
        background-color: #dddddd;
      }

      i {
        margin: auto !important;
        color: #000000;
      }
    }

    .text {
      display: flex;
      align-items: center;

      i {
        margin-right: 16px;
      }
    }
  }

  .content-wrapper {
    flex: 1 1 auto;
    min-height: 0px;
    overflow: hidden;

    &.scrollable {
      overflow: auto;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      padding: 24px;

      .tabs .tab-list {
        margin-top: 0;
        margin-bottom: 24px;

        .tab-list-item {
          padding: 0 4px;
          padding-top: 0;
          padding-bottom: 24px;
          font-weight: 400;
          &:not(.tab-list-active) {
            color: #000;
            &:hover {
              color: #1260eb;
            }
          }
        }
      }

      .section-title {
        margin-bottom: 24px;
        font-size: 18px;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 18px;
      }

      .inputs-wrapper {
        .section-title {
          margin-bottom: 8px;
        }

        .input-group {
          width: 480px;

          .input {
            border-radius: 12px;
            height: 56px;
          }

          &.tracker-title {
            .input {
              font-size: 16px;
            }
          }
        }

        .tracker-url-wrapper {
          position: relative;

          .input {
            font-size: 16px;
          }
          .icon-wrapper {
            position: absolute;
            height: 100%;
            top: 0;
            right: 0;
            margin-right: 8px;
            display: flex;

            i {
              margin: auto;
              padding: 8px;
              cursor: pointer;
            }
          }

          .input-group-wrapper {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 2px;

            .input-group {
              &.is-invalid {
                .input-main-wrapper {
                  border-color: var(--r-400);

                  i {
                    color: var(--r-400);
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }

      .in-app-toggle {
        display: flex;
        gap: 8px;
        color: rgba(0, 0, 0, 0.4);
        align-items: center;
        margin-bottom: 12px;

        .jimo-toggle-wrapper {
          display: flex;
        }
      }

      .extension-alert {
        width: 480px;
        border-radius: 12px;
      }

      .recording-wrapper {
        display: flex;
        margin: auto;
        margin-bottom: 16px;
        padding: 8px;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        border-radius: 30px;
        background: var(--surface-accent-secondary-negative, #fcf5f7);
      }

      .pending-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .pending-title {
          color: var(--content-primary, #071331);
          text-align: center;
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 125%; /* 25px */
        }

        .pending-subtitle {
          color: var(--content-secondary, #4d637b);
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 24px */
        }
      }
    }
  }

  .actions {
    margin: 0 24px 24px 24px;
    justify-content: flex-end;
    display: flex;
    gap: 8px;

    .back-btn {
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

.dropdown-build-url-suggestions-content {
  z-index: 99999 !important;
  border-radius: 12px;
  width: 480px;
  max-height: 300px;
  display: flex;

  .dropdown-content {
    background: #fff !important;
    flex: 1 auto;
    overflow-y: auto;
    padding: 8px;
    margin-top: 6px;
    border-radius: 12px;

    .url-suggestion {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.7);
      padding: 16px;
      border-radius: 12px;
      transition: all 100ms ease-in-out;
      overflow: hidden;
      &:hover {
        background-color: #e3ecff;

        .btn-delete-url {
          opacity: 1;
        }
      }

      .btn-delete-url {
        position: absolute;
        right: 8px;
        top: 8px;
        transition: all 100ms ease-in-out;
        opacity: 0;
        padding: 8px 10px;
        border-radius: 6px;
        background-color: #fff;
        border: 1px solid #e5e5e5;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          background-color: rgb(255, 237, 237);
        }

        i {
          color: #ff3737;
        }
      }
    }
  }
}
