.settings-role-manager {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;

  .card-default-roles {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .card-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .list-roles {
      display: flex;
      gap: 12px;

      .item-role {
        flex: 1;
        border-radius: 16px;
        border: 1px solid var(--n-100);
        padding: 12px 20px;
        cursor: pointer;
        transition: all 100ms ease;
        &.role-admin {
          background-image: url(./imgs/bg-admin.svg);
          background-size: cover;
          &:hover {
            background-color: var(--b-100);
          }
        }
        &.role-member {
          background-image: url(./imgs/bg-contributor.svg);
          background-size: cover;
          &:hover {
            background-color: var(--p-100);
          }
        }
        &.role-editor {
          background-image: url(./imgs/bg-editor.svg);
          background-size: cover;
          &:hover {
            background-color: var(--o-100);
          }
        }
        &.role-viewer {
          background-image: url(./imgs/bg-viewer.svg);
          background-size: cover;
          &:hover {
            background-color: var(--g-100);
          }
        }

        img {
          margin-bottom: 16px;
        }
        .role-name-wrapper {
          display: flex;
          margin-bottom: 8px;

          span {
            margin-left: 8px;
          }
        }
      }
    }
  }
  .card-custom-roles {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .card-header-main {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
    .list-roles {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 12px;

      .item-role {
        background-color: var(--n-50);
        border-radius: 16px;
        padding: 12px 20px;
        cursor: pointer;
        transition: all 100ms ease;
        &:hover {
          background-color: var(--n-100);
        }

        .role-members-avatars {
          margin-bottom: 16px;
          display: flex;

          .item-avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid #fff;
            width: 32px;
            height: 32px;
            background-size: cover;
            background-position: center;
            &:not(.more) {
              font-size: 18px;
              font-weight: 500;
              color: #fff;
            }
            &.empty {
              border: 1px dashed var(--n-600);
            }
            &.more {
              background-color: var(--n-200);
            }
            &:first-child {
              margin-left: 0px;
            }
            @for $i from 2 through 4 {
              &:nth-child(#{$i}n) {
                margin-left: -3 * $i * 1px;
                z-index: 1 + $i;
                position: relative;
              }
            }
          }
        }

        .role-name-wrapper {
          display: flex;
          margin-bottom: 8px;

          span {
            margin-left: 8px;
          }
        }
      }
    }
  }
}

.built-in-role-edit-disabled-content {
  max-width: 200px;
}

.modal-role-permissions-overlay {
  justify-content: flex-end;
  padding: 16px;
  box-sizing: border-box;
}

.modal-role-permissions {
  width: 660px;
  border-radius: 12px;
  box-shadow: 0px 24px 94px 0px rgba(0, 0, 0, 0.07);
  padding: 0;
  overflow-y: hidden;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  &.empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #00000056;
  }
  .alert {
    border-radius: 12px;
    margin-bottom: 12px;
    font-weight: 500;
  }

  .role-title {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--n-100);
    padding: 24px;
    flex: 0;
  }
  .role-scroll-wrapper {
    flex: 1 0;
    overflow: auto;
    padding: 24px;
  }

  .role-name-wrapper {
    .input-legend {
      small {
        opacity: 0;
        transition: all 100ms ease;
        margin-left: 4px;
        &.visible {
          opacity: 1;
        }
      }
    }
  }

  .permissions-section {
    padding: 24px;
    margin-top: 24px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    position: relative;
    cursor: pointer;
    &.built-in {
      opacity: 0.6;
    }

    .permission-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      &.disabled {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
    .permissions-list {
      & > div {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
      .permission-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        &.is-disabled {
          cursor: not-allowed;
        }
      }
      .permission-infos {
        display: flex;
        align-items: center;

        .icon-wrapper {
          font-size: 24px;
          margin-right: 16px;
          color: var(--n-700);
        }
        .name-wrapper {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
