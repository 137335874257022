.variable-element {
  display: inline-flex;
  gap: 4px;
  border: 1px solid var(--n-400);
  background-color: var(--n-100);
  padding: 4px 8px;
  border-radius: 8px;
  height: 30px;
  box-sizing: border-box !important;
  align-items: center;
  cursor: pointer;
  margin: 0 2px;

  &:hover {
    border-color: var(--n-800);
    background-color: var(--n-200);

    .variable-element-content {
      color: var(--n-800);

      i {
        color: var(--n-800);
      }
    }
  }

  &.selected {
    background-color: var(--b-100);
    border-color: var(--b-400);

    .variable-element-content {
      color: var(--b-400);

      i {
        color: var(--b-400);
      }
    }
  }

  .variable-element-content {
    display: flex;
    align-items: center;

    .hubspot-logo,
    .segment-logo {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }

    i {
      margin: auto;
      color: var(--n-700);
      font-size: 16px;
    }
  }
}
