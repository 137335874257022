.s-onboarding-details-step-1-1 {
  width: 546px;
  margin-left: auto;
  margin-right: auto;
  &.is-exiting {
    h1 {
      animation: onboarding-text-slide-right-out 600ms ease-in-out 500ms both;
    }
    p {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 300ms both;
    }
    form {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 100ms both;
    }
  }

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    width: 396px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    animation: onboarding-text-slide-right 600ms ease-in-out 600ms both;
    // animation: onboarding-text-slide-right 600ms ease-in-out 1800ms both;
  }
  p {
    text-align: center;
    font-size: 16px;
    animation: onboarding-content-slide-right 600ms ease-in-out 800ms both;
    // animation: onboarding-content-slide-right 600ms ease-in-out 2200ms both;
  }

  form {
    color: #071331;
    animation: onboarding-content-slide-right 600ms ease-in-out 1200ms both;
    // animation: onboarding-content-slide-right 600ms ease-in-out 2800ms both;

    .form-label {
      margin-bottom: 16px;
      margin-top: 32px;
      font-weight: 500;
    }

    .inputs-wrapper {
      display: flex;

      .input-group {
        flex-grow: 1;
        &:nth-child(1) {
          margin-right: 8px;
        }
        &:nth-child(2) {
          margin-left: 8px;
        }
        input {
          border-radius: 12px;
          &:focus {
            box-shadow: inset 0 0 0 2px #e9effd;
            border-color: #88abff;
          }
        }
      }
    }
    .source-select {
      margin-bottom: 12px;
    }
    .input-group {
      &.dashed {
        input {
          border-style: dashed;
        }
      }
      input {
        border-radius: 12px;
        &:focus {
          box-shadow: inset 0 0 0 2px #e9effd;
          border-color: #88abff;
        }
      }
    }

    .usages-wrapper {
      display: grid;
      grid-template-columns: 50% 50%;

      .usage-item {
        display: flex;
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding: 8px;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        margin-bottom: 16px;
        transition: all 100ms ease-in-out;
        &:nth-child(odd) {
          margin-right: 8px;
        }
        &:nth-child(even) {
          margin-left: 8px;
        }
        &:hover {
          border-color: rgba(#1260eb, 0.5);
        }
        &.active {
          border-color: rgba(#1260eb, 0.5);
          background-color: rgba(#1260eb, 0.05);

          &.usage-1 {
            .usage-icon-wrapper {
              background-color: #e6fde8;
            }
          }
        }

        .usage-icon-wrapper {
          padding: 8px;
          background-color: #f1f1f1;
          border-radius: 12px;
          margin-right: 12px;
          display: flex;
          align-items: center;

          i {
            font-size: 24px;
          }
        }
      }
    }

    .btn {
      margin-top: 10%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@-webkit-keyframes onboarding-text-slide-right {
  0% {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 50, 0);
    word-spacing: 10px;
  }
  100% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
    word-spacing: 2px;
  }
}
@keyframes onboarding-text-slide-right {
  0% {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 50, 0);
    word-spacing: 10px;
  }
  100% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
    word-spacing: 0px;
  }
}
@-webkit-keyframes onboarding-content-slide-right {
  0% {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 50, 0);
    word-spacing: 10px;
  }
  100% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
    word-spacing: 2px;
  }
}
@keyframes onboarding-content-slide-right {
  0% {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 50, 0);
  }
  100% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

@-webkit-keyframes onboarding-text-slide-right-out {
  0% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
    word-spacing: 0px;
  }
  100% {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 50, 0);
    word-spacing: 10px;
  }
}
@keyframes onboarding-text-slide-right-out {
  0% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
    word-spacing: 0px;
  }
  100% {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 50, 0);
    word-spacing: 10px;
  }
}
@-webkit-keyframes onboarding-content-slide-right-out {
  0% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  100% {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 50, 0);
  }
}
@keyframes onboarding-content-slide-right-out {
  0% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  100% {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 50, 0);
  }
}
