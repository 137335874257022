.event-header {
  display: flex;
  padding: 24px;
  flex: 1;
  align-items: center;

  .back-btn {
    height: 40px;
    width: 40px;

    i {
      margin: auto;
    }
  }

  .btns-wrapper {
    display: flex;
    margin-left: auto;
    gap: 16px;
  }
}
