@import '../../Variables';

.poke-block-title {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 20px;
  animation: block-title-in $animationBlockInDuration ease-in-out
    $animationBlockInDelay both;
  cursor: default;
  &.is-animating-out {
    animation: block-title-out $animationBlockOutDuration ease-in-out forwards;
  }
  &.align-center {
    text-align: center;
  }
  &.align-right {
    text-align: right;
  }
  &.is-in-poke-banner {
    padding: 0;
    margin: 0;
  }
  &.is-single-banner-block {
    margin: 8px auto;
  }
  &.is-clickable {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
    overflow-wrap: break-word;
    min-height: var(--poke-block-body-font-size);
  }

  .a-div {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  span.variable.in-builder {
    border: 1px dashed;
    border-radius: 4px;
    padding: 2px 4px;
    margin: 0 2px;
  }
}

@-webkit-keyframes block-title-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes block-title-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes block-title-out {
  0% {
    opacity: 1;
    // transform: matrix(1, 0, 0, 1, 0, 0);
    // word-spacing: 0px;
  }
  100% {
    opacity: 0;
    // transform: matrix(1, 0, 0, 1, 25, 0);
    // word-spacing: 10px;
  }
}
@keyframes block-title-out {
  0% {
    opacity: 1;
    // transform: matrix(1, 0, 0, 1, 0, 0);
    // word-spacing: 0px;
  }
  100% {
    opacity: 0;
    // transform: matrix(1, 0, 0, 1, 25, 0);
    // word-spacing: 10px;
  }
}
