.c-label {
  display: inline-flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border: 0;

  &.regular {
    padding: 8px 12px;
  }

  &.small {
    padding: 4px 12px;
  }

  &.x-small {
    padding: 4px 8px;
  }

  &.primary {
    &.neutral {
      background: var(--n-200);
      color: var(--n-800);
    }
    &.negative {
      background: var(--r-400);
      color: var(--white);
    }
    &.alert {
      background: var(--o-500);
      color: var(--white);
    }
    &.brand {
      background: var(--b-400);
      color: var(--white);
    }
    &.positive {
      background: var(--g-400);
      color: var(--white);
    }
  }
  &.secondary {
    &.neutral {
      border: 1px solid var(--n-300);
      background: var(--white);
      color: var(--n-800);
    }
    &.negative {
      background: var(--r-100);
      color: var(--r-500);
    }
    &.alert {
      background: var(--o-100);
      color: var(--o-500);
    }
    &.brand {
      background: var(--b-100);
      color: var(--b-500);
    }
    &.positive {
      background: var(--g-100);
      color: var(--g-500);
    }
  }
}
