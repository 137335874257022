.post-builder-bar-wrapper {
  display: flex;
  flex-direction: column;
  z-index: 2;
  min-height: 0;
  margin-bottom: 8px;
  flex-shrink: 0;

  .post-builder-bar {
    width: fit-content;
    background: #fff;
    display: flex;
    gap: 8px;
    padding-top: 16px;

    .divider {
      margin: auto;
      height: 16px;
    }

    .bar-item {
      cursor: pointer;
      margin: 0 auto;
      height: 40px;
      width: 40px;
      display: flex;
      border-radius: 8px;
      transition: all 150ms ease-in-out;
      border: 2px solid transparent;

      &:hover {
        background: rgba(18, 96, 235, 0.1);
        border: 2px solid #1260eb;
      }
      &.selected {
        background: rgba(18, 96, 235, 0.1);
        color: #1260eb;

        &.translations {
          border-radius: 8px 8px 0px 0px;
        }
      }

      i {
        margin: auto;
        font-size: 24px;
      }
    }
  }

  .changelog-navigator-wrapper,
  .post-block-manager-wrapper,
  .theme-manager-wrapper,
  .translation-manager-wrapper {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 10px 4px 10px 0px rgba(0, 0, 0, 0.06);
  }
}
