.live-timing-options {
  display: flex;

  .date-picker-wrapper {
    width: unset;
    min-width: 232px;
  }

  .date-options-item {
    height: 40px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px 12px;
    color: #999999;
    transition: all 100ms ease-in-out;
    margin-right: 16px;
    border: 1px solid #999999;
    width: auto;
    flex-shrink: 1;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.06);

    &.is-active {
      color: #1260eb;
      border-color: #1260eb;
      small {
        color: rgba(255, 255, 255, 0.6);
      }
    }
    &:hover {
      border-color: #1260eb;
      color: #1260eb;
    }

    i {
      margin-right: 8px;

      &.icon-close {
        font-size: 10px;
      }
    }
  }
}
