@import './../../Colors';

.btn {
  display: flex;
  gap: 8px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  border: 0;
  outline: 0;
  padding: 12px 16px;
  transition: all 200ms ease;
  align-items: center;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  justify-self: flex-start; // Not sure about this, we put it to prevent button to fill the whole parent space
  &.is-disabled {
    cursor: not-allowed;
  }
  &.is-thin {
    padding: 8px 12px;
    border-radius: 8px;

    i {
      font-size: 12px;
    }
  }
  &.is-tertiary {
    padding: 0;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }
  &.is-icon-only {
    padding: 12px;
    &.is-thin {
      i:not(.isax) {
        font-size: 10px;
      }
      i.isax {
        font-size: 16px;
      }
    }
  }

  &.is-secondary.is-default {
    &:not(.is-disabled) {
      background-color: #fff;
      border-color: var(--n-300);
      color: var(--n-800);
      &:hover {
        background-color: var(--n-100);
      }
      &:active {
        background-color: var(--n-300);
        color: var(--n-700);
      }
    }
    &.is-disabled {
      border-color: var(--n-300);
      color: var(--n-500);
      background-color: #fff;
    }
  }
  &.is-secondary.is-danger {
    &:not(.is-disabled) {
      background-color: #fff;
      border-color: var(--n-300);
      color: var(--r-400);
      &:hover {
        color: var(--r-500);
        background-color: var(--r-100);
      }
      &:active {
        background-color: var(--r-100);
        border-color: var(--r-100);
        color: var(--r-300);
      }
    }
    &.is-disabled {
      border-color: var(--n-300);
      color: var(--r-200);
      background-color: #fff;
    }
  }

  &.is-primary.is-default {
    &:not(.is-disabled) {
      background-color: var(--n-800);
      border-color: var(--n-800);
      color: #fff;
      &:hover {
        background-color: var(--n-800);
        color: var(--n-400);
      }
      &:active {
        background-color: var(--n-800);
        color: var(--n-500);
      }
    }
    &.is-disabled {
      border-color: var(--n-300);
      color: var(--n-600);
    }
  }
  &.is-primary.is-danger {
    &:not(.is-disabled) {
      background-color: var(--r-400);
      border-color: var(--r-400);
      color: #fff;
      &:hover {
        color: var(--r-200);
      }
      &:active {
        color: var(--r-300);
      }
    }
    &.is-disabled {
      border-color: var(--n-300);
      color: var(--r-200);
      background-color: var(--r-100);
    }
  }

  &.is-tertiary.is-default {
    &:not(.is-disabled) {
      color: var(--b-400);
      &:hover {
        color: var(--b-300);
      }
      &:active {
        color: var(--b-300);
      }
    }
    &.is-disabled {
      color: var(--b-200);
    }
  }
  &.is-tertiary.is-danger {
    &:not(.is-disabled) {
      color: var(--r-400);
      &:hover {
        color: var(--r-300);
      }
      &:active {
        color: var(--r-300);
      }
    }
    &.is-disabled {
      color: var(--r-200);
    }
  }
  &.is-light {
    background-color: var(--b-100);
    border-color: var(--b-100);
    color: var(--b-400);
    &:hover {
      background-color: var(--b-100);
    }
    &:active {
      background-color: var(--b-100);
    }
    &.is-disabled {
      border-color: var(--n-300);
      color: var(--n-600);
    }
  }

  i {
    &.isax {
      font-size: 20px;
    }
    font-size: 16px;
  }
}

.btn.is-loading.is-loading.is-loading {
  cursor: default;
  text-shadow: none !important;
  color: transparent !important;
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: all 0s linear, opacity 0.1s ease;
  transition: all 0s linear, opacity 0.1s ease;

  &::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -0.64285714em 0 0 -0.64285714em;
    width: 1em;
    height: 1em;
    border-radius: 500rem;
    border: 0.15em solid rgba(0, 0, 0, 0.15);
  }
  &::after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -0.64285714em 0 0 -0.64285714em;
    width: 1em;
    height: 1em;
    -webkit-animation: button-spin 0.6s linear;
    animation: button-spin 0.6s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #fff transparent transparent;
    border-style: solid;
    border-width: 0.15em;
    -webkit-box-shadow: 0 0 0 1px transparent;
    box-shadow: 0 0 0 1px transparent;
  }
  .icon {
    background-color: transparent;
    box-shadow: none;
  }
}

@keyframes button-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
