.checklist-navigator-wrapper {
  flex-grow: 1;
  padding: 12px;
  background: #fff;

  .checklist-navigator {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .section-title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }

    .blocks-wrapper {
      &.empty,
      &.in-app:not(.expanded) {
        .blocks-title {
          border-radius: 12px;
        }
      }

      &.is-checklist-open {
        border-radius: 12px;
        overflow: hidden;
      }

      .blocks-title {
        cursor: pointer;
        display: flex;
        background: white;
        padding: 0;
        border-radius: 12px 12px 0px 0px;
        transition: border-radius 0.3s ease-in-out;

        i {
          font-size: 16px;
          padding: 12px;
          margin: auto 0;
        }

        .icon-expand-wrapper {
          margin-right: 8px;
          padding: 8px;
          font-size: 12px;
          display: flex;

          i {
            padding: 0;
            margin: auto;
            font-size: 12px;
            display: block;
            transition: 0.3s;
          }
        }

        &:hover {
          background: var(--b-150);
        }

        &.selected {
          background: #1260eb;
          color: #fff;
        }

        .step-name-input {
          padding: 0;
          line-height: 16px;
          margin-right: auto;

          .input-main-wrapper {
            border: 0;
            border-radius: 0;

            .input {
              color: #fff;
              padding: 0;
              font-size: 14px;
            }
          }
        }
        .step-name {
          padding: 12px 0;
          height: 16px;
          flex: 1 auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: auto;

          &.is-empty {
            color: #b3b3b3;
          }
        }
      }

      .blocks {
        border-radius: 0 0 12px 12px;

        &.expanded {
          overflow: visible;
          margin-bottom: 2px;
        }

        .navigator-item {
          cursor: pointer;
          background: white;
          display: flex;
          padding: 12px;

          &.activated {
            background: #f4f8ff;

            &.top-block {
              border-radius: 12px 12px 0px 0px;
            }
          }

          &.level-1 {
            padding-left: 40px;
          }

          &.level-2 {
            padding-left: 60px;
          }

          &.level-3 {
            padding-left: 80px;
          }

          .icon-wrapper {
            height: 16px;
            width: 16px;
            margin-right: 12px;
            display: flex;
            background: #e6fde8;
            border-radius: 4px;

            &.header {
              background: var(--g-400);

              i {
                color: white;
              }
            }

            &.progress {
              background: var(--p-200);
            }

            &.task-list {
              background: var(--b-300);

              i {
                color: white;
              }
            }

            &.task-item {
              background: var(--o-500);

              i {
                color: white;
              }
            }

            &.checklist-dismiss {
              background: var(--p-500);

              i {
                color: white;
              }
            }

            i {
              margin: auto;
              font-size: 10px;
              color: #000;

              &.isax {
                font-size: 12px;
              }
            }
          }

          &:hover {
            background: var(--b-150);

            .delete-icon-wrapper {
              display: flex;
            }
          }

          &.selected {
            background: #1260eb;
            color: #fff;
          }

          &:nth-last-child(1) {
            border-radius: 0px 0px 12px 12px;
          }

          .delete-icon-wrapper {
            display: none;
            margin-left: auto;

            i {
              margin: auto;
              font-size: 12px;
            }
          }
        }

        .add-block-wrapper {
          display: flex;
          padding: 12px;
          align-items: center;
          gap: 12px;
          border: 1px solid #e5e5e5;
          border-radius: 0 0 12px 12px;
          background: white;
          justify-content: center;
          cursor: pointer;
          overflow: visible;

          &.detached {
            border-radius: 8px;
            margin-top: 16px;
            margin-bottom: 3px;
          }

          .add-block-content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            i {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
