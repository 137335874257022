.lightweight-element-selector-builder {
  box-shadow: 0px 9px 16px rgb(12 23 37 / 16%);
  border: 1px solid rgba(0, 0, 0, 0.15);
  z-index: 2147483650;
  border-radius: 12px;
  margin: 36px;
  width: 420px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 19;

  .element-selector-builder {
    background: #f7f9fe;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;

    .handle {
      cursor: move;
      border-radius: 12px;
      display: flex;
      align-items: center;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 16px;

      .icon-drag {
        margin-left: auto;
        background: rgba(214, 214, 214, 0.2);
        border-radius: 8px;
        height: 28px;
        width: 28px;
        display: flex;

        i {
          margin: auto;
          font-size: 16px;
        }
      }
    }

    .content {
      .manual-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .element-count {
          display: flex;
          gap: 8px;
          align-items: center;
          padding: 8px 12px;
          border: 1px solid var(--n-300);
          border-radius: 12px;

          &.multiple {
            background: var(--o-200);
            color: var(--o-500);
          }

          &.empty {
            background: var(--r-100);
            color: var(--r-500);
          }

          &.perfect {
            background: var(--g-100);
            color: var(--g-500);
          }

          i {
            font-size: 20px;
          }
        }

        .element {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .label {
            display: flex;
            align-items: center;

            i {
              margin-left: auto;
            }
          }

          .input-group {
            height: 40px;

            .input {
              background: white;
            }
          }

          textarea {
            resize: none;
            overflow: hidden;
          }
        }

        .switch-to-auto {
          display: flex;
          gap: 8px;
          align-items: center;
          margin: auto;
          padding: 4px;
          cursor: pointer;
        }
      }

      .auto-wrapper {
        border-radius: 12px;
        border: 1px solid var(--n-100);
        background: var(--n-100);

        .element-image-wrapper {
          height: 111px;
          display: flex;
          padding: 8px;
          box-sizing: border-box;

          img {
            height: 100%;
            max-width: 100%;
            object-fit: contain;
            margin: auto;
          }
        }

        .preview-content {
          display: flex;
          gap: 12px;
          align-items: center;
          padding: 8px 12px;
          border-top: 1px solid var(--n-300);

          .edit-btn {
            margin-left: auto;
            padding: 4px;
            cursor: pointer;
          }
        }
      }
    }

    .footer {
      display: flex;
      gap: 8px;
      flex: 0 auto;
      margin-top: auto;
      justify-content: flex-end;
    }
  }
}
