.plan-banner-trial {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #fff;
  background-image: url('./imgs/bg.svg');
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;

  .banner-description {
    max-width: 550px;
  }
}
