.turn-into-dropdown-wrapper {
  display: flex;

  .dropdown-trigger {
    display: flex;

    .turn-into-trigger {
      margin: auto 8px;
      cursor: pointer;

      i {
        margin-left: 8px;
        font-size: 12px;
      }
    }
  }
}

.turn-into-dropdown-content {
  max-height: 400px;
  display: flex;

  .dropdown-content {
    display: flex;

    .options-list {
      width: 300px;
      overflow-y: auto;
      padding: 4px;

      .item {
        display: flex;
        border-radius: 8px;
        padding: 6px 12px;
        gap: 8px;
        cursor: pointer;

        &:hover {
          background: #f3f7fe;

          .icon-wrapper {
            border: 1px solid #1260eb;
          }

          .title {
            color: #1260eb;
          }
        }

        .icon-wrapper {
          border-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          height: 46px;
          width: 46px;

          img {
            height: 100%;
            width: 100%;
          }
        }

        .content-wrapper {
          display: flex;
          flex-direction: column;
          gap: 4px;
          justify-content: center;

          .title {
            font-family: Inter;
            font-size: 16px;
          }

          .description {
            color: #7c8a99;
            font-family: Inter;
            font-size: 14px;
          }
        }
      }
    }
  }
}
