.post-statistics-row {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  margin-bottom: 24px;

  .stat-wrapper {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    cursor: pointer;
    max-width: 50%;

    &:hover {
      background: var(--n-100);
    }

    &.selected {
      border-width: 2px;
      background: var(--b-100);

      font-family: Inter;
      font-size: 14px;
      border-color: var(--b-300);
      color: var(--b-400);

      .stat-content {
        .stat-value {
          color: var(--b-400);
        }
      }
    }


    .stat-icon {
      i {
        font-size: 32px;
      }
    }

    .stat-content {
      display: flex;
      flex-flow: column;
      gap: 8px;

      .stat-value {
        display: flex;
        color: var(--neutral-800-black, var(--neutral-neutral-800, #071331));
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; /* 25px */

        .extra-value {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          margin-left: 8px;
        }
      }

      .stat-title {
        color: var(--n-700);
      }
    }
  }
}
