.dropdown-type-picker-content {
  min-width: 158px;
  background: #ffffff;
  box-shadow: 0px 14px 19px rgb(0 0 0 / 3%);
  margin: 4px auto;
  z-index: 1002 !important;

  .dropdown-content {
    border-radius: 12px;

    .dropdown-menu {
      display: flex;
      flex-direction: column;
      padding: 4px;
      overflow-y: auto;

      .divider {
        margin: 4px;
        width: calc(100% - 8px);
      }

      .dropdown-menu-item {
        display: flex;
        gap: 12px;
        align-items: center;
        padding: 12px;
        border-radius: 8px;
        font-family: 'Montserrat';
        color: #000;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;

        cursor: pointer;
        &:hover {
          background-color: #1260eb0f;
        }

        .icon-wrapper {
          display: flex;
          height: 32px;
          width: 32px;
          border-radius: 8px;
          justify-content: center;
          align-items: center;
          background-color: var(--b-150);

          i {
            margin: auto;
            font-size: 18px;
          }
        }
      }
    }
  }
}

.step-type-picker {
  .dropdown-trigger {
    width: 100%;

    .type-picker-trigger {
      display: flex;
      gap: 8px;
      align-items: center;
      padding: 8px 12px 8px 8px;
      gap: 8px;
      border-radius: 12px;
      border: 1px solid var(--n-300);
      cursor: pointer;

      .icon-wrapper {
        height: 32px;
        width: 32px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--b-150);
      }

      i.icon-chevron-bottom {
        margin-left: auto;
        font-size: 10px;
      }
    }
  }
}
