.segment-attributes-wrapper {
  display: flex;
  max-width: 1042px;
  padding: 24px;
  margin: 0 auto 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 24px 94px 0px rgba(0, 0, 0, 0.07);

  .segment-attributes-title {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
  }

  .segment-legacy-warning {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.05);
    display: flex;
    padding: 12px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    box-sizing: border-box;

    .segment-legacy-warning-title {
      color: #000;
      font-size: 16px;
      font-weight: 500;
    }

    .segment-legacy-warning-description {
      cursor: pointer;
      display: flex;
      gap: 8px;
      color: #1260eb;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;

      i {
        font-size: 12px;
        margin: auto;
      }
    }
  }
}
