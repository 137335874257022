.pick-boost-type {
  width: auto;

  .modal-title {
    align-items: center;

    i {
      margin-right: 16px;
    }
  }

  .boost-modal-content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .boost-type-text {
      color: #000;
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
    }

    .boost-types {
      display: flex;
      gap: 16px;

      .boost-type-block {
        width: 240px;
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.03);
        transition: all 200ms ease-in-out 0s;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        animation: fade-in 400ms ease-in-out both;
        @for $i from 1 through 10 {
          &:nth-child(#{$i}n) {
            animation-delay: #{(5 - 5 / ((3 + $i) * 0.3)) * 200}ms;
          }
        }

        &:hover {
          scale: 1.05;
          border-color: #1260eb;
          box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06);

          .title,
          .template-header {
            color: #1260eb;
          }
        }

        .type-header {
          display: flex;
          flex-direction: column;
          height: 192px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;

          .type-wrapper {
            display: flex;
            padding: 10px 12px;

            .icon {
              font-size: 16px;
            }

            .type {
              margin-left: 8px;
              font-size: 16px;
              text-transform: capitalize;
            }
          }

          .img-wrapper {
            display: flex;
            flex: 1 1 auto;
            min-height: 0px;
            overflow: hidden;

            img {
              margin: auto;
              position: relative;
              z-index: 1;
            }
          }
        }

        &.modal {
          .type-header {
            background: #fff9f3;
            border: 1px solid #f0f2f4;
          }
        }

        &.banner {
          .type-header {
            border: 1px solid #f0f2f4;
            background: #f5f2fe;
          }
        }

        &.snippet {
          .type-header {
            border: 1px solid #f0f2f4;
            background: #e6fde8;
          }
        }
      }
    }
  }
}
