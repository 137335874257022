.bell-icon {
  position: relative;
  width: 40px;
  height: 30px;
  display: flex;
}
.bell-icon svg {
  margin: auto;
  position: relative;
  right: 2%;
  width: 100%;
  height: 100%;
  stroke: rgba(0, 0, 0);
  transform: scale(1.2);
}
.bell-icon .bell-icon__group {
  transform-origin: 50% 2px;
  transform: rotate(-8deg);
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
.bell-icon .bell-icon__ball {
  transform-origin: 50% 2px;
  transform: translateX(-6.5%);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}
.bell-icon:focus,
.bell-icon:hover {
  outline: none;
  box-shadow: 0 0 12px -8px rgba(0, 0, 0, 0.6);
}

.bell-icon.animate-on-hover:hover {
  .bell-icon__group {
    animation: none;
    animation: emojiBellAnimateGroupHover 2s forwards !important;
  }
  .bell-icon__ball {
    animation: none;
    animation: emojiBellAnimateBallHover 2s forwards !important;
  }
}
.bell-icon.animate-on-mount {
  .bell-icon__group {
    animation: emojiBellAnimateGroup 2.3s forwards;
  }
  .bell-icon__ball {
    animation: emojiBellAnimateBall 2.3s forwards;
  }
}

@keyframes emojiBellAnimateGroup {
  0%,
  100% {
    transform: rotate(-8deg);
  }
  17.542% {
    transform: rotate(0deg);
  }
  35.084% {
    transform: rotate(-20deg);
  }
  48.2405% {
    transform: rotate(20deg);
  }
  57.0115% {
    transform: rotate(-20deg);
  }
  64.9054% {
    transform: rotate(8deg);
  }
  74.5535% {
    transform: rotate(-15deg);
  }
  78.939% {
    transform: rotate(-7deg);
  }
}
@keyframes emojiBellAnimateBall {
  0%,
  100% {
    transform: translateX(-6.5%);
  }
  17.542% {
    transform: translateX(0%);
  }
  21.9275% {
    transform: translateX(-1%);
  }
  35.084% {
    transform: translateX(11%);
  }
  48.2405% {
    transform: translateX(-11%);
  }
  52.626% {
    transform: translateX(0%);
  }
  59.6428% {
    transform: translateX(10%);
  }
  68.4138% {
    transform: translateX(-11%);
  }
  78.939% {
    transform: translateX(11%);
  }
  85.9558% {
    transform: translateX(-11%);
  }
}
@keyframes emojiBellAnimateGroupHover {
  0%,
  100% {
    transform: rotate(-8deg);
  }
  17.542% {
    transform: rotate(0deg);
  }
  35.084% {
    transform: rotate(-20deg);
  }
  48.2405% {
    transform: rotate(20deg);
  }
  57.0115% {
    transform: rotate(-20deg);
  }
  64.9054% {
    transform: rotate(8deg);
  }
  74.5535% {
    transform: rotate(-15deg);
  }
  78.939% {
    transform: rotate(-7deg);
  }
}
@keyframes emojiBellAnimateBallHover {
  0%,
  100% {
    transform: translateX(-6.5%);
  }
  17.542% {
    transform: translateX(0%);
  }
  21.9275% {
    transform: translateX(-1%);
  }
  35.084% {
    transform: translateX(11%);
  }
  48.2405% {
    transform: translateX(-11%);
  }
  52.626% {
    transform: translateX(0%);
  }
  59.6428% {
    transform: translateX(10%);
  }
  68.4138% {
    transform: translateX(-11%);
  }
  78.939% {
    transform: translateX(11%);
  }
  85.9558% {
    transform: translateX(-11%);
  }
}
