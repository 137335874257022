.modal-feedback-cancel-plan {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 560px;
  box-sizing: border-box;

  .modal-header {
    padding: 16px;
    border-bottom: 1px solid var(--n-300);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .modal-body {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    textarea {
      min-height: 86px;
    }
  }
  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    padding: 12px 16px;
    border-top: 1px solid var(--n-300);
  }
}
