.floating-report-filters {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
  padding: 12px;
  align-items: center;
  gap: 20px;
  border-radius: 16px;
  border: 1px solid var(--border-minimal, #e5e5e5);
  background: var(--neutral-neutral-0, #fff);
  box-shadow: 0px 5px 28px 0px rgba(0, 0, 0, 0.09);
  text-wrap: nowrap;

  .content-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    .icon-wrapper {
      display: flex;
      padding: 8px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border-radius: 12px;
      background: var(--blue-100, #f3f7fe);

      i {
        color: #1260eb;
      }
    }

    .content {
      display: flex;
      align-items: center;
      gap: 12px;
      color: #000;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */

      .step-info-wrapper,
      .step-answer-wrapper {
        display: flex;
        padding: 8px 12px;
        align-items: center;
        gap: 8px;
        border-radius: 12px;
        border: 1px solid var(--border-minimal, #e5e5e5);
        background: var(--surface-primary, #fff);
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .step-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    .clear-filters-btn {
      color: var(--content-accent-brand, #1260eb);
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 125%; /* 17.5px */
      cursor: pointer;
      padding: 4px;
    }
  }
}
