.task-media {
  position: relative;
  z-index: 2;
  height: 260px;
  overflow: hidden;

  // Image
  &.is-image {
    &.is-fit {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .media-file {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  // Video
  &.is-video {
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  &.poke-block-clickable > iframe {
    pointer-events: none;
  }
  &.is-clickable {
    position: relative;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      img,
      & > div {
        transform: scale(1.02);
      }
      i.icon-search {
        opacity: 1;
      }
    }

    img,
    & > div {
      transition: all 100ms ease;
    }
  }

  i.icon-search {
    opacity: 0;
    position: absolute;
    bottom: 8px;
    right: 8px;
    border-radius: 8px;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    transition: all 100ms ease;
  }
}
