.draft-request-list {
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: hidden;

  .filter-header {
    display: flex;
    margin: 24px 24px 12px 24px;

    .input-search {
      flex: 1;
      margin-right: 28px;

      input {
        height: 40px;
        background: #f8f8f8;
        border: 1px solid rgba(0, 0, 0, 0.04);
        border-radius: 12px;
      }
    }

    .dropdown-trigger {
      margin: auto;
    }
  }

  .divider {
    margin: 0 24px;
    width: calc(100% - 48px);
  }

  .filtered-tags-row {
    margin: 8px 20px;
    display: flex;
    flex-wrap: wrap;

    .filter-tag {
      margin: 4px;
      padding: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      color: rgba(0, 0, 0, 0.4);
      font-weight: 600;
      text-transform: uppercase;

      i {
        margin-left: 10px;
        font-size: 12px;
        cursor: pointer;
        transition: all 300ms ease-in-out 0s;

        &:hover {
          color: #000000;
        }
      }
    }
  }

  .scroll-wrapper {
    flex: 1 1 auto;
    overflow-y: auto;

    .list-wrapper {
      padding-bottom: 12px;

      .count-row {
        display: flex;
        margin: 0 24px 16px 24px;
        align-items: center;

        .requests-title {
          font-weight: 600;
          font-size: 20px;
        }

        .add-request-btn {
          margin-left: auto;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 16px;
          height: 40px;
          width: 40px;

          i {
            margin-right: 0;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.4);
          }
        }
      }

      .list,
      .held-list {
        .request {
          cursor: pointer;
          position: relative;
          margin-bottom: 16px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background: #ffffff;
          margin: 8px 24px;
          border: 1px solid #f2f2f2;

          &:hover,
          &.selected {
            background: #f7f7f7;
          }

          &.is-unread {
            &::before {
              position: absolute;
              left: -8px;
              top: -8px;
              content: '';
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background-color: #1260eb;
            }
            .isax-link-2 {
              margin-left: 10px;
            }
          }
          .badge {
            margin-right: 5px;
            flex: 0 0 10px;
          }
          .jimer-avatar {
            height: 40px;
            width: 40px;
            margin-bottom: auto;
            margin-right: 16px;
          }
          .infos {
            .title {
              font-weight: 600;
              font-size: 18px;
              margin-bottom: 8px;
            }
            .content {
              margin-bottom: 8px;
            }
            .from {
              color: #777da6;
              font-size: 14px;

              span {
                font-weight: 500;
                color: #000000;
              }
            }
          }
          .icon-chevron-right {
            position: absolute;
            right: 24px;
          }
          .isax-link-2 {
            background-color: red;
            padding: 12px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.1);
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.dropdown-request-manage-tags-content {
  margin-top: 8px;

  .tags-wrapper {
    width: 240px;

    .header {
      padding: 10px;
      display: flex;

      .title {
        font-weight: 600;
      }

      .manage-tags-btn {
        margin-left: auto;
        font-weight: 500;
        color: #1260eb;
        cursor: pointer;
      }
    }

    .tags-list {
      display: flex;
      flex-wrap: wrap;
      padding: 4px;

      .tag-option {
        cursor: pointer;
        margin: 4px;
        padding: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        color: rgba(0, 0, 0, 0.4);
        font-weight: 600;
        text-transform: uppercase;
        transition: all 300ms ease-in-out 0s;

        &.selected {
          background: rgba(0, 0, 0, 0.1);
          border: 1px solid #1260eb;
        }
      }
    }
  }
}
