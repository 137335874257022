.s-pushes {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-flow: column;
  background-color: white;
  padding: 0 32px;

  &.is-loading {
    justify-content: center;
    align-items: center;
  }
  & > .row.main-row {
    height: 100%;
    overflow: hidden;

    & > div:last-child {
      height: 100%;
    }
  }
  &.has-not-installed {
    overflow: unset;
  }

  .all-empty-state-wrapper,
  .live-for-vote-empty-state-wrapper,
  .feature-preview-empty-state-wrapper,
  .release-empty-state-wrapper,
  .survey-empty-state-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tip-welcome-live-for-vote {
    margin-bottom: 42px;
  }

  .evolution-list-wrapper {
    overflow: hidden;
    height: 100%;
  }

  .loader-wrapper {
    margin: auto;
  }

  .pushes-row {
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;
    flex: 1 auto;
    overflow: hidden;
    flex-direction: column;
    margin: 0;

    & > div:not(:first-child) {
      padding: 0;
    }
  }
}

.evolution-view-menu-dropdown-content {
  .dropdown-content {
    padding: 4px;

    .menu-item {
      border-radius: 6px !important;
    }
  }
}

.s-pushes-init-loading {
  height: 100%;

  .skeleton-header {
    height: 70px;
    padding: 24px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    animation: skeleton-pushes 1000ms ease infinite;

    .skeleton-title {
      flex: 0 0 60px;
      height: 16px;
      background-color: rgb(222, 222, 222);
      border-radius: 8px;
    }
    .skeleton-btn-create {
      flex: 0 0 120px;
      height: 38px;
      border-radius: 12px;
      background-color: rgb(222, 222, 222);
    }
  }
  .skeleton-views {
    height: 42px;
    padding: 24px;
    padding-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;

    .skeleton-view-item {
      flex: 0 0 80px;
      height: 12px;
      background-color: rgb(222, 222, 222);
      border-radius: 12px;
      margin-right: 24px;
      animation: skeleton-pushes 1000ms ease infinite;

      @for $i from 2 through 25 {
        &:nth-child(#{$i}n) {
          animation-delay: #{($i - 1) * 50}ms;
        }
      }
    }
  }
  .skeleton-view-editor {
    height: 66px;
    display: flex;
    padding: 24px;
    padding-bottom: 0;

    .skeleton-setting-item {
      flex: 0 0 120px;
      height: 32px;
      border-radius: 12px;
      background-color: rgb(222, 222, 222);
      margin-right: 24px;
      animation: skeleton-pushes 1000ms ease infinite;

      @for $i from 2 through 25 {
        &:nth-child(#{$i}n) {
          animation-delay: #{($i - 1) * 50}ms;
        }
      }
    }
  }
}

.poke-list-wrapper {
  height: 100%;

  &.hidden {
    display: none;
  }
}

@-webkit-keyframes skeleton-pushes {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes skeleton-pushes {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
