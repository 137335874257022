.theme-manager-wrapper {
  background: #fff;
  display: flex;
  flex-direction: column;
  min-height: 0;
  position: relative;
  flex: 1 auto;

  .theme-manager {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 0;
    padding: 16px 0;

    .theme-manager-header {
      display: flex;
      padding: 0px 16px 16px 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .theme-manager-title {
        margin: auto 0;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
      }

      i {
        cursor: pointer;
        margin-left: auto;
        color: #9598a9;
        padding: 4px;
      }
    }

    .actions {
      padding: 0 16px;
      display: flex;
      gap: 8px;

      .btn {
        align-items: center;
      }

      .smart-theme-btn {
        background-image: linear-gradient(
          to right,
          #000428 0%,
          #004e92 51%,
          #000428 100%
        );
        transition: 0.5s;
        background-size: 200% auto;
        color: white;
        box-shadow: 0 0 20px #eee;
        display: flex;
        align-items: center;
        &:hover {
          background-position: right center;
          text-decoration: none;
        }

        .badge-ai {
          margin-left: 8px;
        }
      }
    }
    .themes-wrapper {
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow-y: auto;

      .theme {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 12px 16px;
        gap: 8px;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        cursor: pointer;

        &.selected {
          background: rgba(18, 96, 235, 0.08);
          border: 1px solid #1260eb;
        }

        &:hover {
          background: rgba(18, 96, 235, 0.08);

          .theme-name {
            color: #1260eb;
          }
          .theme-actions {
            display: flex;
          }
        }

        .theme-colors {
          display: flex;
          gap: 8px;

          .theme-color {
            width: 32px;
            height: 32px;
            border-radius: 12px;
            border: 1px solid var(--n-300);
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);

            &:nth-child(1) {
              width: 81px;
            }
          }
        }

        .theme-actions {
          position: absolute;
          bottom: 8px;
          right: 8px;
          display: none;
          gap: 8px;
        }
      }
    }
  }

  .theme-manager-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 6px;
    padding: 12px;
    border-radius: 12px;
    border: 3px solid rgba(18, 96, 235, 0.3);
    background: #fff;
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    gap: 12px;

    .theme-manager-footer-title {
      color: #000;
      font-size: 16px;
      font-family: Montserrat;
      font-weight: 600;
    }

    .theme-manager-footer-content {
      font-size: 14px;
      font-family: Inter;
      font-weight: 500;
    }

    .theme-manager-footer-actions {
      display: flex;
      gap: 8px;
      justify-content: flex-end;
    }
  }
}

.update-theme-modal {
  padding: 0;
  width: unset !important;

  .modal-title {
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 24px;
  }

  .content {
    padding: 24px;
    font-weight: 400;
    font-size: 18px;

    span {
      font-weight: 600;
    }
  }

  .actions {
    padding: 24px;
    margin-top: 0;
    gap: 8px;
    justify-content: flex-end;
  }
}
