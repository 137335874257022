.feature-usage-chart {
  background-color: #ffffff;
  flex: auto;

  .loader {
    margin: auto;
  }
  .block-header {
    display: flex;

    .title {
      display: flex;
      gap: 8px;
      align-items: center;
      color: var(--content-primary, #071331);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 30px */
    }

    .actions {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 24px;

      .chart-type-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-radius: 16px;

        .chart-type {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          display: flex;
          padding: 8px 12px;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--states-disabled-border, #e5e5e5);
          cursor: pointer;

          &:nth-child(1) {
            border-radius: 16px 0px 0px 16px;
          }

          &:nth-last-child(1) {
            border-radius: 0px 16px 16px 0px;
          }

          &.selected {
            border: 1px solid rgba(18, 96, 235, 0.6);
            background: rgba(18, 96, 235, 0.1);
          }
        }
      }
    }
  }

  .block-content {
    display: flex;
    min-height: 0;
    height: 400px;

    .primary-chart-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      border-radius: 20px;
      height: 100%;
      width: 100%;
      margin: 0;

      .chart-header {
        display: flex;
        margin-bottom: 10px;
        padding: 0;

        .title {
          font-size: 18px;
          align-self: center;
        }
        .select-actions {
          margin-left: auto;
          display: flex;
          gap: 8px;

          .primary-chart-select,
          .primary-chart-tour-select,
          .compare-evolutions-select {
            .jimo-select__control {
              height: 40px;
              box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);

              .jimo-select__value-container {
                padding: 0 8px;
              }
            }

            .jimo-select__menu {
              padding-top: 0;
              padding-bottom: 0;
              .jimo-select__option {
                min-height: 32px;
                padding: 8px 12px;
              }
            }

            .select-icon-value-container {
              display: flex;
              flex-flow: nowrap;

              .img-wrapper {
                height: 24px;
                width: 24px;
                display: flex;
                background: #e6f5fd;
                border-radius: 7.2px;
                margin-right: 8px;

                i {
                  margin: auto;
                  font-size: 12px;
                  color: #000000;
                }
              }

              .label {
                font-weight: 600;
                font-size: 14px;
                color: #000000;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .select-icon-option {
              .img-wrapper {
                height: 24px;
                width: 24px;
                display: flex;
                background: #e6f5fd;
                border-radius: 7.2px;
                margin-right: 8px;

                i {
                  margin: auto;
                  font-size: 12px;
                }
              }
            }
          }

          .primary-chart-select,
          .compare-evolutions-select {
            min-width: 100px;
            margin-right: 10px;
          }
        }
      }

      .primary-chart {
        flex: 1 1 auto;
        min-height: 0;

        &.empty {
          display: flex;
        }

        .no-data {
          margin: auto;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
}
