.s-onboarding-details-join {
  width: 546px;
  margin-left: auto;
  margin-right: auto;
  &.is-exiting {
    h1 {
      animation: onboarding-text-slide-right-out 600ms ease-in-out 500ms both;
    }
    p {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 300ms both;
    }
    .project-list-title,
    .project-list,
    .actions-wrapper {
      animation: onboarding-content-slide-right-out 600ms ease-in-out 100ms both;
    }
  }

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    width: 396px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    animation: onboarding-text-slide-right 600ms ease-in-out 600ms both;
    margin-bottom: 0;
  }
  p {
    text-align: center;
    font-size: 16px;
    animation: onboarding-content-slide-right 600ms ease-in-out 800ms both;
    margin-bottom: 24px;
  }
  .project-list-title,
  .project-list,
  .actions-wrapper {
    animation: onboarding-content-slide-right 600ms ease-in-out 1000ms both;
  }

  .project-list {
    overflow-x: auto;
    margin-bottom: 24px;
    max-height: 500px;
    background-color: rgba(18, 98, 235, 0.05);
    border: 2px solid rgba(18, 98, 235, 0.1);
    padding: 12px;
    border-radius: 12px;

    .item-project {
      padding: 8px;
      display: flex;
      border-radius: 12px;
      align-items: center;
      background-color: #fff;
      border: 1px solid rgba($color: #000000, $alpha: 0.1);
      &:not(:last-child) {
        margin-bottom: 12px;
      }

      .project-icon {
        margin-right: 12px;
        border: 1px solid rgba($color: #000000, $alpha: 0.1);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        flex: 0 36px;
        height: 36px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
      }
      .project-infos {
        display: flex;
        align-items: center;
        margin-right: auto;

        .project-members {
          margin-left: 8px;
          opacity: 0.5;
        }
      }
      .project-name {
        font-size: 18px;
        font-weight: 500;
      }
      .project-members-list {
        margin-right: 8px;
        display: flex;

        .item-member {
          border-radius: 50%;
          width: 24px;
          height: 24px;
          background-color: #f1f1f1;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-size: 16px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          border: 1px solid #787878;

          @for $i from 1 through 20 {
            &:nth-child(#{$i}n) {
              margin-left: -6px;
              z-index: 100 - $i;
              position: relative;
            }
          }
        }
      }
    }
  }

  .actions-wrapper {
    display: flex;
    justify-content: center;

    .btn.is-secondary {
      margin-right: 12px;
    }
  }
}
