.post-language-selector {
  display: flex;
  padding: 8px;
  gap: 8px;
  border-radius: 16px;
  background: #e7effd;

  .language-list {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    .language {
      display: flex;
      height: 38px;
      box-sizing: border-box;
      font-size: 16px;
      padding: 4px 16px;
      background: #fff;
      border-radius: 12px;
      cursor: pointer;
      text-transform: capitalize;

      &:not(.active):hover {
        color: #1260eb;
      }
      &.active {
        color: #1260eb;
        border: 2px solid #1260eb;
      }

      .language-content {
        margin: auto;

        i {
          font-size: 12px;
          margin-left: 8px;
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }

    .dropdown-trigger {
      align-self: center;

      .add-translation-btn {
        height: 38px;
        white-space: nowrap;

        &.has-languages {
          i {
            margin-right: 0 !important;
          }
        }

        i {
          margin: auto;
          margin-right: 8px !important;
          font-size: 18px !important;
        }
      }
    }
  }
}
.dropdown-add-languages-content {
  z-index: 8000 !important;

  .dropdown-content {
    .menu {
      .menu-item {
        font-size: 18px;
        padding: 12px 24px;
        text-transform: capitalize;

        i {
          font-size: 12px;
          border-radius: 3px;
          margin-top: 4px;
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(90, 90, 90);
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.delete-language-modal {
  padding: 0;
  width: unset !important;

  .modal-title {
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 24px;
  }

  .content {
    padding: 24px;
    font-weight: 400;
    font-size: 18px;
  }

  .actions {
    padding: 24px;
    margin-top: 0;
    gap: 8px;
    justify-content: flex-end;
  }
}
