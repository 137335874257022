.settings-tabs {
  display: flex;
  border-bottom: 1px solid var(--n-300);
  gap: 12px;

  .item {
    border-bottom: 3px solid transparent;
    transition: all ease-in-out 100ms;
    padding: 12px 4px;

    &.is-active {
      color: var(--b-400);
      border-bottom-color: var(--b-400);
    }
    &:not(.is-active) {
      color: var(--n-600);
      &:hover {
        color: var(--b-400);
      }
    }
  }
}
