@mixin bar-animation($time, $dimension) {
  visibility: hidden;
  height: 0;
  -webkit-animation: animate-height;
  -moz-animation: animate-height;
  animation: animate-height;
  animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  -webkit-animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  -moz-animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  animation-duration: $time * 2;
  -webkit-animation-duration: $time * 2;
  -moz-animation-duration: $time * 2;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

@-webkit-keyframes animate-height {
  0% {
    height: 0;
  }
  100% {
    visibility: visible;
  }
}
@-moz-keyframes animate-height {
  0% {
    height: 0;
  }
  100% {
    visibility: visible;
  }
}
@keyframes animate-height {
  0% {
    height: 0;
  }
  100% {
    visibility: visible;
  }
}

.no-completion-data {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 16px;
  margin: 64px;
  color: var(--content-tertiary, #9ca1ad);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.tracker-completion-activity {
  display: flex;
  flex: 1 1 auto;
  overflow-x: auto;

  .week-activity-item {
    display: flex;
    flex-direction: column;
    gap: 14px;
    flex: 1 1 100px;
    min-width: 100px;

    &:hover {
      .chart-wrapper {
        background: var(--n-100);
      }
    }

    .step-stats {
      display: flex;
      flex-direction: column;
      margin: 0 auto;

      .percentage {
        color: #000;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 500;
        margin: 0 auto;
      }
      .users-count {
        color: rgba(0, 0, 0, 0.4);
        font-family: Inter;
        font-size: 12;
        font-weight: 400;
        margin: 0 auto;
      }
    }

    .chart-wrapper {
      height: 280px;
      flex: 1 1 auto;
      display: flex;
      position: relative;
      box-sizing: border-box;
      overflow: hidden;
      padding: 0 32px;
      padding-top: 16px;
      gap: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .chart {
        background: #ebeef2;
        border-radius: 20px 20px 0px 0px;
        margin-top: auto;
        width: 100%;
        overflow: hidden;
        transition: all 0.2s ease-in-out;
        background: #dfe2e5;
      }

      &:hover,
      &.hovered {
        .chart {
          &:nth-child(1) {
            background: #5791f7;
          }

          &:nth-child(2) {
            background: #ebbb12;
          }

          &:nth-child(3) {
            background: #a44ee9;
          }

          &:nth-child(4) {
            background: #32cc1d;
          }

          &:nth-child(5) {
            background: #3edafc;
          }

          &:nth-child(6) {
            background: #f7a357;
          }
        }
      }

      .chart {
        @include bar-animation(0.2s, height);
        animation-delay: 0.2s;
        -webkit-animation-delay: 0.2s;
      }
    }

    .step-info {
      display: flex;
      gap: 16px;
      justify-content: center;
      align-items: center;

      .step-index {
        display: flex;
        width: 32px;
        height: 32px;
        padding: 6px 0px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 69px;
        border: 2px solid #000;
        box-sizing: border-box;
        color: var(--content-primary, #071331);
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; /* 20px */
      }

      .step-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .week-label {
      display: flex;

      .week-label-text {
        margin: auto;
      }
    }
  }

  .loader-wrapper {
    display: flex;
    flex: 1 auto;

    .loader {
      margin: auto;
    }
  }
}

.tooltip-tracker-week-details-content {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(5px);
  padding: 0;

  .tracker-week-details-content {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .title {
      color: #000;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 18px */
    }

    .groups {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .group {
        display: flex;
        padding: 4px;
        align-items: flex-start;
        gap: 12px;

        .color-indication {
          width: 6px;
          align-self: stretch;
          border-radius: 2px;
          background: #1260eb;
        }

        .data {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .group-pct {
            color: #000;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 24px */
          }

          .group-value {
            color: var(--neutral-600, var(--neutral-neutral-600, #7c8a99));
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 18px */
          }
        }
      }
    }

    .see-breakdown-btn {
      width: 100%;
      justify-content: center;
    }
  }
}
