.preview-selector {
  display: flex;
  flex-direction: column;

  .preview-selector-header {
    padding: 8px 12px;
  }

  .preview-selector-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;

    .preview-option {
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 8px 12px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid transparent;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        border-color: var(--n-100);
        box-shadow: 0px 5px 15px 0px #0000000f;

        .preview-option-title,
        i {
          color: var(--b-400);
        }
      }

      i {
        font-size: 20px;
        transition: all 0.2s ease;
      }
    }
  }
}
