.modal-manual-trigger {
  overflow: hidden;
  width: 528px;
  box-sizing: border-box;
  padding: 0;

  .modal-title {
    display: flex;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 24px;
    font-family: 'Montserrat';
    margin-bottom: 0;

    i {
      margin-right: 16px;
    }
  }

  .content {
    margin: 24px;

    .code-snippet-wrapper {
      position: relative;
      padding: 8px;
      background: #fbfbfb;
      max-height: 325px;
      border-radius: 6px;
      font-size: 14px;
      text-align: left;
      overflow: auto;

      .btn-copy-wrapper {
        position: absolute;
        right: 8px;
        top: 16px;
      }
      pre {
        font-weight: 200;
        max-height: 325px;
        overflow-y: auto;
        margin-right: 100px;
      }
    }
  }
}
