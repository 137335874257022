.s-get-started {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1380px) {
    .iframe-wrapper {
      display: none;
    }
  }
  .iframe-wrapper {
    position: absolute;
    right: -390px;
    top: -150px;
    width: 420px;
    height: 317px;
    overflow: hidden;
    iframe {
      position: absolute;
      left: -60%;
      top: -40%;
    }
  }
  .gs-top {
    padding: 24px 40px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .gs-body {
    flex: 1 auto;
    padding: 40px;
    min-height: 0;
    overflow-y: auto;
  }

  .gs-container {
    max-width: 1040px;
    position: relative;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: auto;

    .video-group-wrapper {
      display: flex;
      position: relative;

      img {
        width: 100%;
      }

      .video-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;
        max-height: 90%;
        width: 521px;
        max-width: 90%;
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 14px 14px 0px rgba(0, 0, 0, 0.06);

        .video {
          height: 100% !important;
          width: 100% !important;
        }
      }
    }

    .gs-cards-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .gs-card-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 16px;

        &.completed {
          .item-content {
            text-decoration: line-through;
          }
        }

        .section-index {
          display: flex;
          width: 36px;
          height: 36px;
          padding: 6px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 40px;
          border: 2px solid #000;
          color: #000;
          font-family: Montserrat;
          font-size: 20px;
          font-weight: 600;
          box-sizing: border-box;

          &.checked {
            background: #057068;
            color: #fff;
            border: none;
          }
        }

        .item-timing {
          border-radius: 12px;
          background: #f6f6f6;
          height: 40px;
          width: 40px;
          color: #000;
          font-size: 12px;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .item-content {
          display: flex;
          flex-direction: column;

          small {
            font-weight: 500;
            margin-top: 6px;
          }
        }
        .item-actions {
          flex: 1;

          .btn {
            display: block;
            margin-left: auto;
            font-weight: 600;
            border-radius: 12px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background: #fff;
            height: 40px;
            width: 40px;
            padding: 0;
            display: flex;

            i {
              margin: auto;
              font-size: 14px;
            }
          }
        }
      }

      .gs-card-wrapper {
        display: flex;
        position: relative;

        &.disabled {
          &:hover {
            .gs-card {
              opacity: 0.3;
            }

            .install-first {
              display: flex;
            }
          }

          .gs-card {
            cursor: not-allowed;

            & > div {
              pointer-events: none;
            }
          }
        }

        .gs-card {
          flex: 1 auto;
        }

        .install-first {
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .resources-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .resources-header {
        display: flex;

        .resources-title {
          font-weight: 600;
          font-size: 16px;
        }

        .resources-navigator {
          display: flex;
          margin-left: auto;
          gap: 8px;

          .btn {
            height: 40px;
            width: 40px;
            display: flex;
            padding: 0;
            border-radius: 12px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            background: #fff;
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.06);

            i {
              margin: auto;
            }
          }
        }
      }

      .resources-content {
        display: flex;
        gap: 24px;
        overflow-x: auto;
        scroll-behavior: smooth;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        .resource-card {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 205px;
          flex-shrink: 0;
          cursor: pointer;

          &.large {
            width: auto;

            .resource-content {
              height: 260px;
              width: 420px;
            }
          }

          &:not(.large) {
            .resource-content {
              height: 220px;
            }
          }

          .resource-content {
            border-radius: 10px;
            box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.06);
            display: flex;
            overflow: hidden;
            position: relative;

            .resource-video {
              height: auto !important;
            }
          }

          .resource-title {
            color: #000;
            font-family: Inter;
            font-size: 18px;
            font-weight: 500;
          }

          .resource-subtitle {
            color: #000;
            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.gs-container {
  border-radius: 12px;
  box-shadow: 0px 24px 94px rgba(0, 0, 0, 0.07);
  padding: 24px;
  font-family: 'Montserrat', sans-serif;
  background-color: #fff;
  &.is-success {
    background-color: #effced;
    border: 2px solid rgba(21, 146, 1, 0.4);
  }
}

.gs-top {
  padding: 24px 40px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.gs-card {
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}
