@import './../../Colors';

.comment-list {
  display: flex;
  flex-direction: column;
  gap: 48px;

  .comment {
    position: relative;
    display: flex;
    gap: 8px;

    .user-avatar {
      flex: 0 0 40px;
      height: 40px;
      background-color: #fdefe6;
      line-height: 40px;
      font-size: 24px;
      color: #000;
      margin-right: 16px;
      margin-top: 6px;
    }

    .comment-content-wrapper {
      flex: 1 1 auto;

      .card {
        background: var(--white);
        padding: 12px 16px 12px 16px;
        gap: 8px;
        border-radius: 16px;
        border: 1px;
        word-break: break-all;

        &:hover {
          .author {
            color: var(--b-400);
          }
        }

        .top-wrapper {
          display: flex;
          justify-content: space-between;

          .left-side {
            display: flex;
            gap: 4px;

            .date-posted {
              color: var(--b-400);
            }

            .author {
              font-weight: 500;

              &.is-clickable {
                cursor: pointer;
              }

              .muted {
                margin-left: 4px;
                color: rgba(0, 0, 0, 0.4);
                font-weight: 300;
              }
            }
          }

          .actions {
            display: flex;

          }

          .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px;

            &:hover {
              background-color: var(--n-100);
            }
          }
        }

        .message {
          color: rgba(0, 0, 0, 0.8);
        }
      }

      .actions {
        display: flex;
        align-items: center;
        margin-top: 8px;
        color: #000;

        .btn.is-liked {
          color: var(--r-500);
        }

        .btn-like {
          margin-right: 8px;
        }

        .btn-comment-reply {
          margin-right: 8px;
        }

        .time {
          margin-left: auto;
        }
      }

      .replies {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 16px;
      }
    }
  }

  .input-comment-reply-wrapper {
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 8px;

    .right-side {
      display: flex;
      gap: 12px;
      flex: 1 0 auto;

      .input-comment-reply {
        flex: 1 0 auto;
      }

      .button-group-actions {
        display: flex;
        gap: 12px;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.modal-edit-comment {
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;

  .content-wrapper {
    flex: 1 1 auto;
    min-height: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 24px;

    &.scrollable {
      overflow: auto;
    }

    textarea {
      min-height: 140px;
      padding: 16px;
    }
  }

  .modal-footer {
    display: flex;
    padding: 0px;
    padding-top: 16px;
  }
}

.modal-footer {
  display: flex;
}
