@import './../../Mixin';

.select-group-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .select-legend {
    &.disabled {
      color: var(--n-500);
    }
  }
}

.jimo-select,
.jimo-select__menu-portal {
  &.jimo-select--is-disabled {
    .jimo-select__control {
      background-color: var(--n-100);
    }
  }

  .jimo-select__control {
    min-height: 0;
    border: 1px solid var(--n-300);
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: none;
    cursor: pointer;
    color: #ffffff;
    &:hover {
      border: 1px solid var(--n-500);
    }
  }
  .jimo-select__indicator-separator {
    display: none;
  }
  .jimo-select__option {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 12px;
    background-color: transparent;
    cursor: pointer;
    line-height: 150%;
    font-size: 14px;

    &:active {
      &:hover {
        color: var(--b-400) !important;
      }
    }
    &:hover {
      background-color: var(--b-100);
    }
    &:focus {
      background-color: rgba(0, 0, 0, 0.05);
    }
    &:disabled {
      opacity: 0.2;
    }
    &.jimo-select__option--is-selected {
      color: var(--b-400);
      background-color: var(--b-100);
    }
  }
  .jimo-select__single-value {
    flex: 1;
    color: var(--n-800);
    display: inline-block;
    gap: 8px;
  }
  .jimo-select__menu-list {
    padding: 0;
  }
  .jimo-select__menu {
    background-color: #ffffff;
    border-radius: 12px;
    overflow: hidden;
    width: max-content;
    min-width: 100%;
    z-index: 9;
    border: 1px solid var(--n-300);
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
  }
  .jimo-select__value-container {
    padding: 12px 16px;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    line-height: 150%;
  }
  .jimo-select__input-container {
    color: var(--n-800);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
  .jimo-select__placeholder {
    color: var(--n-500);
    font-family: 'Inter', sans-serif;
    line-height: 150%;
    font-size: 16px;
  }
  .jimo-select__indicators {
    font-size: 14px;
    color: var(--n-800);
    padding-right: 16px;
  }

  .jimo-select__group {
    padding: 0;
  }

  .jimo-select__group-heading {
    background-color: var(--n-100);
    padding: 8px 12px;
    text-transform: none;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: var(--n-700);
    margin-bottom: 0;
  }
}

.jimo-select.small {
  .jimo-select__control {
    border-radius: 8px;
  }
  .jimo-select__input-container {
    font-size: 14px;
  }
  .jimo-select__placeholder {
    font-size: 14px;
  }
  .jimo-select__menu {
    border-radius: 8px;
  }
  .jimo-select__option {
    padding: 8px;
  }
  .jimo-select__value-container {
    font-size: 14px;
    padding: 8px 12px;
  }
  .jimo-select__indicators {
    font-size: 10px;
    padding-right: 12px;
  }
}

.jimo-creatable {
  .jimo-creatable__control {
    &.jimo-creatable__control--is-focused {
      border-color: var(--b-400);
      box-shadow: none;
    }
  }
}
