.slack-settings-setup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  .card-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: 1px solid var(--border-minimal, var(--n-300));
    padding: 16px;
    border-radius: 8px;
    background: var(--n-50);
  }

  .card-private-channels {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .step-item {
      display: flex;
      gap: 8px;
      justify-content: flex-start;
      align-items: flex-start;
      font-weight: 500;

      .item-left {
        width: 24px;
        height: 24px;
        border-radius: 22px;
        background: var(--n-300);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .item-right {
        display: flex;
        align-items: center;

        text {
          .item-command {
            padding: 2px 6px 2px 6px;
            border-radius: 4px;
            background: var(--b-100);
            margin: 0px 8px 0px 8px;
          }
        }
      }
    }
  }

  .settings-card {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
