.integration-gtm {
  .empty-access-token {
    display: flex;

    .btn {
      flex: 1;
      justify-content: center;
    }
  }

  .integration-gtm-content {
    .jimo-select {
      margin-bottom: 12px;
    }
  }

  .gtm-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      text-transform: uppercase;
      opacity: 0.4;
      font-weight: 600;
    }
  }
}
