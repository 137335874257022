.effect {
  &.effect-moving-border {
    content: '';
    display: block;
    height: 300px;
    width: 100px;
    position: absolute;
    transform: translate(0);
    z-index: 0;
    top: 50%;
    transform-origin: top center;
    border-radius: inherit;

    @keyframes moving-border-rotate {
      0% {
        transform: rotate(0deg);
        animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
      }
      50% {
        transform: rotate(180deg);
        animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &.effect-shimmer {
    content: '';
    display: block;
    background-size: 200% 100%;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
    border-radius: inherit;

    @keyframes shimmer {
      0% {
        background-position: -200% 0;
      }
      100% {
        background-position: 200% 0;
      }
    }
  }

  &.effect-glow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    z-index: 1;
    pointer-events: none;
    box-shadow: 0 0 5px 2px var(--glow-color-80);

    @keyframes glow {
      0% {
        box-shadow: 0 0 5px 2px var(--glow-color-80);
      }
      50% {
        box-shadow: 0 0 10px 5px var(--glow-color);
      }
      100% {
        box-shadow: 0 0 5px 2px var(--glow-color-80);
      }
    }

    .glow {
      animation: glow var(--speed) ease-in-out infinite;
    }
  }
}
