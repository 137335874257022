.issue-reporting {
  .table-issues {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .loader {
      margin: auto;
    }

    .header {
      flex: 0 1 auto;
      display: grid;
      grid-template-columns: 25fr 15fr 20fr 30fr 10fr;
      box-sizing: border-box;
      gap: 12px;
      color: var(--neutral-500, var(--neutral-neutral-500, #9ca1ad));
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
    }

    .loader-wrapper {
      display: flex;
      justify-content: center;
    }
    .list {
      flex: 1 0 auto;
      overflow: auto;
      flex: 1 1 100%;
      box-sizing: border-box;

      .row-issue {
        border-radius: 12px;
        display: grid;
        grid-template-columns: 25fr 15fr 20fr 30fr 10fr;
        gap: 12px;
        align-items: center;
        transition: all 300ms ease-in-out 0s;

        &:not(:last-child) {
          margin-bottom: 24px;
        }

        .step-name {
          color: #000;
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;

          &.is-clickable {
            cursor: pointer;

            &:hover {
              color: #1260eb;
            }
          }
        }

        .issue-jimer {
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          &:hover {
            .username {
              text-decoration: underline;
              color: var(--b-400);
            }
          }

          .avatar-img-wrapper {
            img {
              width: 38px;
              height: 38px;
            }
          }
          .username-wrapper {
            display: flex;
            flex-direction: column;

            .username {
              text-align: left;
              display: flex;
              flex-direction: column;
              overflow: hidden;
              font-family: Inter;
              font-size: 16px;
              font-weight: 500;
            }

            .anonymous-name {
              color: rgba(0, 0, 0, 0.5);
              font-family: Inter;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }

        .url {
          color: #000;
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
        }

        .description {
          color: #000;
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
        }

        .date {
          color: #000;
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .users-pagination {
        justify-content: center;
      }
    }
  }
}

.tooltip-issue-url-content,
.tooltip-issue-date-content {
  background-color: #ffffff;
  padding: 12px;
  color: #000000;
  box-sizing: border-box;
  border-radius: 12px;
  font-size: 14px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
