.block-settings.changelog-feedback {
  .welcome-title-content-input {
    textarea {
      height: 74px !important;
    }
  }

  .welcome-message-content-input {
    textarea {
      height: 114px !important;
    }
  }
}
