.bar-chart {
  height: 100%;
  width: 100%;
  position: relative;

  .chart-tooltip {
    display: inline-flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);

    .date {
      color: var(--n-700);
    }

    .value {
      color: var(--neutral-800-black, var(--neutral-neutral-800, #071331));
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 125%;
      /* 25px */
    }
  }
}
