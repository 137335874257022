.post-statistics {
  background-color: var(--white);
  flex: auto;

  .loader {
    margin: auto;
  }
  .block-header {
    .block-title {
      display: flex;
      align-items: center;

      .chart-date-picker {
        margin-left: auto;

        .input {
          height: 40px;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
          border: 1px solid rgba(0, 0, 0, 0.15);
          color: #000000;
          font-weight: 600;
          font-size: 14px;
          border-radius: 12px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: #fff;
          text-align: center;
          cursor: pointer;

          &:hover {
            border: 1px solid rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }

  .block-content {
    display: flex;
    min-height: 0;

    .left-statistics-wrapper {
      min-width: 0;
      flex: 1 1 auto;

      .wrapper1 {
        display: flex;
        gap: 24px;

        .analytics-wrapper {
          flex: 1 auto;
          min-width: 0;

          .main-wrapper {
            height: 400px;
            flex: 1 1 auto;
            display: flex;

            &.is-tab-step {
              overflow-x: auto;
            }
          }
        }
      }
    }
  }
}
