@import '../../Variables';

.poke-block-opinion {
  animation: block-opinion-in $animationBlockInDuration ease-in-out
    $animationBlockInDelay both;
  &.is-animating-out {
    animation: block-opinion-out $animationBlockOutDuration ease-in-out both;
  }

  .opinion-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .labels-wrapper {
    margin-top: 6px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
  }
}
.poke-block-opinion.type-smiley {
  .opinion-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 24px;
    padding: 10px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.05);
    transition: all 100ms ease-in-out;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
    &.is-active {
      background-color: rgba(0, 0, 0, 0.1);
    }
    &.is-muted {
      filter: grayscale(1);
      &:hover {
        filter: grayscale(0);
      }
    }
  }
}

.poke-block-opinion.type-star {
  padding-left: 0;
  padding-right: 0;

  .opinion-wrapper {
    position: relative;
    margin-bottom: 8px;
  }
  .opinion-item {
    flex: 1;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    .star-wrapper {
      width: 100%;
      height: 200%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      & > div {
        flex: 0 0 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }
}

@-webkit-keyframes block-opinion-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes block-opinion-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes block-opinion-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes block-opinion-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
