.onboarding-register-testimonials {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  animation: onboarding-testimonials-in 500ms ease-in-out 2000ms both;
  position: relative;
  z-index: 0;

  .testimonials-list {
    .item-testimonial {
      background-color: #fff;
      padding: 40px;
      border-radius: 24px;
      &.anim-prev-out {
        animation: testimonial-prev-out 500ms ease-in-out forwards;
      }
      &.anim-prev-in {
        animation: testimonial-prev-in 500ms ease-in-out forwards;
      }
      &.anim-next-out {
        animation: testimonial-next-out 500ms ease-in-out forwards;
      }
      &.anim-next-in {
        animation: testimonial-next-in 500ms ease-in-out forwards;
      }

      .content-wrapper {
        font-size: 16px;
        margin: 24px 0;
      }
      .person-wrapper {
        display: flex;
        align-items: center;

        .testimonial-avatar {
          background-color: #fceee5;
          flex: 0 0 45px;
          height: 45px;
          background-size: cover;
          border-radius: 12px;
        }
        .person-name-role {
          display: flex;
          flex-direction: column;
          font-size: 16px;
          justify-content: space-evenly;
          align-self: stretch;
          margin-left: 24px;

          small {
            font-weight: 300;
          }
        }
      }
    }
  }

  .testimonial-navigation-wrapper {
    color: #fff;
    margin: 48px 0;

    i {
      cursor: pointer;
      padding: 11px 10px;
      border: 2px solid #fff;
      border-radius: 50%;
      font-size: 10px;
      transition: all 50ms ease-in-out;
      &.disabled {
        opacity: 0.3;
        cursor: default;
      }
      &:not(.disabled):hover {
        background-color: #fff;
        color: #071331;
      }
    }
    .icon-arrow-left {
      margin-right: 6px;
    }
    .icon-arrow-right {
      margin-left: 6px;
    }
  }

  .testimonials-check-list {
    color: #fff;
    .item-check {
      display: flex;
      align-items: center;
      line-height: 20px;
    }

    i {
      padding: 12px;
      border-radius: 50%;
      margin-right: 24px;
      &.active {
        color: #071331;
        background-color: #fff;
      }
    }
  }
}

@-webkit-keyframes onboarding-testimonials-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes onboarding-testimonials-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes onboarding-testimonials-out {
  0% {
    opacity: 1;
    width: 40%;
    height: inherit;
  }
  50% {
    opacity: 0;
    width: 40%;
    height: inherit;
  }
  100% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
@keyframes onboarding-testimonials-out {
  0% {
    opacity: 1;
    width: 40%;
    height: inherit;
  }
  50% {
    opacity: 0;
    width: 40%;
    height: inherit;
  }
  100% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

/* Testimonials */
@-webkit-keyframes testimonial-prev-in {
  0% {
    opacity: 0;
    transform: matrix(0.85, 0, 0, 0.85, -200, 0);
  }
  50% {
    opacity: 1;
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}
@keyframes testimonial-prev-in {
  0% {
    transform: matrix(0.85, 0, 0, 0.85, -200, 0);
    opacity: 0;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes testimonial-next-out {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(0.85, 0, 0, 0.85, -200, 0);
    opacity: 0;
  }
}
@keyframes testimonial-next-out {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(0.85, 0, 0, 0.85, -200, 0);
    opacity: 0;
  }
}
@-webkit-keyframes testimonial-next-in {
  0% {
    opacity: 0;
    transform: matrix(0.85, 0, 0, 0.85, 200, 0);
  }
  50% {
    opacity: 1;
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}
@keyframes testimonial-next-in {
  0% {
    transform: matrix(0.85, 0, 0, 0.85, 200, 0);
    opacity: 0;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes testimonial-prev-out {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(0.85, 0, 0, 0.85, 200, 0);
    opacity: 0;
  }
}
@keyframes testimonial-prev-out {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
  50% {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(0.85, 0, 0, 0.85, 200, 0);
    opacity: 0;
  }
}
