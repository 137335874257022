.block-settings.hotspot {
  .position-picker {
    .position-icon-wrapper {
      display: flex;
      margin: auto 4px;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;

      i {
        margin: auto 4px;
        font-size: 14px;
        width: 32px;
        box-sizing: border-box;
        border-radius: 12px;
        text-align: center;
      }
    }
  }
}
