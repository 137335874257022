.zapier-settings-setup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  .api-key-card {
    width: 100%;
    box-sizing: border-box;

    .card-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .settings-card {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
