.modal-welcome-plan {
  padding: 24px 34px;
  min-height: 220px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  width: 440px;
  box-sizing: border-box;
  overflow: hidden;
  &.plan-startup {
    .animation-wrapper {
      iframe {
        top: -200px;
        right: -460px;
        width: 200%;
        height: 500px;
      }
    }
    .plan-logo {
      background-image: url(../../assets/imgs/logo-startup.svg);
    }
  }
  &.plan-growth {
    .animation-wrapper {
      iframe {
        top: -180px;
        right: -320px;
        width: 170%;
        height: 440px;
        transform: rotate(-190deg);
      }
    }
    .plan-logo {
      background-image: url(../../assets/imgs/logo-growth.svg);
    }
  }
  &.plan-scale {
    .animation-wrapper {
      iframe {
        top: -360px;
        right: -360px;
        width: 200%;
        height: 500px;
        transform: rotate(-200deg);
      }
    }
    .plan-logo {
      background-image: url(../../assets/imgs/logo-scale.svg);
    }
  }

  .animation-wrapper {
    iframe {
      position: absolute;
    }
  }

  .modal-main {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .plan-title {
    text-align: center;
    & > div {
      text-transform: capitalize;
    }
  }

  .plan-description {
    text-align: center;
  }

  .btn {
    width: 100%;
    justify-content: center;
    position: relative;
    // z-index: 3;
  }

  .animation-confetti {
    position: absolute;
    top: -80px;
    transform: scale(1.5);
    transition: opacity 100ms ease, visibility 100ms ease 200ms;
    left: 0;
    right: -40px;
    margin-left: auto;
    margin-right: auto;
    &.completed {
      opacity: 0;
      visibility: hidden;
    }
  }
}
