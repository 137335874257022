.step-node-content-wrapper {
  cursor: default;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.is-editing-content {
    &.is-selected {
      .step-node-content {
        background: transparent;
        box-shadow: none;
        border: 1px solid transparent;
        overflow: visible;

        .trigger-wrapper:not(:hover) {
          opacity: 0.3;
        }
      }
    }
  }

  &.is-editing-triggers {
    &.is-selected {
      .step-node-content {
        background: transparent;
        box-shadow: none;
        border: 1px solid transparent;
        overflow: visible;

        .step-node-poke-wrapper:not(:hover) {
          opacity: 0.3;
        }

        .trigger-wrapper {
          &:not(.is-selected):not(:hover) {
            opacity: 0.3;
          }
        }
      }
    }
  }

  &.is-placeholder {
    pointer-events: none;

    .step-node-info {
      visibility: hidden;
    }

    .step-node-content {
      background: transparent;
      box-shadow: none;
      border: 1px dashed var(--b-400);

      & > div {
        animation: pulseOpacity 2s infinite;
      }
    }
  }

  &.is-dragging {
    .step-node-info {
      .title {
        opacity: 0;
      }

      .action:not(.drag-handle) {
        opacity: 0;
      }
    }
    .step-node-content {
      transform: rotate(3deg);
      border: 1px solid #00000014;
      box-shadow: -3px 13px 23.1px 0px #0000000a;
    }
  }

  .step-node-info {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: auto 0;

    .icon {
      height: 40px;
      width: 40px;
      display: flex;
      border: 1px solid var(--n-300);
      border-radius: 12px;

      i {
        margin: auto;
        font-size: 18px;
      }
    }

    .content {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;

      .title {
        flex-grow: 1;
        transition: all 0.3s ease;

        .step-name-input {
          .input-main-wrapper {
            border: 0;

            .input {
              color: var(--n-700);
              font-family: Inter;
              font-size: 12px;
              font-weight: 500;
              line-height: 18px;
              text-align: left;
              padding: 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              border-bottom: 1px solid transparent;

              &:hover,
              &:focus {
                border-bottom: 1px solid #0000001a;
              }
            }
          }
        }
      }

      .actions {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: 4px;
        margin-left: auto;

        .action {
          display: flex;
          align-items: center;
          gap: 4px;
          padding: 4px;
          border-radius: 4px;
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover {
            background: var(--n-300);
          }

          i {
            color: var(--n-800);
          }
        }

        .drag-handle {
          cursor: move;
        }
      }
    }
  }
  .step-node-content {
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border: 1px solid var(--n-300);
    background: #eaeaeae0;
    padding: 16px;
    transition: all 0.3s ease;
    border-radius: 8px;
    overflow: hidden;

    &:hover {
      border: 1px solid #1260eb;
      box-shadow: 0px 0px 0px 4.32px #176bff1a;
      background: #eaeaea;
    }

    .step-node-content-inner {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .step-node-dot-wrapper {
        display: flex;
        position: relative;
        margin-right: auto;
      }

      .step-node-poke-group {
        display: flex;

        .cursor-wrapper {
          display: flex;
          position: relative;
          margin-right: auto;
          margin-bottom: auto;
        }

        .step-node-poke-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          &.is-cursor {
            margin-top: 33px;
            margin-left: -7px;
          }

          .step-node-poke-render {
            overflow: hidden;
            transform: scale(1);
            transition: all 0.2s ease;

            &:not(.auto-height) {
              .jimo-poke {
                height: 100%;
              }
            }
          }
        }
      }
    }

    .step-node-triggers-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .trigger-wrapper,
      .add-trigger-empty {
        position: relative;
        display: flex;
        align-items: center;
        transition: all 0.3s ease;

        .trigger-icons-wrapper {
          height: 46px;
          position: relative;
          display: flex;
          align-items: center;
          display: flex;
          border-radius: 12px;
          border: 1px solid var(--n-400);
          background: white;
          transition: all 0.2s ease;
          cursor: pointer;
          overflow: hidden;
          margin: auto;
          min-width: 220px;
          flex-grow: 1;

          &.is-invalid {
            border: 1px solid var(--r-400);
            box-shadow: 0px 0px 0px 3px #f9757533;
          }

          &:hover,
          &.is-selected {
            border: 1px solid #1260eb;
            box-shadow: 0px 0px 0px 4px #176bff1a;
          }

          .custom-indicator-label {
            height: 100%;
            min-width: 28px;
            margin-right: 10px;

            .label {
              color: var(--n-700);
              font-family: Montserrat;
              font-size: 12px;
              font-weight: 700;
              display: flex;
              align-items: center;

              i {
                margin: auto;
              }
            }

            .arrow-right {
              border-bottom: solid 23px transparent;
              border-top: solid 23px transparent;
              border-left: solid 10px #ebebeb;
              right: -10px;
            }
          }

          .trigger-icons {
            display: flex;
            padding: 6px;
            gap: 12px;
            align-items: center;
            position: relative;
            flex-grow: 1;

            .condition-icons-wrapper,
            .action-icons-wrapper {
              display: flex;
              gap: 8px;
              align-items: center;
            }

            .condition-icons-wrapper {
              margin: auto;
              flex-grow: 1;
              justify-content: center;
            }

            .action-icons-wrapper {
              margin-left: auto;
              margin-right: 5px;
              min-width: 22px;

              .action-icon {
                height: 24px;
                width: 24px;
                background: var(--b-100);
                color: var(--b-400);

                i {
                  font-size: 16px;
                }
              }
            }

            .condition-icon {
              display: flex;
              height: 32px;
              width: 32px;
              border-radius: 8px;

              &.element {
                background-color: var(--g-100);
              }

              &.input {
                background-color: var(--p-200);
              }

              &.url {
                background-color: var(--b-150);
              }

              &.delay {
                background-color: var(--n-200);
              }

              &.logic-group {
                background-color: var(--o-300);
              }

              i {
                margin: auto;
                font-size: 16px;

                &.isax {
                  font-size: 20px;
                }
              }
            }

            .go-to-icon-wrapper {
              position: absolute;
              height: 18px;
              width: 18px;
              right: -9px;
              background: var(--b-150);
              border-radius: 4px;
              display: flex;
            }
          }
        }

        .dismiss-icon-wrapper,
        .launch-experience-icon-wrapper {
          position: absolute;
          height: 18px;
          width: 18px;
          right: -9px;
          border-radius: 4px;
          display: flex;

          i {
            margin: auto;
            font-size: 12px;
          }
        }

        .dismiss-icon-wrapper {
          background: #ffd7d7;
        }

        .launch-experience-icon-wrapper {
          background: var(--b-150);
        }
      }

      .add-trigger-empty {
        .trigger-icons-wrapper {
          flex-grow: 0;

          .custom-indicator-label {
            i {
              font-size: 16px;
            }
          }

          .content {
            display: flex;
            align-items: center;
            gap: 4px;
            margin: auto;

            i {
              font-size: 12px;
            }
          }
        }
      }

      .add-trigger-btn {
        width: fit-content;
        margin: auto;
      }
    }
  }
}

@keyframes pulseOpacity {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}
