.s-templates {
  display: flex;
  flex: 1 0 0;
  align-items: flex-start;
  align-self: stretch;
  height: 100%;
  overflow: hidden;

  .templates-sidebar {
    background-color: #fff;
  }

  .templates-content-wrapper {
    flex: 1 1 auto;
    height: 100%;
    overflow-y: auto;
    position: relative;
    background-color: var(--b-100);
    display: flex;
    align-items: center;
    flex-direction: column;

    .template-picker-wrapper {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: center;
      flex: 1 1;
      max-width: 1040px;
      width: 100%;
      box-sizing: border-box;

      .template-picker-header {
        padding: 32px 32px;
        padding-bottom: 0;
      }

      .groups-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;
        align-self: stretch;
        padding-top: 16px;
        overflow-y: auto;

        .group {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-self: stretch;

          .group-title {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            align-items: center;
            padding: 0 32px;
          }

          .templates-wrapper {
            padding: 16px 32px;
          }
        }
      }

      .templates-wrapper {
        padding: 32px 32px;
        padding-top: 16px;
        max-width: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(182px, 1fr));
        grid-gap: 20px;
        overflow-y: auto;

        .template-block {
          flex: 1 1 0;
          max-width: 224px;
          height: 220px;
          cursor: pointer;
          overflow: hidden;
          transition: all 200ms ease-in-out 0s;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          border-radius: 16px;
          background: #fff;
          box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.04);
          outline: 2px solid transparent;

          &:hover {
            outline: 2px solid var(--Blue-300, #85b1ff);
            box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
          }

          .template-header {
            height: 120px;
            overflow: hidden;
            flex-shrink: 0;

            .img-wrapper {
              display: flex;
              flex: 1 1 auto;
              min-height: 0px;
              height: 120px;

              img {
                margin: auto;
                position: relative;
                z-index: 1;
                height: 100%;
                width: 100%;
                object-fit: cover;
              }
            }
          }

          .content {
            z-index: 2;
            display: flex;
            padding: 12px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
          }
        }
      }
    }
  }
}
