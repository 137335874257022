.concept-thumbnail-link {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .section-item {
    &.concept-thumbnail-media {
      flex-direction: column;
      gap: 2px;
    }

    .image-input-wrapper {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 12px;
      height: 104px;
      width: 100%;
      display: flex;

      .btn-file {
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background-color: #fff;
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
        position: relative;
        padding: 10px 12px;
        font-weight: 500;
        text-align: center;
        cursor: pointer;
        transition: all 200ms ease;
        margin: auto;

        &:not(.is-uploading):hover {
          background-color: #f7f7f7;
          cursor: pointer;
        }
        &.is-uploading {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f7f7f7;

          .loader {
            margin-right: 12px;
          }
        }
      }

      .file-wrapper {
        flex: 1 auto;
        display: flex;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.05);
        align-items: center;
        padding: 12px 24px;
        color: #9598a9;
        border-radius: 8px;
        gap: 6px;
        justify-content: center;
        position: relative;

        &:hover {
          .btn-delete {
            opacity: 1;
          }
        }

        .file-preview-name-wrapper {
          display: flex;
          align-items: center;
          color: #000;

          img {
            margin-right: 12px;
          }
        }
        i {
          cursor: pointer;
        }

        .btn-delete {
          opacity: 0;
          position: absolute;
          top: 4px;
          right: 4px;
          transition: opacity 300ms ease;
        }
      }
    }
  }

  .recent-uploads-wrapper {
    font-size: 12px;

    .recent-label {
      margin-bottom: 8px;
    }

    .recent-uploads-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;

      .recent-upload {
        height: 56px;
        width: 122px;
        border-radius: 8px;
        border: 2px solid transparent;
        box-sizing: border-box;
        position: relative;

        &.selected {
          border-color: #1260eb !important;

          .icon-wrapper {
            display: flex;
            i {
              color: #1260eb !important;
            }
          }
        }

        &:not(.placeholder):hover {
          border-color: rgba(0, 0, 0, 0.15);

          .icon-wrapper {
            display: flex;
          }
        }

        .recent-upload-placeholder {
          height: 100%;
          width: 100%;
          object-fit: cover;
          background: rgba(0, 0, 0, 0.05);
          border-radius: 8px;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 8px;
          cursor: pointer;
        }

        .icon-wrapper {
          position: absolute;
          height: 16px;
          width: 16px;
          top: -8px;
          right: -8px;
          background: #fff;
          display: none;

          i {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.15);
          }
        }
      }
    }
  }
}
