.modal-tag-manager {
  max-height: 700px;
  overflow-y: scroll;
  max-width: 470px;
  box-sizing: border-box;
  &.has-mode-add-or-edit {
    .inputs-wrapper {
      display: flex;

      .input-name {
        flex: 1 0 auto;
      }
      .input-color {
        flex: 0 0 117px;
        margin-left: 12px;
        &.input-group.labeled .label {
          padding-right: 6px;
          background-color: rgba(0, 0, 0, 0.05);
          display: flex;
          align-items: center;
        }
        input {
          padding-left: 6px;
          border-left: none;
        }
        .label {
          transition: all 150ms ease-in-out;
        }
        &.disabled {
          .label {
            border-color: transparent;
            background: rgba(0, 0, 0, 0.06) !important;
          }
        }
      }
      .preview-color {
        display: block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
      }
    }
    .picker-popover {
      right: 0;
      top: 56px;
      z-index: 10;
    }
    .tag-preview {
      position: relative;
      height: 134px;
      background-image: url(./imgs/edit_preview_bg.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 16px;
      margin: 24px 0;
      overflow: hidden;

      .tag-preview-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
      .btn-tag {
        position: relative;
        margin-left: 48px;
        margin-top: 48px;
      }
      p {
        margin-left: 48px;
        margin-top: 8px;
        color: #000;
        font-weight: 500;
        font-size: 24px;
      }
    }

    .editor-language-wrapper {
      margin-bottom: 10px;

      .evolution-editor-language {
        padding: 0;
      }
    }
  }

  .empty {
    text-align: center;
    color: rgba(255, 255, 255, 0.2);
    font-style: italic;
  }
}
