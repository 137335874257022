.poke-block-choice {
  gap: 8px;
  display: flex;
  flex-direction: column;

  &.is-animating-out {
    .option-choice {
      animation: block-choice-option-out 500ms ease-in-out forwards;
      animation-delay: 0ms !important;
    }
  }

  .option-choice {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12); // keep commented in case we want to add auto option
    border-radius: 12px;
    padding: 16px;
    font-size: 20px;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    animation: block-choice-option-in 700ms ease-out 700ms both;
    &.is-animating-out {
      animation: block-choice-option-out 700ms ease-out forwards;
    }
    &:hover,
    &.is-active {
      border-color: rgba(var(--blockChoiceAccentColor), 0.3);
      color: rgba(var(--blockChoiceAccentColor), 1);
      i {
        color: rgba(var(--blockChoiceAccentColor), 0.3);
      }
    }
    &:active {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0);
    }
    &.is-active {
      background-color: rgba(var(--blockChoiceAccentColor), 0.06);
      border: 1px solid rgba(var(--blockChoiceAccentColor), 0.5);
      box-shadow: 0px 0px 0px 1px rgba(var(--blockChoiceAccentColor), 0.5) inset;
      i {
        color: rgba(var(--blockChoiceAccentColor), 1);
      }
    }

    @for $i from 2 through 20 {
      &:nth-child(#{$i}n) {
        animation-delay: #{($i - 1) * 100 + 700}ms;
      }
    }

    i {
      margin-right: 8px;
    }
  }
}

// Compact
.poke-block-choice.compact {
  flex-direction: row;
  flex-wrap: wrap;

  .option-choice {
    padding: 8px 16px;
    border-radius: 21px;
  }
}

@-webkit-keyframes block-choice-option-in {
  0% {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, -20, 0);
  }
  100% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
@keyframes block-choice-option-in {
  0% {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, -20, 0);
  }
  100% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
@-webkit-keyframes block-choice-option-out {
  0% {
    opacity: 1;
    // transform: matrix(1, 0, 0, 1, 0, 0);
  }
  100% {
    opacity: 0;
    // transform: matrix(1, 0, 0, 1, 50, 0);
  }
}
@keyframes block-choice-option-out {
  0% {
    opacity: 1;
    // transform: matrix(1, 0, 0, 1, 0, 0);
  }
  100% {
    opacity: 0;
    // transform: matrix(1, 0, 0, 1, 50, 0);
  }
}
