.s-settings-installation-identification {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .settings-header {
    display: flex;
    justify-content: space-between;

    .header-actions {
      display: flex;
      gap: 16px;

      .btn.is-tertiary {
        color: var(--g-300) !important;
        font-weight: 500;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .settings-card {
    a {
      color: var(--b-400);
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }

  code {
    background-color: unset;
    color: unset;
  }

  .card-identify-verification {
    display: flex;
    flex-direction: column;

    .card-header {
      display: flex;
      gap: 16px;

      .left-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    .card-body {
      display: flex;
      gap: 16px;
      margin-top: 24px;

      .left-wrapper {
        flex: 1;
      }
    }
  }

  .card-command-identify {
    display: flex;
    flex-direction: column;

    .card-header {
      display: flex;
      gap: 16px;
      margin-bottom: 24px;

      .left-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    .card-body {
      pre {
        background-color: var(--n-800) !important;
        border-radius: 12px;
        padding: 24px !important;
        max-height: 320px;
        overflow-y: auto;
        margin: 0;
      }
    }
  }

  .card-command-attributes {
    display: flex;
    flex-direction: column;

    .card-header {
      display: flex;
      gap: 16px;
      margin-bottom: 24px;

      .left-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;
      gap: 24px;

      pre {
        background-color: var(--n-800) !important;
        border-radius: 12px;
        padding: 24px !important;
        max-height: 320px;
        overflow-y: auto;
        margin: 0;
      }

      .command-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
  }
}
