.jimo-toast-container {
  width: unset !important;
  max-width: 500px;
  &.Toastify__toast-container--bottom-center {
    .jimo-toast-wrapper {
      margin-bottom: 16px;
      //     min-height: 0;
      //     padding: 0;
      //     border-radius: 8px;
      //     border-bottom-right-radius: 0;
      //     border-bottom-left-radius: 0;
    }
  }
}

.jimo-toast-wrapper.Toastify__toast {
  padding: unset;
  box-shadow: 0px 12px 38px 0px rgba(0, 0, 0, 0.15);
  min-height: unset;
  border-radius: 6px;
  min-width: 360px;
  color: #000;
  font-family: 'Montserrat';
  background-color: #fff;
  border-radius: 8px;

  &.toast--info {
    background-color: #fff;
    border: 2px solid var(--n-300);

    .icon-wrapper {
      color: var(--n-700);
      font-size: 24px;
      transform: rotate(180deg);
    }
  }
  &.toast--success {
    background-color: var(--g-100);
    border: 2px solid var(--g-300);

    .icon-wrapper {
      color: var(--g-500);
      font-size: 24px;
    }
  }
  &.toast--danger {
    background-color: var(--r-100);
    border: 2px solid var(--r-200);

    .icon-wrapper {
      color: var(--r-400);
      font-size: 24px;
    }
  }
  &.toast--warning {
    background-color: var(--o-100);
    border: 2px solid var(--o-300);

    .icon-wrapper {
      color: var(--o-500);
      font-size: 24px;
    }
  }

  .toast-content {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    max-width: 430px;
    color: #000;
    font-family: 'Montserrat', sans-serif;
    border-radius: 8px;

    .main-wrapper {
      display: grid;
      grid-template-columns: 50px calc(100% - 100px) 50px;
      justify-content: center;
      align-items: center;
      &.is-multi-line {
        .left-element {
          padding-top: 12px;
          align-self: flex-start;
        }
        .right-element {
          align-self: flex-start;
          padding-top: 6px;

          i {
            margin-top: 0px;
          }
        }
      }
    }

    .left-element {
      display: flex;
      justify-content: center;

      .icon-wrapper {
        font-size: 24px;
      }
    }
    .center-element {
      padding: 12px 16px;
      padding-left: 0;
      padding-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .second-line {
        margin-top: 8px;
        font-weight: 500;
      }
    }
    .right-element {
      font-size: 8px;
      display: flex;
      justify-content: center;
      align-self: flex-start;

      i {
        margin-top: 8px;
        padding: 8px;
        border-radius: 8px;
        transition: all ease 100ms;
        font-size: 12px;
        color: var(--n-700);
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      padding: 0 12px 12px 12px;
      &.has-multiple-actions {
        .toast-action {
          flex: 0;
        }
      }

      .toast-action {
        flex: 1;
        &.btn {
          font-size: 14px;
          padding: 8px;
          border-radius: 12px;
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.06);
          border: 1px solid rgba(0, 0, 0, 0.1);
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: 14px;
          }
        }
      }
    }
  }
}
