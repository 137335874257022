.select-type-attribute {
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: 0 12px;

  .icon-wrapper {
    height: 24px;
    width: 24px;
    border-radius: 8px;
    display: flex;
    margin-right: 8px;
    flex-shrink: 0;

    i {
      font-size: 16px;
      margin: auto;
    }

    .jimo-icon {
      width: 16px;
      height: 16px;
    }
  }

  .content {
    min-width: 0;

    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
