.poke-audience-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f7f9fe;

  &.is-empty {
    display: flex;
    align-items: center;
    justify-content: center;

    .poke-audience {
      display: flex;
    }
  }

  .loader-wrapper {
    display: flex;
    flex: 1 auto;
    align-items: center;
    justify-content: center;
  }

  .poke-audience {
    overflow-y: auto;
    flex: 1 auto;
    align-items: center;
    padding: 32px;

    .poke-audience-customize {
      display: flex;
      align-items: center;
      max-width: 1084px;
      margin: 0 auto;
      margin-bottom: 16px;

      .poke-audience-apply-settings-btn,
      .poke-audience-apply-settings-dropdown {
        margin-left: auto;
        height: 46px;
      }

      .poke-audience-apply-settings-dropdown {
        width: 256px;

        .jimo-select__menu-list {
          max-height: 284px;
        }

        ::-webkit-scrollbar {
          width: 0;
        }
      }

      .jimo-select {
        .jimo-select__control {
          height: 46px;
        }

        .option-wrapper {
          display: flex;
          align-items: center;
          gap: 8px;

          i {
            font-size: 20px;
            color: var(--b-400);
          }
        }

        .value-container {
          display: flex;
          flex: unset;
          align-items: center;
          gap: 8px;
          color: var(--n-800);
          height: 100%;
          width: 100%;
          top: 0;
          padding: 8px 12px;
          cursor: text;

          .placeholder {
            pointer-events: none;
          }

          .img-wrapper {
            display: flex;

            i {
              font-size: 20px;
            }
          }

          .jimo-select__input {
            width: 100%;
          }
        }
      }
    }

    .divider {
      display: flex;
      max-width: 1042px;
      padding: 0 22px;
      margin: 0 auto;
      margin-bottom: 24px;
    }
  }
}
