.intercom-help-section {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .section {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .section-resources {
    .section-content {
      display: flex;
      gap: 16px;
    }
  }
  .section-disconnect {
    .btn {
      justify-content: center;
    }
  }
}
