.block-settings.visual-cue {
  .visual-cue-offset {
    display: flex;
    gap: 8px;

    .input-group {
      flex: 1 1 0px;
      min-width: 0;
    }
  }
}
