.modal-plan-preview {
  max-height: 90%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 440px;
  box-sizing: border-box;
  overflow: hidden;
  &.plan-startup {
    .animation-wrapper {
      iframe {
        top: -200px;
        right: -420px;
        width: 200%;
        height: 500px;
      }
    }

    .plan-features {
      .item-feature {
        i {
          color: var(--g-400);
        }
      }
    }
  }
  &.plan-growth {
    .animation-wrapper {
      iframe {
        top: -220px;
        right: -350px;
        width: 200%;
        height: 500px;
        transform: rotate(-200deg);
      }
    }

    .plan-features {
      .item-feature {
        i {
          color: var(--o-400);
        }
      }
    }
  }
  &.plan-scale {
    .animation-wrapper {
      iframe {
        top: -360px;
        right: -360px;
        width: 200%;
        height: 500px;
        transform: rotate(-200deg);
      }
    }

    .plan-features {
      .item-feature {
        i {
          color: var(--b-400);
        }
      }
    }
  }

  .animation-wrapper {
    iframe {
      position: absolute;
    }
  }

  .btn-plan-preview-close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 3;
  }

  .modal-main {
    padding: 24px;
    padding-top: 96px;
    padding-bottom: 12px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    overflow-y: auto;
    & > div:not(:first-child) {
      z-index: 3;
    }

    @media screen and (max-height: 700px) {
      padding-bottom: 24px;
    }

    .background-gradient-wrapper {
      position: absolute;
      height: 220px;
      width: 100%;
      top: 0;
      left: 0;
      overflow: hidden;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;

      .background-gradient {
        margin-left: auto;
        margin-right: auto;
        width: 316px;
        height: 262px;
        border-radius: 315px;
        filter: blur(50px);
        top: -60%;
        position: relative;
      }
    }
    .btn {
      width: 100%;
      justify-content: center;
      position: relative;
    }
  }

  .plan-logo {
    width: 96px;
    flex: 0 0 96px;
    background-size: cover;
    background-position: center;
  }

  .plan-title {
    text-align: center;
  }

  .plan-description {
    text-align: center;
  }

  .plan-features {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-right: auto;
    margin-top: 12px;

    .item-feature {
      display: flex;
      gap: 14px;

      i {
        color: var(--g-400);
        position: relative;
        top: 4px;
        font-size: 16px;
      }
    }
  }

  .modal-footer {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
    padding: 16px 24px;
    flex: 0 1;
    @media screen and (max-height: 700px) {
      border-top: 1px solid var(--n-300);
    }

    .btn {
      justify-content: center;
    }
    .label-no-credit-card {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;

      i {
        font-size: 20px;
      }
    }
  }
}
