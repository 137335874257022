.modal-jimer-profile {
  margin: 8px;
  right: 0 !important;
  top: 0 !important;
  left: unset !important;
  position: absolute;
  height: calc(100% - 16px);
  width: 656px !important;
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 12px;
  overflow: hidden;
}

.jimer-profile-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;

  .jimer-profile {
    min-height: 0;
    display: flex;
    flex-direction: column;
    padding: 0;
    flex-grow: 1;

    .jimer-banner {
      position: relative;

      .jimer-banner-img {
        width: 100%;
      }

      .avatar-img-wrapper {
        position: absolute;
        margin-right: 16px;
        height: 84px;
        width: 84px;
        left: 24px;
        bottom: -15px;

        .avatar {
          height: 84px;
          width: 84px;
        }
      }
    }

    .jimer-header {
      display: flex;
      margin-bottom: 24px;
      padding: 24px;
      padding-bottom: 0;

      .back-btn {
        background-color: #f4f4f4;
        height: 40px;
        width: 40px;
        border-radius: 12px;
        padding: 0;
        margin: auto 0;
        margin-right: 16px;

        &:hover {
          background-color: #dddddd;
        }

        i {
          margin: auto !important;
          color: #000000;
        }
      }

      .jimer-details {
        align-self: center;

        .username {
          display: flex;
          margin-bottom: 4px;
          font-weight: 500;
          font-size: 16px;
        }

        .email {
          color: rgba(0, 0, 0, 0.5);
        }
      }

      .last-seen {
        margin-left: auto;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);

        i {
          margin-right: 4px;
          font-size: 16px;
        }
      }

      .jimer-tags-wrapper {
        margin-left: auto;
      }
    }

    .segments {
      display: flex;
      gap: 8px;

      .attributes-btn {
        padding: 6px 12px;
        border-radius: 26px;
        font-weight: 600;
        font-size: 14px;

        i {
          font-size: 8px;
          margin-left: 8px;
        }
      }

      .segment {
        padding: 6px 12px;
        border-radius: 26px;
        font-weight: 500;
      }
    }

    .tabs {
      .tab-list {
        padding-left: 24px;
        margin-bottom: 0;

        .tab-list-item {
          margin: 0 12px;
          position: relative;

          &.coming-soon {
            &::after {
              position: absolute;
              white-space: nowrap;
              background-color: rgb(255, 242, 0);
              border-radius: 4px;
              padding: 4px 4px;
              left: 50%;
              z-index: 2;
              top: 8px;
              font-size: 6px;
              text-transform: uppercase;
              font-weight: 800;
              content: 'Coming soon';
              display: block;
              color: #000;
            }
          }
        }
      }
    }
  }

  .loader-wrapper {
    margin: auto;
  }

  .empty-state-wrapper {
    flex: 1 auto;
    display: flex;

    .activity-empty-state {
      margin: auto;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
