.environment-selector-wrapper-content {
  width: 300px;

  .dropdown-content {
    border-radius: 16px;

    .environment-selector-content {
      display: flex;
      flex-direction: column;

      .environment-selector-header {
        padding: 12px;
        padding-bottom: 0;
      }

      .environment-selector-center {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        padding: 12px;

        .environment-option {
          display: flex;
          gap: 12px;
          align-items: center;
          padding: 8px;
          align-self: stretch;
          border-radius: 8px;
          cursor: pointer;

          &:not(.disabled) {
            &:hover,
            &.selected {
              background: var(--b-100);

              .environment-name {
                color: var(--b-400);
              }
            }

            &.selected {
              .environment-checkbox {
                i {
                  color: var(--b-400);
                }
              }
            }
          }

          &.disabled {
            cursor: default;

            &.selected {
              .environment-checkbox {
                i {
                  color: var(--n-400);
                }
              }
            }
          }

          .environment-icon {
            display: flex;
            height: 40px;
            width: 40px;
            border-radius: 12px;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            flex-shrink: 0;

            &.all-domains {
              border-radius: 8px;
              border: 1px solid var(--n-400);
              box-sizing: border-box;
            }
          }

          .environment-info {
            display: flex;
            flex-direction: column;
            gap: 4px;
            flex-grow: 1;
            min-width: 0;

            .environment-name,
            .environment-domains {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .environment-checkbox {
            flex-shrink: 0;

            i {
              font-size: 20px;
              color: var(--n-400);
            }
          }
        }

        .no-environments {
          display: flex;
          flex-direction: column;
          gap: 8px;
          align-items: center;
          text-align: center;
          padding: 12px;
          background: var(--b-100);
          align-self: stretch;
          border-radius: 12px;

          img {
            height: 50px;
          }
        }
      }

      .environment-selector-footer {
        display: flex;
        flex-direction: column;
        border-top: 1px solid var(--n-300);
        padding: 12px;
        gap: 12px;

        .domains-list-wrapper {
          display: flex;
          gap: 8px;

          .icon-wrapper {
            i {
              align-content: center;
              font-size: 16px;
            }
          }

          .domains-list {
            display: flex;
            flex-direction: column;
            gap: 2px;
            min-width: 0;

            .domain {
              &.disabled {
                color: var(--n-400);
                text-decoration: line-through;
              }
            }
          }

          .chevron-wrapper {
            margin-left: auto;
            margin-bottom: auto;
            transition: transform 0.2s;
            cursor: pointer;

            &.is-open {
              transform: rotate(180deg);
            }
          }
        }

        .update-btn {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}

.environment-selector-btn {
  border-radius: 12px !important;
  background: white !important;

  &:hover,
  &.is-open {
    border-color: var(--b-400) !important;
    box-shadow: 0px 0px 0px 4px #1260eb33;

    .label {
      color: var(--b-400);
    }
  }
  .icons-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    position: relative;

    .icon-wrapper {
      display: flex;
      height: 20px;
      width: 20px;
      border-radius: 6px;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      border: 2px solid white;
      position: absolute;
      z-index: 3;

      &.index-1 {
        left: 10px;
        z-index: 2;
      }

      &.index-2 {
        left: 10px;
        z-index: 1;
      }
    }
  }
}
