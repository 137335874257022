.color-picker-input {
  cursor: pointer;
  position: relative;

  .label-left {
    .preview-color {
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
    }
  }

  &.labeled-right {
    .input {
      border-radius: 0;
      border-right: 0;
      padding-left: 0;
      transition: none;
    }

    .label-right {
      border-radius: 0 12px 12px 0;
      padding: 8px;
      color: rgba(0, 0, 0, 0.2);
      font-size: 12px;
      display: flex;
      transition: none;

      i {
        margin: auto;
        padding: 6px;
        cursor: pointer;
      }
    }
  }
}

.color-picker-popup-content {
  .sketch-picker {
    width: 100% !important;
    padding: 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    border: 0 !important;
    background: none !important;
  }
}
