.tooltip-content {
  &.is-hidden-content {
    display: none;
  }
  background-color: #ffffff;
  padding: 16px;
  color: #000000;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 16px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);

  &.dark-content {
    background: #071331;
    padding: 8px;
    color: #fff;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
  }
}

.tooltip-poke-builder-header-content {
  border: 1px solid #080f25;
  background-color: #080f25;
  font-size: 12px;
  padding: 8px 16px;
  border-radius: 24px;
  color: #fff;
}

.tooltip-auto-save-content {
  border: 1px solid #159201;
  background-color: #159201;
  font-size: 12px;
  padding: 8px 16px;
  border-radius: 24px;
  color: var(--neutral-300, var(--neutral-neutral-300, #e5e5e5));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}
