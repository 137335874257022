.s-settings-themes {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .settings-header {
    display: flex;
    justify-content: space-between;

    .header-actions {
      display: flex;
      gap: 12px;

      .btn-smart-theme {
        i.icon-ai {
          font-size: 18px;
          color: var(--p-400);
        }
      }
    }
  }

  .settings-themes-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;

    .item-theme {
      background-color: #fff;
      border-radius: 16px;
      padding: 16px;
      box-shadow: 0px 0px 94px 0px rgba(0, 0, 0, 0.04);

      .theme-colors {
        display: grid;
        grid-template-columns: auto 20% 20% 20%;
        gap: 8px;
        margin-bottom: 18px;

        .theme-color {
          border-radius: 16px;
          border: 1px solid var(--n-300);
          height: 48px;
        }
      }
      .theme-content {
        display: flex;
        justify-content: space-between;
      }
      .theme-infos-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .theme-name {
          display: flex;
          gap: 8px;
          align-items: center;

          .label-default {
            border-radius: 16px;
            padding: 4px 8px;
            background-color: var(--b-100);
            color: var(--b-400);
            font-weight: 500;
          }
        }
      }
      .theme-actions {
        .btn-theme-options {
          padding: 12px 16px;
        }
      }
    }
  }
}

.dropdown-theme-actions-content {
  .dp-menu {
    .dp-menu-item {
      i {
        width: 18px;
        text-align: center;
      }
    }
  }
}
