.comment-tab {
  border-radius: 16px;
  flex: 0 1;
  display: flex;
  flex-direction: column;

  .comment-list-wrapper {
    background: var(--white);
    flex: 1;
    overflow: auto;
    gap: 24px;

    .comment-count {
      opacity: 0.8;
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      i {
        font-size: 18px;
        margin-right: 8px;
      }
    }

    .comment-content-wrapper {
      .card {
        display: block;
        border: 1px solid var(--border-minimal, var(--n-300));

        i {
          color: black;
          font-size: 14px;
        }
        .message {
          color: black;
        }
      }
    }
  }
  .comment-respond-wrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    width: calc(100% - 48px);
    background-color: white;
    padding: 16px 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    animation: 500ms fade-in-top;

    input {
      background-color: rgb(242 242 242);
      box-shadow: none;
    }
  }
  .empty-state-comments {
    .subtitle {
      text-align: center;
      margin: 32px;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

