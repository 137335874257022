.position-in-element-picker {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background: var(--n-50);
  border: 1px solid var(--n-300);
  flex-grow: 1;

  .position-in-element-row {
    display: flex;
    gap: 16px;
    flex-grow: 1;

    .position-in-element-item {
      border-radius: 8px;
      height: 24px;
      flex: 1 1 0px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: var(--n-100);
      }

      &.selected {
        .position-in-element-item-dot {
          height: 12px;
          width: 12px;
          background: var(--b-400);
        }
      }

      .position-in-element-item-dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: var(--n-300);
        margin: auto;
      }
    }
  }
}
